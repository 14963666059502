export default [
    {
        id: 1,
        title: "Ajwain Seeds",
        price: 90.0,
        img: require("./assets/Herbs & Spices/ajwain-seeds-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green Roots Ajwain (Bishops weed) is a hheighterb which deals with many health issues and skin issues. Can fight against bacteria and fungus.',
        SKU: 'SKU',
        SKU1: 'GR002HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' ajwain, cholesterol control, healthy breathing, healthy metabolism, menstruation cycle natural remedy, seed, sweight loss',
        Benefits: 'Benefits',
        Benefits1: 'Prevents coughing and opens respiratory passage',
        Benefits2: 'Induces menstrual cycle and relieves pain ',
        Benefits3: 'Fights against ulcer and constipation',
        Benefits4: 'Improves cholesterol level',
        Benefits5: 'Lowers Blood pressure',
        Benefits6: 'Helps in weight loss',
        Benefits7: 'Improves metabolism',
        Usage:'Usage',
        Usage1:'Drinking tea of Ajwain helps relieve in menstrual cramps and relieves cough.',
        Usage2:'Daily intake of 1 teaspoon of Ajwain helps in improving metabolism, lowers cholesterol, helps maintain weight and helps against ulcer and constipation.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '55 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    {
        id: 2,
        title: "Ajwain Seeds",
        price: 180.0,
        img: require("./assets/Herbs & Spices/ajwain-seeds-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Ajwain (Bishops weed) is a herb which deals with many health issues and skin issues. Can fight against bacteria and fungus.',
        SKU: 'SKU',
        SKU1: 'GR002HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' ajwain, cholesterol control, healthy breathing, healthy metabolism, menstruation cycle natural remedy, seed, sweight loss',
        Benefits: 'Benefits',
        Benefits1: 'Prevents coughing and opens respiratory passage',
        Benefits2: 'Induces menstrual cycle and relieves pain ',
        Benefits3: 'Fights against ulcer and constipation',
        Benefits4: 'Improves cholesterol level',
        Benefits5: 'Lowers Blood pressure',
        Benefits6: 'Helps in weight loss',
        Benefits7: 'Improves metabolism',
        Usage:'Usage',
        Usage1:'Drinking tea of Ajwain helps relieve in menstrual cramps and relieves cough.',
        Usage2:'Daily intake of 1 teaspoon of Ajwain helps in improving metabolism, lowers cholesterol, helps maintain weight and helps against ulcer and constipation.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '55 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },


    {
        id: 3,
        title: "Amla Powder (Indian Gooseberry)",
        price: 200.0,
        img: require("./assets/Herbs & Spices/amla-powder-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        
        dis: 'Amla powder is made from the ground-up leaves of the Indian gooseberry. Itâ€™s been used in Ayurvedic medicine for centuries to treat everything from diarrhea to jaundice. Also, beneficial for eyesight, skin health, constipation, diabetes, anaemia, nervous system health and prevention and cure from a lot of other diseases and conditions.',
        SKU: 'SKU',
        SKU1: 'GR003HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' amla, diabetes cure, hair strengthening, heart health, immune booster, indian gooseberry, nerve health, powder, skin care',
        Benefits: 'Benefits',
        Benefits1: 'Improves eye vision',
        Benefits2: 'Improves skin health',
        Benefits3: 'Prevents ageing',
        Benefits4: 'Increases immunity',
        Benefits5: 'Improves hair health',
        Benefits6: 'Prevents constipation',
        Benefits7: 'Improves nervous system health',
        Benefits8: 'Improves memory and brain health',
        Benefits9: 'Protects and cures diabetes',
        Benefits10: 'Improves cardiovascular health',
        Benefits11: 'Prevents anemia',
        Usage:'Usage',
        Usage1:'By mixing 2 teaspoons of Amla Powder with 2 teaspoons of honey will help you get immediate results with sore throat and cold.',
        Usage2:'Take a cup of water and mix it with half teaspoon of Amla Powder can heal mouth ulcer and pain by gargling with this mixture.',
        Usage3:'Mix Amla Powder in Henna and apply on the hair makes your hair nourish and helps slowing down greying of hair, also it gives shine to your hairs.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Amla',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '120 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 4,
        title: "Asgandh Nagori Powder",
        price: 800.0,
        img: require("./assets/Herbs & Spices/asgandh-nagori-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: '(Indian Ginseng Powder) Asgandh Nagori Powder (Indian ginseng powder) is effective in regulating sleep, easing stress and combating depression. Boosts testosterone in men , helps in recovering of joint pains and boosts anti-ageing.',
        SKU: 'SKU',
        SKU1: 'GR004HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' anti ageing, anti anxiety, anti depression, asgandh nagori, fertility booster, immune booster, indian ginseng powder, nerve health, sleep cycle, testosterone booster',
        Benefits: 'Benefits',
        Benefits1: 'Calms the nervous system',
        Benefits2: 'Regulates sleep',
        Benefits3: 'Eases stress',
        Benefits4: 'Helps overcome laziness',
        Benefits5: 'Helps boost fertility',
        Benefits6: 'Helps combat depression',
        Benefits7: 'Reduces anxiety',
        Benefits8: 'Boosts testosterone in men',
        Benefits9: 'Boosts immunity',
        Benefits10: 'Boosts recovery of joint pains',
        Benefits11: 'Boosts anti-ageing',
        Usage:'Usage',
        Usage1:'It can be consumed directly with water by taking one (01) teaspoon of the Asgandh Nagori powder. Or by converting it into capsules.',
        Usage2:'TFor joints and muscles pain take one (01) teaspoon Asgandh Nagori powder daily with milk.',
        Usage3:'For diabetes, take one (01) or half (1/2) teaspoon of Asgandh Nagori powder.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 5,
        title: "Chia Seeds",
        price: 400.0,
        img: require("./assets/Herbs & Spices/chia-seeds-herbs-50-1-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        
        dis: 'Green Roots Chia seeds contain large amounts of fiber and omega 3 fatty acids, plenty of high quality protein and several essential minerals and antioxidants. Which are good for skin, and health.',
        SKU: 'SKU',
        SKU1: 'GR005HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' anti inflammation, bone health, chia, heart health, seeds, skin tightening, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Lowers risk of heart diseases',
        Benefits2: 'Helps in weight loss',
        Benefits3: 'Helps reduce inflammation',
        Benefits4: 'Good for skin tightening',
        Benefits5: 'Gives nutrition to the bones',
        Benefits6: 'FRIENDLY TO USE IN DAILY DIET',
        Usage:'Usage',
        Usage1:'In a glass of water add 1 tablespoon of chia seeds, leave it overnight drink early morning. Same procedure applied for night use, which helps reduce fats from body.',
        Usage2:'Adding in protein shakes or healthy meals gives energy to body and bones.',
        Usage3:'Mixing two tablespoons of chia seeds with a half cup of coconut oil and a tablespoon of lemon juice, let this mask be on your face for 10 to 112 minutes, it will form a gel-like layer, follow this with massaging your face in circular motion for few minutes, which helps to exfoliate your skin and wash with Lukewarm water.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    

    {
        id: 6,
        title: "Chia Seeds",
        price: 800.0,
        img: require("./assets/Herbs & Spices/chia-seeds-herbs-50-1-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Chia seeds contain large amounts of fiber and omega 3 fatty acids, plenty of high quality protein and several essential minerals and antioxidants. Which are good for skin, and health.',
        SKU: 'SKU',
        SKU1: 'GR005HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' anti inflammation, bone health, chia, heart health, seeds, skin tightening, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Lowers risk of heart diseases',
        Benefits2: 'Helps in weight loss',
        Benefits3: 'Helps reduce inflammation',
        Benefits4: 'Good for skin tightening',
        Benefits5: 'Gives nutrition to the bones',
        Benefits6: 'FRIENDLY TO USE IN DAILY DIET',
        Usage:'Usage',
        Usage1:'In a glass of water add 1 tablespoon of chia seeds, leave it overnight drink early morning. Same procedure applied for night use, which helps reduce fats from body.',
        Usage2:'Adding in protein shakes or healthy meals gives energy to body and bones.',
        Usage3:'Mixing two tablespoons of chia seeds with a half cup of coconut oil and a tablespoon of lemon juice, let this mask be on your face for 10 to 112 minutes, it will form a gel-like layer, follow this with massaging your face in circular motion for few minutes, which helps to exfoliate your skin and wash with Lukewarm water.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 7,
        title: "Dhamasa Booti",
        price: 120.0,
        img: require("./assets/Herbs & Spices/dhamsa-booti-herbs-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: '(Fagonia Arabica) Dhamasa Booti is a single herbal medicine and very effective on general health issues i.e cancers, hepatitis, heart disorders. Along with that it also works on curing asthma, managing weight, skin problems, gum problems, allergies and blood purification.',
        SKU: 'SKU',
        SKU1: 'GR008HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti allergy, antioxidant, blood purifier, cancer beneficent, dhamsa booti, fertility booster, gum health, hepatitis cure, painkiller, skin health, smallpox cure',
        Benefits: 'Benefits',
        Benefits1: 'Beneficial for cancers, specially blood cancer and liver cancer',
        Benefits2: 'Cures hepatitis, smallpox, reproductive issues',
        Benefits3: 'Beneficial for heart, skin, gums, kidneys',
        Benefits4: 'Works as a painkiller',
        Benefits5: 'Helps fight against allergies, blood pressure',
        Benefits6: 'Antioxidant',
        Benefits7: 'Helps cure asthma',
        Benefits8: 'Helps manage weight.',
        Usage:'Usage',
        Usage1:'Prepare a cup of Dhamasa tea by putting one teaspoon in a cup of water and boil for couple of minutes util its color turns light brown.',
        Usage2:'Taking it twice a day helps in curing breast cancer and pain relieve.',
        Usage3:'Soak 3 tablespoons of Dhamasa into 2 glass of water leave it overnight. Boil it in the morning to reduce half of the liquid and drink it as tea, twice a day for cancer preventing and pain relieving.',
        Usage4:'For dizziness, consume 3 tablespoons of Dhamasa Booti with ghee twice a day.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Dhamsa',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 8,
        title: "Flax Seeds",
        price: 90.0,
        img: require("./assets/Herbs & Spices/flax-seed-50-gram-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green Roots Flax Seeds (alsi) is full of nutrients and high in proteins. It is high in omega 3, reduces the risk of cancer and helps improves cholesterol. Helps control blood sugar and maintaining and losing weight. Flax seeds can be mixed with anything easily to add in daily use.',
        SKU: 'SKU',
        SKU1: 'GR009HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' blood pressure natural medicin, ecancer beneficent, cholesterol control, diabetes cotrol, fiber boostflax, natural protein, omega 3, seeds, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Full of nutrients',
        Benefits2: 'High in omega 3',
        Benefits3: 'Reduces the risk of cancer',
        Benefits4: 'Rich in dietary fiber',
        Benefits5: 'Helps improves cholesterol',
        Benefits6: 'Reduces blood pressure',
        Benefits7: 'High in proteins',
        Benefits8: 'Helps control blood sugar',
        Benefits9: 'Helps in maintaining and losing weight',
        Benefits10: 'Can be mixed with anything easily to add in daily use.',
        Usage:'Usage',
        Usage1:'To make a weight loss drink out of flax seeds, take one (01) teaspoon of flax seed powder and mix it in a glass of boiled water, add a little amount of fresh lemon and drink daily for visible results.',
        Usage2:'To make a healthy supplement for hair and skin take one (01) teaspoon of Flax Seeds Powder and one (01) teaspoon of Pumpkin Seeds powder â€“ take about one (01) teaspoon of the mixture daily with milk or water.',
        Usage3:'Flax seeds are filled with protein and can be sprinkled on smoothies or fruit salads.',
        Usage4:'By taking one (01) or half (1/2) teaspoon of Flax Seeds powder with water helps in diabetes control',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
       
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',  
    },

    {
        id: 9,
        title: "Flax Seeds",
        price: 180.0,
        img: require("./assets/Herbs & Spices/flax-seed-50-gram-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        
        dis: 'Green Roots Flax Seeds (alsi) is full of nutrients and high in proteins. It is high in omega 3, reduces the risk of cancer and helps improves cholesterol. Helps control blood sugar and maintaining and losing weight. Flax seeds can be mixed with anything easily to add in daily use.',
        SKU: 'SKU',
        SKU1: 'GR009HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' blood pressure natural medicin, ecancer beneficent, cholesterol control, diabetes cotrol, fiber boostflax, natural protein, omega 3, seeds, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Full of nutrients',
        Benefits2: 'High in omega 3',
        Benefits3: 'Reduces the risk of cancer',
        Benefits4: 'Rich in dietary fiber',
        Benefits5: 'Helps improves cholesterol',
        Benefits6: 'Reduces blood pressure',
        Benefits7: 'High in proteins',
        Benefits8: 'Helps control blood sugar',
        Benefits9: 'Helps in maintaining and losing weight',
        Benefits10: 'Can be mixed with anything easily to add in daily use.',
        Usage:'Usage',
        Usage1:'To make a weight loss drink out of flax seeds, take one (01) teaspoon of flax seed powder and mix it in a glass of boiled water, add a little amount of fresh lemon and drink daily for visible results.',
        Usage2:'To make a healthy supplement for hair and skin take one (01) teaspoon of Flax Seeds Powder and one (01) teaspoon of Pumpkin Seeds powder â€“ take about one (01) teaspoon of the mixture daily with milk or water.',
        Usage3:'Flax seeds are filled with protein and can be sprinkled on smoothies or fruit salads.',
        Usage4:'By taking one (01) or half (1/2) teaspoon of Flax Seeds powder with water helps in diabetes control',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        Ingredients:'Ingredients',
        Ingredients1:'Dhamsa',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },
]