export default [
  {
    id: 1,
    title: "Allergy Cure",
    price: 2000.0,
    img: require("./assets/Medicine/allergy-cure-greenrootspk.com_.png"),
    quantity: 1,
    gram: '140 Grams',

    dis: 'Green Roots Allergy Cure is an effective medicine for most commonly found allergy problems, including skin allergy, blood allergy, and asthma. It also treats acne and pimples.',
    SKU: 'SKU',
    SKU1: 'GR002MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' allergy herbal cureallergy herbal medicineallergy medicineallergy natural cureallergy natural medicineallergy remedy',
    Benefits: 'Benefits',
    Benefits1: 'Helps fight all sorts of blood allergies.',
    Benefits2: 'Helps purify blood.',
    Benefits3: 'Helps clear acne and acne related problems',
    Usage: 'Usage',
    Usage1: 'Take 1/2 teaspoon after breakfast and dinner with water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Ingredients',
    Ingredients2: 'Gul gaozaban',
    Ingredients3: 'Gul mandi',
    Ingredients4: 'Saunf',
    Ingredients5: 'Mulethi',
    Ingredients6: 'Unaab',
    Ingredients7: 'Gul e benafsha',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place.',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: 'Weight',
    Weight1: '175 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '140 Grams',
  },

  {
    id: 2,
    title: "Acidity Medicine",
    price: 1800.0,
    img: require("./assets/Medicine/acidity-medicine-greenrootspk.com_.png"),
    quantity: 1,
    gram: '140 Grams',

    dis: 'Green Roots Acidity is a solution to all stomach problems.',
    SKU: 'SKU',
    SKU1: 'GR001MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'acidity cure, acidity herbal cure, acidity herbal remedy, acidity medicine, acidity natural cure, acidity natural medicine',
    Benefits: 'Benefits',
    Benefits1: 'Helps cure H-Pylori.',
    Benefits2: 'Prevents bloating.',
    Benefits3: 'Helps cure ulcers.',
    Benefits4: 'Helps fight gases',
    Benefits5: 'Helps fight all sort of stomach problems.',
    Benefits6: 'Helps against burning in the chest.',
    Benefits7: 'Helps cure acidity.',
    Usage: 'Usage',
    Usage1: 'Take 1/2 teaspoon before breakfast and before dinner with plain water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Choti Elaichi',
    Ingredients2: 'Mint',
    Ingredients3: 'Saunf',
    Ingredients4: 'Zeera Safed',
    Ingredients5: 'Tripla',
    Ingredients6: 'Dhania',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: ' Weight',
    Weight1: '175 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '140 grams',
  },


  {
    id: 3,
    title: "Cardium 90",
    price: 1400.0,
    img: require("./assets/Medicine/cardium-90-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Cardium-90 is a herbal medicine for blood pressure patients that controls excessive stress level and makes blood vessels strong.',
    SKU: 'SKU',
    SKU1: 'GR003MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' blood pressure natural medicine, BP herbal cure, bp natural cure, bp natural medicine, high blood pressure, high blood pressure natural remedy',
    Benefits: 'Benefits',
    Benefits1: 'Medicine for high Blood Pressure.',
    Benefits2: 'Not to be taken without Doctor’s prescription.',
    Benefits3: 'Controls high blood pressure.',
    Benefits4: '100% natural.',
    Usage: 'Usage',
    Usage1: 'For normal patients: 1/2 teaspoon before breakfast and 1/2 teaspoon before dinner. Both to be consumed with Lukewarm water.',
    Usage2: 'For acute patients: Two (02) teaspoon before breakfast, Half (1/2) teaspoon before lunch and dinner. All to be consumed with lukewarm water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Rauwolfia',
    Ingredients2: 'Fennel seeds',
    Ingredients3: 'Knot grass',
    Ingredients4: 'Senna leaves',
    Ingredients5: 'Saffron',
    Ingredients6: 'Burada aaj',
    Ingredients7: 'Mirabilis Jalapa',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: 'Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 Grams',
  },


  {
    id: 4,
    title: "Clear Vision",
    price: 1400.0,
    img: require("./assets/Medicine/clear-vision-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Clear Vision improves eyesight and helps in reducing dryness, itching and excessive watering of eyes. It prevents increased cloudiness of eyes by improving the vision. It also soothes eyes hence eliminating irritation generally produced due to repeated usage of cellphones, computers and Television.',
    SKU: 'SKU',
    SKU1: 'GR004MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' eyesight cure, eyesight herbal cure, eyesight herbal remedy, eyesight natural medicine, eyesight natural remedy, vision cure',
    Benefits: 'Benefits',
    Benefits1: 'Beneficial for kids, adults, and old people for improving eyesight.',
    Benefits2: 'Beneficial for people who use glasses.',
    Benefits3: 'Enhances weak eyesight.',
    Benefits4: 'Beneficial to protect eyesight from decreasing with age.',
    Benefits5: 'Beneficial for people with weak eyesight as well as normal eyesight.',
    Benefits6: 'Helps with watery eyes.',
    Benefits7: 'Helps clear eye vision.',
    Benefits8: 'Prevents “Motiya” production.',
    Usage: 'Usage',
    Usage1: '(For Children) Half Teaspoon before/after meal in the morning and night with milk',
    Usage2: '(For Adults) One Teaspoon before/after meal in the morning and night with milk',
    Ingredients: 'Ingredients',
    Ingredients1: 'Sucrose',
    Ingredients2: 'Coriander seeds',
    Ingredients3: 'Fennel (Saunf)',
    Ingredients4: 'Almonds',
    Ingredients5: 'Cumin seeds',
    Ingredients6: 'Black pepper',
    Special: 'Special Instructions',
    Special1: 'NOT SUITABLE FOR DIABETIC AND PREGNANT / NURSING MOTHERS.',
    Special2: 'AVOID Exposure to Moisture.',
    Special3: 'STORE Medicines In a Cool, Dry Place',
    Special4: 'READ Medicine Labels and Instructions Carefully.',
    Weight: ' Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },


  {
    id: 5,
    title: "Cycle Disorder",
    price: 1200.0,
    img: require("./assets/Medicine/cycle-disorder-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Cycle Disorder is helpful in issues related with Menstruation (or periods).',
    SKU: 'SKU',
    SKU1: 'GR005MD',
    Category: 'Medicine',
    Tags: ' Tags',
    Tags1: ' menstruation cycle herbal cure, menstruation cycle natural medicine, menstruation cycle natural remedy, mensuration issuesperiod cycle cure, period cycle natural medicine, period cycle natural remedy, period issues',
    Benefits: 'Benefits',
    Benefits1: 'Helpful for irregularities in the period cycle',
    Benefits2: 'Helpful for women suffering with PCOs',
    Benefits3: 'Helpful for hormonal imbalances.',
    Usage: 'Usage',
    Usage1: 'Take 1 teaspoon with water before breakfast. In case of discontinued periods from 4 to 6 months, it should also be taken at night as well before sleeping.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Kalonji',
    Ingredients2: 'Ajwain',
    Ingredients3: 'Methi dana',
    Ingredients4: 'Sabut Ispaghol',
    Ingredients5: 'Mint',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    Weight: ' Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },


  {
    id: 6,
    title: "Height Plus",
    price: 1200.0,
    img: require("./assets/Medicine/height-plus-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Height Plus Medicine is the best formula to increase height at an ideal point.',
    SKU: 'SKU',
    SKU1: 'GR008MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' height enhancement medicine, height herbal medicine, height increase medicine, height medicine, height natural medicine, short height cure, short height remedy',
    Benefits: 'Benefits',
    Benefits1: 'Beneficial for girls from 10 years till 24 years',
    Benefits2: 'Beneficial for boys from 10 years till 24 years.',
    Benefits3: 'Helps strengthen bones',
    Benefits4: 'Provides calcium and Vitamin D.',
    Benefits5: 'Helps overcome weaknesses.',
    Usage: 'Usage',
    Usage1: 'Take 1/2 (half) teaspoon twice a day with milk or plain water.(morning and night).',
    Ingredients: 'Ingredients',
    Ingredients1: 'Black sesame',
    Ingredients2: 'Almonds',
    Ingredients3: 'Misri',
    Ingredients4: 'Asgand nagori',
    Ingredients5: 'Qast e shireen',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'NOT SUITABLE FOR PEOPLE OVER 24 YEARS.',
    SpecialInstructions2: 'AVOID Exposure to Moisture.',
    SpecialInstructions3: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions4: 'READ Medicine Labels and Instructions Carefully.',
    Weight: 'Weight',
    Weight1: '230 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },


  {
    id: 7,
    title: "Insulinia Plus",
    price: 1200.0,
    img: require("./assets/Medicine/insulina-plus-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Insulinia Plus maintains your blood sugar level and treats glucose tolerance well. The most special herb used in this medicine called Gymnema Sylvestre (known as sugar destroyer) that increases the beta-cell function especially among type-2 sugar patients.',
    SKU: 'SKU',
    SKU1: 'GR009MD',
    Category: 'Medicine',
    Tags: ' Tags',
    Tags1: ' blood sugar, insulin cure, insulin herbal cure, insulin herbal medicine, insulin herbal remedy, insulin natural cure, insulin natural medicine, insulin natural remedy',
    Benefits: 'Benefits',
    Benefits1: 'Helps to maintain the blood sugar level..',
    Benefits2: 'Increases the beta-cell functions in the body.',
    Usage: 'Usage',
    Usage1: 'For Type 1: Take One (01) teaspoons before breakfast and One (01) tablespoon before dinner.',
    Usage2: 'For Type 2: Take One (01) teaspoon three times a day before meal.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Gymnema sylvestre',
    Ingredients2: 'Cinquefoils',
    Ingredients3: 'Bitter Oleander',
    Ingredients4: 'Butterstick',
    Ingredients5: 'Gentian',
    Ingredients6: 'Black plum seeds.',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    Weight: 'Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },


  {
    id: 8,
    title: "Jawarish E Sakoon",
    price: 1800.0,
    img: require("./assets/Medicine/jawarish-e-sukoon-greenrootspk.com_.png"),
    quantity: 1,
    gram: '250 Grams',


    dis: 'Green Roots Jawarish-e-Sakoon is a natural cure for problems like ulcer, piles, constipation, acidity, irregular heartbeat and gastric problems.',
    SKU: 'SKU',
    SKU1: 'GR010MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'constipation remedy, piles cure, stomach health, stomach ulcer, ulcer treatment',
    Benefits: 'Benefits',
    Benefits1: 'Treatment for Ulcers',
    Benefits2: 'Treatment for Piles',
    Benefits3: 'Beneficial for constipation.',
    Usage: 'Usage',
    Usage1: 'One (01) tablespoon with hot milk or water before sleeping at night',
    Ingredients: 'Ingredients',
    Ingredients1: 'Harh Murabba',
    Ingredients2: 'Gajar Murabba',
    Ingredients3: 'Saib Murabba',
    Ingredients4: 'Amla Murabba',
    Ingredients5: 'Gule Surkh',
    Ingredients6: 'Elaichi Khurd',
    Ingredients7: 'Sonth (zanjabeel)',
    Ingredients8: 'Sana Makhi',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'NOT SUITABLE FOR PREGNANT / NURSING MOTHERS.',
    Weight: 'Weight',
    Weight1: '265 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '250 grams',
  },


  {
    id: 9,
    title: "Joint Cure",
    price: 3400.0,
    img: require("./assets/Medicine/joint-cure-greenrootspk.com_.png"),
    quantity: 1,
    gram: '250 Grams',

    dis: 'Green Roots Joint Cure strengthen your bones and possesses Anti inflammatory properties that is a complete cure to the back and joint pains. It can also be used for muscles pain and arthritis',
    SKU: 'SKU',
    SKU1: 'GR011MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' arthritis cure, arthritis herbal cure, arthritis natural cure, bone strength, calcium supplement, muscle strength, vitamin d',
    Benefits: 'Benefits',
    Benefits1: 'Strengthen bones and muscles.',
    Benefits2: 'Treats arthritis.',
    Benefits3: 'Makes the bones strong.',
    Benefits4: 'Treats the deficiency of Vitamin D in bones.',
    Benefits5: 'Gives Calcium to bones.',
    Benefits6: 'Used for all types of muscles and bones pain.',
    Usage: 'Usage',
    Usage1: 'Half (1/2) teaspoon before breakfast and half (1/2) teaspoon after dinner with plain water or milk.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Asgandh Nagori',
    Ingredients2: 'Musli Safaid',
    Ingredients3: 'Qast E Shireen',
    Ingredients4: 'Sonth',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: 'Weight',
    Weight1: '175 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '140 grams',
  },
]