import React from 'react';


import "./Header.css";

function Header() {

  return (
    <header className='header_1'>
    <div className='navbarr ' >
   
  </div>
  </header>
  )
}

export default Header