export default [
  {
    id: 1,
    title: "Aloe Vera Oil",
    price: 1000.0,
    img: require("./assets/Essential Oil/aloe-vera-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml:'30 ML',
    dis:'Green Roots Aloe Vera Oil helps in skin rejuvenating and repairs damaged skin cells. It is also beneficial for hair cells and healthy hair growth.',
    SKU:'SKU',
    SKU1:'GR002OL', 
    Category:'Essential Oils',
    Tags:'Tags:',
    Tags1:'aloe vera oil, anti ageing, anti dandruff, essential oil, hair growth, hair nourishment, skin care, skin cell, repair skin lightening skin nourishment',
    Benefits:'Benefits',
    Benefits1:'Helps repairing skin cells',
    Benefits2:'Has great anti-ageing properties.',
    Benefits3:'Beneficial in removing stretch marks and wrinkles',
    Benefits4:'Heals dark spot on the skin and helps in dry skin issues.',
    Benefits5:'Makes hair healthy, strong and dandruff free.',
    Usage:'Usage',
    Usage1:'By mixing Aloe Vera oil with any carrier oil, for example, with coconut or Jojoba oil and applying on skin will help treat and heal damaged skin, lightens skin tone, and help in removing fine lines.',
    Usage2:'By applying the mixture onto your hair scalp and all over the hair helps repair damaged hair.',
    Usage3:'It also prevents hair loss and dandruff and helps in healthy hair growth.',
    Weight:'Weight',
    Weight1:'80 g',
    dicQuantity:'Quantity',
    dicQuantity1:'30 ml',

  },
  {
    id: 2,
    title: "Almond Oil",
    price: 1600.0,
    img: require("./assets/Natural Oil/almond-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Almond Oil is a good source to give nourishment to the hair, helps in hair growth. Almond oil is a good moisturizer for skin and body. Also good for anti Aging.',
    SKU: 'SKU',
    SKU1: 'GR001OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' almond oil, constipation cure, hair nourishment, hair repair, natural oil, skin lightening, skin nourishment, skin whitening, vitamin E',
    Benefits: 'Benefits',
    Benefits1: 'Rich in vitamin E which helps in whitening of skin.',
    Benefits2: 'Good moisturizer for skin.',
    Benefits3: 'Gives nourishment to damaged hairs.',
    Benefits4: 'Helps in relieving constipation by taking once in a day with milk. ',
    Usage: 'Usage',
    Usage1: 'Apply on your hair on alternate days for good nourishment of the hair.',
    Usage2: 'Apply on face and body after wash and also at night before sleep.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 3,
    title: "Cardium 90",
    price: 1400.0,
    img: require("./assets/Medicine/cardium-90-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Cardium-90 is a herbal medicine for blood pressure patients that controls excessive stress level and makes blood vessels strong.',
    SKU: 'SKU',
    SKU1: 'GR003MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' blood pressure natural medicine, BP herbal cure, bp natural cure, bp natural medicine, high blood pressure, high blood pressure natural remedy',
    Benefits: 'Benefits',
    Benefits1: 'Medicine for high Blood Pressure.',
    Benefits2: 'Not to be taken without Doctor’s prescription.',
    Benefits3: 'Controls high blood pressure.',
    Benefits4: '100% natural.',
    Usage: 'Usage',
    Usage1: 'For normal patients: 1/2 teaspoon before breakfast and 1/2 teaspoon before dinner. Both to be consumed with Lukewarm water.',
    Usage2: 'For acute patients: Two (02) teaspoon before breakfast, Half (1/2) teaspoon before lunch and dinner. All to be consumed with lukewarm water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Rauwolfia',
    Ingredients2: 'Fennel seeds',
    Ingredients3: 'Knot grass',
    Ingredients4: 'Senna leaves',
    Ingredients5: 'Saffron',
    Ingredients6: 'Burada aaj',
    Ingredients7: 'Mirabilis Jalapa',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: 'Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 Grams',
  },
  {
    id: 4,
    title: "Acne Clear Face Wash",
    price: 1000.0,
    img: require("./assets/Health & Beauty/acne-clear-face-wash-greenrootspk.com_.png"),
    quantity: 1,
    ml:'200 ML',
    dis:'Green Roots Acne Clear Face Wash not only removes dirt and oil but also helps in treating acne from the first wash. It deeply cleanses the skin and prevents future breakouts, leaving the skin whiter and brighter. Skin becomes clear with continuous use.',
    SKU:'SKU',
    SKU1:'GR001HB',
    Category:'Health & Beauty',
    Tags:'Tags',
    Tags1:'acne facewashanti acne facewashanti  bacterial facewashfacewashoily skin careskin nourishing facewash',
    Benefits:'Benefits',
    Benefits1:'Suitable for oily skin.',
    Benefits2:'Helps fight against Acne.',
    Benefits3:'Reduces production of bacteria on the skin.',
    Benefits4:'Prevents the skin from drying and peeling.',
    Benefits5:'Speeds healing.',
    Benefits6:'Decreases the formation of pimples.',
    Usage:' Usage',
    Usage1:'Wash your face twice a day. (To be used daily)',
    Ingredients:'Ingredients',
    Ingredients1:'Fuller’s earth clay',
    Ingredients2:'Orange Peel',
    Ingredients3:'Wild Turmeric',
    Ingredients4:'Rose Whitener',
    Ingredients5:'Glycerin',
    Ingredients6:'Vitamin E',
    Ingredients7:'Tea Tree Oil',
    Weight:'Weight',
    Weight1:'245 g',
    dicQuantity1:'200 mL',
    dicQuantity:'Quantity',
  },
  {
    id: 5,
    title: "Ajwain Seeds",
    price: 90.0,
    img: require("./assets/Herbs & Spices/ajwain-seeds-greenrootspk.com_.png"),
    quantity: 1,
    gram: '50 Grams',

    dis: 'Green Roots Ajwain (Bishops weed) is a herb which deals with many health issues and skin issues. Can fight against bacteria and fungus.',
    SKU: 'SKU',
    SKU1: 'GR002HS',
    Category: 'Herbs & Spices',
    Tags: 'Tags',
    Tags1: ' ajwain, cholesterol control, healthy breathing, healthy metabolism, menstruation cycle natural remedy, seed, sweight loss',
    Benefits: 'Benefits',
    Benefits1: 'Prevents coughing and opens respiratory passage',
    Benefits2: 'Induces menstrual cycle and relieves pain ',
    Benefits3: 'Fights against ulcer and constipation',
    Benefits4: 'Improves cholesterol level',
    Benefits5: 'Lowers Blood pressure',
    Benefits6: 'Helps in weight loss',
    Benefits7: 'Improves metabolism',
    Usage:'Usage',
    Usage1:'Drinking tea of Ajwain helps relieve in menstrual cramps and relieves cough.',
    Usage2:'Daily intake of 1 teaspoon of Ajwain helps in improving metabolism, lowers cholesterol, helps maintain weight and helps against ulcer and constipation.',
    Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
    SpecialInstructions:'Special Instructions',
    SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
    SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
    Weight: ' Weight',
    Weight1: '55 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '50 grams',
},
{
  id: 6,
  title: "Eucalyptus Oil",
  price: 1500.0,
  img: require("./assets/Essential Oil/euclyptus-oil-greenrootspk.com_.png"),
  quantity: 1,
  ml:'30 ML',
  dis:'Green Roots eucalyptus oil is useful in a lot of ways, among many usages it heals wounds, helps reduce mental exhaustion, relieves muscle pain and treats fever.',
  SKU:'SKU',
  SKU1:'GR015OL', 
  Category:'Essential Oils',
  Tags:'Tags:',
  Tags1:'anti lice, diabetes control, essential oil, eucalyptus oil, healing, healthy teeth, intestinal germs, menstrual energy, muscle relaxant, pain reliever, respiratory issues cure.',
  Benefits:'Benefits',
  Benefits1:'Heals wounds',
  Benefits2:'Removes mental exhaustion',
  Benefits3:'Good for dental care',
  Benefits4:'Removes intestinal germs',
  Benefits5:'Treats fever',
  Benefits6:'Treats respiratory problems', 
  Benefits7:'Relieves muscle pains',
  Benefits8:'Removes lice',
  Benefits9:'Manages diabetes',
  Usage:'Usage',
  Usage1:'Can be used to take steam (few drops)',
  Usage2:'Can be used in a warm bath/shower',
  Usage3:'Can be inhaled',
  Usage4:'Can be used as a painkiller massage oil',
  Usage5:'Can be used on skin mixed with any other carrier oil (12 drops for one ounce of carrier oil)',
  Usage6:'Can be used for hair mixed with any carrier oil.',
  Weight:'Weight',
  Weight1:'75 g',
  dicQuantity:'Quantity',
  dicQuantity1:'30 ml',

},


{
  id: 7,
  title: "Wheat Germ Oil",
  price: 2400.0,
  img: require("./assets/Essential Oil/wheat-germ-oil-greenrootspk.com_.png"),
  quantity: 1,
  ml:'30 ML',
  dis:'(Vitamin E Oil) Green Roots Wheatgerm Oil, also known worldwide as vitamin E oil, richest source of Vitamin E, with multiple health benefits, Energy boosting qualities as well as helps slowing the process of ageing',
  SKU:'SKU',
  SKU1:'GR062OL', 
  Category:'Essential Oils',
  Tags:'Tags:',
  Tags1:'anti ageing, anti hair fall, birth defect prevention, blood sugar control, cholesterol control, eczema relief, energizing, essential oil, healthy brain, vitamin e oil, wheat germ oil.',
  Benefits:'Benefits',
  Benefits1:'Lowers LDL cholesterol.',
  Benefits2:'Boosts energy capacity',
  Benefits3:'Slows down ageing',
  Benefits4:'Prevents hair fall',
  Benefits5:'Reduces stress levels',
  Benefits6:'Works as a remedy for eczema',
  Benefits7:'Regulates Nervous System',
  Benefits8:'Promotes Blood Sugar Level',
  Benefits9:'Fights Fat Accumulation',
  Benefits10:'Prevents Birth Defects',
  Usage:'Usage',
  Usage1:'Massaging the oil on the body alleviates pain, pressure, hence greatly uplifting memory and positively influencing mood.',
  Usage2:'Applying wheat germ oil on hair is a magical solution to attain smooth, soft, silky tresses.',
  Usage3:'Just a few drops of this miraculous tincture is enough, to decrease free radical damage to skin cells, thus diminishing signs of ageing like wrinkles, fine lines and giving a youthful, unblemished appearance.',
  Usage4:'Add wheat germ oil to your diet to get all the benefits mentioned above. You can also add it in the following ways:',
  Usage5:'By baking in cookies, breads, waffles, etc.',
  Usage6:'By sprinkling it over ice creams, soups, yogurt, salads, or cereals.',
  Weight:'Weight',
  Weight1:'80 g',
  dicQuantity:'Quantity',
  dicQuantity1:'30 ml',
 
},


{
  id: 8,
  title: "Tea Tree Oil",
  price: 1900.0,
  img: require("./assets/Essential Oil/tea-tree-oil-greenrootspk.com_.png"),
  quantity: 1,
  ml:'30 ML',
  dis:'Green Roots Tea Tree essential Oil is used typically for infections such as acne, fungal infections of nails, lice, scabies, and athletes foots.',
  SKU:'SKU',
  SKU1:'GR059OL', 
  Category:'Essential Oils',
  Tags:'Tags:',
  Tags1:'anti bacterial, essential oil, hair nourishment, healing, healthy scalp, inflammation remedy, ringworm cure, skin care, sore relief, tea tree oil, yeast infection cure.',
  Benefits:'Benefits',
  Benefits1:'Acts as a healing ointment',
  Benefits2:'Soothes inflamed skin',
  Benefits3:'Effective against acne',
  Benefits4:'Helps relieve sore',
  Benefits5:'Beneficial for ringworm and athlete’s feet',
  Benefits6:'Improves hair and scalp health',
  Benefits7:'Kills bacteria',
  Benefits8:'Removes yeast infections',
  Usage:'Usage',
  Usage1:'Can be used on wounds directly (few drops)',
  Usage2:'Can be added to a cup of water (2-3 drops) and used as a mouthwash',
  Usage3:'Can be applied to the skin mixed with any carrier oil',
  Usage4:'Can be applied for dandruff (5% of tea tree oil mixed with shampoo per wash)',
  Usage5:'For healthy hair, mix with any carrier oil (ratio 1-10) and apply.',
  Ingredients:'Ingredients',
  Ingredients1:'Pure Tea Tree Extract',
  Weight:'Weight',
  Weight1:'80 g',
  dicQuantity:'Quantity',
  dicQuantity1:'30 ml',

},


{
  id: 9,
  title: "Sandalwood Oil",
  price: 9500.0,
  img: require("./assets/Essential Oil/sandalwood-oil-1-greenrootspk.com_.png"),
  quantity: 1,
  ml:'15 ML',
  dis:'Sandalwood oil is believed to be beneficial in treating both physical and mental disorders, including anxiety, bronchitis, diarrhea, fatigue, fever, gallbladder problems, high blood pressure, indigestion, insomnia, liver problems, low libido, sore throat, and urinary tract infections.',
  SKU:'SKU',
  SKU1:'GR054OL', 
  Category:'Essential Oils',
  Tags:'Tags:',
  Tags1:'alertness, anti anxiety, anti bacterial, cold cure, digestion issues cure, essential oil, gallbladder treatment, hair growth, healing, hemorrhoids cure, liver treatment, muscle issues cure, sandalwood oil, scabies cure, scalp nourishment, scar remover, skin cancer protection, skin nourishment, UTI beneficent.',
  Benefits:'Benefits',
  Benefits1:'Increase alertness.',
  Benefits2:'Manages anxiety.',
  Benefits3:'Supports wound healing.',
  Benefits4:'Guards against skin cancer.',
  Benefits5:'Fights bacteria.',
  Benefits6:'Beneficial for common cold',
  Benefits7:'Beneficial for urinary tract infections',
  Benefits8:'Treats liver and gallbladder problems',
  Benefits9:'Treats digestive problems',
  Benefits10:'Beneficial for muscle problems',
  Benefits11:'Works for mental disorders', 
  Benefits12:'Helps treat hemorrhoids',
  Benefits13:'Helps treat scabies',
  Benefits14:'Stimulates the roots, improves hair growth, and increases circulation in the scalp.',
  Benefits15:'Softens the skin',
  Benefits16:'Sandalwood reduces the appearance of bruises and scars',
  Usage:'Usage',
  Usage1:'Apply it directly to your skin',
  Usage2:'Put a few drops in your lotion',
  Usage3:'Heat it in a small kettle of water to scent your home',     
  Usage4:'Evaporate it using an essential oil diffuser',
  Usage5:'Add it to your bath water',
  Usage6:'Apply sandalwood essential oil on your face and massage gently. Leave it on overnight and wash with lukewarm water the next morning. (For skin softening)',
  Ingredients:'Ingredients',
  Ingredients1:'Pure Sandalwood Extract',
  Weight:'Weight',
  Weight1:'80 g',
  dicQuantity:'Quantity',
  dicQuantity1:'15 ml',

},
]