export default [
  {
    id: 1,
    title: "Almond Oil",
    price: 1600.0,
    img: require("./assets/Natural Oil/almond-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Almond Oil is a good source to give nourishment to the hair, helps in hair growth. Almond oil is a good moisturizer for skin and body. Also good for anti Aging.',
    SKU: 'SKU',
    SKU1: 'GR001OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' almond oil, constipation cure, hair nourishment, hair repair, natural oil, skin lightening, skin nourishment, skin whitening, vitamin E',
    Benefits: 'Benefits',
    Benefits1: 'Rich in vitamin E which helps in whitening of skin.',
    Benefits2: 'Good moisturizer for skin.',
    Benefits3: 'Gives nourishment to damaged hairs.',
    Benefits4: 'Helps in relieving constipation by taking once in a day with milk. ',
    Usage: 'Usage',
    Usage1: 'Apply on your hair on alternate days for good nourishment of the hair.',
    Usage2: 'Apply on face and body after wash and also at night before sleep.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 2,
    title: "Almond Oil",
    price: 2600.0,
    img: require("./assets/Natural Oil/almond-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '200 ML',

    dis: 'Green Roots Almond Oil is a good source to give nourishment to the hair, helps in hair growth. Almond oil is a good moisturizer for skin and body. Also good for anti Aging.',
    SKU: 'SKU',
    SKU1: 'GR001OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' almond oil, constipation cure, hair nourishment, hair repair, natural oil, skin lightening, skin nourishment, skin whitening, vitamin E',
    Benefits: 'Benefits',
    Benefits1: 'Rich in vitamin E which helps in whitening of skin.',
    Benefits2: 'Good moisturizer for skin.',
    Benefits3: 'Gives nourishment to damaged hairs.',
    Benefits4: 'Helps in relieving constipation by taking once in a day with milk. ',
    Usage: 'Usage',
    Usage1: 'Apply on your hair on alternate days for good nourishment of the hair.',
    Usage2: 'Apply on face and body after wash and also at night before sleep.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '200 mL',
  },
  {
    id: 3,
    title: "Amla Oil (Indian Gooseberry)",
    price: 650.0,
    img: require("./assets/Natural Oil/amla-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Amla Oil (Indian gooseberry oil) is to make hair soft and shiny prevents hair loss gives volume to Hair. Is good to relax your mind by massaging on hair scalp',
    SKU: 'SKU',
    SKU1: 'GR003OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'amla oil, hair growth, hair nourishment, hair repair, indian gooseberry, natural oil, stress reliever',
    Benefits: 'Benefits',
    Benefits1: 'Makes hair soft.',
    Benefits2: 'Helps in growth of hair.',
    Benefits3: 'Used to relax mind when massaged in the hair',
    Usage: 'Usage',
    Usage1: 'Apply on the hair at night wash it in morning.',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 4,
    title: "Anti Lice Oil",
    price: 1500.0,
    img: require("./assets/Natural Oil/anti-lice-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Anti Lice Hair Oil helps you get rid of lice and dandruff. It also makes your hair strong and prevents hair loss.',
    SKU: 'SKU',
    SKU1: 'GR005OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' anti lice, anti nits, natural oil, scalp protection',
    Benefits: 'Benefits',
    Benefits1: 'Removes lice',
    Benefits2: 'Removes nits',
    Benefits3: 'Finishes the development of the eggs which transform into lice and nits in the scalp.',
    Benefits4: 'Prevents lice and nits from being initially born in the hair.',
    Usage: 'Usage',
    Usage1: 'Use Green Roots Anti Lice Oil thrice a week and let it be in your hairs for at least two hours.',
    Ingredients:'Ingredients',
    Ingredients1:'Neem Oil (Margosa Leaves Oil)',
    Ingredients2:'Arugula Oil',
    Ingredients3:'Eucalyptus Oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 5,
    title: "Argan Oil",
    price: 7000.0,
    img: require("./assets/Natural Oil/argan-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Argan Oil is rich in Vitamin E, which helps in Anti-Aging and also used as a hair serum regrowth that makes hair shiny, smooth and strong.',
    SKU: 'SKU',
    SKU1: 'GR006OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti ageing, argan oil, hair nourishment, hair repair, hair strengthening, natural oil, skin care',
    Benefits: 'Benefits',
    Benefits1: 'Makes hair long and thick.',
    Benefits2: 'Protects from sun damage.',
    Benefits3: 'Promotes healthy skin.',
    Benefits4: 'Anti-ageing for skin. ',
    Usage: 'Usage',
    Usage1: 'Apply on the skin massage a little at night and wash in the morning.',
    Usage2: 'Apply on the hair at night wash in the morning.',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 6,
    title: "Castor Oil",
    price: 400.0,
    img: require("./assets/Natural Oil/castor-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Castor Oil helps in hair regrowth. It also works for low density hair by increasing hair volume. Castor Oil relieves constipation.',
    SKU: 'SKU',
    SKU1: 'GR009OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'castor oil, constipation remedy, hair growth, hair nourishment, natural oil, pure oil',
    Benefits: 'Benefits',
    Benefits1: 'Helps in growth of hair.',
    Benefits2: 'Helps in the growth of lashes and eyebrows hair.',
    Benefits3: 'Helps in relieving constipation.',
    Usage: 'Usage',
    Usage1: 'Apply on hair at night wash in morning.',
    Usage2: 'Apply on eyelashes and eyebrows at night before sleep.',
    Usage3: 'Take 1 teaspoon mix with a glass of warm milk before sleep.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 7,
    title: "Castor Oil",
    price: 600.0,
    img: require("./assets/Natural Oil/castor-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '200 ML',

    dis: 'Green Roots Castor Oil helps in hair regrowth. It also works for low density hair by increasing hair volume. Castor Oil relieves constipation.',
    SKU: 'SKU',
    SKU1: 'GR009OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'castor oil, constipation remedy, hair growth, hair nourishment, natural oil, pure oil',
    Benefits: 'Benefits',
    Benefits1: 'Helps in growth of hair.',
    Benefits2: 'Helps in the growth of lashes and eyebrows hair.',
    Benefits3: 'Helps in relieving constipation.',
    Usage: 'Usage',
    Usage1: 'Apply on hair at night wash in morning.',
    Usage2: 'Apply on eyelashes and eyebrows at night before sleep.',
    Usage3: 'Take 1 teaspoon mix with a glass of warm milk before sleep.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '200 mL',
  },
  {
    id: 8,
    title: "Coconut Oil",
    price: 450.0,
    img: require("./assets/Natural Oil/coconut-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Coconut Oil offers natural nourishment to your hair. Is a good Moisturizer to skin and body.',
    SKU: 'SKU',
    SKU1: 'GR012OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'coconut oil, hair fall, hair nourishment, hair repair, hair strength, natural oil, pure oil, skin care, skin moisturizing, skin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Nourishes your hairs.',
    Benefits2: 'Makes your hair shiny and smooth.',
    Benefits3: 'It is a good moisturizer for the skin and body.',
    Benefits4: 'Beneficial for treating hair fall. ',
    Benefits5: 'Usage Apply on hair for treating hair fall',
    Benefits6: 'Apply on skin and body at night to moisturize.',
    Usage: 'Usage',
    Usage1: 'Apply on hair for treating hair fall',
    Usage2: 'Apply on skin and body at night to moisturize.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 9,
    title: "Coconut Oil",
    price: 750.0,
    img: require("./assets/Natural Oil/coconut-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '200 ML',

    dis: 'Green Roots Coconut Oil offers natural nourishment to your hair. Is a good Moisturizer to skin and body.',
    SKU: 'SKU',
    SKU1: 'GR012OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'coconut oil, hair fall, hair nourishment, hair repair, hair strength, natural oil, pure oil, skin care, skin moisturizing, skin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Nourishes your hairs.',
    Benefits2: 'Makes your hair shiny and smooth.',
    Benefits3: 'It is a good moisturizer for the skin and body.',
    Benefits4: 'Beneficial for treating hair fall. ',
    Benefits5: 'Usage Apply on hair for treating hair fall',
    Benefits6: 'Apply on skin and body at night to moisturize.',
    Usage: 'Usage',
    Usage1: 'Apply on hair for treating hair fall',
    Usage2: 'Apply on skin and body at night to moisturize.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '200 mL',
  },
  {
    id: 10,
    title: "Dry Apricot Oil",
    price: 1500.0,
    img: require("./assets/Natural Oil/dry-apricot-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    
    dis: 'Green Roots Dry Apricot Oil is for rejuvenating skin. The Oil can also be used for making hair long and strong. Dry Apricot Oil is very effective when used on the skin for removing dryness.',
    SKU: 'SKU',
    SKU1: 'GR013OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'apricot oilconstipation curedry apricoteven skin tonehair growthhealthy bonesnatural oilpure oilskin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Improves skin tone.',
    Benefits2: 'Lowers appearance of fine lines.',
    Benefits3: 'Enhance bones health.',
    Benefits4: 'Treats constipation. ',
    Benefits5:'Helps in the growth of hair.',
    Usage: 'Usage',
    Usage1: 'Apply on the hair at night before sleep wash in morning for best results.',
    Usage2: 'Apply a little amount of Apricot oil on skin at night wash your face in morning.',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 11,
    title: "Tumba Oil",
    price: 2000.0,
    img: require("./assets/Natural Oil/tumba-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Tumba Oil is effective in controlling diabetes, good for nerve pain in diabetic patients, works for high blood pressure and relieving constipation.',
    SKU: 'SKU',
    SKU1: 'GR060OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'constipation cure, diabetes control, high blood pressure, natural oil, nerve pain relief, pain relief, pure oil, tumba oil',
    Benefits: 'Benefits',
    Benefits1: 'Helps control diabetes',
    Benefits2: 'Good for nerve pain in diabetic patients',
    Benefits3: 'Works for high blood pressure',
    Benefits4: 'Works for constipation',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 12,
    title: "Taramira Oil",
    price: 500.0,
    img: require("./assets/Natural Oil/taramira-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Taramira is used for hair loss, baldness of the hair..',
    SKU: 'SKU',
    SKU1: 'GR058OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti dandruff, anti lice, baldness protection, natural oil, pure oil, taramira oil',
    Benefits: 'Benefits',
    Benefits1: 'Reduces dandruff and lice',
    Benefits2: 'Reduces stress',
    Benefits3: 'Prevents from Baldness',
    Usage: 'Usage',
    Usage1: 'Apply on to hair scalp at night or 2 hours before washing hair.',
    Ingredients:'Ingredients',
    Ingredients1:'Bitter Mustard',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 13,
    title: "Shikakai Oil (Hair Fruit)",
    price: 800.0,
    img: require("./assets/Natural Oil/shikakai-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: '(Acacia Concinna) Green Roots Shikakai Oil is used to make hair shiny, strong and long. Shikakai Oil is also very effective for hair growth.',
    SKU: 'SKU',
    SKU1: 'GR056OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'hair care, hair fruit, hair nourishment, hair strengthening, natural oil, premature hair greying, pure oi, lshikakai oil',
    Benefits: 'Benefits',
    Benefits1: 'Moisturizes the hair',
    Benefits2: 'Prevents split ends',
    Benefits3: 'Reduces hair fall',
    Benefits4: 'Makes hair soft and tangled free',
    Benefits5: 'Prevents hair from greying',
    Usage: 'Usage',
    Usage1: 'Apply on the hair roots and all over the hairs at night or 2 hours before washing hairs.',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 14,
    title: "Sesame Oil",
    price: 550.0,
    img: require("./assets/Natural Oil/sesame-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Sesame Oil 100% pure, lightly filtered, retaining sesame’s aroma, rich flavor and nutrients.',
    SKU: 'SKU',
    SKU1: 'GR055OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'cooking oil, hair growth, hair strength, natural oil, pain relief, pure oil, sesame oil',
    Benefits: 'Benefits',
    Benefits1: 'Makes hair strong and helps in growth.',
    Benefits2: 'Works as a pain reliever oil.',
    Benefits3: 'Used for cooking.',
    Usage: 'Usage',
    Usage1: 'Apply on hair roots at night before sleep.',
    Usage2: 'Massage onto your body to relieve in joint pains',
    Weight: ' Weight',
    Weight1: '215 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 15,
    title: "Sesame Oil",
    price: 900.0,
    img: require("./assets/Natural Oil/sesame-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '200 ML',

    dis: 'Green Roots Sesame Oil 100% pure, lightly filtered, retaining sesame’s aroma, rich flavor and nutrients.',
    SKU: 'SKU',
    SKU1: 'GR055OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'cooking oil, hair growth, hair strength, natural oil, pain relief, pure oil, sesame oil',
    Benefits: 'Benefits',
    Benefits1: 'Makes hair strong and helps in growth.',
    Benefits2: 'Works as a pain reliever oil.',
    Benefits3: 'Used for cooking.',
    Usage: 'Usage',
    Usage1: 'Apply on hair roots at night before sleep.',
    Usage2: 'Massage onto your body to relieve in joint pains',
    Weight: ' Weight',
    Weight1: '215 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '200 mL',
  },
  {
    id: 16,
    title: "Relaxing Massage Oil",
    price: 2000.0,
    img: require("./assets/Natural Oil/relaxing-massage-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Relaxing Massage Oil is the mixture of pure Olive Oil and essential Lavender Oil, which is used as the best healing therapy to relax your body and mind. It simply relieving the stress level of body by calming down the nerves by stabilizing the blood circulation.',
    SKU: 'SKU',
    SKU1: 'GR050OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' massage oil, muscle relaxant, natural oil, nerve relaxant, pain relief, pain reliever, pure oil, relaxant, relaxing oil',
    Benefits: 'Benefits',
    Benefits1: 'Beneficial in relaxing your body.',
    Benefits2: 'Beneficial in relaxing your mind.',
    Benefits3: 'Beneficial in relieving body pain.',
    Usage: 'Usage',
    Usage1: 'Massage on body for 10 to 15 minutes at night.',
    Usage2: 'Can be applied on body and head for 10 to 15 minutes.',
    Ingredients:'Ingredients',
    Ingredients1:'Olive oil',
    Ingredients2:'Lavender oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 17,
    title: "Regrowth Hair Oil",
    price: 2200.0,
    img: require("./assets/Natural Oil/regrowth-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Regrowth Hair Oil.',
    SKU: 'SKU',
    SKU1: 'GR049OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' anti bacterial, anti fungal, anti hair fall, hair growth, hair lengthening, hair repair, hair strengthening, natural oil, pure oil, regrowth oil, scalp nourishment, silky hair',
    Benefits: 'Benefits',
    Benefits1: 'Makes the hair long',
    Benefits2: 'Makes the hair thick',
    Benefits3: 'Increases the volume of the hair',
    Benefits4: 'Increases elasticity in the hair',
    Benefits5: 'Prevents hair falling',
    Benefits6: 'Boosts new hair growth',
    Benefits7: 'Keeps the scalp nourished & hydrated',
    Benefits8: 'Keeps the hair soft and silky',
    Benefits9: 'Anti fungal and anti bacterial properties.',
    Usage: 'Usage',
    Usage1: 'Apply onto the hair scalp leave it overnight or you can also apply two (02) hours before washing hair. Same can be done two or three times in a week for visible results.',
    Ingredients:'Ingredients',
    Ingredients1:'Roghane asthma',
    Ingredients2:'Sahil e nabatati',
    Ingredients3:'Pumpkin seed oil',
    Ingredients4:'Barhami booti',
    Ingredients5:'Bheri ke patey',
    Ingredients6:'Nagar motha',
    Ingredients7:'Sesame oil coconut oil',
    Ingredients8:'Balchar',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 18,
    title: "Premature Hair Greying Oil",
    price: 1700.0,
    img: require("./assets/Natural Oil/premature-hair-grey-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Premature Greying Hair Oil is the mixture of staff Tree Oil, bitter Apple Oil and Ginkgo Biloba Oil, which helps in the treatment of premature greying of hairs. It also nourishes the scalp.',
    SKU: 'SKU',
    SKU1: 'GR047OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti hair greying, hair nourishment, natural oil, premature hair greying, pure oil',
    Benefits: 'Benefits',
    Benefits1: 'Beneficial for reducing the growth of premature grey hair',
    Benefits2: 'Nourishes the hair',
    Usage: 'Usage',
    Usage1: 'Green Roots Premature Greying Hair Oil can be used thrice a week.',
    Ingredients:'Ingredients',
    Ingredients1:'Staff tree oil',
    Ingredients2:'Bitter apple oil',
    Ingredients3:'Ginkgo Biloba oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 19,
    title: "Pumpkin Seed Oil",
    price: 1700.0,
    img: require("./assets/Natural Oil/pumpkin-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Pumpkin seeds Oil is used for hair fall and hair growth. Used as an anti-ageing oil. Improves better sleep',
    SKU: 'SKU',
    SKU1: 'GR048OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti hair fall, hair growth, migraine relief, natural oil, pain reliever, peaceful sleep, pumpkin seed oil, pure oil, skin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Improves skin tone.',
    Benefits2: 'Treats hair loss.',
    Benefits3: 'Helps in hair growth.',
    Benefits4: 'Improves better sleep.',
    Benefits5: 'Helps in reducing migraine.',
    Usage: 'Usage',
    Usage1: 'Apply little amount of pumpkin seeds oil on your face at night before sleeping',
    Usage2: 'Massage pumpkin seeds oil on forehead to help with migraine..',
    Usage3:'Apply onto your hair roots at night before sleep and wash in the morning.',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 20,
    title: "Flax Seed Oil",
    price: 950.0,
    img: require("./assets/Natural Oil/flax-seed-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: '(Alsi Oil) Green Roots Flaxseed Oil (Alsi Seed Oil) is a great source of Omega 3 Fatty Acids, which are vital to a person’s physical and mental health. Used for skin pigmentation. Helps in regrowth of hair and makes hair healthy.',
    SKU: 'SKU',
    SKU1: 'GR017OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti ageing, flax seed, hair growth, natural oil, nerve health, omega 3, pure oil',
    Benefits: 'Benefits',
    Benefits1: 'Rich in omega 3 which helps in physical and mental health.',
    Benefits2: 'Used to make volume to the hairs.',
    Benefits3: 'Good for anti-ageing for skin.',
    Usage: 'Usage',
    Usage1: 'Apply on the hair at night wash in the morning.',
    Usage2: 'Apply on the skin at night wash in the morning.',
    Usage3: 'Take 1 teaspoon daily for healthy skin, hair and body.',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 21,
    title: "Organic Virgin Coconut Oil",
    price: 2300.0,
    img: require("./assets/Natural Oil/Organic-Virgin-Coconut-Oil.jpeg"),
    quantity: 1,
    ml: '260 ML',

    dis: 'Green Roots Organic Virgin Coconut Oil is used for healthy cooking, keeps your weight maintained, and makes hair shiny and strong. It also helps in getting skin allergy reduced. ',
    SKU: 'SKU',
    SKU1: 'GR041OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'alzheimers disease, cholesterol control, coconut oil, hair care, heart health, hunger control, natural oil, omega 3, organic virgin coconut oil, pure oil, seizure control, skin care, teeth care',
    Benefits: 'Benefits',
    Benefits1: 'Contains healthy fatty acids',
    Benefits2: 'Boosts heart health',
    Benefits3: 'Encourages fat burning',
    Benefits4: 'Reduces hunger',
    Benefits5: 'Reduces seizures',
    Benefits6: 'Raises HDL (good) cholesterol',
    Benefits7: 'Protects your skin, hair, and teeth',
    Benefits8: 'Boosts brain function in Alzheimer’s disease',
    Benefits9: 'Helps reduce harmful abdominal fat. ',
    Usage: 'Usage',
    Usage1: 'Used as a cooking oil, add 1 teaspoon in your tea, coffee or water drink in morning to maintain your weight.',
    Usage2: 'Apply on the allergic area of skin helps heal allergy and itching.',
    Weight: ' Weight',
    Weight1: '480 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '260 mL',
  },
  {
    id: 22,
    title: "Pain Killer Massage Oil",
    price: 2000.0,
    img: require("./assets/Natural Oil/pain-killer-massage-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Pain Killer Massage Oil is the mixture of pure Olive and Lemon Grass Oil, which is the best remedy to treat muscle and joints pain. It also relaxes the body and quickly heals the pain.',
    SKU: 'SKU',
    SKU1: 'GR042OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'joint pain relief, massage oil, muscle relaxant, natural oil, pain killer, pain relief, pure oil',
    Benefits: 'Benefits',
    Benefits1: 'Helps in relieving joints and muscles pain.',
    Benefits2: 'It relaxes the body',
    Benefits3: 'Quickly heals the pain.',
    Usage: 'Usage',
    Usage1: 'Massage on body at night before sleep.',
    Ingredients:'Ingredients',
    Ingredients1:'Olive Oil',
    Ingredients2:'Lemongrass Oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 23,
    title: "Onion Oil",
    price: 3600.0,
    img: require("./assets/Natural Oil/onion-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Onion oil is rich in sulfur which prevents breakage, split ends and thinning of hair. Onion oil will also ensure that you have a shiny and healthy hair. Onion oil nourishes your scalp and can be used as a conditioner, before your shampoo. It acts as a natural conditioner that prevents dryness and controls frizz. It’s highly beneficial for premature greying of the hair, as well as prevents certain types of cancers and is beneficial for diabetes, allergies, brain health and other health related problems.',
    SKU: 'SKU',
    SKU1: 'GR039OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'alopecia cure, baldness remedy, blood circulation, blood sugar control, brain health, eye health, hair nourishment, healthy breathing, natural oil, onion oil, oral health, premature hair greyin, gpure oil, skin nourishment, vitamin a, vitamin c, vitamin E',
    Benefits: 'Benefits',
    Benefits1: 'Prevents and treats baldness',
    Benefits2: 'Prevents breakage, split ends and thinning of hair & premature greying',
    Benefits3: 'Good for oily and acne prone skin',
    Benefits4: 'Rich in vitamin A, C and E',
    Benefits5: 'Improves blood circulation',
    Benefits6: 'Fights dandruff',
    Benefits7: 'Nourishes hair follicles',
    Benefits8: 'Prevents neck and head cancer',
    Benefits9: 'Promotes respiratory health',
    Benefits10: 'Good for oral health',
    Benefits11: 'Enhances brain health',
    Benefits12: 'Lowers blood sugar levels',
    Benefits13: 'Enhances eye health',
    Benefits14: 'Prevents inflammation and allergies.',
    Benefits15: 'Beneficial for alopecia & asthma',
    Usage: 'Usage',
    Usage1: 'You can warm onion oil mixed with coconut oil and massage it all over your scalp and length. Keep it overnight and then rinse it well with organic shampoo.',
    Usage2:'For faster results, onion hair oil should be used twice daily (once in the morning and once in the evening) for several weeks',
    Usage3:'It’s great for flavoring scrambled eggs, cooked meats, salad dressings',
    Usage4:'Applying onion juice to the hair and scalp could increase blood supply to hair follicles, which in turn improves hair growth.',
    Usage5:'Applying gel containing onion extract to the skin, alone or with other ingredients, for at least 10 weeks improves the appearance of scars.',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 24,
    title: "Olive Oil",
    price: 750.0,
    img: require("./assets/Natural Oil/olive-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Olive Oil can be used for hair, body and face and also by taking Olive Oil constipation can be relieved. Olive Oil works as one of the best massage oil, which helps in relieving body pain and stress.',
    SKU: 'SKU',
    SKU1: 'GR038OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'constipation cure, massage oil, natural oil, olive oil, pure oil, skin care, skin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Is a great natural moisturizer for body and skin.',
    Benefits2: 'Helps relieve constipation.',
    Benefits3: 'It works as a best massage oil.',
    Usage: 'Usage',
    Usage1: 'Mix few drops of lemongrass essential oil with pure olive oil and massage on body will help reduce muscles and joints pain.',
    Usage2: 'Take 1 teaspoon of olive oil with warm milk at night helps you relieve in constipation.',
    Usage3: 'Apply on hair to nourish hair.',
    Weight: ' Weight',
    Weight1: '215 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 25,
    title: "Olive Oil",
    price: 1250.0,
    img: require("./assets/Natural Oil/olive-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '200 ML',

    dis: 'Green Roots Olive Oil can be used for hair, body and face and also by taking Olive Oil constipation can be relieved. Olive Oil works as one of the best massage oil, which helps in relieving body pain and stress.',
    SKU: 'SKU',
    SKU1: 'GR038OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'constipation cure, massage oil, natural oil, olive oil, pure oil, skin care, skin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Is a great natural moisturizer for body and skin.',
    Benefits2: 'Helps relieve constipation.',
    Benefits3: 'It works as a best massage oil.',
    Usage: 'Usage',
    Usage1: 'Mix few drops of lemongrass essential oil with pure olive oil and massage on body will help reduce muscles and joints pain.',
    Usage2: 'Take 1 teaspoon of olive oil with warm milk at night helps you relieve in constipation.',
    Usage3: 'Apply on hair to nourish hair.',
    Weight: ' Weight',
    Weight1: '215 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '200 mL',
  },
  {
    id: 26,
    title: "Hair Growth Oil",
    price: 1400.0,
    img: require("./assets/Natural Oil/hair-growth-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '250 ML',

    dis: 'Green Roots Hair Growth Oil is the perfect mixture of few of the best treatment oils that helps in getting the hair growth maintained. It also makes your hair long, healthy and strong. Hair Growth Oil also makes hair silky, shiny and smooth while at the same time prevents hair loss.',
    SKU: 'SKU',
    SKU1: 'GR021OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' anti dandruff, anti hair fall, anti hair loss, hair growth, hair growth oil, hair nourishment, hair repair, hair strength, natural oil, pure oil, scalp conditioner, scalp nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Stimulates new hair growth and makes hair long & healthy.',
    Benefits2: 'Stops hair breakage & hair fall and restores hair from root to tip.',
    Benefits3: 'Makes hair shiny & smooth.',
    Benefits4: 'Prevents premature gray hair and reduces baldness.',
    Benefits5: 'Complete scalp treatment',
    Benefits6: 'Eliminates dandruff, flaky scalp & dryness.',
    Usage: 'Usage',
    Usage1: 'Green Roots Hair Growth Oil should be used at night at least thrice a week.',
    Ingredients:'Ingredients',
    Ingredients1:'Pumpkin Seed Oil',
    Ingredients2:'Jasmine Oil',
    Ingredients3:'Coconut Oil',
    Weight: ' Weight',
    Weight1: '250 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '250 mL',
  },
  {
    id: 27,
    title: "Kalonji (Nigella Sativa) Oil",
    price: 1200.0,
    img: require("./assets/Natural Oil/kalonji-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    
    dis: 'Green Roots Kalonji Oil is the solution to many hair, skin and body problems. As it is said by the Prophet Muhammad (peace be upon him) that ‘there is healing in black cumin (Kalonji) for all diseases except death’.(Sahih Bukhari).',
    SKU: 'SKU',
    SKU1: 'GR026OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti hair fall, cancer prevention, ear pain relief, fever relief, hair growth, immune booster, kalonji, natural oil, nigella sativa, pure oil',
    Benefits: 'Benefits',
    Benefits1: 'Beneficial for hair growth.',
    Benefits2: 'Beneficial for hair fall.',
    Benefits3: 'Beneficial for the pain in Ears.',
    Benefits4: 'Beneficial for controlling fever',
    Benefits5: 'Cancer preventing',
    Benefits6: 'Boosters immune system',
    Usage: 'Usage',
    Usage1: 'Mix Kalonji oil with Olive oil and Castor oil for hair growth, Apply at night before sleep massage gently on scalp wash in morning..',
    Usage2: 'Take 1 tablespoon of Kalonji oil add 1,2 drops of lemon juice drink once in a day it helps to control fever and high body temperature.',
    Usage3:'Put few 2,3 drops of Kalonji oil into your ear to relieve the pain in ears.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 28,
    title: "Kalonji (Nigella Sativa) Oil",
    price: 1900.0,
    img: require("./assets/Natural Oil//kalonji-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '200 ML',

    dis: 'Green Roots Kalonji Oil is the solution to many hair, skin and body problems. As it is said by the Prophet Muhammad (peace be upon him) that ‘there is healing in black cumin (Kalonji) for all diseases except death’.(Sahih Bukhari).',
    SKU: 'SKU',
    SKU1: 'GR026OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti hair fall, cancer prevention, ear pain relief, fever relief, hair growth, immune booster, kalonji, natural oil, nigella sativa, pure oil',
    Benefits: 'Benefits',
    Benefits1: 'Beneficial for hair growth.',
    Benefits2: 'Beneficial for hair fall.',
    Benefits3: 'Beneficial for the pain in Ears.',
    Benefits4: 'Beneficial for controlling fever',
    Benefits5: 'Cancer preventing',
    Benefits6: 'Boosters immune system',
    Usage: 'Usage',
    Usage1: 'Mix Kalonji oil with Olive oil and Castor oil for hair growth, Apply at night before sleep massage gently on scalp wash in morning..',
    Usage2: 'Take 1 tablespoon of Kalonji oil add 1,2 drops of lemon juice drink once in a day it helps to control fever and high body temperature.',
    Usage3:'Put few 2,3 drops of Kalonji oil into your ear to relieve the pain in ears.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '200 mL',
  },
  {
    id: 29,
    title: "Neem (Margosa) Oil",
    price: 1000.0,
    img: require("./assets/Natural Oil/neem-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Neem oil (Margosa Oil) is perfect cure for healing fungus present in skin and hair. It also helps in preventing hair loss. It can also be used to heal the wounds.',
    SKU: 'SKU',
    SKU1: 'GR034OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti dandruff, anti fungal, eczema cure, margosa, natural oil, neem, pure oil, scar remover',
    Benefits: 'Benefits',
    Benefits1: 'Anti-fungal properties.',
    Benefits2: 'Beneficial in removing acne scars.',
    Benefits3: 'Heals in Eczema.',
    Benefits4: 'Treats hair loss.',
    Benefits5: 'Treats dandruff.',
    Usage: 'Usage',
    Usage1: 'Put Neem oil on hair scalp 2 hours before washing hair helps treat hair loss and dandruff.',
    Usage2: 'Apply little amount of Neem oil on a cotton pad apply on acne scar will help remove scars.',
    Usage3: 'Applied on the part of body and hair where you have fungus or eczema to heal.',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 31,
    title: "Mustard Oil",
    price: 350.0,
    img: require("./assets/Natural Oil/mustard-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Mustard Oil is the purest extracts of finest quality mustard seeds with variety of uses beneficial for hair and skin care. Mustard Oil also enhances the taste of food when used in cooking.',
    SKU: 'SKU',
    SKU1: 'GR033OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' anti inflammation, hair nourishment, healthy heart, mustard, natural oil, pain relief, pure oil, skin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Supports heart health.',
    Benefits2: 'Alleviate pain.',
    Benefits3: 'Promotes skin and hair health.',
    Benefits4: 'Helps in treating cold symptoms.',
    Benefits5: 'Reduces inflammation. ',
    Usage: 'Usage',
    Usage1: 'Applying Mustard Oil on hair helps in growth of hair and it prevents hair loss as well.',
    Usage2: 'Applying Mustard Oil on skin helps in improving fine lines and wrinkles.',
    Usage3: 'Massaging Mustard Oil on body relieves pain and inflammation.',
    Usage4: 'Cooking with Mustard Oil helps reduces bad cholesterol level and supports in getting the overall health of heart.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 32,
    title: "Mustard Oil",
    price: 500.0,
    img: require("./assets/Natural Oil/mustard-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '200 ML',

    dis: 'Green Roots Mustard Oil is the purest extracts of finest quality mustard seeds with variety of uses beneficial for hair and skin care. Mustard Oil also enhances the taste of food when used in cooking.',
    SKU: 'SKU',
    SKU1: 'GR033OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' anti inflammation, hair nourishment, healthy heart, mustard, natural oil, pain relief, pure oil, skin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Supports heart health.',
    Benefits2: 'Alleviate pain.',
    Benefits3: 'Promotes skin and hair health.',
    Benefits4: 'Helps in treating cold symptoms.',
    Benefits5: 'Reduces inflammation. ',
    Usage: 'Usage',
    Usage1: 'Applying Mustard Oil on hair helps in growth of hair and it prevents hair loss as well.',
    Usage2: 'Applying Mustard Oil on skin helps in improving fine lines and wrinkles.',
    Usage3: 'Massaging Mustard Oil on body relieves pain and inflammation.',
    Usage4: 'Cooking with Mustard Oil helps reduces bad cholesterol level and supports in getting the overall health of heart.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '200 mL',
  },
  {
    id: 33,
    title: "Jasmine Oil",
    price: 700.0,
    img: require("./assets/Natural Oil/jasmine-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    
    dis: 'The aroma of Green Roots Jasmine Oil is used to relax stress level and depression. Jasmine Oil can also be used as an antiseptic fluid. By using Jasmine Oil you can also improve hair strength by making them healthy while by using it on skin can reveal a better healthy and shiny skin surface',
    SKU: 'SKU',
    SKU1: 'GR023OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' anti allergy, antiseptic, eczema relief, hair nourishment, hair strengthening, jasmine, natural oil, pure oil, scar remover, skin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'It is an antiseptic oil which helps in Allergy problems of skin.',
    Benefits2: 'Strengthens the roots of hair.',
    Benefits3: 'Provides moisture to the hair.',
    Benefits4: 'Lighten scars',
    Benefits5: 'Helps dry skin and Eczema',
    Usage: 'Usage',
    Usage1: 'Apply Jasmine oil directly to hair or mix with any other hair oil for hair growth.',
    Usage2:'Apply on the skin at night to lighten scars.',
    Usage3:'Inhale Jasmine oil to ease and relieve depression and stress.',
    Ingredients:'Ingredients',
    Ingredients1:'Jasmine Leaves Extract',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 34,
    title: "Malkangni Oil",
    price: 2300.0,
    img: require("./assets/Natural Oil/malkangni-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Malkangni Oil (Celastrus Oil) is best to cure with brain disorders, which highly enhances brain memory. Malkangni Oil also helps to treat with premature greying of hair and controls the hair color to keep it maintained and make it black. Helpful in body pain.',
    SKU: 'SKU',
    SKU1: 'GR031OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' arthritis relief, healing, malkangni, natural oil, paralysis relief, premature hair greying relief, pure oil, sciatica pain relief',
    Benefits: 'Benefits',
    Benefits1: 'Beneficial for sciatica pain.',
    Benefits2: 'Beneficial for paralysis.',
    Benefits3: 'Beneficial for arthritis.',
    Benefits4: 'Beneficial for blackening of the premature grey hairs.',
    Benefits5: 'Beneficial in healing wounds.',
    Usage: 'Usage',
    Usage1: 'Massaging of Malkangni oil on body gives relief in body pain..',
    Usage2: 'Apply Malkangni oil on hair scalp and all over hair for treating premature grey hair',
    Weight: ' Weight',
    Weight1: '	135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 35,
    title: "Miracle Oil",
    price: 1600.0,
    img: require("./assets/Natural Oil/miracle-oil-greenrootspk.com_Front.png"),
    quantity: 1,
    ml: '150 ML',

    dis: 'Green Roots Miracle Oil reduces all body pain and stiffness. It cures Arthritis (joint inflammation) and heals frozen joints. It also helps in relieving paralysis patients.',
    SKU: 'SKU',
    SKU1: 'GR032OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'cervical pain relief, frozen shoulder relief, joint pain relief, knee pain relief, miracle oil, muscle relaxant, muscular pain relief, natural oil, pain relief, paralysis relief, pure oil',
    Benefits: 'Benefits',
    Benefits1: 'Beneficial for knee pain.',
    Benefits2: 'Beneficial for joint pain.',
    Benefits3: 'Beneficial for cervical pain.',
    Benefits4: 'Beneficial for Frozen shoulder.',
    Benefits5: 'Beneficial for muscular pain.',
    Benefits6: 'Beneficial for all joints and bones in the body.',
    Benefits7: 'Beneficial for massage purpose in paralysis.',
    Usage: 'Usage',
    Usage1: 'Applying Green Roots Miracle Oil on the affected areas by lightly rubbing for 10 to 15 minutes at night before sleep.',
    Weight: ' Weight',
    Weight1: '170 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '150 mL',
  },
  {
    id: 36,
    title: "Neem & Mustard Oil",
    price: 1300.0,
    img: require("./assets/Natural Oil/neem-mustard-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

  
    dis: 'Green Roots Neem & Mustard Oil is very effective for hair fall and dandruff.',
    SKU: 'SKU',
    SKU1: 'GR063OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti dandruff, anti hair fall, mustard, natural oil, neem, neem mustard, pure oil',
    Benefits: 'Benefits',
    Benefits1: 'This oil is a mixture of neem and mustard oil, beneficial for hair fall and dandruff.',
    Usage: 'Usage',
    Usage1: 'Can be applied overnight and washed in the morning, or can be applied 2 hours before washing the hair/shower.',
    Ingredients:'Ingredients',
    Ingredients1:'Neem oil',
    Ingredients2:'Mustard oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 37,
    title: "Neem & Virgin Coconut Oil",
    price: 1400.0,
    img: require("./assets/Natural Oil/neem-virgin-coconut-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Neem & Virgin Coconut Oil is beneficial for hair loss and hair fall.',
    SKU: 'SKU',
    SKU1: 'GR064OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti hair fall, anti hair loss, hair nourishment, natural oil, neem neem virgin coconut, pure oil, scalp nourishment, virgin coconut',
    Benefits: 'Benefits',
    Benefits1: 'This oil is a mixture of neem and virgin coconut oil, beneficial for hair loss/hair fall. Boosts hair growth and moisturizes the scalp.',
    Usage: 'Usage',
    Usage1: 'Can be applied overnight and washed in the morning, or can be applied 2 hours before washing the hair/shower)',
    Ingredients:'Ingredients',
    Ingredients1:'Neem oil',
    Ingredients2:'Virgin coconut oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 38,
    title: "Argan Jojoba Oil",
    price: 4300.0,
    img: require("./assets/Natural Oil/argan-jojoba-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Argan-Jojoba Oil makes the hair thicker and stronger, repairs split ends and fights dandruff.',
    SKU: 'SKU',
    SKU1: 'GR065OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti acne, anti dandruff, argan, argan jojoba, even skin tone, hair nourishmen, thair repair, hair strengthening, jojoba, natural oil, pure oil, skin lightening, skin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Makes the hair thicker and stronger, repairs split ends and fights dandruff.',
    Benefits2: 'Skin benefits; regulates sebum, treated acne, smooths complexion, lightens the skin.',
    Usage: 'Usage',
    Usage1: '(For hair) Before showering, layer onto the dry hair, massage or comb in thoroughly, and leave in for 15-20 minutes, and then wash. It can also be used to apply on the ends to repairs and prevent split ends. Can also be used as a serum post hair wash.',
    Usage2:'(For skin) Can be applied directly to the skin on all parts of the body, particularly face, hands, and legs.',
    Ingredients:'Ingredients',
    Ingredients1:'Argan oil',
    Ingredients2:'Jojoba oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 39,
    title: "Paralysis Oil",
    price: 4500.0,
    img: require("./assets/Natural Oil/paralysis-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Paralysis Oil.',
    SKU: 'SKU',
    SKU1: 'GR067OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' natural oil, sparalysis cure, paralysis herbal remedy, paralysis natural cure, paralysis natural remedy, paralysis oil, pure oils',
    Benefits: 'Benefits',
    Benefits1: 'An oil specially made for people suffering from paralysis.',
    Ingredients:'Ingredients',
    Ingredients1:'Roghan Alsi (flaxseed oil)',
    Ingredients2:'Roghan Zaitoon (olive oil)',
    Ingredients3:'Sammulfar (red, yellow, black and white) (kushta sammulfar )',
    Usage: 'Usage',
    Usage1: 'Warm the oil to a lukewarm temperature and then use on the effected parts of the body.',
    Usage2:'The best use of this oil is to be applied while the patient is standing next to a wall (increases blood circulation)',
    Usage3:'Massage thoroughly with this oil everyday, two times for at least 30 minutes. Massage with a heavy pressure so the hands and body get warm in the process.',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 40,
    title: "Anti Dandruff & Hair Fall Oil",
    price: 1250.0,
    img: require("./assets/Natural Oil/anti-dandruff-hair-fall-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Anti Dandruff and Hair Fall oil is to prevent hair fall and dandruff completely within one month. Also makes hair roots strong.',
    SKU: 'SKU',
    SKU1: 'GR004OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' anti dandruff, anti hair fall, anti hair loss, dandruff prevention, hair care, hair nourishment, natural oil, pure oil, scalp nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Helps finish existing dandruff in the hair.',
    Benefits2: 'Prevents dandruff occurrence.',
    Benefits3: 'Beneficial for hair fall.',
    Usage: 'Usage',
    Usage1: 'Put Anti Dandruff and Hair fall Oil on the hair scalp at night before sleep for best results. Wash in the morning. Use the Oil on alternate days.',
    Ingredients:'Ingredients',
    Ingredients1:'Mustard oil',
    Ingredients2:'Neem oil',
    Ingredients3:'Lemon oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 41,
    title: "Egg Oil",
    price: 2800.0,
    img: require("./assets/Natural Oil/egg-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Egg oil is an excellent natural solution to prevent hair loss, treat dandruff, prevent hair from greying, moisture the scalp and promote new healthy hair growth. Egg oil is extracted from egg yolks which are rich in triglycerides with cholesterol and Phospholipids.',
    SKU: 'SKU',
    SKU1: 'GR014OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'antibacterial, blood circulation, cell growth, cuticle softener, egg oil, hair follicles regeneration, hair growth, hair regrowth, hair repair, healing, natural oil, omega 3, proteins, pure oil, skin nourishment, yolk strength',
    Benefits: 'Benefits',
    Benefits1: 'Rich in proteins',
    Benefits2: 'Rich source of omega 3',
    Benefits3: 'Enhances blood circulation',
    Benefits4: 'Enhances cell growth ',
    Benefits5: 'Regenerates hair follicles',
    Benefits6: 'Acts as a moisturizer for the skin',
    Benefits7: 'Can be used as a cuticle softener',
    Benefits8: 'Conditions the skin ',
    Benefits9: 'Antibacterial',
    Benefits10: 'Treats hair loss.',
    Benefits11: 'Regrows hair',
    Benefits12: 'Heals dry and damaged skin',
    Benefits13: 'Repairs damaged hair',
    Benefits14: 'Nourishes the hair roots',
    Benefits15: 'Helps in regrowth of the hair',
    Usage: 'Usage',
    Usage1: 'Apply the oil and massage gently over the hair scalp. Leave for at least 2 hours before washing or overnight.',
    Weight: ' Weight',
    Weight1: '140 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 42,
    title: "Jojoba Oil",
    price: 1700.0,
    img: require("./assets/Natural Oil/jojoba-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Jojoba Oil is extracted from the best Jojoba seeds to help in many problems of skin and hair.',
    SKU: 'SKU',
    SKU1: 'GR024OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti ageing, damaged hair, dry hair, hair growth, hair nourishment, hair repair, jojoba, makeup remover, natural oil, pure oil, scalp nourishment, scar remover',
    Benefits: 'Benefits',
    Benefits1: 'Beneficial for repairing dry and damage hair.',
    Benefits2: 'Moisturizes the hair scalp.',
    Benefits3: 'Helps to grow hair..',
    Benefits4: 'Gives volume to the hair.',
    Benefits5: 'Beneficial to fight against fine lines and wrinkles',
    Benefits6: 'Removes acne scars',
    Benefits7: 'Used as a moisturizer.',
    Benefits8: 'Used as a makeup remover',
    Usage: 'Usage',
    Usage1: 'Apply on the hair scalp and overall hair, massage gently at night or 2 hours before hair wash.',
    Usage2:'Apply on the skin at night before sleep.',
    Usage3:'For removing makeup apply little amount of Jojoba oil on a cotton pad and wipe all over your face.',
    Ingredients:'Ingredients',
    Ingredients1:'Pure Jojoba Oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 43,
    title: "Oil for Dry Hair",
    price: 1800.0,
    img: require("./assets/Natural Oil/dry-hair-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Oil for Dry Hair is mixture of Egg, Coconut Oil, Olive Oil and Lemon Oil which can be used to moisture the scalp to help preventing fizziness of hair by leaving it soft and shiny.',
    SKU: 'SKU',
    SKU1: 'GR036OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'coconut, dry hair oil, egg oil, hair balance, hair growth, hair nourishment, lemon oil, olive',
    Benefits: 'Benefits',
    Benefits1: 'Helps keep hair nourished.',
    Benefits2: 'Balances the oil levels of the hair.',
    Usage: 'Usage',
    Usage1: 'Green Roots Oil for Dry Hair can be used thrice a week.',
    Ingredients:'Ingredients',
    Ingredients1:'Coconut Oil',
    Ingredients2:'Olive Oil',
    Ingredients3:'Lemon Oil',
    Ingredients4:'Egg Oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 44,
    title: "Oil for Oily Hair",
    price: 1500.0,
    img: require("./assets/Natural Oil/oily-hair-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Oil for Oily Hair is an effective formula that helps in avoiding inflammation, scalp infections, reducing sweat on the scalp, hair loss and dandruff.',
    SKU: 'SKU',
    SKU1: 'GR037OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' hair growth, hair nourishment, healthy brain, healthy digestion, healthy respiration, immune booster, natural oil, nausea cure, oily hair, oral health, pure oil, skin disease cure, skin nourishment, stress reliever',
    Benefits: 'Benefits',
    Benefits1: 'Reduces stress',
    Benefits2: 'Reduces nausea',
    Benefits3: 'Improves concentration',
    Benefits4: 'Keeps hair healthy',
    Benefits5: 'Supports digestion',
    Benefits6: 'Boosts oral health',
    Benefits7: 'Treats skin conditions',
    Benefits8: 'Improves respiratory functions',
    Benefits9: 'Improves immune system functions',
    Benefits10: 'Treats multiple skin conditions.',
    Usage: 'Usage',
    Usage1: 'Green Roots Oil for Oily Hairs can be used thrice a week.',
    Ingredients:'Ingredients',
    Ingredients1:'Coconut Oil',
    Ingredients2:'Almond Oil',
    Ingredients3:'Jojoba Oil',
    Ingredients4:'Tea Tree Oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 44,
    title: "Snake Oil",
    price: 4800.0,
    img: require("./assets/Natural Oil/sesame-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Snake Oil makes hairs extremely strong and protects them falling. Another important quality of Snake Oil is that it regrows hairs, in addition to this, it also treats dandruff and reduces it to limits.',
    SKU: 'SKU',
    SKU1: 'GR057OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti dandruff, anti hair fall, damaged hair, hair growth, hair nourishment, hair regrowth, hair repair, natural oil, pure oil, snake oil',
    Benefits: 'Benefits',
    Benefits1: 'Prevents hair falling.',
    Benefits2: 'Prevents dandruff.',
    Benefits3: 'Helps in hair growth faster.',
    Benefits4: 'Repairs damaged hairs. ',
    Usage: 'Usage',
    Usage1: 'Apply on the hair roots 2 hours before washing your hair.',
    Ingredients:'Ingredients',
    Ingredients1:'Snake Liver Oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 45,
    title: "Water Damaged & Hair Fall Oil",
    price: 2500.0,
    img: require("./assets/Natural Oil/water-damaged-hair-fall-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Water Damage & Hair Fall Oil is a rich source of minerals and vitamins. The oil prevents hair fall and improves hairs damaged from water.',
    SKU: 'SKU',
    SKU1: 'GR061OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti hair fall, anti hair loss, damaged hair, hair fall oil, hair nourishmen, thair repair, water damaged oil',
    Benefits: 'Benefits',
    Benefits1: 'Prevents hair loss.',
    Benefits2: 'Repairs damaged hair.',
    Benefits3: 'Nourishes the hair.',
    Usage: 'Usage',
    Usage1: 'Green Roots Water Damage & Hair Fall Oil can be used thrice a week.',
    Ingredients:'Ingredients',
    Ingredients1:'Mustard oil',
    Ingredients2:'Olive oil',
    Ingredients3:'Sesame oil',
    Ingredients4:'Castor oil',
    Ingredients5:'Lemon oil',
    Ingredients6:'Almond oil',
    Ingredients7:'Jojoba oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },

]