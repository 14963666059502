export default [
  {
    id: 1,
    title: "Acne Clear Face Wash",
    price: 1000.0,
    img: require("./assets/Health & Beauty/acne-clear-face-wash-greenrootspk.com_.png"),
    quantity: 1,
    ml:'200 ML',
    dis:'Green Roots Acne Clear Face Wash not only removes dirt and oil but also helps in treating acne from the first wash. It deeply cleanses the skin and prevents future breakouts, leaving the skin whiter and brighter. Skin becomes clear with continuous use.',
    SKU:'SKU',
    SKU1:'GR001HB',
    Category:'Health & Beauty',
    Tags:'Tags',
    Tags1:'acne facewashanti acne facewashanti  bacterial facewashfacewashoily skin careskin nourishing facewash',
    Benefits:'Benefits',
    Benefits1:'Suitable for oily skin.',
    Benefits2:'Helps fight against Acne.',
    Benefits3:'Reduces production of bacteria on the skin.',
    Benefits4:'Prevents the skin from drying and peeling.',
    Benefits5:'Speeds healing.',
    Benefits6:'Decreases the formation of pimples.',
    Usage:' Usage',
    Usage1:'Wash your face twice a day. (To be used daily)',
    Ingredients:'Ingredients',
    Ingredients1:'Fuller’s earth clay',
    Ingredients2:'Orange Peel',
    Ingredients3:'Wild Turmeric',
    Ingredients4:'Rose Whitener',
    Ingredients5:'Glycerin',
    Ingredients6:'Vitamin E',
    Ingredients7:'Tea Tree Oil',
    Weight:'Weight',
    Weight1:'245 g',
    dicQuantity1:'200 mL',
    dicQuantity:'Quantity',
  },


  {
      id: 2,
      title: "Acne Regime",
      price: 3600.0,
      img: require("./assets/Health & Beauty/acne-regime-greenrootspk.com_-600x480.png"),
      quantity: 1,
      // ml: '200 ML',
      dis:'Green Roots Acne Regime is a complete bundle for getting rid of acne.',
      SKU:' GR028HB',
      Category:'Health & Beauty',
      Tags:'acne creamacne facewashacne teaanti acneanti acne regimeanti bacterialskin nourishingskincare',
      Benefits:'Benefits',
      Benefits1:'Suitable for oily skin.',
      Benefits2:'Helps fight against Acne.',
      Benefits3:'Reduces production of bacteria on the skin.',
      Benefits4:'Prevents the skin from drying and peeling.',
      Benefits5:'Speeds healing.',
      Benefits6:'Decreases the formation of pimples.',
      Benefits7:'Help get rid of acne marks.',
      Benefits8:'Helps clean and purify blood.',
      Benefits9:'Helps prevent acne.',
      Benefits10:'Helps against the sign of ageing.',
      Benefits11:'Cures inflammation.',
      Benefits12:'Fights with infectious pimples and prevent them in the future.',
      Benefits13:'Fights boils and acne vulgaris',
      Benefits14:'Prevents itching.',
      Benefits15:'Reduces the production of sebum.',
      Benefits16:'Soothes inflammation.',
      Benefits17:'Speeds the process of healing acne.',
      Benefits18:'Works as a skin protectant.',
      Benefits19:'Fights bacteria and fungus on the skin.',
      Benefits20:'Prevents skin from breaking out.',
      Benefits21:'Has antiseptic qualities.',
      Benefits22:'Dries out pimples and reduces acne marks',
      UsageofAcneClear :'Usage of Acne Clear Facewash',
      UsageofAcneClear1:'Wash your face twice a day. (To be used daily)',
      UsageofAcneDiffuser:'Usage of Acne Diffuser Herbal Tea',
      UsageofAcneDiffuser1:'Take on and half cup / glass of water, add on tablespoon of the powder and cook / boil. Cook / boil enough for one cup / glass of water to be left behind and consume once a day. (Anytime in the day or night).',
      UsageofAntiAcne:'Usage of Anti Acne Night Cream',
      UsageofAntiAcne1:'Apply on your face daily at night before sleeping.',
      Ingredients:'Collective Ingredients',
      Ingredients1:'Fuller’s earth clay',
      Ingredients2:'Orange Peel',
      Ingredients3:'Wild Turmeric',
      Ingredients4:'Rose Whitener',
      Ingredients5:'Glycerin',
      Ingredients6:'Vitamin E.',
      Ingredients7:'Tea Tree Oil',
      Ingredients8:'Unaab (Jujube)',
      Ingredients9:'Gul E Mandi (East Indian Globe Thistle)',
      Ingredients10:'Mezereum',
      Ingredients11:'Zinc Oxide',
      Ingredients12:'Pomegranate Flower',
      Ingredients13:'Calamine Powder',
      Ingredients14:'Milk Thistle',
      Ingredients15:'Sphaeranthus indicus',
      Ingredients16:'Chinaberry',
      Ingredients17:'Tamarix Galicia',
      Weight:'Weight',
      Weight1:'550 g',
      Facewash:'Facewash Quantity',
      Facewash1:'200 mL',
      HerbalTeaQuantity:'Herbal Tea Quantity',
      HerbalTeaQuantity1:'160 grams',
      NightCream:'Night Cream Quantity',
      NightCream1:'30 grams',

    },
    {
      id: 3,
      title: "Age Reverse Oil",
      price: 9500.0,
      img: require("./assets/Health & Beauty/age-reverse-oil-greenrootspk.com_.png"),
      quantity: 1,
      ml: '30 ML',
      dis:'Green Roots Age Reversing Oil is the compound mixture of essential oils with Almond oil as a base. Which helps out making you look younger, removes wrinkles from face. Gives a natural glow to skin.',
      SKU:'GR004HB',
      Category:'Health & Beauty',
      Tags:'age reverse oilanti ageingeye rejuvenatingface oilskin glowing oilskin tone lightening oil',
      Benefits:'Benefits',
      Benefits1:'Removes fine lines from under Eyes.',
      Benefits2:'Removes wrinkles from Face and neck.',
      Benefits3:'Beneficial for skin glow.',
      Benefits4:'Fairs the complexion.',
      Usage:'Usage',
      Usage1:'Apply few drops of Age Reversing Oil all over your face and neck at night before sleeping',
      Ingredients:'Ingredients',
      Ingredients1:'Almond oil',
      Ingredients2:'Rose oil',
      Ingredients3:'Sandal oil',
      Ingredients4:'Rosemary oil',
      Ingredients5:'Lavender oil',
      Ingredients6:'Tea tree oil',
      Ingredients7:'Lemon oil',
      Ingredients8:'Niaouli',
      Ingredients9:'Carrot oil',
      Ingredients10:'Frankincense oil',
      Ingredients11:'Fennel oil.',
      Weight:'Weight',
      Weight1:'	50 g',
      dicQuantity:'Quantity',
      dicQuantity1:'30 mL',

    },

    
    {
      id: 4,
      title: "Aloe Vera & Neem Face Wash",
      price: 1000.0,
      img: require("./assets/Health & Beauty/aloevera-neem-facewash-greenrootspk.com_.png"),
      quantity: 1,
      ml:'200 ML',
      dis:'Green Roots Aloe Vera and Neem face wash helps purifies and deeply cleanses the skin. Lightens marks and spots on the skin. Also has antifungal properties.',
      SKU:' GR005HB',
      Category:'Health & Beauty',
      Tags:'aloe vera neem facewashanti fungaleven skin tone facewashskin cleansingskin glowingskin lightening facewash',
      Benefits:'Benefits',
      Benefits1:'Lightens marks and spots on the skin.',
      Benefits2:'Helps prevents acne from appearing.',
      Benefits3:'Antibacterial.',
      Benefits4:'Anti fungal.',
      Benefits5:'Reduces melanin production.',
      Benefits6:'Helps even tone skin.',
      Benefits7:'Makes the skin clean and glowing.',
      Usage:'Usage',
      Usage1:'Wash your face twice a day. (For daily use)',
      Ingredients:'Ingredients',
      Ingredients1:'Melia',
      Ingredients2:'Chinaberry',
      Ingredients3:'Aloe Vera',
      Ingredients4:'Jojoba',
      Ingredients5:'Zinc oxide',
      Ingredients6:'White sandal',
      Weight:'Weight',
      Weight1:'245 g',
      dicQuantity:'diaQuantity',
      dicQuantity1:'200 mL',

    },


    {
      id: 5,
      title: "Aloe Vera Face Wash",
      price: 1000.0,
      img: require("./assets/Health & Beauty/aloe-vera-facewash-greenrootspk.com_.png"),
      quantity: 1,
      ml:'200 ML',
      dis:'Green Roots Aloe Vera Face Wash contains Aloe Vera extracts in it. It easily rinses away makeup, impurities, leaving no residue of dirt behind. It leaves the skin fresh, cool and calm. It works as a good moisturizer which makes the skin smooth.',
      SKU:'GR006HB',
      Category:'Health & Beauty',
      Tags:'anti ageingantibacterialskin lighteningskin lightening facewashskin moisturizingsunburn soothing',
      Benefits:'Benefits',
      Benefits1:'Helps soothe sunburn.',
      Benefits2:'Moisturizes the skin',
      Benefits3:'Fights skin ageing',
      Benefits4:'Lightens blemishes on the skin',
      Benefits5:'Antibacterial',
      Usage:'Usage',
      Usage1:'Wash your face twice a day. (For daily use)',
      Ingredients:'Ingredients',
      Ingredients1:'German Base',
      Ingredients2:'Aloe Vera Gel',
      Ingredients3:'Rose water',
      Ingredients4:'Vitamin E',
      Ingredients5:'Glycerin',
      Ingredients6:'Cleansing milk',
      Weight:'Weight',
      Weight1:'245 g',
      dicQuantity:'Quantity',
      dicQuantity1:'200 mL',

    },

    
    {
      id: 6,
      title: "Hair Repair Shampoo",
      price: 2200.0,
      img: require("./assets/Health & Beauty/hair-repair-shampoo-greenrootspk.com_.png"),
      quantity: 1,
      ml:'500 ML',
      dis:'(Coconut Shampoo) Made with all natural ingredients to give your hair shine, volume and health.',
      SKU:'SKU',
      SKU1:'GR012HB',
      Category:'Health & Beauty',
      Tags:'Tags',
      Tags1:'anti hair loss, damaged hair, damaged hair, shampoo dry hair shampoo, hair repair hair repair shampoo hair, strengthening shampoo.',
      Benefits:'Benefits',
      Benefits1:'High in vitamins C, E, B-1, B-3, B-5, and B-6.',
      Benefits2:'High in protein',
      Benefits3:'Restores dry hair and scalp.',
      Benefits4:'Restores moisture and strength',
      Benefits5:'Slows hair loss.',
      Benefits6:'Restores damaged follicles',
      Benefits7:'Makes hair stronger, thicker and healthier',
      Benefits8:'Tames dry and frizzy hair',
      Benefits9:'Combats dandruff',
      Benefits10:'Leaves hair more manageable and de tangled.',
      Usage:'Usage',
      Usage1:'Wash your hair daily.',
      Ingredients:'Ingredients',
      Ingredients1:'Coconut',
      Ingredients2:'Amla',
      Ingredients3:'Moringa',
      Ingredients4:'Valerian',
      Ingredients5:'Parmelia perlata',
      Ingredients6:'Air Creeper',
      Ingredients7:'Soap Nut (soap berries)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'NOT SUITABLE FOR DANDRUFF PRONE HAIR',
      Weight:'Weight',
      Weight1:'560 g',
      dicQuantity:'Quantity',
      dicQuantity1:'500 mL',

    },


    {
      id: 7,
      title: "Length & Strength Shampoo",
      price: 1600.0,
      img: require("./assets/Health & Beauty/length-strength-shampoo-greenrootspk.com_.png"),
      quantity: 1,
      ml:'500 ML',
      dis:'Green Roots Length & Strength Shampoo is made with all natural ingredients to make your hair longer, shinier & thicker.',
      SKU:'SKU',
      SKU1:'GR016HB',
      Category:'Health & Beauty',
      Tags:'Tags',
      Tags1:'anti dandruff, anti hair fall, hair nourishment, hair volume booster, length and strength shampoo, length shampoo, scalp conditioner, strength shampoo.',
      Benefits:'Benefits',
      Benefits1:'Conditions the scalp',
      Benefits2:'Boosts volume',
      Benefits3:'Reduces the appearance of dandruff',
      Benefits4:'Controls hair fall',
      Benefits5:'Promotes hair growth',
      Benefits6:'Adds shine and softness',
      Benefits7:'Gives stronger, thicker hair',
      Benefits8:'Prevents dry scalp',
      Usage:'Usage',
      Usage1:'Wash your hair daily.',
      Ingredients:'Ingredients',
      Ingredients1:'Amla',
      Ingredients2:'Areetha',
      Ingredients3:'Shikakai',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'NOT SUITABLE FOR PEOPLE HAVING DRY HAIR',
      Weight:'Weight',
      Weight1:'550 g',
      dicQuantity:'Quantity',
      dicQuantity1:'500 mL',

    },


    {
      id: 8,
      title: "Milky Face Wash",
      price: 1000.0,
      img: require("./assets/Health & Beauty/milky-facewash-greenrootspk.com_.png"),
      quantity: 1,
      ml:'200 ML',
      dis:'Green Roots Milky White Face Wash is designed for all skin types to make the skin fresh and comfortable. It naturally sets the stage for a healthy, beautiful complexion. It leaves skin feeling soft, smooth and perfectly refreshed. It also keeps the skin moisturized.',
      SKU:'SKU',
      SKU1:'GR017HB',
      Category:'Health & Beauty',
      Tags:'Tags',
      Tags1:'milky facewash, sensitive skin facewash, skin nourishment, skin soothing, sunburn protection.',
      Benefits:'Benefits',
      Benefits1:'Beneficial for sensitive skin.',
      Benefits2:'Beneficial for dry skin.',
      Benefits3:'Keeps the skin hydrated.',
      Benefits4:'Helps protect against sunburn for sensitive skin.',
      Benefits5:'Enhances face texture.',
      Benefits6:'Leaves skin feeling soft and smooth.',
      Usage:'Usage',
      Usage1:'Wash your face with Milky Face Wash twice a day (for daily use).',
      Ingredients:'Ingredients',
      Ingredients1:'Rose Whitener',
      Ingredients2:'Aloe Vera water',
      Ingredients3:'Goat Milk Powder',
      Ingredients4:'Vitamin E',
      Ingredients5:'Almond Oil',
      Ingredients6:'Glycerin',
      Weight:'Weight',
      Weight1:'	245 g',
      dicQuantity:'Quantity',
      dicQuantity1:'200 mL',

    },


    {
      id: 9,
      title: "Aloe Vera Gel",
      price: 750.0,
      img: require("./assets/Health & Beauty/aloe-vera-gel-greenrootspk.com_.png"),
      quantity: 1,
      ml:'285 ML',
      dis:'Green Roots Aloe Vera Gel is used for many skin problems like scars, acne and is used to make frizzy hair soft. Aloe Vera Gel makes your hair straight and volumized',
      SKU:'SKU:',
      SKU1:'GR031HB',
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'aloe vera extract, aloe vera gel, anti marks, anti scars, hair strengthening, pure aloe vera, skin care, skin lightening, skin nourishing.',
      Benefits:'Benefits',
      Benefits1:'Good to remove acne and acne scars',
      Benefits2:'Works well in case of allergy and redness on face, arms and legs',
      Benefits3:'To remove fizziness of hairs and to increase the volume of hairs',
      Benefits4:'Makes hairs soft, silky and shinny',
      Usage:'Usage',
      Usage1:'For skin, take a small amount of Aloe Vera gel and apply it on required areas until dried and rinse with water.',
      Usage2:'For hairs, Aloe Vera gel can be directly applied on hairs or by mixing it with any hair oil and a small amount of honey to apply on the hairs for 30 minutes before washing your hairs as it works as protein for your hair.',
      Ingredients:'Ingredients',
      Ingredients1:'Aloe Vera Gel',
      Weight:'Weight',
      Weight1:'	300 g',
      dicQuantity:'Quantity',	
      dicQuantity1:'285 mL',
    },


    {
      id: 10,
      title: "Pigmentation Regime",
      price: 6400.0,
      img: require("./assets/Health & Beauty/pigmentation-regime-greenrootspk.com.png"),
      quantity: 1,
      // ml:'30 ML',
      dis:'Green Roots Pigmentation Regime with night cream, sun block, serum and face-wash makes you get completely rid of Pigmentation from face. Helps to even tone the skin, leaves the skin clean and spot free.',
      SKU:'SKU:',
      SKU1:'GR021HB',
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'facial serum, pigmentation, skin care, skin glowing, skin nourishing, skin whitening.',
      BenefitsofPigmentationNightCream:'Benefits of Pigmentation Night Cream',
      BenefitsofPigmentationNightCream1:'Works in removing pigmentation from the skin',
      BenefitsofPigmentationSunBlock:'Benefits of Pigmentation Sun Block',
      BenefitsofPigmentationSunBlock1:'Protects from harmful sun rays.',
      BenefitsofPigmentationSunBlock2:'Protects from sunburn.',
      BenefitsofPigmentationSunBlock3:'Protects the skin from stove heat (kitchen stove heat).',
      BenefitsofPigmentationSunBlock4:'Protects from dust and dirt',
      BenefitsofPigmentationSerum:'Benefits of Pigmentation Serum',
      BenefitsofPigmentationSerum1:'Removes pigmentation from the skin.',
      BenefitsofPigmentationSerum2:'Clears all sorts of marks from the skin.',
      BenefitsofPigmentationSerum3:'Enhances complexion.',
      BenefitsofPigmentationSerum4:'Improves the collagen level.',
      BenefitsofPigmentationSerum5:'Protects the skin from aging early',
      BenefitsofPigmentationOrangeFacewash:'Benefits of Pigmentation Orange Face wash',
      BenefitsofPigmentationOrangeFacewash1:'A part of Regime for washing the face daily.',
      BenefitsofPigmentationOrangeFacewash2:'Helps clear the skin.',
      BenefitsofPigmentationOrangeFacewash3:'Brightens the skin.',
      BenefitsofPigmentationOrangeFacewash4:'Keeps the skin hydrated.',
      BenefitsofPigmentationOrangeFacewash5:'Helps eradicate pigmentation.',
      Usage:'Usage',
      Usage1:'Pigmentation face wash for Daily use, twice a day in the morning and at night.',
      Usage2:'Pigmentation serum to be used in the morning after face wash, apply all over your face.',
      Usage3:'Pigmentation sun block to be applied on your face, after the serum is absorbed in your skin.',
      Usage4:'Pigmentation night cream will be used after washing the face with pigmentation face wash at night on the affected areas of your face.',
      NightCreamIngredient:'Night Cream Ingredient:',
      NightCreamIngredient1:'Herpes Monnieri',
      NightCreamIngredient2:'Sphaeranthus Inclucus',
      NightCreamIngredient3:'Wild Turmeric',
      NightCreamIngredient4:'White Organ Oil',
      NightCreamIngredient5:'Wheat Oil',
      NightCreamIngredient6:'Tree Oil',
      NightCreamIngredient7:'Camela Powder',
      NightCreamIngredient8:'Zinc Oxide',
      NightCreamIngredient9:'Zincum Metelicum',
      NightCreamIngredient10:'Golonum Xanthocorpum Salt',
      NightCreamIngredient11:'Kristie Salt',
      SunBlockIngredients:'Sun Block Ingredients:',
      SunBlockIngredients1:'Golomun Xanthocarpum',
      SunBlockIngredients2:'Kristie Salt',
      SunBlockIngredients3:'Coconut',
      SunBlockIngredients4:'Sandal White',
      SunBlockIngredients5:'Mica White',
      SunBlockIngredients6:'Zinc Oxide',
      SunBlockIngredients7:'Calamine',
      SunBlockIngredients8:'Alum White',
      SunBlockIngredients9:'Mica Black',
      SunBlockIngredients10:'Pomegranate Flower',
      SerumIngredients:'Serum Ingredients:',
      SerumIngredients1:'Vitamin E Oil',
      SerumIngredients2:'Argan Oil',
      SerumIngredients3:'Butter Almond Oil',
      SerumIngredients4:'Wheat Oil',
      SerumIngredients5:'Liquid Paraffin',
      SerumIngredients6:'Herpes Monnicri',
      SerumIngredients7:'Golomun Xanthocarpum',
      SerumIngredients8:'Kristie Metelicum',
      SerumIngredients9:'Camela Powder',
      FaceWashIngredients:'Face Wash Ingredients:',
      FaceWashIngredients1:'Orange Peel',
      FaceWashIngredients2:'Lemon Peel',
      FaceWashIngredients3:'Herpes Monnieri',
      FaceWashIngredients4:'Spaerantus Inclucus',
      FaceWashIngredients5:'Barassica Compound',
      FaceWashIngredients6:'Diatomaceous Earth',
      FaceWashIngredients7:'White Sandal',
      FaceWashIngredients8:'White Turmeric',
      Weight:'Weight', 
      Weight1:'530 g', 
      NightCream:'Night Cream',
      NightCream1:'30 mL',
      SunBlock:'Sun Block',
      SunBlock1:'30 mL',
      Serum:'Serum',
      Serum1:'15 mL',
      Facewash:'Facewash',
      Facewash1:'200 mL',

    },


    {
      id: 11,
      title: "Skin Glowing Cream",
      price: 1600.0,
      img: require("./assets/Health & Beauty/skin-glowing-cream-greenrootspk.com.png"),
      quantity: 1,
      ml: '50 ML',
      dis:'Green Roots Skin Glowing Cream helps Nourishes and whitens the skin, leaving it soft, clear and glowing naturally. It can also be used on body to even tone the skin',
      SKU:'SKU:',
      SKU1:'GR026HB',
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'even skin tone, skin care, skin cleansing, skin glowing, skin lightening, skin whitening.',
      Benefits:'Benefits',
      Benefits1:'Makes the skin glow',
      Benefits2:'Makes the skin clean and fair',
      Benefits3:'Beneficial for two toned skin',
      Benefits4:'Makes the skin fresh and removes dullness',
      Benefits5:'Reduces pigmentation, brown spots and other skin blemishes',
      Benefits6:'Reduces the appearance of stretch marks',
      Benefits7:'Brightens skin tone',
      Benefits8:'Make your skin soft and smooth',
      Usage:'Usage',
      Usage1:'Apply day and night on your face daily.',
      Ingredients:'Ingredients',
      Ingredients1:'Saffron',
      Ingredients2:'Zinc Oxide',
      Ingredients3:'Bitter Almond',
      Ingredients4:'Coconut Milk',
      Ingredients5:'Symplocos Racemosa Roxb',
      Ingredients6:'Husn e Yousuf',
      Ingredients7:'Camilla Powder',
      Ingredients8:'Stone Flower',
      Weight:'Weight',
      Weight1:'225 g',
      dicQuantity:'Quantity',	
      dicQuantity1:'50 mL',

    },


    {
      id: 12,
      title: "Pigmentation Face Wash",
      price: 1200.0,
      img: require("./assets/Health & Beauty/pigmentation-facewash-greenrootspk.com_.png"),
      quantity: 1,
      ml:'200 ML',
      dis:'Green Roots Pigmentation Face Wash makes you get completely rid of Pigmentation from face. Helps to even tone the skin, leaves the skin clean and spot free.',
      SKU:'SKU:',
      SKU1:'GR035HB',
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'even skin tone, facewash, pigmentation, skin brightening, skin cleansing, skin lightening.',
      Benefits:'Benefits',
      Benefits1:'A part of Regime for washing the face daily.',
      Benefits2:'Helps clear the skin.',
      Benefits3:'Brightens the skin.',
      Benefits4:'Keeps the skin hydrated.',
      Benefits5:'Helps eradicate pigmentation.',
      Usage:'Usage',
      Usage1:'Wash your face twice a day.',
      Ingredients:'Ingredients',
      Ingredients1:'Orange Peel',
      Ingredients2:'Lemon Peel',
      Ingredients3:'Herpes Monnieri',
      Ingredients4:'Spaerantus Inclucus',
      Ingredients5:'Barassica Compound',
      Ingredients6:'Diatomaceous Earth',
      Ingredients7:'White Sandal',
      Ingredients8:'White Turmeric',
      Weight:'Weight',
      Weight1:'245 g',
      dicQuantity:'Quantity',	
      dicQuantity1:'200 mL',

    },


    {
      id: 13,
      title: "Pigmentation Serum",
      price: 2000.0,
      img: require("./assets/Health & Beauty/pigmentation-serum-greenrootspk.com_.png"),
      quantity: 1,
      ml:'15 ML',
      dis:'Green Roots Pigmentation Serum helps you get completely rid of Pigmentation from face. Helps to even tone the skin, leaves the skin clean and spot free.',
      SKU:'SKU:',
      SKU1:'GR037HB',
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'pigmentation, serum, skin brightening, skin care, skin lightening, skin nourishing.',
      Benefits:'Benefits',
      Benefits1:'Removes pigmentation from the skin.. Clears all sorts of marks from the skin.',
      Benefits2:'Enhances complexion.',
      Benefits3:'Improves the collagen level.',
      Benefits4:'Protects the skin from aging early',
      Usage:'Usage',
      Usage1:'Take few drops of Pigmentation serum and apply on the skin at day time daily.',
      SerumIngredients:'Serum Ingredients:',
      SerumIngredients1:'Vitamin E Oil',
      SerumIngredients2:'Argan Oil',
      SerumIngredients3:'Butter Almond Oil',
      SerumIngredients4:'Wheat Oil',
      SerumIngredients5:'Liquid Paraffin',
      SerumIngredients6:'Herpes Monnicri',
      SerumIngredients7:'Golomun Xanthocarpum',
      SerumIngredients8:'Kristie Metelicum',
      SerumIngredients9:'Camela Powder',
      Weight:'Weight',
      Weight1:'50 g',
      dicQuantity:'Quantity',	
      dicQuantity1:'15 mL',

    },


    {
      id: 14,
      title: "Pigmentation Night Cream",
      price: 2000.0,
      img: require("./assets/Health & Beauty/pigmentation-cream-greenrootspk.com_.png"),
      quantity: 1,
      ml: '30 ML',
      dis:'Green Roots Pigmentation Night Cream helps you get completely rid of Pigmentation from face. Helps to even tone the skin, leaves the skin clean and spot free.',
      SKU:'SKU:',
      SKU1:'GR036HB',
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:' cream, pigmentation, skin brightening, skin care, skin lightening, skin nourishing.',
      Benefits:'Benefits',
      Benefits1:'Works in removing pigmentation from the skin',
      Usage:'Usage',
      Usage1:'Apply on the pigmented areas of the skin at night daily.',
      Ingredients:'Ingredients',
      Ingredients1:'Herpes Monnieri',
      Ingredients2:'Sphaeranthus Inclucus',
      Ingredients3:'Wild Turmeric',
      Ingredients4:'White Organ Oil',
      Ingredients5:'Wheat Oil',
      Ingredients6:'Tree Oil',
      Ingredients7:'Camela Powder',
      Ingredients8:'Zinc Oxide',
      Ingredients9:'Zincum Metelicum',
      Ingredients10:'Golonum Xanthocorpum Salt',
      Ingredients11:'Kristie Salt',
      Weight:'Weight',
      Weight1:'285 g',
      dicQuantity:'Quantity',	
      dicQuantity1:'30 mL',

    },


    {
      id: 15,
      title: "Pigmentation Sun Block",
      price: 1200.0,
      img: require("./assets/Health & Beauty/pigmentation-sun-block-greenrootspk.com.png"),
      quantity: 1,
      ml: '30 ML',
      dis:'Green Roots Pigmentation Regime with night cream, sun block, serum and face-wash makes you get completely rid of Pigmentation from face. Helps to even tone the skin, leaves the skin clean and spot free.',
      SKU:'SKU:',
      SKU1:'GR038HB',
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'cream, pigmentation, skin lightening, skin protection, sun block, sunburn protection, UV protection.',
      Benefits:'Benefits',
      Benefits1:'Protects from harmful sun rays.',
      Benefits2:'Protects from sunburn.',
      Benefits3:'Protects the skin from stove heat (kitchen stove heat).',
      Benefits4:'Protects from dust and dirt',
      Usage:'Usage',
      Usage1:'Apply on the face daily at day time before leaving the house or going into the kitchen',
      Ingredients:'Ingredients',
      Ingredients1:'Golomun Xanthocarpum',
      Ingredients2:'Kristie Salt',
      Ingredients3:'Coconut',
      Ingredients4:'Sandal White',
      Ingredients5:'Mica White',
      Ingredients6:'Zinc Oxide',
      Ingredients7:'Calamine',
      Ingredients8:'Alum White',
      Ingredients9:'Mica Black',
      Ingredients10:'Pomegranate Flower',
      Weight:'Weight',
      Weight1:'285 g',
      dicQuantity:'Quantity',	
      dicQuantity1:'30 mL',

    },


    {
      id: 16,
      title: "Rose Water Bottle",
      price: 550.0,
      img: require("./assets/Health & Beauty/rose-water-bottle-greenrootspk.com.png"),
      quantity: 1,
      ml: '500 ML',
      dis:'Green Roots Rose Water captures the essence of roses and originates its equity from ‘Rose’. Our Rose Water has been extracted from best quality fresh roses. Rose Water is effective for heart, specifically for cardiac problems. It is also works as coolant for eyes, gets eyes clean and cool. With regular use of Green Roots Rose Water, your skin gets a rose-like glow. Green Roots Rose Water can also be used for mild skin care conditions.',
      SKU:'SKU:',
      SKU1:'GR022HB',
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'100% pure, arqe ghulab, even skin tone, eye health, fresh skin, healthy heart, rejuvenating, rose, rose waterskin, hydrating.',
      Benefits:'Benefits',
      Benefits1:'100% pure',
      Benefits2:'Keep the skin hydrated',
      Benefits3:'Keeps the skin fresh',
      Benefits4:'Works well as a cleanser',
      Benefits5:'Useful for the eyes',
      Benefits6:'Works as a natural skin toner',
      Benefits7:'Refreshes and cools',
      Benefits8:'Beneficial for cardiac problems',
      Benefits9:'Rejuvenating agent.',
      Usage:'Usage',
      Usage1:'Take 1/4 of Rose Water in a glass of plain water, drink early morning with some black resins (3,4).',
      Weight:'Weight',
      Weight1:'550 g',
      dicQuantity:'Quantity',	
      dicQuantity1:'500 mL',

    },


    {
      id: 17,
      title: "Rose Water Spray",
      price: 180.0,
      img: require("./assets/Health & Beauty/rose-spray-greenrootspk.com_.png"),
      quantity: 1,
      ml: '100 ML',
      dis:'Green Roots Rose Water Spray Bottle makes it easier for you to get benefits of rose water by just spraying it on the face, eyes or skin. Rose Water has been extracted from best quality fresh roses. By spraying on face it gives a fresh look and removes dirt from the face. it is also a good toner for the face. Spraying it on the hair can also help eradicate dandruff.',
      SKU:'SKU:',
      SKU1:'GR023HB',
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'100% pure rose water, arqe ghulab, cleanser, eye health, rejuvenating, rose spray, rose water, skin hydrating.',
      Benefits:'Benefits',
      Benefits1:'100% pure',
      Benefits2:'Keep the skin hydrated',
      Benefits3:'Keeps the skin fresh',
      Benefits4:'Works well as a cleanser',
      Benefits5:'Useful for the eyes',
      Benefits6:'Works as a natural skin toner',
      Benefits7:'Refreshes and cools',
      Benefits8:'Beneficial for redness of eyes.',
      Benefits9:'Rejuvenating agent',
      Usage:'Usage',
      Usage1:'Just spray all over your face for daily use. You can also spray on hair scalp.',
      Weight:'Weight',
      Weight1:'165 g',
      dicQuantity:'Quantity',	
      dicQuantity1:'100 mL',

    },


    {
      id: 18,
      title: "Acne Diffuser (Herbal Tea)",
      price: 600.0,
      img: require("./assets/Health & Beauty/acne-diffuser-greenrootspk.com_.png"),
      quantity: 1,
      gram: '160 Grams',
      dis:'Green Roots Acne Diffuser Herbal Tea is very effective for treating acne marks, signs of ageing, cures inflammation and prevent itching.',
      SKU:'SKU',
      SKU1:'GR002HB',
      Category:'Health & Beauty',
      Tags:'Tags',
      Tags1:'acne diffuser, acne herbal cure, acne natural cure, anti acne, east indian globe thistle, herbal tea, jujube, unaab.',
      Benefits:'Benefits',
      Benefits1:'Help get rid of acne marks.',
      Benefits2:'Helps clean and purify blood.',
      Benefits3:'Helps prevent acne.',
      Benefits4:'Helps against the sign of ageing.',
      Benefits5:'Cures inflammation.',
      Benefits6:'Fights with infectious pimples and prevent them in the future.',
      Benefits7:'Fights boils and acne vulgaris.',
      Benefits8:'Prevents itching.',
      Usage:'Usage',
      Usage1:'Take on and half cup / glass of water, add on tablespoon of the powder and cook / boil. Cook / boil enough for one cup / glass of water to be left behind and consume once a day. (Anytime in the day or night).',
      Ingredients:'Ingredients',
      Ingredients1:'Unaab (Jujube)',
      Ingredients2:'Gul E Mandi (East Indian Globe Thistle)',
      Weight:'Weight',
      Weight1:'175 g',
      dicQuantity:'Quantity',	
      dicQuantity1:'100 mL',
     

    },


    {
      id: 19,
      title: "Activated Charcoal Face Mask",
      price: 1100.0,
      img: require("./assets/Health & Beauty/activatedOutofstock.png"),
      quantity: 1,
      gram: '70 Grams',
      dis:'Green Roots Activated Charcoal Face Mask deeply cleanses, exfoliates and minimizes the pores from the skin. Makes skin clean and clear removes dead skin.',
      Availability:'Availability',
      Availability1:'Out of stock',
      SKU:'SKU',
      SKU1:'GR003HB',
      Category:'Health & Beauty',
      Tags:'Tags',
      Tags1:'activated charcoal, charcoal face mask, clear skin, oily skin care, skin lightening, skin nourishing facewash.',
      Benefits:'Benefits',
      Benefits1:'Helps draw impurities and dirt from your skin.',
      Benefits2:'Reduces acne breakouts.',
      Benefits3:'Helps reduce oil on the skin.',
      Benefits4:'Reduces and minimize pores.',
      Benefits5:'Softens the skin.',
      Benefits6:'Removes dead surface of the skin cells.',
      Benefits7:'Helps reduces acne scars and blemishes.',
      Benefits8:'Helps lighten up the complexion',
      Usage:'Usage',
      Usage1:'Take the amount of powder required to cover the area of application. Mix with rose water and apply evenly all over the face. Leave for about 20-25 minutes and rinse off with water',
      Ingredients:'Ingredients',
      Ingredients1:'Charcoal powder',
      Weight:'Weight',
      Weight1:'260 g',
      dicQuantity:'Quantity',
      dicQuantity1:'70 grams',

    },


    {
      id: 20,
      title: "Anti Acne Night Cream",
      price: 2000.0,
      img: require("./assets/Health & Beauty/anti-acne-night-cream-greenrootspk.com_.png"),
      quantity: 1,
      gram: '30 Grams',
      dis:'Green Roots Anti Acne Night Cream is a herbal healing treatment which deals with all types of acne and pimple problems. By fighting against bacteria and fungus which produces acne.',
      SKU:'SKU:',
      SKU1:'GR007HB', 
      Category:'Health & Beauty', 
      Tags:'Tags:',
      Tags1:'ant bacterial, anti acne, anti acne cream, anti fungal, anti inflammation creaman, tiseptic, sebum reduction, skin lightening, skin protection.',
      Benefits:'Benefits',
      Benefits1:'Reduces the production of sebum.',
      Benefits2:'Soothes inflammation..',
      Benefits3:'Speeds the process of healing acne.',
      Benefits4:'Works as a skin protectant.',
      Benefits5:'Fights bacteria and fungus on the skin.',
      Benefits6:'Prevents skin from breaking out.',
      Benefits7:'Has antiseptic qualities..',
      Benefits8:'Dries out pimples and reduces acne marks',
      Usage:'Usage',
      Usage1:'Apply on your face daily at night before sleeping.',
      Ingredients:'Ingredients',
      Ingredients1:'Mezereum',
      Ingredients2:'Zinc Oxide',
      Ingredients3:'Pomegranate Flower',
      Ingredients4:'Calamine Powder',
      Ingredients5:'Milk Thistle',
      Ingredients6:'Sphaeranthus indicus',
      Ingredients7:'Chinaberry',
      Ingredients8:'Tamarix Galicia',
      Weight:'Weight',
      Weight1:'	125 g',
      dicQuantity:'Quantity',
      dicQuantity1:'30 grams',

    },


    {
      id: 21,
      title: "Coco & Saffron Mask",
      price: 1600.0,
      img: require("./assets/Health & Beauty/coco-saffron-mask-greenrootspk.com_.png"),
      quantity: 1,
      gram: '70 Grams',
      dis:'Green Roots Coco and saffron Mask with the goodness of saffron, Almond, Chocolate and Barley is helpful to make your face clean from all sorts of spots, scars, pigmentation. Helps to slow down the fine lines from the skin',
      SKU:'SKU:',
      SKU1:'GR008HB',  
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'coco saffron mask, cocoa, cocoa saffron, pigmentation, saffron, skin lightening, skin nourishing, skin protection, skin whitening.',
      Benefits:'Benefits',
      Benefits1:'Whitens the skin tone..',
      Benefits2:'Reduces pigmentation, brown spots and blemishes.',
      Benefits3:'Gives skin elasticity and structure.',
      Benefits4:'Makes the skin soft.',
      Benefits5:'Helps slow down the signs of aging.',
      Benefits6:'Nourishes the skin.',
      Benefits7:'Cocoa helps filter UV rays.',
      Usage:'Usage',
      Usage1:'Take the amount required to cover the area of application.',
      Usage2:'Can be mixed with milk or yogurt. Apply evenly throughout the area of application and leave for 20-25 minutes. Rinse with water.',
      Ingredients:'Ingredients',
      Ingredients1:'Flour',
      Ingredients2:'Chocolate powder',
      Ingredients3:'Almond',
      Ingredients4:'Saffron',
      Weight:'Weight',
      Weight1:'275 g',
      dicQuantity:'Quantity',
      dicQuantity1:'70 grams',
    },


    {
      id: 22,
      title: "Detox Orange",
      price: 12000.0,
      img: require("./assets/Health & Beauty/detox-orange-greenrootspk.com_.png"),
      quantity: 1,
      gram: '640 Grams',
      dis:'Green Roots Detox Orange Probiotic fiber is the best herbal formula for losing weight faster. Not only does it make you slim it also makes you, look younger and improves the digestive system.Note: Not to be used by Diabetic Patients',
      SKU:'SKU',
      SKU1:'GR009HB', 
      Category:'Health & Beauty',
      Tags:'Tags',
      Tags1:'detox, healthy digestion, metabolism fix, orange extract, quick weight loss, skin care, weight loss.',
      Benefits:'Benefits',
      Benefits1:'Lose Weight (minimum 5-8 kg in a month)',
      Benefits2:'Best detox drink for male & female.',
      Benefits3:'Helps lose weight effectively.',
      Benefits4:'Promotes skin care.',
      Benefits5:'Improves bowel movement.',
      Benefits6:'Improves the digestive system',
      Usage:'Usage',
      Usage1:'Take 500ml water and put one tablespoon of Detox Orange early in the morning. On an empty stomach',
      Usage2:'Take 200ml water and put one tablespoon of Detox Orange before dinner. On an empty stomach.',
      UsageNotice:'(Detox Orange may effect certain people having specific medical or physical conditions – it is highly advisable to STOP USING IT IMMEDIATELY if it affects you in any way and consult your personal physician)',
      Ingredients:'Ingredients',
      Ingredients1:'Fiber Blend',
      Ingredients2:'Arabinogalactan',
      Ingredients3:'Organic psyllium',
      Ingredients4:'Husk (Plantago Ovata)',
      Ingredients5:'Psyllium Seed',
      Ingredients6:'Flaxseed Oil',
      Ingredients7:'Inulin (FOS)',
      Ingredients8:'Colon Blend',
      Ingredients9:'Rhubarb Root',
      Ingredients10:'Marshmallow Root',
      Ingredients11:'Slippery Elm Bark',
      Ingredients12:'Cape Aloe (Aloe Ferox)',
      Ingredients13:'Fennel Seed',
      Ingredients14:'Fennel Seed',
      Ingredients15:'Mineral Blend',
      Ingredients16:'Fermented Goat Milk Mineral Whey',
      Ingredients17:'Magnesium Citrate',
      Ingredients18:'Beet Root',
      Ingredients19:'Botanical Blend',
      Ingredients20:'Milk Thistle Extract',
      Ingredients21:'Hyssop Leaf',
      Ingredients22:'Ginger Root',
      Ingredients23:'Yucca Root',
      Ingredients24:'Roseman Leaf',
      Ingredients25:'Turmeric Root Extract',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'NOT SUITABLE FOR DIABETIC PATIENTS (Please consult our doctor in case of diabetic customized Detox Orange))',
      SpecialInstructions2:'– NOT SUITABLE FOR PREGNANT / NURSING MOTHERS.',
      SpecialInstructions3:'– Keep it airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions4:'– Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight:'Weight',
      Weight1:'	690 g',
      dicQuantity:'Quantity',
      dicQuantity1:'640 grams',

    },


    {
      id: 23,
      title: "Fit Aura-7",
      price: 3000.0,
      img: require("./assets/Health & Beauty/fit-aura-7-medicine-greenrootspk.com_.png"),
      quantity: 1,
      gram: '175 Grams',
      dis:'Green Roots Fit Aura – 7 serves as one of the best herbal sources that reduces excessive fats from the body and tones it down in the most ideal shape. It also helps in relieving constipation.',
      SKU:'SKU',
      SKU1:'GR010HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'constipation curedetoxquick weight lossweight lossweight reducer',
      Benefits:'Benefits',
      Benefits1:'Helps reduce weight.',
      Benefits2:'3-4 Kgs per month',
      Benefits3:'Helps relieves constipation',
      Benefits4:'Detoxes the excessive the body.',
      Benefits5:'Reduces weight from belly and thighs.',
      Usage:'Usage',
      Usage1:'For Adults: 1 (one) teaspoon before breakfast and 1 (one) teaspoon before sleeping at night. Both to be consumed with Lukewarm water.',
      Usage2:'For Children: (10-18): 1/2 (half) teaspoon before breakfast and 1 (one) teaspoon before sleeping. Both to be consumed with Lukewarm water.',
      UsageNotice:'(Fit Aura 7 may effect certain people having specific medical or physical conditions – it is highly advisable to STOP USING IT IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      Ingredients:'Ingredients',
      Ingredients1:'Senna leaves',
      Ingredients2:'Fenugreek Seeds',
      Ingredients3:'Salicha',
      Ingredients4:'Lak Maqsol',
      Ingredients5:'Cinquefoils',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'NOT SUITABLE FOR PREGNANT / NURSING MOTHERS.',
      SpecialInstructions2:'Keep it airtight after opening the seal (at all times).',
      Weight:'Weight',
      Weight1:'190 g',
      dicQuantity:'Quantity',
      dicQuantity1:'175 grams',

    },


    {
      id: 24,
      title: "Fit Aura - Lite",
      price: 1500.0,
      img: require("./assets/Health & Beauty/fit-aura-greenrootspk.com_.png"),
      quantity: 1,
      gram: '160 Grams',
      dis:'Green Roots FIT – AURA LITE is a source to reduce the excessive fat from the body and tone it down in the best shape.',
      SKU:'SKU',
      SKU1:'GR011HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'fitness regime, physical fitness, physical fitness natural medicine, physical fitness natural remedy.',
      Benefits:'Benefits',
      Benefits1:'This 3 month fitness program will give your body a beautiful shape automatically and a sense to stay healthy with no side-effects.',
      Usage:'Usage',
      Usage1:'Two teaspoons a day. One teaspoon in the morning before breakfast and one teaspoon in the evening, both to be consume with half cup Arq-e- Mehzal or Lukewarm water.',
      Ingredients:'Ingredients',
      Ingredients1:'Bishop’s Weed',
      Ingredients2:'Dried Ginger',
      Ingredients3:'Hill Mint',
      Ingredients4:'Lac seed',
      Ingredients5:'Senna',
      Ingredients6:'Mastic Gum',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'NOT SUITABLE FOR PREGNANT / NURSING MOTHERS.',
      SpecialInstructions2:'Keep it airtight after opening the seal (at all times).',
      Weight:'Weight',
      Weight1:'175 g',
      dicQuantity:'Quantity',
      dicQuantity1:'160 grams',

    },


    {
      id: 25,
      title: "Herbal Tea",
      price: 1600.0,
      img: require("./assets/Health & Beauty/herbal-tea-greenrootspk.com_.png"),
      quantity: 1,
      gram: '175 Grams',
      dis:'Green Roots Herbal Tea is not only used to help with cough and flu but also is best to treat all types of allergies, asthma and blood infections.',
      SKU:'SKU',
      SKU1:'GR013HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'anti allergy, cough cure, flu reliever, healthy breathing, herbal tea, immune booster, lungs detox.',
      Benefits:'Benefits',
      Benefits1:'Works on dust allergy',
      Benefits2:'Beneficial to relieve flu',
      Benefits3:'Beneficial for colds and sneezing',
      Benefits4:'Keeps the lungs clean',
      Benefits5:'Beneficial for dry cough.',
      Benefits6:'Beneficial for congestion and mucus prone cough.',
      Usage:'Usage',
      Usage1:'(For Children) 1/2 (half) tablespoon of Herbal Tea and mix it in a half cup of boiling water, serve it twice a day. (Sugar or honey can also be add as per taste or need)',
      Usage2:'(For Adults) Take 01 (one) tablespoon of Herbal Tea mix it in a cup of boiling water, drink thrice a day. (Sugar or honey can also be add as per taste or need).',
      Ingredients:'Ingredients',
      Ingredients1:'Unaab',
      Ingredients2:'Sounf',
      Ingredients3:'Mulethi',
      Ingredients4:'Gul e banafsha',
      Ingredients5:'Gul e gaozuban',
      Weight:'Weight',
      Weight1:'200 g',
      dicQuantity:'Quantity',
      dicQuantity1:'175 grams',

   },


   {
      id: 26,
      title: "Husn E Yusuf Cream",
      price: 1200.0,
      img: require("./assets/Health & Beauty/husne-yusuf-cream-greenrootspk.com_.png"),
      quantity: 1,
      gram: '210 Grams',
      dis:'Green Roots Husn-e-Yousuf beauty night cream make your skin moisturized, Also helps in fairness, removes dark spots and scars. Good for all skin types.',
      SKU:'SKU',
      SKU1:'GR015HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'anti acne, skin brightening, skin care, skin hydrating, skin moisturizing, skin soothing.',
      Benefits:'Benefits',
      Benefits1:'Keeps the skin hydrated.',
      Benefits2:'Keeps the skin moisture.',
      Benefits3:'Beneficial for dry skin.',
      Benefits4:'Beneficial for combination skin, it also makes the skin smooth and soft.',
      Benefits5:'Removes acne marks.',
      Benefits6:'Reduces freckles and dark spots.',
      Benefits7:'Whitens and brightens skin tone.',
      Usage:'Usage',
      Usage1:'Apply gently on your face at night before sleeping.',
      Ingredients:'Ingredients',
      Ingredients1:'Milk base',
      Ingredients2:'Husn e Yousuf',
      Ingredients3:'Vitamin E',
      Ingredients4:'Alpha whitening agent',
      Ingredients5:'Kojic acid',
      Weight:'Weight',
      Weight1:'230 g',
      dicQuantity:'Quantity',
      dicQuantity1:'210 grams',
      
   },


    {
      id: 27,
      title: "Moisturizing Soap",
      price: 600.0,
      img: require("./assets/Health & Beauty/moisturizingsoapoutofstock.png"),
      quantity: 1,
      gram: '100 Grams',
      dis:'Green Roots Herbal Moisturizing soap is used to make your skin moisturized and gives glow, and is and anti-aging aid as well.',
      Availability:'Availability',
      Availability1:'Out of stock',
      SKU:'SKU',
      SKU1:'GR018HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:' anti ageing, anti ageing soap, moisturizing soap, skin moisturizing, skin nourishment.',
      Benefits:'Benefits',
      Benefits1:'Helps in moisturizing skin.',
      Benefits2:'Helps in anti-ageing.',
      Usage:'Usage',
      Usage1:'Wash your face and body daily with Moisturizing Soap to keep it moisturized.',
      Ingredients:'Ingredients',
      Ingredients1:'Glycerin',
      Ingredients2:'Coconut Extract',
      Ingredients3:'Olive Extract',
      Weight:'Weight',
      Weight1:'100 g',
      dicQuantity:'Quantity',
      dicQuantity1:'100 grams',

    },


    {
      id: 28,
      title: "Whitening Soap",
      price: 600.0,
      img: require("./assets/Health & Beauty/whiteningsoapoutofstock.png"),
      quantity: 1,
      gram: '95 Grams',
      dis:'Green Roots Herbal whitening soap is natural way to remove dullness and tan from the skin and body.',
      Availability:'Availability',
      Availability1:'Out of stock',
      SKU:'SKU',
      SKU1:'GR034HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'skin care, skin moisturizing, skin tone lightening, skin whitening.',
      Benefits:'Benefits',
      Benefits1:'Beneficial for skin whitening.',
      Benefits2:'Beneficial to remove dullness.',
      Benefits3:'Beneficial to remove spots.',
      Usage:'Usage',
      Usage1:'Wash your face and body with this soap daily.',
      Ingredients:'Ingredients',
      Ingredients1:'Papaya Extracts',
      Ingredients2:'Rosemary Extract',
      Ingredients3:'Licorice Extract',
      Weight:'Weight',
      Weight1:'95 g',
      dicQuantity:'Quantity',
      dicQuantity1:'95 grams',
      
   },


    {
      id: 29,
      title: "Moroccan Rose Moisturizer Cream",
      price: 2100.0,
      img: require("./assets/Health & Beauty/moroccan-rose-moisturizer-cream-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',
      dis:'Green Roots Moroccan Rose Moisturizer Cream not only moisturizes your skin but the powerful Argan oil enriched with Vitamin A and E, and Rose oil also fights against fine lines and aging of the skin. By leaving the skin looking soft, smooth and younger looking',
      SKU:'SKU',
      SKU1:' GR019HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'anti ageing, anti wrinkles, skin lightening, skin moisturizing, skin nourishment, skin soothing, skin toning.',
      Benefits:'Benefits',
      Benefits1:'Keeps the skin soft and smooth',
      Benefits2:'Works as an anti aging agent.',
      Benefits3:'Keeps the skin moisturized.',
      Benefits4:'Helps reduce wrinkles.',
      Benefits5:'Lightens marks and scars.',
      Benefits6:'Nourishes dry skin.',
      Benefits7:'Beneficial for two toned skin.',
      Benefits8:'Lightens fine lines from skin and reduces the appearance in future',
      Usage:'Usage',
      Usage1:'Apply on you skin, neck, hands after washing to moisturize the skin. Use at day and night.',
      Ingredients:'Ingredients',
      Ingredients1:'Rose Oil',
      Ingredients2:'Argan Oil',
      Ingredients3:'Cocoa Butter',
      Ingredients4:'Caramel',
      Ingredients5:'Almonds',
      Ingredients6:'Aqua',
      Ingredients7:'Glycerin',
      Weight:'Weight',
      Weight1:'330 g',
      dicQuantity:'Quantity',
      dicQuantity1:'100 grams',
     
    },


    {
      id: 30,
      title: "Ubtan",
      price: 2100.0,
      img: require("./assets/Health & Beauty/greenrootspk.com-ubtan.png"),
      quantity: 1,
      gram: '140 Grams',
      dis:'Green Roots Ubtan is best to remove acne, acne scars, brightens dull skin, improves complexion and make your skin even tone.',
      SKU:'SKU',
      SKU1:'GR030HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'skin care, skin lightening, skin nourishing, skin soothing, ubtan.',
      Benefits:'Benefits',
      Benefits1:'Makes skin smooth.',
      Benefits2:'Makes skin shine and glow.',
      Benefits3:'Clears acne and acne marks.',
      Benefits4:'Very beneficial for people with dull skin',
      Benefits5:'Helps fight with two tone skin.',
      Benefits6:'Helps make skin fairer.',
      Usage:'Usage',
      Usage1:'Use this powder with milk, yogurt, and rose water (whatever is suitable) make a paste and apply it. when completely dried, rinse with water.',
      UsageNotice:'(This remedy may effect certain people having specific medical or physical conditions – it is highly advisable to STOP USING THE REMEDY IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      Ingredients:'Ingredients',
      Ingredients1:'Peeli Sarsu',
      Ingredients2:'Charongi',
      Ingredients3:'Haldi',
      Ingredients4:'Besan',
      Ingredients5:'Zafran',
      Weight:'Weight',
      Weight1:'165 g',
      dicQuantity:'Quantity',
      dicQuantity1:'140 grams',

    },


    {
      id: 31,
      title: "Skin Glowing Mask",
      price: 2000.0,
      img: require("./assets/Health & Beauty/skin-glowing-mask-greenrootspk.com.png"),
      quantity: 1,
      gram: '140 Grams',
      dis:'Green Roots Skin Glow Mask is a best natural way to remove blackheads, whiteheads and dirt from the skin. Makes your skin glowing and fresh.',
      SKU:'SKU',
      SKU1:'GR027HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'even skin tone, skin care, skin cleanser, skin glowing mask, skin lightening, skin nourishing',
      Benefits:'Benefits',
      Benefits1:'Actively removes dust absorbed in the skin.',
      Benefits2:'Efficient in removing black heads and white heads.',
      Benefits3:'Improves skin health and glow.',
      Benefits4:'Also beneficial for two toned skin.',
      Usage:'Usage',
      Usage1:'Use this powder with milk, yogurt, and rose water (whatever is suitable for your skin), make a paste and apply it for 30 minutes and rinse. ',
      Ingredients:'Ingredients',
      Ingredients1:'Saffron',
      Ingredients2:'Zinc oxide',
      Ingredients3:'Bitter almond',
      Ingredients4:'Coconut milk',
      Ingredients5:'Symplocos racemosa roxb',
      Ingredients6:'Husn e Yousuf',
      Ingredients7:'Camilla powder',
      Ingredients8:'Stone flower',
      Weight:'Weight',
      Weight1:'175 g',
      dicQuantity:'Quantity',
      dicQuantity1:'140 grams',
    
    },


    {
      id: 32,
      title: "Pigmentation Mask",
      price: 2800.0,
      img: require("./assets/Health & Beauty/pigmentation-mask-greenrootspk.com_.png"),
      quantity: 1,
      gram: '175 Grams',
      dis:'Green Roots Pigmentation Mask is a complete cure to remove pigmentation from skin and makes your skin fair. Also removes dark spots.',
      SKU:'SKU',
      SKU1:'GR020HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'anti marks, pigmentation, skin care, skin glowing, skin lightening, skin whitening.',
      Benefits:'Benefits',
      Benefits1:'Removes pigmentation from the face.',
      Benefits2:'Makes skin fairer and glowing.',
      Benefits3:'Removes dark spots.',
      Benefits4:'Makes skin clearer and smooth.',
      Usage:'Usage',
      Usage1:'Take this powder as per your need and make a fine paste with honey (not so thick or thin) apply it on your skin, leave it for an hour and wash with water.',
      Ingredients:'Ingredients',
      Ingredients1:'Baljhar',
      Ingredients2:'Nishasta',
      Ingredients3:'Maghz e badam',
      Ingredients4:'Husn e yousuf',
      Ingredients5:'Orange Peel',
      Ingredients6:'Zafran fried powder',
      Weight:'Weight',
      Weight1:'190 g',
      dicQuantity:'Quantity',
      dicQuantity1:'175 grams',

  },


    {
      id: 33,
      title: "Saffron Scrub",
      price: 1500.0,
      img: require("./assets/Health & Beauty/saffron-scrub-greenrootspk.com_.png"),
      quantity: 1,
      gram: '75 Grams',
      dis:'Green Roots Saffron Face Scrub enriched with Almonds, rice and Saffron deals with all kind of skin scar, pigmentation, spots etc.',
      SKU:'SKU',
      SKU1:'GR024HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'anti ageing, even skin tone, pigmentation, saffron, skin lightening, skin nourishing, skin soothing.',
      Benefits:'Benefits',
      Benefits1:'Nourishes the skin, making is soft and smooth.',
      Benefits2:'Helps slow down the process of ageing.',
      Benefits3:'Helps reduces brown spots and pigmentation.',
      Benefits4:'Lightens skin tone.',
      Benefits5:'Reduces dark patches.',
      Usage:'Usage',
      Usage1:'Take the amount of the powder required to cover the area of application. Can be mixed with milk or yogurt. Scrub gently on the skin in circular motion. Leave for 20-25 minutes and rinse off with water.',
      Ingredients:'Ingredients',
      Ingredients1:'Rice',
      Ingredients2:'Almonds',
      Ingredients3:'Saffron',
      Weight:'Weight',
      Weight1:'280 g',
      dicQuantity:'Quantity',
      dicQuantity1:'75 grams',

    },


    {
      id: 34,
      title: "Sandal Orange Scrub",
      price: 900.0,
      img: require("./assets/Health & Beauty/sandal-orange-scrub-greenrootspk.com_.png"),
      quantity: 1,
      gram: '75 Grams',
      dis:'Green Roots Sandal & Orange Face Scrub is rich in Vitamin C, Zinc and Iron. It whitens the skin, works as an anti-ageing agent and aids in skin exfoliation and helps to dry out acne.',
      SKU:'SKU',
      SKU1:'GR025HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:' anti acne, anti ageing, even skin tone, orange, sandal, sandal orange, scrub, skin care, skin lightening, skin nourishing, skin whitening.',
      Benefits:'Benefits',
      Benefits1:'Whitens the skin tone',
      Benefits2:'Works as an anti-tan agent',
      Benefits3:'Prevents pimples, acne and sores from developing.',
      Benefits4:'Prevents exposure to dust and dirt can cause bacterial growth on your skin.',
      Benefits5:'The richness of orange slows down the production of wrinkles, and premature ageing.',
      Benefits6:'Orange aids in skin exfoliation and helps to dry out acne, improving the overall look of your skin.',
      Benefits7:'Saffron extracts acts as an excellent natural ingredient for reducing pigmentation, brown spots and other skin blemishes.',
      Benefits8:'Works on skin lightening.',
      Benefits9:'Barley extracts work as collagen and gives skin structure and elasticity.',
      Benefits10:'It also contains zinc, B vitamins, and iron, all of which are essential for promoting the health of skin.',
      Benefits11:'Barley also contains selenium, a mineral that doubles as a powerful antioxidant, which helps protect skin cells from free radical damage.',
      Howtouse:'How to use',
      Howtouse1:'Take the amount of powder required to cover the area that needs application. Can be mixed with milk or yoghurt. Scrub gently on the skin and wash off with water after 20-25 minutes.',
      Ingredients:'Ingredients',
      Ingredients1:'Sandal Powder',
      Ingredients2:'Orange Peel Powder',
      Ingredients3:'Joh',
      Weight:'Weight',
      Weight1:'285 g',
      dicQuantity:'Quantity',
      dicQuantity1:'75 grams',
      
    },


    {
      id: 35,
      title: "Honey",
      price: 1200.0,
      img: require("./assets/Health & Beauty/honeyoutofstock.png"),
      quantity: 1,
      gram: '250 Grams',
      dis:'Green Roots Honey is a cure for many problems of the body, hair and skin.',
      Availability:'Availability',
      Availability1:'Out of stock',
      SKU:'SKU',
      SKU1:'GR014HB',
      Category:'Health & Beauty',
      Tags:'Tags',
      Tags1:'100% organic honey, antibacterial, antifungal, antioxidants, cold cure, diarrhea cure, energizing, healing, healthy digestion, heartburn remedy, honey, immunity booster, infection cure, peaceful sleep, pure honey, shehed, sore throat cure.',
      Benefits:'Benefits',
      Benefits1:'Good source of antioxidants',
      Benefits2:'Heals wounds',
      Benefits3:'Antibacterial',
      Benefits4:'Antifungal',
      Benefits5:'Helps in digestive issues',
      Benefits6:'Helps soothe sore throats',
      Benefits7:'Boosts immunity',
      Benefits8:'Cures diarrhea',
      Benefits9:'Helps reduce heartburn',
      Benefits10:'Helps fight infections in the body',
      Benefits11:'Helps relieve cough and flu symptoms',
      Benefits12:'Induces good sleep',
      Benefits13:'Helps overcome weakness.',
      Usage:'Usage',
      Usage1:'Take 1 table of Honey with water daily to boost immunity, helps in digestion.',
      Usage2:'Apply on Skin to remove scars, and gives glow to skin..',
      Usage3:'Applying on hair makes Hair soft and thick',
      Weight:'Weight',
      Weight1:'350 g',
      dicQuantity:'Quantity',
      dicQuantity1:'250 grams',
     
    },
    {
      id: 36,
      title: "Tea Tree Soap",
      price: 700.0,
      img: require("./assets/Health & Beauty/tea-tree-soap-greenrootspk.com.png"),
      quantity: 1,
      gram: '100 Grams',
      dis:'Green Roots Tea-Tree Herbal soap is best and natural way to combat all types of acne prone and allergic skin.',
      SKU:'SKU',
      SKU1:'GR029HB', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'anti acneanti inflammationantibacterialeczema cureinflection curenatural soapoily skinorganic soappsoriasis reliefskin healthskin immunityskin nourishmenttea treetea tree soap',
      Benefits:'Benefits',
      Benefits1:'Benefits of Tea Tree for the skin.',
      Benefits2:'Tea tree can help soothe dry skin by reducing itching and irritation.',
      Benefits3:'Helps combat oily skin.',
      Benefits4:'Helps treat Eczema.',
      Benefits5:'Helps heal infections.',
      Benefits6:'Anti-Inflammatory.',
      Benefits7:'Reduces swelling and redness',
      Benefits8:'Antibacterial',
      Benefits9:'Treats acne.',
      Benefits10:'Boost skin immunity.',
      Benefits11:'Helps fight psoriasis.',
      Usage:'Usage',
      Usage1:'Wash your face and body daily',
      Ingredients:'Ingredients',
      Ingredients1:'Tea Tree Oil Extract',
      Ingredients2:'Chloroxylenol',
      Weight:'Weight',
      Weight1:'500 g',
      dicQuantity:'Quantity',
      dicQuantity1:'100 grams',

    },



    {
      id: 37,
      title: "Vitamin C Serum Cream ",
      price: 1200.0,
      img: require("./assets/newgreen  Web Products 1500 x1200px-05/vitaminserum.jpg"),
      quantity: 1,
      ml: '15 ML',
      dis:'Enhance your skin care routine with our newly launched Vitamin C Serum Cream.',
      SKU:'SKU',
      SKU1:'GR037HB-1', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'pigmentation serumskin brightening skin care skin lightening skin nourishing',
      Benefits:'Benefits',
      Benefits1:'Works on discoloration and uneven skin tone.',
      Benefits2:'Enhances the skin texture.',
      Benefits3:'Leaves the skin glowing .',
      Benefits4:'Thin & nonsticky texture.',
      Benefits5:'Lightweight to use .',
      Benefits6:'Works on scars and marks.',
    
     
      Weight:'Weight',
      Weight1:'30 g',
      dicQuantity:'Quantity',
      dicQuantity1:'15  ML',

    },


    {
      id: 38,
      title: "Rosey - Lip and Cheek Tint ",
      price: 1600.0,
      img: require("./assets/newgreen  Web Products 1500 x1200px-05/Roseylip.jpg"),
      quantity: 1,
      ml: '15 ML',
      dis:'Enhance your skin care routine with our newly launched Rosey Lip & Cheek tint.',
      SKU:'SKU',
      SKU1:'GR037HB-1-1', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'pigmentation serum skin brightening skin care skin lightening skin nourishing',
      Howtouse:'How to use',
      Howtouse1:'Take one or two drops, as per required on your fingertips and apply on the lips, and cheeks.',
    
     
      Weight:'Weight',
      Weight1:'30 g ',
      dicQuantity:'Quantity',
      dicQuantity1:'15  ML',

    },





    // {
    //   id: 39,
    //   title: "Saffron – Secret to your best self ",
    //   price: 900.0,
    //   img: require("./assets/newgreen  Web Products 1500 x1200px-05/saffron.jpg"),
    //   quantity: 1,
    //   gram: '1 gram ',
    //   dis:'Introducing our newly launched Saffron – Secret to your best self .',
    //   SKU:'SKU',
    //   SKU1:'GR029HB', 
    //   Category:'Health & Beauty',
    //   Tags:'Tags:',
    //   Tags1:'anti acneanti inflammationantibacterialeczema cureinflection curenatural soapoily skinorganic soappsoriasis reliefskin healthskin immunityskin nourishmenttea treetea tree soap',
    //   Benefits:'Benefits',
    //   Benefits1:'Powerful Antioxidant .',
    //   Benefits2:'Contains cancer fighting properties .',
    //   Benefits3:'Helps reduce PMS symptoms  .',
    //   Benefits4:'Helps reduce appetite and aids weight loss .',
    //   Benefits5:'Improves mood  .',
    //   Benefits6:'Treats depressive symptoms .',
    
    //   Benefits7:'Improves skin tone  .',
    //   Benefits8:'Helps reduces access sun tan  .',
    //   Benefits9:'Provides a natural radiance to the skin  .',
    //   Benefits10:'Smoothens skin texture   .',
    //   Benefits11:'Helps treat hyperpigmentation, acne, acne scars .',
     
    //   Weight:'Weight',
    //   Weight1:'1 g',
    //   dicQuantity:'Quantity',
    //   dicQuantity1:'1 grams',

    // },

    {
      id: 39,
      title: "Pure Saffron ",
      price: 900.0,
      img: require("./assets/newgreen  Web Products 1500 x1200px-05/saffron.jpg"),
      quantity: 1,
      ml: '15 ML ',
      dis:'Introducing our newly launched Saffron – Secret to your best self .',
      SKU:'SKU',
      SKU1:'GR037HB-1-1-1', 
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'pigmentation serumskin brightening skin careskin lightening skin nourishing',
      Benefits:'Benefits',
      Benefits1:'Powerful Antioxidant .',
      Benefits2:'Contains cancer fighting properties .',
      Benefits3:'Helps reduce PMS symptoms  .',
      Benefits4:'Helps reduce appetite and aids weight loss .',
      Benefits5:'Improves mood  .',
      Benefits6:'Treats depressive symptoms .',
    
      Benefits7:'Improves skin tone  .',
      Benefits8:'Helps reduces access sun tan  .',
      Benefits9:'Provides a natural radiance to the skin  .',
      Benefits10:'Smoothens skin texture   .',
      Benefits11:'Helps treat hyperpigmentation, acne, acne scars .',
     
      Weight:'Weight',
      Weight1:'1 g',
      dicQuantity:'Quantity',
      dicQuantity1:'15 ML',

    },
    
]