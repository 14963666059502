export default [
  {
    id: 1,
    title: "Almond Oil",
    price: 1600.0,
    img: require("./assets/Natural Oil/almond-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Almond Oil is a good source to give nourishment to the hair, helps in hair growth. Almond oil is a good moisturizer for skin and body. Also good for anti Aging.',
    SKU: 'SKU',
    SKU1: 'GR001OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' almond oil, constipation cure, hair nourishment, hair repair, natural oil, skin lightening, skin nourishment, skin whitening, vitamin E',
    Benefits: 'Benefits',
    Benefits1: 'Rich in vitamin E which helps in whitening of skin.',
    Benefits2: 'Good moisturizer for skin.',
    Benefits3: 'Gives nourishment to damaged hairs.',
    Benefits4: 'Helps in relieving constipation by taking once in a day with milk. ',
    Usage: 'Usage',
    Usage1: 'Apply on your hair on alternate days for good nourishment of the hair.',
    Usage2: 'Apply on face and body after wash and also at night before sleep.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 2,
    title: "Almond Oil",
    price: 2600.0,
    img: require("./assets/Natural Oil/almond-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '200 ML',

    dis: 'Green Roots Almond Oil is a good source to give nourishment to the hair, helps in hair growth. Almond oil is a good moisturizer for skin and body. Also good for anti Aging.',
    SKU: 'SKU',
    SKU1: 'GR001OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' almond oil, constipation cure, hair nourishment, hair repair, natural oil, skin lightening, skin nourishment, skin whitening, vitamin E',
    Benefits: 'Benefits',
    Benefits1: 'Rich in vitamin E which helps in whitening of skin.',
    Benefits2: 'Good moisturizer for skin.',
    Benefits3: 'Gives nourishment to damaged hairs.',
    Benefits4: 'Helps in relieving constipation by taking once in a day with milk. ',
    Usage: 'Usage',
    Usage1: 'Apply on your hair on alternate days for good nourishment of the hair.',
    Usage2: 'Apply on face and body after wash and also at night before sleep.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '200 mL',
  },
  {
    id: 3,
    title: "Amla Oil (Indian Gooseberry)",
    price: 650.0,
    img: require("./assets/Natural Oil/amla-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Amla Oil (Indian gooseberry oil) is to make hair soft and shiny prevents hair loss gives volume to Hair. Is good to relax your mind by massaging on hair scalp',
    SKU: 'SKU',
    SKU1: 'GR003OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'amla oil, hair growth, hair nourishment, hair repair, indian gooseberry, natural oil, stress reliever',
    Benefits: 'Benefits',
    Benefits1: 'Makes hair soft.',
    Benefits2: 'Helps in growth of hair.',
    Benefits3: 'Used to relax mind when massaged in the hair',
    Usage: 'Usage',
    Usage1: 'Apply on the hair at night wash it in morning.',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 4,
    title: "Anti Lice Oil",
    price: 1500.0,
    img: require("./assets/Natural Oil/anti-lice-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Anti Lice Hair Oil helps you get rid of lice and dandruff. It also makes your hair strong and prevents hair loss.',
    SKU: 'SKU',
    SKU1: 'GR005OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: ' anti lice, anti nits, natural oil, scalp protection',
    Benefits: 'Benefits',
    Benefits1: 'Removes lice',
    Benefits2: 'Removes nits',
    Benefits3: 'Finishes the development of the eggs which transform into lice and nits in the scalp.',
    Benefits4: 'Prevents lice and nits from being initially born in the hair.',
    Usage: 'Usage',
    Usage1: 'Use Green Roots Anti Lice Oil thrice a week and let it be in your hairs for at least two hours.',
    Ingredients:'Ingredients',
    Ingredients1:'Neem Oil (Margosa Leaves Oil)',
    Ingredients2:'Arugula Oil',
    Ingredients3:'Eucalyptus Oil',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 5,
    title: "Argan Oil",
    price: 7000.0,
    img: require("./assets/Natural Oil/argan-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Argan Oil is rich in Vitamin E, which helps in Anti-Aging and also used as a hair serum regrowth that makes hair shiny, smooth and strong.',
    SKU: 'SKU',
    SKU1: 'GR006OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'anti ageing, argan oil, hair nourishment, hair repair, hair strengthening, natural oil, skin care',
    Benefits: 'Benefits',
    Benefits1: 'Makes hair long and thick.',
    Benefits2: 'Protects from sun damage.',
    Benefits3: 'Promotes healthy skin.',
    Benefits4: 'Anti-ageing for skin. ',
    Usage: 'Usage',
    Usage1: 'Apply on the skin massage a little at night and wash in the morning.',
    Usage2: 'Apply on the hair at night wash in the morning.',
    Weight: ' Weight',
    Weight1: '135 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 6,
    title: "Castor Oil",
    price: 400.0,
    img: require("./assets/Natural Oil/castor-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Castor Oil helps in hair regrowth. It also works for low density hair by increasing hair volume. Castor Oil relieves constipation.',
    SKU: 'SKU',
    SKU1: 'GR009OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'castor oil, constipation remedy, hair growth, hair nourishment, natural oil, pure oil',
    Benefits: 'Benefits',
    Benefits1: 'Helps in growth of hair.',
    Benefits2: 'Helps in the growth of lashes and eyebrows hair.',
    Benefits3: 'Helps in relieving constipation.',
    Usage: 'Usage',
    Usage1: 'Apply on hair at night wash in morning.',
    Usage2: 'Apply on eyelashes and eyebrows at night before sleep.',
    Usage3: 'Take 1 teaspoon mix with a glass of warm milk before sleep.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 7,
    title: "Castor Oil",
    price: 600.0,
    img: require("./assets/Natural Oil/castor-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '200 ML',

    dis: 'Green Roots Castor Oil helps in hair regrowth. It also works for low density hair by increasing hair volume. Castor Oil relieves constipation.',
    SKU: 'SKU',
    SKU1: 'GR009OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'castor oil, constipation remedy, hair growth, hair nourishment, natural oil, pure oil',
    Benefits: 'Benefits',
    Benefits1: 'Helps in growth of hair.',
    Benefits2: 'Helps in the growth of lashes and eyebrows hair.',
    Benefits3: 'Helps in relieving constipation.',
    Usage: 'Usage',
    Usage1: 'Apply on hair at night wash in morning.',
    Usage2: 'Apply on eyelashes and eyebrows at night before sleep.',
    Usage3: 'Take 1 teaspoon mix with a glass of warm milk before sleep.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '200 mL',
  },
  {
    id: 8,
    title: "Coconut Oil",
    price: 450.0,
    img: require("./assets/Natural Oil/coconut-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '120 ML',

    dis: 'Green Roots Coconut Oil offers natural nourishment to your hair. Is a good Moisturizer to skin and body.',
    SKU: 'SKU',
    SKU1: 'GR012OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'coconut oil, hair fall, hair nourishment, hair repair, hair strength, natural oil, pure oil, skin care, skin moisturizing, skin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Nourishes your hairs.',
    Benefits2: 'Makes your hair shiny and smooth.',
    Benefits3: 'It is a good moisturizer for the skin and body.',
    Benefits4: 'Beneficial for treating hair fall. ',
    Benefits5: 'Usage Apply on hair for treating hair fall',
    Benefits6: 'Apply on skin and body at night to moisturize.',
    Usage: 'Usage',
    Usage1: 'Apply on hair for treating hair fall',
    Usage2: 'Apply on skin and body at night to moisturize.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 mL',
  },
  {
    id: 9,
    title: "Coconut Oil",
    price: 750.0,
    img: require("./assets/Natural Oil/coconut-oil-greenrootspk.com_.png"),
    quantity: 1,
    ml: '200 ML',

    dis: 'Green Roots Coconut Oil offers natural nourishment to your hair. Is a good Moisturizer to skin and body.',
    SKU: 'SKU',
    SKU1: 'GR012OL',
    Category: 'Natural Oils',
    Tags: 'Tags',
    Tags1: 'coconut oil, hair fall, hair nourishment, hair repair, hair strength, natural oil, pure oil, skin care, skin moisturizing, skin nourishment',
    Benefits: 'Benefits',
    Benefits1: 'Nourishes your hairs.',
    Benefits2: 'Makes your hair shiny and smooth.',
    Benefits3: 'It is a good moisturizer for the skin and body.',
    Benefits4: 'Beneficial for treating hair fall. ',
    Benefits5: 'Usage Apply on hair for treating hair fall',
    Benefits6: 'Apply on skin and body at night to moisturize.',
    Usage: 'Usage',
    Usage1: 'Apply on hair for treating hair fall',
    Usage2: 'Apply on skin and body at night to moisturize.',
    Weight: ' Weight',
    Weight1: '210 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '200 mL',
  },
]