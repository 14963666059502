import React, { useState } from 'react';
import { Navbar, Container, Nav, } from 'react-bootstrap';
import logo from "../assets/Green Roots Logo (2).png"
// import logo from "../assets/Dr Essa lockup-01.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./Headertwo.css";

function Header() {
  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [searchValue, setSearchValue] = useState('');

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        console.log('Search value:', searchValue);
        // Add your search logic here
    };
  const [expanded, setExpanded] = useState(false);
  return (
    <header className='header_2'>
      <div className='navbarr' >
        <Navbar expand="lg" expanded={expanded}>
          <Container className='container_header'>
            <div className="main_headerrrr_2">

              <Navbar.Brand href="/"><img src={logo} className='img__navbar' /></Navbar.Brand>

              <div className="navvv_barrrr">
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} style={{ backgroundColor: 'none' }} />

                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mx-auto w-100 align-items-center justify-content-evenly">
                    <div className="main_headerr_2">
                      <div className='links'>
                        <Link to="/" className='navbar__a'>Home</Link>
                        <Link to="/About" className='navbar__a'>About Us</Link>
                        <Link to="/Story" className='navbar__a'>Our Story</Link>
                        <Link to="/Vison" className='navbar__a'>Our Vision</Link>
                      </div>
                      <div className='btns'>
                        <div className="contact_me">
                          <i className="fa-solid fa-phone" style={{ fontSize: '16px' }}></i>
                          <a href="tel::+92-21 35303761-4" className='a_num' style={{ fontSize: '16px' }}>:+92-21 35303761-4</a> <br />
                        </div>
                        <div className="button d-none">
                          <Link to="#" className="login-button">
                            <FontAwesomeIcon icon={faUser} className='login_icons' />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Nav>
                </Navbar.Collapse>
                <div className="offcanvas__">
                  <Button variant="primary" onClick={handleShow} style={{ color: '#000', backgroundColor: '#fff', border: 'none' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16" viewBox="0 0 30 16" fill="currentColor">
                      <rect width="30" height="1.5"></rect>
                      <rect y="7" width="20" height="1.5"></rect>
                      <rect y="14" width="30" height="1.5"></rect>
                    </svg>
                  </Button>

                  <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>
                        <img src={logo} alt="" style={{ width: 'auto', height: '60px' }} />

                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <div className='links'>
                        <ul>
                          <li><Link to="/" className='navbar__a' style={{ fontSize: "20px" }} >Home</Link></li>
                          <li> <Link to="/About" style={{ fontSize: "20px" }} className='navbar__a'  >About Us</Link></li>
                          <li> <Link to="/Story" style={{ fontSize: "20px" }} className='navbar__a'  >Our Story</Link></li>
                          <li>   <Link to="/Vison" style={{ fontSize: "20px" }} className='navbar__a'  >Our Vision</Link></li>



                         
</ul>

                      </div>
                      <div className='btns'>
                  
                        <div className="button d-none">
                          <Link to="#" className="login-button">
                            <FontAwesomeIcon icon={faUser} className='login_icons' />
                          </Link>
                        </div>
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
                <div className="whats_icon2">
                  <Link to="https://shop.greenrootspk.com/cart/">
                    <i className="fa-solid fa-cart-shopping" style={{ fontSize: '20px', color: '' }}> <span className="counter"></span></i>
                  </Link>
                </div>
              </div>

            </div>

        
          </Container>
          <div className="contact_me_ ">
                          <i className="fa-solid fa-phone" style={{ fontSize: '16px' }}></i>
                          <a href="tel::+92-21 35303761-4" className='a_num' style={{ fontSize: '16px' }}>:+92-21 35303761-4</a> <br />
                        </div>
        </Navbar>
    
      </div>

      
    </header>
  )
}

export default Header