export default [
    {
      id: 1,
      title: "Aloe Vera Oil",
      price: 1000.0,
      img: require("./assets/Essential Oil/aloe-vera-oil-greenrootspk.com_.png"),
      quantity: 1,
      ml:'30 ML',
      dis:'Green Roots Aloe Vera Oil helps in skin rejuvenating and repairs damaged skin cells. It is also beneficial for hair cells and healthy hair growth.',
      SKU:'SKU',
      SKU1:'GR002OL', 
      Category:'Essential Oils',
      Tags:'Tags:',
      Tags1:'aloe vera oil, anti ageing, anti dandruff, essential oil, hair growth, hair nourishment, skin care, skin cell, repair skin lightening skin nourishment',
      Benefits:'Benefits',
      Benefits1:'Helps repairing skin cells',
      Benefits2:'Has great anti-ageing properties.',
      Benefits3:'Beneficial in removing stretch marks and wrinkles',
      Benefits4:'Heals dark spot on the skin and helps in dry skin issues.',
      Benefits5:'Makes hair healthy, strong and dandruff free.',
      Usage:'Usage',
      Usage1:'By mixing Aloe Vera oil with any carrier oil, for example, with coconut or Jojoba oil and applying on skin will help treat and heal damaged skin, lightens skin tone, and help in removing fine lines.',
      Usage2:'By applying the mixture onto your hair scalp and all over the hair helps repair damaged hair.',
      Usage3:'It also prevents hair loss and dandruff and helps in healthy hair growth.',
      Weight:'Weight',
      Weight1:'80 g',
      dicQuantity:'Quantity',
      dicQuantity1:'30 ml',

    },

   
    {
        id: 2,
        title: "Black Pepper Oil",
        price: 6000.0,
        img: require("./assets/Essential Oil/black-pepper-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Black Pepper Oil.',
        SKU:'SKU',
        SKU1:'GR007OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti anxiety, black pepper oil, cancer beneficent, constipation cure, essential oil, healthy digestion, healthy metabolism, joint pain relief, muscle relaxant, nutrition absorption, weight loss.',
        Benefits:'Benefits',
        Benefits1:'Increases nutrition absorption',
        Benefits2:'Improves digestion',
        Benefits3:'Aids in weight loss',
        Benefits4:'Promotes sweating and urination',
        Benefits5:'Relieves joint & muscle pains',
        Benefits6:'Calms anxiety',
        Benefits7:'Boosts metabolism',
        Benefits8:'Prevents new fat cells from forming',
        Benefits9:'Acts as an antidepressant',
        Benefits10:'Promotes dental health',
        Benefits11:'Prevents cancer',
        Benefits12:'Relieves gas & constipation',
        UsesofBlackPepperoil:'UsesofBlackPepperoil',
        UsesofBlackPepperoil1:'Do not apply to skin undiluted',
        UsesofBlackPepperoil2:'Can be used for cooking',
        UsesofBlackPepperoil3:'Can be mixed with carrier oils for multiple skin benefits',
        UsesofBlackPepperoil4:'Can be used for massages mixed with other oils',
        UsesofBlackPepperoil5:'Can be used on chest and nose for flu and chest congestion mixed with any carrier oil',
        UsesofBlackPepperoil6:'Can be used as a diffuser and can be inhaled as well',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


//     {
//         id: 3,
//         title: "Carrot Seed Oil",
//         price: 5000.0,
//         img: require("./assets/Essential Oil/carrotseedoiloustofstock.png"),
//         quantity: 1,
//         ml:'30 ML',
//         dis:'Carrot seed essential oil has shown antibacterial, antifungal, anti-inflammatory, and antioxidant properties. What it doesn’t have are the vitamins and nutrients provided by carrots. Like other essential oils, carrot seed oil isn’t meant to be ingested.',
//         Availability:'Availability',
//         Availability1:' : Out of stock',
//         SKU:'SKU',
//         SKU1:'GR008OL', 
//         Category:'Essential Oils',
//         Tags:'Tags:',
//         Tags1:'antioxidant aromatherapy cancer beneficent carrot seed oil detox essential oil hair nourishment hair strengthening menstrual cycles exual performance stress reliever worms cure',
//         Benefits:'Benefits',
//         Benefits1:'Contains antioxidants & anti ageing properties',
//         Benefits2:'Fights infection',
//         Benefits3:'Detoxifies blood',
//         Benefits4:'Prevent acidity',
//         Benefits5:'Kills worms',
//         Benefits6:'Can be used for aromatherapy',
//         Benefits7:'Controls menstrual cycle',
//         Benefits8:'Strengthens hair & makes them shiny',
//         Benefits9:'Firms and tones skin',
//         Benefits10:'Helps cure cancer of prostate, mouth, stomach and kidneys.',
//         Benefits11:'Cures infections and prevents tetanus',
//         Benefits12:'Provides relief from stress',
//         Benefits13:'Protects from macular degeneration and sexual weaknesses',
//         Benefits14:'Expels gas from intestines',
//         Usage:'Usage',
//         Usage1:'When used for aromatherapy, you can sprinkle a few drops onto a cloth or tissue and inhale deeply, or use a commercial aromatherapy diffuser or vaporizer. Practitioners of aromatherapy often combine carrot seed oil with other therapeutic oils, such as cedar, cinnamon, grapefruit, or geranium essential oil.',
//         Usage2:'Like all essential oils, carrot seed oil must be mixed with a carrier oil before applying topically. For this reason, it cannot be used as a tanning oil. Tanning oils, including those with SPFs, attract the sun’s UVA rays to your skin. Some people use them to try to tan safely, but there is no way to get a safe tan.',
//         Usage3:'It can be added to a cream or used in a mixture with almond oil: it tones the skin and even removes wrinkles. It is usually enough to add 3-5 drops of essential oils of carrots to the basis of 10 g oil or cream. In winter and early spring, it helps to compensate for the deficiency of vitamins in the skin',
//         Usage4:'When used topically, add no more than 12 drops of carrot seed oil to 1 fluid ounce (30 milliliters) of a carrier oil, lotion, vegetable butter, or moisturizing cream. Carrot seed oil must never be used near the eyes, in the nose or ears, on the genitals, or on any other sensitive areas of skin.',
//         Weight:'Weight',
//         Weight1:'80 g',

//    },


    {
        id: 4,
        title: "Cinnamon Oil",
        price: 1600.0,
        img: require("./assets/Essential Oil/cinnamon-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Cinnamon Oil.',
        SKU:'SKU',
        SKU1:'GR010OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti bacterial, anti depressionar, thritis cure, blood purifier, cinnamon oil, diabetes control, essential oil, healthy heart, kidney protection, menstruation, cycle, pain relief.',
        Benefits:'Benefits',
        Benefits1:'Provides pain relief',
        Benefits2:'Helps with arthritis',
        Benefits3:'Helps with diabetes',
        Benefits4:'Improves digestive health',
        Benefits5:'Helps with irregular menstruation',
        Benefits6:'Boosts the immune system',
        Benefits7:'Prevents urinary infections',
        Benefits8:'Protects the kidneys',
        Benefits9:'Helps fight depression',
        Benefits10:'Cleans the air from bacteria',
        Benefits11:'Removes bad breath',
        Benefits12:'Purifies blood',
        Benefits13:'Works as a brain tonic',
        Benefits14:'Helps fight skin infections',
        Benefits15:'Helps in respiratory problems',
        Benefits16:'Beneficial for heart disorders',
        Usage:'Usage',
        Usage1:'In aromatherapy, essential oils are diffused or spritzed into the air so they can be breathed in or absorbed into the skin. Aromatherapy has been linked to many benefits, including reduced depression and anxiety, and better sleep.',
        Usage2:'Mask made of cinnamon, olive oil, and honey may be effective at promoting the growth and thickening of hair.',
        Usage3:'Cinnamon oil is used as an ingredient in toiletries, such as toothpaste, mouthwash, soap, and body lotion. It’s also used to scent room sprays, candles, and seasonal items like pine cones and garlands.',
        Usage4:'A general recommendation is to keep the concentration of cinnamon oil low if it’s going to come in contact with skin. For example, one guideline of .01 percent concentration or less is equivalent to 1 drop of cinnamon oil for 30 to 40 milliliters (mL) of carrier liquid.',
        Usage5:'To scent your home, try adding one drop of cinnamon oil to fabric sachets or dried flowers. Or you can add a couple drops to a diffuser with water',
        Usage6:'Cinnamon oil can be mixed with a carrier oil and used for massage. Mix one drop of cinnamon oil with two to three cups of a carrier oil, such as almond oil, and use for massage or to moisturize skin.',
        Usage7:'It’s very important to dilute cinnamon oil with a carrier oil, prior to use. Try using one drop of cinnamon essential oil to two or more cups of a carrier oil, such as olive, almond, jojoba, or sesame oil.',
        Usage8:'Never rub or massage cinnamon oil directly on skin unless it’s diluted with a carrier oil.',
        Usage9:'Not to apply directly on skin',
        Weight:'Weight',
        Weight1:'85 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },
    

    {
        id: 5,
        title: "Clove Oil",
        price: 1400.0,
        img: require("./assets/Essential Oil/clove-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Clove Oil.',
        SKU:'SKU',
        SKU1:'GR011OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'allergy cure, anti ageing, blood circulation, blood purifier, blood sugar control, clove oil, essential oil, healing, healthy digestion, immune booster, nausea cure, pain reliever.',
        Benefits:'Benefits',
        Benefits1:'Relieves indigestion and nausea',
        Benefits2:'Makes skin look younger',
        Benefits3:'Works for ear aches',
        Benefits4:'Boosts immune system',
        Benefits5:'Treats colds, coughs, sty and cholera',
        Benefits6:'Heals wounds, cuts and injuries',
        Benefits7:'Relieves toothaches and removes bad breath ',
        Benefits8:'Controls sugar levels in diabetes',
        Benefits9:'Relieves stress, headaches and pains',
        Benefits10:'Purifies blood',
        Benefits11:'Stimulates blood circulation',
        Usage:'Usage',
        Usage1:'To use it for a toothache, dip a clean tissue, cotton swab, or cotton ball into the oil and wipe it over the gums at the point of the pain',
        Usage2:'Can be applied to the skin mixed with carrier oils or creams and products',
        Usage3:'Using the oil as a spray is an easy way to add the spicy scent of clove to a room. Mixed with water',
        Usage4:'Cannot be used for drinking purpose',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    
    {
        id: 6,
        title: "Eucalyptus Oil",
        price: 1500.0,
        img: require("./assets/Essential Oil/euclyptus-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots eucalyptus oil is useful in a lot of ways, among many usages it heals wounds, helps reduce mental exhaustion, relieves muscle pain and treats fever.',
        SKU:'SKU',
        SKU1:'GR015OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti lice, diabetes control, essential oil, eucalyptus oil, healing, healthy teeth, intestinal germs, menstrual energy, muscle relaxant, pain reliever, respiratory issues cure.',
        Benefits:'Benefits',
        Benefits1:'Heals wounds',
        Benefits2:'Removes mental exhaustion',
        Benefits3:'Good for dental care',
        Benefits4:'Removes intestinal germs',
        Benefits5:'Treats fever',
        Benefits6:'Treats respiratory problems', 
        Benefits7:'Relieves muscle pains',
        Benefits8:'Removes lice',
        Benefits9:'Manages diabetes',
        Usage:'Usage',
        Usage1:'Can be used to take steam (few drops)',
        Usage2:'Can be used in a warm bath/shower',
        Usage3:'Can be inhaled',
        Usage4:'Can be used as a painkiller massage oil',
        Usage5:'Can be used on skin mixed with any other carrier oil (12 drops for one ounce of carrier oil)',
        Usage6:'Can be used for hair mixed with any carrier oil.',
        Weight:'Weight',
        Weight1:'75 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 7,
        title: "Wheat Germ Oil",
        price: 2400.0,
        img: require("./assets/Essential Oil/wheat-germ-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'(Vitamin E Oil) Green Roots Wheatgerm Oil, also known worldwide as vitamin E oil, richest source of Vitamin E, with multiple health benefits, Energy boosting qualities as well as helps slowing the process of ageing',
        SKU:'SKU',
        SKU1:'GR062OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti ageing, anti hair fall, birth defect prevention, blood sugar control, cholesterol control, eczema relief, energizing, essential oil, healthy brain, vitamin e oil, wheat germ oil.',
        Benefits:'Benefits',
        Benefits1:'Lowers LDL cholesterol.',
        Benefits2:'Boosts energy capacity',
        Benefits3:'Slows down ageing',
        Benefits4:'Prevents hair fall',
        Benefits5:'Reduces stress levels',
        Benefits6:'Works as a remedy for eczema',
        Benefits7:'Regulates Nervous System',
        Benefits8:'Promotes Blood Sugar Level',
        Benefits9:'Fights Fat Accumulation',
        Benefits10:'Prevents Birth Defects',
        Usage:'Usage',
        Usage1:'Massaging the oil on the body alleviates pain, pressure, hence greatly uplifting memory and positively influencing mood.',
        Usage2:'Applying wheat germ oil on hair is a magical solution to attain smooth, soft, silky tresses.',
        Usage3:'Just a few drops of this miraculous tincture is enough, to decrease free radical damage to skin cells, thus diminishing signs of ageing like wrinkles, fine lines and giving a youthful, unblemished appearance.',
        Usage4:'Add wheat germ oil to your diet to get all the benefits mentioned above. You can also add it in the following ways:',
        Usage5:'By baking in cookies, breads, waffles, etc.',
        Usage6:'By sprinkling it over ice creams, soups, yogurt, salads, or cereals.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',
       
    },

    
    {
        id: 8,
        title: "Tea Tree Oil",
        price: 1900.0,
        img: require("./assets/Essential Oil/tea-tree-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Tea Tree essential Oil is used typically for infections such as acne, fungal infections of nails, lice, scabies, and athletes foots.',
        SKU:'SKU',
        SKU1:'GR059OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti bacterial, essential oil, hair nourishment, healing, healthy scalp, inflammation remedy, ringworm cure, skin care, sore relief, tea tree oil, yeast infection cure.',
        Benefits:'Benefits',
        Benefits1:'Acts as a healing ointment',
        Benefits2:'Soothes inflamed skin',
        Benefits3:'Effective against acne',
        Benefits4:'Helps relieve sore',
        Benefits5:'Beneficial for ringworm and athlete’s feet',
        Benefits6:'Improves hair and scalp health',
        Benefits7:'Kills bacteria',
        Benefits8:'Removes yeast infections',
        Usage:'Usage',
        Usage1:'Can be used on wounds directly (few drops)',
        Usage2:'Can be added to a cup of water (2-3 drops) and used as a mouthwash',
        Usage3:'Can be applied to the skin mixed with any carrier oil',
        Usage4:'Can be applied for dandruff (5% of tea tree oil mixed with shampoo per wash)',
        Usage5:'For healthy hair, mix with any carrier oil (ratio 1-10) and apply.',
        Ingredients:'Ingredients',
        Ingredients1:'Pure Tea Tree Extract',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 9,
        title: "Sandalwood Oil",
        price: 9500.0,
        img: require("./assets/Essential Oil/sandalwood-oil-1-greenrootspk.com_.png"),
        quantity: 1,
        ml:'15 ML',
        dis:'Sandalwood oil is believed to be beneficial in treating both physical and mental disorders, including anxiety, bronchitis, diarrhea, fatigue, fever, gallbladder problems, high blood pressure, indigestion, insomnia, liver problems, low libido, sore throat, and urinary tract infections.',
        SKU:'SKU',
        SKU1:'GR054OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'alertness, anti anxiety, anti bacterial, cold cure, digestion issues cure, essential oil, gallbladder treatment, hair growth, healing, hemorrhoids cure, liver treatment, muscle issues cure, sandalwood oil, scabies cure, scalp nourishment, scar remover, skin cancer protection, skin nourishment, UTI beneficent.',
        Benefits:'Benefits',
        Benefits1:'Increase alertness.',
        Benefits2:'Manages anxiety.',
        Benefits3:'Supports wound healing.',
        Benefits4:'Guards against skin cancer.',
        Benefits5:'Fights bacteria.',
        Benefits6:'Beneficial for common cold',
        Benefits7:'Beneficial for urinary tract infections',
        Benefits8:'Treats liver and gallbladder problems',
        Benefits9:'Treats digestive problems',
        Benefits10:'Beneficial for muscle problems',
        Benefits11:'Works for mental disorders', 
        Benefits12:'Helps treat hemorrhoids',
        Benefits13:'Helps treat scabies',
        Benefits14:'Stimulates the roots, improves hair growth, and increases circulation in the scalp.',
        Benefits15:'Softens the skin',
        Benefits16:'Sandalwood reduces the appearance of bruises and scars',
        Usage:'Usage',
        Usage1:'Apply it directly to your skin',
        Usage2:'Put a few drops in your lotion',
        Usage3:'Heat it in a small kettle of water to scent your home',     
        Usage4:'Evaporate it using an essential oil diffuser',
        Usage5:'Add it to your bath water',
        Usage6:'Apply sandalwood essential oil on your face and massage gently. Leave it on overnight and wash with lukewarm water the next morning. (For skin softening)',
        Ingredients:'Ingredients',
        Ingredients1:'Pure Sandalwood Extract',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'15 ml',

    },
]