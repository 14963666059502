export default [
    {
        id: 1,
        title: "Singhara Powder",
        price: 220.0,
        img: require("./assets/Home Page Pictures/Custom Recommendation/singhara-powder-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',
        dis: 'Singhara is a herb which is rich in calcium, fibers, potassium and minerals.',
        SKU: 'SKU',
        SKU1: 'GR025HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags:',
        Tags1: 'antioxidant, bone health, cholesterol control, energizing fiber, healthy brain, herb, potassium, singhara, water retention, weight loss.',
        Benefits: 'Benefits',
        Benefits1: 'Good for energy',
        Benefits2: 'Powerhouse of antioxidants and minerals',
        Benefits3: 'Helps in weight loss',
        Benefits4: 'Rich in potassium',
        Benefits5: 'Helps water retention in the body',
        Benefits6: 'Beneficial for diabetes',
        Benefits7: 'Rich in fiber',
        Benefits8: 'Rich in vitamins',
        Benefits9: 'Increases brain function',
        Benefits10: 'Reduces cholesterol and blood sugar levels',
        Benefits11: 'Makes the bones stronger',
        Benefits12: 'Gluten free',
        Benefits13: 'Reduces the risk of diseases.',
        Usage: 'Usage',
        Usage1: '(For energy and brain function) â€“ take 1 teaspoon once in a day with milk.',
        Usage2: '(For water Retention in the body and Diabetes) â€“ take 1 teaspoon once in a day with water.',
        Usage3: 'Or used in other herbal remedies.',
        UsageNotice: '(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        SpecialInstructions: 'Special Instructions',
        SpecialInstructions1: 'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2: 'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: 'Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',

    },

    {
        id: 2,
        title: "Singhara Powder",
        price: 450.0,
        img: require("./assets/Home Page Pictures/Custom Recommendation/singhara-powder-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',
        dis: 'Singhara is a herb which is rich in calcium, fibers, potassium and minerals.',
        SKU: 'SKU',
        SKU1: 'GR025HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags:',
        Tags1: 'antioxidant, bone health, cholesterol control, energizing fiber, healthy brain, herb, potassium, singhara, water retention, weight loss.',
        Benefits: 'Benefits',
        Benefits1: 'Good for energy',
        Benefits2: 'Powerhouse of antioxidants and minerals',
        Benefits3: 'Helps in weight loss',
        Benefits4: 'Rich in potassium',
        Benefits5: 'Helps water retention in the body',
        Benefits6: 'Beneficial for diabetes',
        Benefits7: 'Rich in fiber',
        Benefits8: 'Rich in vitamins',
        Benefits9: 'Increases brain function',
        Benefits10: 'Reduces cholesterol and blood sugar levels',
        Benefits11: 'Makes the bones stronger',
        Benefits12: 'Gluten free',
        Benefits13: 'Reduces the risk of diseases.',
        Usage: 'Usage',
        Usage1: '(For energy and brain function) â€“ take 1 teaspoon once in a day with milk.',
        Usage2: '(For water Retention in the body and Diabetes) â€“ take 1 teaspoon once in a day with water.',
        Usage3: 'Or used in other herbal remedies.',
        UsageNotice: '(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        SpecialInstructions: 'Special Instructions',
        SpecialInstructions1: 'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2: 'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: 'Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',

    },

    {
        id: 3,
        title: "Relaxing Massage Oil",
        price: 2000.0,
        img: require("./assets/Home Page Pictures/Custom Recommendation/relaxing-massage-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml: '120 ML',
        dis: 'Green Roots Relaxing Massage Oil is the mixture of pure Olive Oil and essential Lavender Oil, which is used as the best healing therapy to relax your body and mind. It simply relieving the stress level of body by calming down the nerves by stabilizing the blood circulation.',
        SKU: 'SKU',
        SKU1: 'GR050OL',
        Category: 'Natural Oils',
        Tags: 'Tags:',
        Tags1: 'massage oil, muscle relaxant, natural oil, nerve relaxant, pain relief, pain reliever, pure oil, relaxant, relaxing oil.',
        Benefits: 'Benefits',
        Benefits1: 'Beneficial in relaxing your body.',
        Benefits2: 'Beneficial in relaxing your mind.',
        Benefits3: 'Beneficial in relieving body pain.',
        Usage: 'Usage',
        Usage1: 'Massage on body for 10 to 15 minutes at night.',
        Usage2: 'Can be applied on body and head for 10 to 15 minutes',
        Ingredients: 'Ingredients',
        Ingredients1: 'Olive oil',
        Ingredients2: 'Lavender oil',
        Weight: 'Weight',
        Weight1: '135 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '120 grams',

    },


    {
        id: 4,
        title: "Hair Repair Shampoo",
        price: 2200.0,
        img: require("./assets/Home Page Pictures/Custom Recommendation/hair-repair-shampoo-greenrootspk.com_.png"),
        quantity: 1,
        ml: '500 ML',
        dis: '(Coconut Shampoo) Made with all natural ingredients to give your hair shine, volume and health.',
        SKU: 'SKU',
        SKU1: 'GR012HB',
        Category: 'Health & Beauty',
        Tags: 'Tags:',
        Tags1: 'anti hair loss, damaged hair, damaged hair shampoo, dry hair shampoo, hair repair, hair repair shampoo, hair strengthening shampoo.',
        Benefits: 'Benefits',
        Benefits1: 'High in vitamins C, E, B-1, B-3, B-5, and B-6.',
        Benefits2: 'High in protein',
        Benefits3: 'Restores dry hair and scalp.',
        Benefits4: 'Restores moisture and strength',
        Benefits5: 'Slows hair loss.',
        Benefits6: 'Restores damaged follicles',
        Benefits7: 'Makes hair stronger, thicker and healthier',
        Benefits8: 'Tames dry and frizzy hair',
        Benefits9: 'Combats dandruff',
        Benefits10: 'Leaves hair more manageable and de tangled.',
        Usage: 'Usage',
        Usage1: 'Wash your hair daily.',
        Ingredients: 'Ingredients',
        Ingredients1: 'Coconut',
        Ingredients2: 'Amla',
        Ingredients3: 'Moringa',
        Ingredients4: 'Valerian',
        Ingredients5: 'Parmelia perlata',
        Ingredients6: 'Air Creeper',
        Ingredients7: 'Soap Nut (soap berries)',
        SpecialInstructions: 'Special Instructions',
        SpecialInstructions1: 'NOT SUITABLE FOR DANDRUFF PRONE HAIR',
        Weight: 'Weight',
        Weight1: '560 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '500 grams',

    },

    {
        id: 5,
        title: "Ispagol Chilka",
        price: 800.0,
        img: require("./assets/Home Page Pictures/Custom Recommendation/ispagol-chilka-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',
        dis: 'Green Roots Ispaghol Chilka (Psyllium Husk).',
        SKU: 'SKU',
        SKU1: 'GR062HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags:',
        Tags1: 'blood sugar control, chilk, acholesterol control, constipation cure, diarrhea cure, healthy heart, herb, ispaghol, laxative, prebiotics, psyllium husk, satiety boost, weight loss.',
        Benefits: 'Benefits',
        Benefits1: 'Psyllium relieves constipation. Psyllium is a bulk forming laxative.',
        Benefits2: 'It may help treat diarrhea.',
        Benefits3: 'It can lower blood sugar levels.',
        Benefits4: 'It may boost satiety and aid weight loss.',
        Benefits5: 'It can lower cholesterol levels.',
        Benefits6: 'It seems to be good for the heart.',
        Benefits7: 'It has prebiotic effects.',
        Usage: 'Usage',
        Usage1: 'Can consume Isabgol for healthy bowel movement.',
        Usage2: 'Can be taken orally with water.',
        Usage3: 'As a bulk laxative supplement, 5 g with a glass of water 3 times per day is a common starting point. People can increase this gradually if they find it tolerable. It depends on the product how many grams are in 1 teaspoon or tablespoon, but 1 tablespoon is a common recommendation as a serving for psyllium husk.',
        Usage4: 'The best time to consume Isabgol for weight loss is on an empty stomach in the morning or at night before going to bed',
        Usage5: 'Psyllium usually produces a bowel movement within 12 to 72 hours. Do not take psyllium for longer than 7 days in a row without a doctorâ€™s advice.',
        Usage6: 'Mix isabgol in warm water and add a few drops of lemon in it. You can drink the mixture right before your meal. Also, you can drink this mixture first thing in the morning. By drinking it early in the morning helps in losing weight.',
        Weight: 'Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',

    },

    {
        id: 6,
        title: "Oily Hair Oil",
        price: 1500.0,
        img: require("./assets/Home Page Pictures/Custom Recommendation/oily-hair-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml: '120 ML',
        dis: 'Green Roots Oil for Oily Hair is an effective formula that helps in avoiding inflammation, scalp infections, reducing sweat on the scalp, hair loss and dandruff.',
        SKU: 'SKU',
        SKU1: 'GR037OL',
        Category: 'Natural Oils',
        Tags: 'Tags:',
        Tags1: 'hair growth, hair nourishment, healthy brain, healthy digestion, healthy respiration, immune booster, natural oil, nausea cure, oily hair, oral health, pure oil, skin disease cure, skin nourishment, stress reliever.',
        Benefits: 'Benefits',
        Benefits1:'Reduces stress', 
        Benefits2:'Reduces nausea', 
        Benefits3:'Improves concentration', 
        Benefits4:'Keeps hair healthy',        
        Benefits5:'Supports digestion', 
        Benefits6:'Boosts oral health', 
        Benefits7:'Treats skin conditions', 
        Benefits8:'Improves respiratory functions', 
        Benefits9:'Improves immune system functions', 
        Benefits10:'Treats multiple skin conditions.', 
        Usage: 'Usage',
        Usage:'Green Roots Oil for Oily Hairs can be used thrice a week.', 
        Ingredients:'Ingredients',
        Ingredients1:'Coconut Oil',
        Ingredients2:'Almond Oil',
        Ingredients3:'Jojoba Oil',
        Ingredients4:'Tea Tree Oil',
        Weight: 'Weight',
        Weight1: '135 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '120 grams',
    },

    {
        id: 7,
        title: "Pain Killer Massage Oil",
        price: 2000.0,
        img: require("./assets/Home Page Pictures/Custom Recommendation/pain-killer-massage-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml: '120 ML',
        dis: 'Green Roots Pain Killer Massage Oil is the mixture of pure Olive and Lemon Grass Oil, which is the best remedy to treat muscle and joints pain. It also relaxes the body and quickly heals the pain.',
        SKU: 'SKU',
        SKU1: 'GR042OL',
        Category: 'Natural Oils',
        Tags: 'Tags:',
        Tags1: 'joint pain relief, massage oil, muscle relaxant, natural oil, pain killer, pain relief, pure oil.',
        Benefits: 'Benefits',
        Benefits1: 'Helps in relieving joints and muscles pain.',
        Benefits2: 'It relaxes the body',
        Benefits3: 'Quickly heals the pain.',
        Usage: 'Usage',
        Usage1: 'Massage on body at night before sleep.',
        Ingredients: 'Ingredients',
        Ingredients1: 'Olive Oil',
        Ingredients2: 'Lemongrass Oil',
        Weight: 'Weight',
        Weight1: '135 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '120 grams',

    },

    {
        id: 8,
        title: "Neem-Leaves",
        price: 100.0,
        img: require("./assets/Home Page Pictures/Custom Recommendation/neem-leaves-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',
        dis: 'Green Roots Neem Leaves are used for leprosy and beneficial for eye disorders, intestinal worms, diseases of the heart and blood vessels (cardiovascular disease) and beneficial for diabetes.',
        SKU: 'SKU',
        SKU1: 'GR046HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags:',
        Tags1: 'appetite remedy, diabetes remedy, eye disorder remedy, gum disease remedy, healthy heart, intestinal worms cure, leaves, leprosy remedy, liver health, neem, skin ulcer remedy.',
        Benefits: 'Benefits',
        Benefits1: 'Used for leprosy',
        Benefits2: 'Beneficial for eye disorders',
        Benefits3: 'Beneficial for intestinal worms',
        Benefits4: 'Beneficial for diseases of the heart and blood vessels (cardiovascular disease)',
        Benefits5: 'Beneficial for diabetes',
        Benefits6: 'Cures fevers',
        Benefits8: 'Beneficial for gum disease (gingivitis)',
        Benefits9: 'Beneficial for liver problems',
        Benefits10: 'Also used for birth control',
        Benefits11: 'Beneficial for bloody nose and skin ulcers',
        Benefits12: 'Beneficial for loss of appetite',
        Weight: 'Weight',
        Weight1: '50 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',

    },

    {
        id: 9,
        title: "Mehndi Leaves",
        price: 150.0,
        img: require("./assets/Home Page Pictures/Custom Recommendation/mehndi-leaves-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',
        dis: 'Green Roots Mehndi Leaves cures wounds, anti bacterial, anti fungal, improves hair health, works as a harm free hair dye and eases arthritis pains.',
        SKU: 'SKU',
        SKU1: 'GR051HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags:',
        Tags1: 'antibacterial, antifungal, arthritis relief, blood pressure control, fever relief, hair dye, hair growth, healing, mehndi leaves.',
        Benefits: 'Benefits',
        Benefits1: 'Cures wounds',
        Benefits2: 'Anti bacterial, anti fungal',
        Benefits3: 'Improves hair health',
        Benefits4: 'Works as a harm free hair dye',
        Benefits5: 'Eases arthritis pains',
        Benefits6: 'Draws out fever gives relief from headaches',
        Benefits8: 'Regulates blood pressure',
        Weight: 'Weight',
        Weight1: '50 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',

    },

    {
        id: 10,
        title: "Husne Yousuf",
        price: 300.0,
        img: require("./assets/Home Page Pictures/Custom Recommendation/husne-yusuf-cream-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',
        dis: '(Kaolin Clay) Kaolin clay is a cosmetic clay rich in silica and with a neutral pH. Itâ€™s often called White Clay or China Clay and of all the cosmetic clays, kaolin clay is the mildest. It works wonders for the skin, making it glow, healthy, tight, and bright. It helps in preventing wrinkles as well.',
        SKU: 'SKU',
        SKU1: 'GR013HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags:',
        Tags1: 'anti acne, anti ageing, herb, husne yusuf, kaolin clay, skin glow, skin lightening, skin nourishment.',
        Benefits: 'Benefits',
        Benefits1: 'Makes skin healthy & glow',
        Benefits2: 'Helps in skin tightening',
        Benefits3: 'Reduces acne',
        Benefits4: 'Brightens the skin',
        Benefits5: 'Prevents wrinkles',
        Usage: 'Usage',
        Usage1: 'Husn e Yousuf herb can be used in many beauty related remedies and herbal face masks. One of which is making a face mask that glows your skin and works as antiageing agent â€“ take a little amount of Husn e Yousuf herb and mix it with rose water, milk or yougurt make a paste and apply on skin for 20 to 25 minutes, rinse with water or wash with soft hands.',
        UsageNotice: '(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
        Ingredients: 'Ingredients',
        Ingredients1: 'Kaolin Clay',
        SpecialInstruction: 'Special Instruction',
        SpecialInstruction1: 'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstruction2: 'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: 'Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',

    },

    {
        id: 11,
        title: "Husne Yousuf",
        price: 600.0,
        img: require("./assets/Home Page Pictures/Custom Recommendation/husne-yusuf-cream-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',
        dis: '(Kaolin Clay) Kaolin clay is a cosmetic clay rich in silica and with a neutral pH. Itâ€™s often called White Clay or China Clay and of all the cosmetic clays, kaolin clay is the mildest. It works wonders for the skin, making it glow, healthy, tight, and bright. It helps in preventing wrinkles as well.',
        SKU: 'SKU',
        SKU1: 'GR013HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags:',
        Tags1: 'anti acne, anti ageing, herb, husne yusuf, kaolin clay, skin glow, skin lightening, skin nourishment.',
        Benefits: 'Benefits',
        Benefits1: 'Makes skin healthy & glow',
        Benefits2: 'Helps in skin tightening',
        Benefits3: 'Reduces acne',
        Benefits4: 'Brightens the skin',
        Benefits5: 'Prevents wrinkles',
        Usage: 'Usage',
        Usage1: 'Husn e Yousuf herb can be used in many beauty related remedies and herbal face masks. One of which is making a face mask that glows your skin and works as antiageing agent â€“ take a little amount of Husn e Yousuf herb and mix it with rose water, milk or yougurt make a paste and apply on skin for 20 to 25 minutes, rinse with water or wash with soft hands.',
        UsageNotice: '(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
        Ingredients: 'Ingredients',
        Ingredients1: 'Kaolin Clay',
        SpecialInstruction: 'Special Instruction',
        SpecialInstruction1: 'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstruction2: 'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: 'Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },































]