export default [
  {
    id: 1,
    title: "Acne Clear Face Wash",
    price: 1000.0,
    img: require("./assets/Health & Beauty/acne-clear-face-wash-greenrootspk.com_.png"),
    quantity: 1,
    ml:'200 ML',
    dis:'Green Roots Acne Clear Face Wash not only removes dirt and oil but also helps in treating acne from the first wash. It deeply cleanses the skin and prevents future breakouts, leaving the skin whiter and brighter. Skin becomes clear with continuous use.',
    SKU:'SKU',
    SKU1:'GR001HB',
    Category:'Health & Beauty',
    Tags:'Tags',
    Tags1:'acne facewashanti acne facewashanti  bacterial facewashfacewashoily skin careskin nourishing facewash',
    Benefits:'Benefits',
    Benefits1:'Suitable for oily skin.',
    Benefits2:'Helps fight against Acne.',
    Benefits3:'Reduces production of bacteria on the skin.',
    Benefits4:'Prevents the skin from drying and peeling.',
    Benefits5:'Speeds healing.',
    Benefits6:'Decreases the formation of pimples.',
    Usage:' Usage',
    Usage1:'Wash your face twice a day. (To be used daily)',
    Ingredients:'Ingredients',
    Ingredients1:'Fuller’s earth clay',
    Ingredients2:'Orange Peel',
    Ingredients3:'Wild Turmeric',
    Ingredients4:'Rose Whitener',
    Ingredients5:'Glycerin',
    Ingredients6:'Vitamin E',
    Ingredients7:'Tea Tree Oil',
    Weight:'Weight',
    Weight1:'245 g',
    dicQuantity1:'200 mL',
    dicQuantity:'Quantity',
  },


  {
      id: 2,
      title: "Acne Regime",
      price: 3600.0,
      img: require("./assets/Health & Beauty/acne-regime-greenrootspk.com_-600x480.png"),
      quantity: 1,
      // ml: '200 ML',
      dis:'Green Roots Acne Regime is a complete bundle for getting rid of acne.',
      SKU:' GR028HB',
      Category:'Health & Beauty',
      Tags:'acne creamacne facewashacne teaanti acneanti acne regimeanti bacterialskin nourishingskincare',
      Benefits:'Benefits',
      Benefits1:'Suitable for oily skin.',
      Benefits2:'Helps fight against Acne.',
      Benefits3:'Reduces production of bacteria on the skin.',
      Benefits4:'Prevents the skin from drying and peeling.',
      Benefits5:'Speeds healing.',
      Benefits6:'Decreases the formation of pimples.',
      Benefits7:'Help get rid of acne marks.',
      Benefits8:'Helps clean and purify blood.',
      Benefits9:'Helps prevent acne.',
      Benefits10:'Helps against the sign of ageing.',
      Benefits11:'Cures inflammation.',
      Benefits12:'Fights with infectious pimples and prevent them in the future.',
      Benefits13:'Fights boils and acne vulgaris',
      Benefits14:'Prevents itching.',
      Benefits15:'Reduces the production of sebum.',
      Benefits16:'Soothes inflammation.',
      Benefits17:'Speeds the process of healing acne.',
      Benefits18:'Works as a skin protectant.',
      Benefits19:'Fights bacteria and fungus on the skin.',
      Benefits20:'Prevents skin from breaking out.',
      Benefits21:'Has antiseptic qualities.',
      Benefits22:'Dries out pimples and reduces acne marks',
      UsageofAcneClear :'Usage of Acne Clear Facewash',
      UsageofAcneClear1:'Wash your face twice a day. (To be used daily)',
      UsageofAcneDiffuser:'Usage of Acne Diffuser Herbal Tea',
      UsageofAcneDiffuser1:'Take on and half cup / glass of water, add on tablespoon of the powder and cook / boil. Cook / boil enough for one cup / glass of water to be left behind and consume once a day. (Anytime in the day or night).',
      UsageofAntiAcne:'Usage of Anti Acne Night Cream',
      UsageofAntiAcne1:'Apply on your face daily at night before sleeping.',
      Ingredients:'Collective Ingredients',
      Ingredients1:'Fuller’s earth clay',
      Ingredients2:'Orange Peel',
      Ingredients3:'Wild Turmeric',
      Ingredients4:'Rose Whitener',
      Ingredients5:'Glycerin',
      Ingredients6:'Vitamin E.',
      Ingredients7:'Tea Tree Oil',
      Ingredients8:'Unaab (Jujube)',
      Ingredients9:'Gul E Mandi (East Indian Globe Thistle)',
      Ingredients10:'Mezereum',
      Ingredients11:'Zinc Oxide',
      Ingredients12:'Pomegranate Flower',
      Ingredients13:'Calamine Powder',
      Ingredients14:'Milk Thistle',
      Ingredients15:'Sphaeranthus indicus',
      Ingredients16:'Chinaberry',
      Ingredients17:'Tamarix Galicia',
      Weight:'Weight',
      Weight1:'550 g',
      FacewashQuantity:'FacewashQuantity',
      FacewashQuantity1:'200 mL',
      HerbalTeaQuantity:'HerbalTeaQuantity',
      HerbalTeaQuantity1:'160 grams',
      NightCreamQuantity:'NightCreamQuantity',
      NightCreamQuantity1:'30 grams',

    },
    {
      id: 3,
      title: "Age Reverse Oil",
      price: 9500.0,
      img: require("./assets/Health & Beauty/age-reverse-oil-greenrootspk.com_.png"),
      quantity: 1,
      ml: '30 ML',
      dis:'Green Roots Age Reversing Oil is the compound mixture of essential oils with Almond oil as a base. Which helps out making you look younger, removes wrinkles from face. Gives a natural glow to skin.',
      SKU:'GR004HB',
      Category:'Health & Beauty',
      Tags:'age reverse oilanti ageingeye rejuvenatingface oilskin glowing oilskin tone lightening oil',
      Benefits:'Benefits',
      Benefits1:'Removes fine lines from under Eyes.',
      Benefits2:'Removes wrinkles from Face and neck.',
      Benefits3:'Beneficial for skin glow.',
      Benefits4:'Fairs the complexion.',
      Usage:'Usage',
      Usage1:'Apply few drops of Age Reversing Oil all over your face and neck at night before sleeping',
      Ingredients:'Ingredients',
      Ingredients1:'Almond oil',
      Ingredients2:'Rose oil',
      Ingredients3:'Sandal oil',
      Ingredients4:'Rosemary oil',
      Ingredients5:'Lavender oil',
      Ingredients6:'Tea tree oil',
      Ingredients7:'Lemon oil',
      Ingredients8:'Niaouli',
      Ingredients9:'Carrot oil',
      Ingredients10:'Frankincense oil',
      Ingredients11:'Fennel oil.',
      Weight:'Weight',
      Weight1:'	50 g',
      dicQuantity:'Quantity',
      dicQuantity1:'30 mL',

    },

    
    {
      id: 4,
      title: "Aloe Vera & Neem Face Wash",
      price: 1000.0,
      img: require("./assets/Health & Beauty/aloevera-neem-facewash-greenrootspk.com_.png"),
      quantity: 1,
      ml:'200 ML',
      dis:'Green Roots Aloe Vera and Neem face wash helps purifies and deeply cleanses the skin. Lightens marks and spots on the skin. Also has antifungal properties.',
      SKU:' GR005HB',
      Category:'Health & Beauty',
      Tags:'aloe vera neem facewashanti fungaleven skin tone facewashskin cleansingskin glowingskin lightening facewash',
      Benefits:'Benefits',
      Benefits1:'Lightens marks and spots on the skin.',
      Benefits2:'Helps prevents acne from appearing.',
      Benefits3:'Antibacterial.',
      Benefits4:'Anti fungal.',
      Benefits5:'Reduces melanin production.',
      Benefits6:'Helps even tone skin.',
      Benefits7:'Makes the skin clean and glowing.',
      Usage:'Usage',
      Usage1:'Wash your face twice a day. (For daily use)',
      Ingredients:'Ingredients',
      Ingredients1:'Melia',
      Ingredients2:'Chinaberry',
      Ingredients3:'Aloe Vera',
      Ingredients4:'Jojoba',
      Ingredients5:'Zinc oxide',
      Ingredients6:'White sandal',
      Weight:'Weight',
      Weight1:'245 g',
      dicQuantity:'diaQuantity',
      dicQuantity1:'200 mL',

    },


    {
      id: 5,
      title: "Aloe Vera Face Wash",
      price: 1000.0,
      img: require("./assets/Health & Beauty/aloe-vera-facewash-greenrootspk.com_.png"),
      quantity: 1,
      ml:'200 ML',
      dis:'Green Roots Aloe Vera Face Wash contains Aloe Vera extracts in it. It easily rinses away makeup, impurities, leaving no residue of dirt behind. It leaves the skin fresh, cool and calm. It works as a good moisturizer which makes the skin smooth.',
      SKU:'GR006HB',
      Category:'Health & Beauty',
      Tags:'anti ageingantibacterialskin lighteningskin lightening facewashskin moisturizingsunburn soothing',
      Benefits:'Benefits',
      Benefits1:'Helps soothe sunburn.',
      Benefits2:'Moisturizes the skin',
      Benefits3:'Fights skin ageing',
      Benefits4:'Lightens blemishes on the skin',
      Benefits5:'Antibacterial',
      Usage:'Usage',
      Usage1:'Wash your face twice a day. (For daily use)',
      Ingredients:'Ingredients',
      Ingredients1:'German Base',
      Ingredients2:'Aloe Vera Gel',
      Ingredients3:'Rose water',
      Ingredients4:'Vitamin E',
      Ingredients5:'Glycerin',
      Ingredients6:'Cleansing milk',
      Weight:'Weight',
      Weight1:'245 g',
      dicQuantity:'Quantity',
      dicQuantity1:'200 mL',

    },

    
    {
      id: 6,
      title: "Hair Repair Shampoo",
      price: 2200.0,
      img: require("./assets/Health & Beauty/hair-repair-shampoo-greenrootspk.com_.png"),
      quantity: 1,
      ml:'500 ML',
      dis:'(Coconut Shampoo) Made with all natural ingredients to give your hair shine, volume and health.',
      SKU:'SKU',
      SKU1:'GR012HB',
      Category:'Health & Beauty',
      Tags:'Tags',
      Tags1:'anti hair loss, damaged hair, damaged hair, shampoo dry hair shampoo, hair repair hair repair shampoo hair, strengthening shampoo.',
      Benefits:'Benefits',
      Benefits1:'High in vitamins C, E, B-1, B-3, B-5, and B-6.',
      Benefits2:'High in protein',
      Benefits3:'Restores dry hair and scalp.',
      Benefits4:'Restores moisture and strength',
      Benefits5:'Slows hair loss.',
      Benefits6:'Restores damaged follicles',
      Benefits7:'Makes hair stronger, thicker and healthier',
      Benefits8:'Tames dry and frizzy hair',
      Benefits9:'Combats dandruff',
      Benefits10:'Leaves hair more manageable and de tangled.',
      Usage:'Usage',
      Usage1:'Wash your hair daily.',
      Ingredients:'Ingredients',
      Ingredients1:'Coconut',
      Ingredients2:'Amla',
      Ingredients3:'Moringa',
      Ingredients4:'Valerian',
      Ingredients5:'Parmelia perlata',
      Ingredients6:'Air Creeper',
      Ingredients7:'Soap Nut (soap berries)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'NOT SUITABLE FOR DANDRUFF PRONE HAIR',
      Weight:'Weight',
      Weight1:'560 g',
      dicQuantity:'Quantity',
      dicQuantity1:'500 mL',

    },


    {
      id: 7,
      title: "Length & Strength Shampoo",
      price: 1600.0,
      img: require("./assets/Health & Beauty/length-strength-shampoo-greenrootspk.com_.png"),
      quantity: 1,
      ml:'500 ML',
      dis:'Green Roots Length & Strength Shampoo is made with all natural ingredients to make your hair longer, shinier & thicker.',
      SKU:'SKU',
      SKU1:'GR016HB',
      Category:'Health & Beauty',
      Tags:'Tags',
      Tags1:'anti dandruff, anti hair fall, hair nourishment, hair volume booster, length and strength shampoo, length shampoo, scalp conditioner, strength shampoo.',
      Benefits:'Benefits',
      Benefits1:'Conditions the scalp',
      Benefits2:'Boosts volume',
      Benefits3:'Reduces the appearance of dandruff',
      Benefits4:'Controls hair fall',
      Benefits5:'Promotes hair growth',
      Benefits6:'Adds shine and softness',
      Benefits7:'Gives stronger, thicker hair',
      Benefits8:'Prevents dry scalp',
      Usage:'Usage',
      Usage1:'Wash your hair daily.',
      Ingredients:'Ingredients',
      Ingredients1:'Amla',
      Ingredients2:'Areetha',
      Ingredients3:'Shikakai',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'NOT SUITABLE FOR PEOPLE HAVING DRY HAIR',
      Weight:'Weight',
      Weight1:'550 g',
      dicQuantity:'Quantity',
      dicQuantity1:'500 mL',

    },


    {
      id: 8,
      title: "Milky Face Wash",
      price: 1000.0,
      img: require("./assets/Health & Beauty/milky-facewash-greenrootspk.com_.png"),
      quantity: 1,
      ml:'200 ML',
      dis:'Green Roots Milky White Face Wash is designed for all skin types to make the skin fresh and comfortable. It naturally sets the stage for a healthy, beautiful complexion. It leaves skin feeling soft, smooth and perfectly refreshed. It also keeps the skin moisturized.',
      SKU:'SKU',
      SKU1:'GR017HB',
      Category:'Health & Beauty',
      Tags:'Tags',
      Tags1:'milky facewash, sensitive skin facewash, skin nourishment, skin soothing, sunburn protection.',
      Benefits:'Benefits',
      Benefits1:'Beneficial for sensitive skin.',
      Benefits2:'Beneficial for dry skin.',
      Benefits3:'Keeps the skin hydrated.',
      Benefits4:'Helps protect against sunburn for sensitive skin.',
      Benefits5:'Enhances face texture.',
      Benefits6:'Leaves skin feeling soft and smooth.',
      Usage:'Usage',
      Usage1:'Wash your face with Milky Face Wash twice a day (for daily use).',
      Ingredients:'Ingredients',
      Ingredients1:'Rose Whitener',
      Ingredients2:'Aloe Vera water',
      Ingredients3:'Goat Milk Powder',
      Ingredients4:'Vitamin E',
      Ingredients5:'Almond Oil',
      Ingredients6:'Glycerin',
      Weight:'Weight',
      Weight1:'	245 g',
      dicQuantity:'Quantity',
      dicQuantity1:'200 mL',

    },


    {
      id: 9,
      title: "Aloe Vera Gel",
      price: 750.0,
      img: require("./assets/Health & Beauty/aloe-vera-gel-greenrootspk.com_.png"),
      quantity: 1,
      ml:'285 ML',
      dis:'Green Roots Aloe Vera Gel is used for many skin problems like scars, acne and is used to make frizzy hair soft. Aloe Vera Gel makes your hair straight and volumized',
      SKU:'SKU:',
      SKU1:'GR031HB',
      Category:'Health & Beauty',
      Tags:'Tags:',
      Tags1:'aloe vera extract, aloe vera gel, anti marks, anti scars, hair strengthening, pure aloe vera, skin care, skin lightening, skin nourishing.',
      Benefits:'Benefits',
      Benefits1:'Good to remove acne and acne scars',
      Benefits2:'Works well in case of allergy and redness on face, arms and legs',
      Benefits3:'To remove fizziness of hairs and to increase the volume of hairs',
      Benefits4:'Makes hairs soft, silky and shinny',
      Usage:'Usage',
      Usage1:'For skin, take a small amount of Aloe Vera gel and apply it on required areas until dried and rinse with water.',
      Usage2:'For hairs, Aloe Vera gel can be directly applied on hairs or by mixing it with any hair oil and a small amount of honey to apply on the hairs for 30 minutes before washing your hairs as it works as protein for your hair.',
      Ingredients:'Ingredients',
      Ingredients1:'Aloe Vera Gel',
      Weight:'Weight',
      Weight1:'	300 g',
      dicQuantity:'Quantity',	
      dicQuantity1:'285 mL',
    },
  ]