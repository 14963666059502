export default [
  {
    id: 1,
    title: "Allergy Cure",
    price: 2000.0,
    img: require("./assets/Medicine/allergy-cure-greenrootspk.com_.png"),
    quantity: 1,
    gram: '140 Grams',

    dis: 'Green Roots Allergy Cure is an effective medicine for most commonly found allergy problems, including skin allergy, blood allergy, and asthma. It also treats acne and pimples.',
    SKU: 'SKU',
    SKU1: 'GR002MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' allergy herbal cureallergy herbal medicineallergy medicineallergy natural cureallergy natural medicineallergy remedy',
    Benefits: 'Benefits',
    Benefits1: 'Helps fight all sorts of blood allergies.',
    Benefits2: 'Helps purify blood.',
    Benefits3: 'Helps clear acne and acne related problems',
    Usage: 'Usage',
    Usage1: 'Take 1/2 teaspoon after breakfast and dinner with water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Ingredients',
    Ingredients2: 'Gul gaozaban',
    Ingredients3: 'Gul mandi',
    Ingredients4: 'Saunf',
    Ingredients5: 'Mulethi',
    Ingredients6: 'Unaab',
    Ingredients7: 'Gul e benafsha',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place.',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: 'Weight',
    Weight1: '175 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '140 Grams',
  },

  {
    id: 2,
    title: "Acidity Medicine",
    price: 1800.0,
    img: require("./assets/Medicine/acidity-medicine-greenrootspk.com_.png"),
    quantity: 1,
    gram: '140 Grams',

    dis: 'Green Roots Acidity is a solution to all stomach problems.',
    SKU: 'SKU',
    SKU1: 'GR001MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'acidity cure, acidity herbal cure, acidity herbal remedy, acidity medicine, acidity natural cure, acidity natural medicine',
    Benefits: 'Benefits',
    Benefits1: 'Helps cure H-Pylori.',
    Benefits2: 'Prevents bloating.',
    Benefits3: 'Helps cure ulcers.',
    Benefits4: 'Helps fight gases',
    Benefits5: 'Helps fight all sort of stomach problems.',
    Benefits6: 'Helps against burning in the chest.',
    Benefits7: 'Helps cure acidity.',
    Usage: 'Usage',
    Usage1: 'Take 1/2 teaspoon before breakfast and before dinner with plain water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Choti Elaichi',
    Ingredients2: 'Mint',
    Ingredients3: 'Saunf',
    Ingredients4: 'Zeera Safed',
    Ingredients5: 'Tripla',
    Ingredients6: 'Dhania',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: ' Weight',
    Weight1: '175 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '140 grams',
  },


  {
    id: 3,
    title: "Cardium 90",
    price: 1400.0,
    img: require("./assets/Medicine/cardium-90-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Cardium-90 is a herbal medicine for blood pressure patients that controls excessive stress level and makes blood vessels strong.',
    SKU: 'SKU',
    SKU1: 'GR003MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' blood pressure natural medicine, BP herbal cure, bp natural cure, bp natural medicine, high blood pressure, high blood pressure natural remedy',
    Benefits: 'Benefits',
    Benefits1: 'Medicine for high Blood Pressure.',
    Benefits2: 'Not to be taken without Doctor’s prescription.',
    Benefits3: 'Controls high blood pressure.',
    Benefits4: '100% natural.',
    Usage: 'Usage',
    Usage1: 'For normal patients: 1/2 teaspoon before breakfast and 1/2 teaspoon before dinner. Both to be consumed with Lukewarm water.',
    Usage2: 'For acute patients: Two (02) teaspoon before breakfast, Half (1/2) teaspoon before lunch and dinner. All to be consumed with lukewarm water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Rauwolfia',
    Ingredients2: 'Fennel seeds',
    Ingredients3: 'Knot grass',
    Ingredients4: 'Senna leaves',
    Ingredients5: 'Saffron',
    Ingredients6: 'Burada aaj',
    Ingredients7: 'Mirabilis Jalapa',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: 'Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 Grams',
  },


  {
    id: 4,
    title: "Clear Vision",
    price: 1400.0,
    img: require("./assets/Medicine/clear-vision-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Clear Vision improves eyesight and helps in reducing dryness, itching and excessive watering of eyes. It prevents increased cloudiness of eyes by improving the vision. It also soothes eyes hence eliminating irritation generally produced due to repeated usage of cellphones, computers and Television.',
    SKU: 'SKU',
    SKU1: 'GR004MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' eyesight cure, eyesight herbal cure, eyesight herbal remedy, eyesight natural medicine, eyesight natural remedy, vision cure',
    Benefits: 'Benefits',
    Benefits1: 'Beneficial for kids, adults, and old people for improving eyesight.',
    Benefits2: 'Beneficial for people who use glasses.',
    Benefits3: 'Enhances weak eyesight.',
    Benefits4: 'Beneficial to protect eyesight from decreasing with age.',
    Benefits5: 'Beneficial for people with weak eyesight as well as normal eyesight.',
    Benefits6: 'Helps with watery eyes.',
    Benefits7: 'Helps clear eye vision.',
    Benefits8: 'Prevents “Motiya” production.',
    Usage: 'Usage',
    Usage1: '(For Children) Half Teaspoon before/after meal in the morning and night with milk',
    Usage2: '(For Adults) One Teaspoon before/after meal in the morning and night with milk',
    Ingredients: 'Ingredients',
    Ingredients1: 'Sucrose',
    Ingredients2: 'Coriander seeds',
    Ingredients3: 'Fennel (Saunf)',
    Ingredients4: 'Almonds',
    Ingredients5: 'Cumin seeds',
    Ingredients6: 'Black pepper',
    Special: 'Special Instructions',
    Special1: 'NOT SUITABLE FOR DIABETIC AND PREGNANT / NURSING MOTHERS.',
    Special2: 'AVOID Exposure to Moisture.',
    Special3: 'STORE Medicines In a Cool, Dry Place',
    Special4: 'READ Medicine Labels and Instructions Carefully.',
    Weight: ' Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },


  {
    id: 5,
    title: "Cycle Disorder",
    price: 1200.0,
    img: require("./assets/Medicine/cycle-disorder-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Cycle Disorder is helpful in issues related with Menstruation (or periods).',
    SKU: 'SKU',
    SKU1: 'GR005MD',
    Category: 'Medicine',
    Tags: ' Tags',
    Tags1: ' menstruation cycle herbal cure, menstruation cycle natural medicine, menstruation cycle natural remedy, mensuration issuesperiod cycle cure, period cycle natural medicine, period cycle natural remedy, period issues',
    Benefits: 'Benefits',
    Benefits1: 'Helpful for irregularities in the period cycle',
    Benefits2: 'Helpful for women suffering with PCOs',
    Benefits3: 'Helpful for hormonal imbalances.',
    Usage: 'Usage',
    Usage1: 'Take 1 teaspoon with water before breakfast. In case of discontinued periods from 4 to 6 months, it should also be taken at night as well before sleeping.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Kalonji',
    Ingredients2: 'Ajwain',
    Ingredients3: 'Methi dana',
    Ingredients4: 'Sabut Ispaghol',
    Ingredients5: 'Mint',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    Weight: ' Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },


  {
    id: 6,
    title: "Height Plus",
    price: 1200.0,
    img: require("./assets/Medicine/height-plus-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Height Plus Medicine is the best formula to increase height at an ideal point.',
    SKU: 'SKU',
    SKU1: 'GR008MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' height enhancement medicine, height herbal medicine, height increase medicine, height medicine, height natural medicine, short height cure, short height remedy',
    Benefits: 'Benefits',
    Benefits1: 'Beneficial for girls from 10 years till 24 years',
    Benefits2: 'Beneficial for boys from 10 years till 24 years.',
    Benefits3: 'Helps strengthen bones',
    Benefits4: 'Provides calcium and Vitamin D.',
    Benefits5: 'Helps overcome weaknesses.',
    Usage: 'Usage',
    Usage1: 'Take 1/2 (half) teaspoon twice a day with milk or plain water.(morning and night).',
    Ingredients: 'Ingredients',
    Ingredients1: 'Black sesame',
    Ingredients2: 'Almonds',
    Ingredients3: 'Misri',
    Ingredients4: 'Asgand nagori',
    Ingredients5: 'Qast e shireen',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'NOT SUITABLE FOR PEOPLE OVER 24 YEARS.',
    SpecialInstructions2: 'AVOID Exposure to Moisture.',
    SpecialInstructions3: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions4: 'READ Medicine Labels and Instructions Carefully.',
    Weight: 'Weight',
    Weight1: '230 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },


  {
    id: 7,
    title: "Insulinia Plus",
    price: 1200.0,
    img: require("./assets/Medicine/insulina-plus-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Insulinia Plus maintains your blood sugar level and treats glucose tolerance well. The most special herb used in this medicine called Gymnema Sylvestre (known as sugar destroyer) that increases the beta-cell function especially among type-2 sugar patients.',
    SKU: 'SKU',
    SKU1: 'GR009MD',
    Category: 'Medicine',
    Tags: ' Tags',
    Tags1: ' blood sugar, insulin cure, insulin herbal cure, insulin herbal medicine, insulin herbal remedy, insulin natural cure, insulin natural medicine, insulin natural remedy',
    Benefits: 'Benefits',
    Benefits1: 'Helps to maintain the blood sugar level..',
    Benefits2: 'Increases the beta-cell functions in the body.',
    Usage: 'Usage',
    Usage1: 'For Type 1: Take One (01) teaspoons before breakfast and One (01) tablespoon before dinner.',
    Usage2: 'For Type 2: Take One (01) teaspoon three times a day before meal.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Gymnema sylvestre',
    Ingredients2: 'Cinquefoils',
    Ingredients3: 'Bitter Oleander',
    Ingredients4: 'Butterstick',
    Ingredients5: 'Gentian',
    Ingredients6: 'Black plum seeds.',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    Weight: 'Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },


  {
    id: 8,
    title: "Jawarish E Sakoon",
    price: 1800.0,
    img: require("./assets/Medicine/jawarish-e-sukoon-greenrootspk.com_.png"),
    quantity: 1,
    gram: '250 Grams',


    dis: 'Green Roots Jawarish-e-Sakoon is a natural cure for problems like ulcer, piles, constipation, acidity, irregular heartbeat and gastric problems.',
    SKU: 'SKU',
    SKU1: 'GR010MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'constipation remedy, piles cure, stomach health, stomach ulcer, ulcer treatment',
    Benefits: 'Benefits',
    Benefits1: 'Treatment for Ulcers',
    Benefits2: 'Treatment for Piles',
    Benefits3: 'Beneficial for constipation.',
    Usage: 'Usage',
    Usage1: 'One (01) tablespoon with hot milk or water before sleeping at night',
    Ingredients: 'Ingredients',
    Ingredients1: 'Harh Murabba',
    Ingredients2: 'Gajar Murabba',
    Ingredients3: 'Saib Murabba',
    Ingredients4: 'Amla Murabba',
    Ingredients5: 'Gule Surkh',
    Ingredients6: 'Elaichi Khurd',
    Ingredients7: 'Sonth (zanjabeel)',
    Ingredients8: 'Sana Makhi',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'NOT SUITABLE FOR PREGNANT / NURSING MOTHERS.',
    Weight: 'Weight',
    Weight1: '265 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '250 grams',
  },


  {
    id: 9,
    title: "Joint Cure",
    price: 3400.0,
    img: require("./assets/Medicine/joint-cure-greenrootspk.com_.png"),
    quantity: 1,
    gram: '250 Grams',

    dis: 'Green Roots Joint Cure strengthen your bones and possesses Anti inflammatory properties that is a complete cure to the back and joint pains. It can also be used for muscles pain and arthritis',
    SKU: 'SKU',
    SKU1: 'GR011MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' arthritis cure, arthritis herbal cure, arthritis natural cure, bone strength, calcium supplement, muscle strength, vitamin d',
    Benefits: 'Benefits',
    Benefits1: 'Strengthen bones and muscles.',
    Benefits2: 'Treats arthritis.',
    Benefits3: 'Makes the bones strong.',
    Benefits4: 'Treats the deficiency of Vitamin D in bones.',
    Benefits5: 'Gives Calcium to bones.',
    Benefits6: 'Used for all types of muscles and bones pain.',
    Usage: 'Usage',
    Usage1: 'Half (1/2) teaspoon before breakfast and half (1/2) teaspoon after dinner with plain water or milk.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Asgandh Nagori',
    Ingredients2: 'Musli Safaid',
    Ingredients3: 'Qast E Shireen',
    Ingredients4: 'Sonth',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: 'Weight',
    Weight1: '175 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '140 grams',
  },


  {
    id: 10,
    title: "Premature Hair Greying Medicine",
    price: 1500.0,
    img: require("./assets/Medicine/premature-hair-greying-medicine-greenrootspk.com_.jpg"),
    quantity: 1,
    gram: '140 Grams',

    dis: 'Green Roots Premature Hair Greying Medicine is used to provide zinc to body. Helps support in mental health, also helps the hair grow black.',
    SKU: 'SKU',
    SKU1: 'GR014MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'hair greying herbal cure, hair greying medicine, hair greying natural cure, hair greying natural medicine, hair greying natural remedy, premature hair greying medicine',
    Benefits: 'Benefits',
    Benefits1: 'Helps cover Zinc deficiency in the body.',
    Benefits2: 'Beneficial to overcome mental weakness.',
    Benefits3: 'Helps in preventing premature greying of the hair.',
    Benefits4: 'Helps in restoring premature grey hair back to its original color. ',
    Benefits5: 'This medicine is not usable for the people above 30 years age.',
    Usage: 'Usage',
    Usage1: 'Take 1/2 or 1 teaspoon in the morning 1 hour after breakfast or once in a day with milk.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Bhangra siyah',
    Ingredients2: 'Amla',
    Ingredients3: 'Badam oil',
    Ingredients4: 'Post baheera',
    Ingredients5: 'Bari har chilka',
    Ingredients6: 'Choti har',
    Ingredients7: 'Methi dana',
    Ingredients8: 'Maghz e badam',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'NOT SUITABLE FOR PEOPLE OVER 30 YEARS.',
    SpecialInstructions2: 'AVOID Exposure to Moisture.',
    SpecialInstructions3: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions4: 'READ Medicine Labels and Instructions Carefully.',
    Weight: ' Weight',
    Weight1: '175 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '140 grams',
  },


  {
    id: 11,
    title: "Sleeping Disorder",
    price: 900.0,
    img: require("./assets/Medicine/sleeping-disorder-greenrootspk.com_.png"),
    quantity: 1,
    gram: '90 Grams',

    dis: 'Green Roots Sleeping Disorder is the natural treatment for the people fighting with the disorder of not sleeping properly having stress and depression.',
    SKU: 'SKU',
    SKU1: 'GR015MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' anti anxiety, peaceful sleep, sleep cycle remedy, sleep disorder cure, stress reliever',
    Benefits: 'Benefits',
    Benefits1: 'Helps fight anxiety.',
    Benefits2: 'Helps relieve stress.',
    Benefits3: 'Benefits in peaceful sleeping without medications.',
    Usage: 'Usage',
    Usage1: 'Take One (01) teaspoon daily at night before sleeping with plain water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Gul e gaow Zaban',
    Ingredients2: 'Gul e Banafsha',
    Ingredients3: 'Magz e kadu',
    Ingredients4: 'Khash Khaash',
    Ingredients5: 'Magz e tarbooz',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: ' Weight',
    Weight1: '175 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '90 grams',
  },

  {
    id: 11,
    title: "Sleeping Disorder",
    price: 1800.0,
    img: require("./assets/Medicine/sleeping-disorder-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Sleeping Disorder is the natural treatment for the people fighting with the disorder of not sleeping properly having stress and depression.',
    SKU: 'SKU',
    SKU1: 'GR015MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' anti anxiety, peaceful sleep, sleep cycle remedy, sleep disorder cure, stress reliever',
    Benefits: 'Benefits',
    Benefits1: 'Helps fight anxiety.',
    Benefits2: 'Helps relieve stress.',
    Benefits3: 'Benefits in peaceful sleeping without medications.',
    Usage: 'Usage',
    Usage1: 'Take One (01) teaspoon daily at night before sleeping with plain water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Gul e gaow Zaban',
    Ingredients2: 'Gul e Banafsha',
    Ingredients3: 'Magz e kadu',
    Ingredients4: 'Khash Khaash',
    Ingredients5: 'Magz e tarbooz',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: ' Weight',
    Weight1: '175 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },
  {
    id: 12,
    title: "Tooth Powder",
    price: 1200.0,
    img: require("./assets/Medicine/tooth-powder-medicine-greenrootspk.com_.jpg"),
    quantity: 1,
    gram: '160 Grams',

    dis: 'Green Roots Tooth Powder is an excellent product that offers all time solution to tooth problems.',
    SKU: 'SKU',
    SKU1: 'GR016MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'bad breath remedy, bleeding gums cure, pyorrhea cure, teeth whitener, tooth ache relief, tooth powder',
    Benefits: 'Benefits',
    Benefits1: 'Helps in Toothache.',
    Benefits2: 'Helps cure Pyorrhea.',
    Benefits3: 'Helps control bleeding of gums.',
    Benefits4: 'Helps to control bad breath of mouth.',
    Benefits5: 'Helps in teeth whitening.',
    Usage: 'Usage',
    Usage1: 'Use twice a day for better results.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Samandari Jhaag',
    Ingredients2: 'Sang e jarahat',
    Ingredients3: 'Phitkari safad',
    Ingredients4: 'Laung',
    Ingredients5: 'Mastag e roomi',
    Ingredients6: 'Suhaga',
    Ingredients7: 'Black salt',
    Weight: ' Weight',
    Weight1: '175 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '160 grams',
  },


  {
    id: 13,
    title: "Sperm Count 2",
    price: 7000.0,
    img: require("./assets/Medicine/sperm-count-2-1-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Sperm Count 2 is helpful in fertility issues.',
    SKU: 'SKU',
    SKU1: 'GR018MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' fertility booster, sperm count, perm herbal medicine, sperm natural cure',
    Benefits: 'Benefits',
    Benefits1: 'Sperm Count 2 is a customized medicine, made for men who suffer from fertility issues such as low sperm count or no sperm count.',
    Usage: 'Usage',
    Usage1: 'Take half a teaspoon of the medicine, one hour after breakfast and half a teaspoon one hour after dinner. Can be taken with milk or water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Tukhme Saras (lebbek tree seeds)',
    Ingredients2: 'Geru (Gairika (Red Ochre))',
    Ingredients3: 'Musli India',
    Ingredients4: 'Majuphal (Quercus infectoria)',
    Ingredients5: 'Mai',
    Ingredients6: 'Badaam (almonds)',
    Ingredients7: 'Chilghoza (pine nuts)',
    Ingredients8: 'Salab Panja (Himalayan Marsh Orchid)',
    Weight: ' Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },


  {

    id: 14,
    title: "Maajoon E Qaboos",
    price: 18000.0,
    img: require("./assets/Medicine/majoon-e-qaboos-greenrootspk.com_.png"),
    quantity: 1,
    gram: '250 Grams',

    dis: '(For Men Only) Green Roots Maajoon e Qaaboos helps overcome male sexual weaknesses, boosts overall body functions and increase energy levels.',
    SKU: 'SKU',
    SKU1: 'GR019MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tag1: ': energizing, fertility booster, majoon e qaboos, sexual performance, sexual weakness',
    Benefits: 'Benefits',
    Benefits1: 'Maajoon e Qaaboos is a supplement for men. It helps overcome sexual weaknesses, boosts overall body functions and energy, provided nutrition to the body.',
    Usage: 'Usage',
    Usage1: 'Take one teaspoon – daily anytime in the day, with milk.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Mastic Gum (mastagi roomi)',
    Ingredients2: 'Marvari (Pearl herb)',
    Ingredients3: 'Kharateen',
    Ingredients4: 'Regmahi (sand fish)',
    Ingredients5: 'Saffron',
    Ingredients6: 'Kholanjaan (galingale)',
    Ingredients7: 'Bisbasa (Myristica fragrans Houtt)',
    Ingredients8: 'Jauz (cornflower)',
    Ingredients9: 'Karanphool (star anise)',
    Ingredients10: 'Jadwaar (delphinium denudatum)',
    Ingredients11: 'Wark nakra',
    Ingredients12: 'Salab Misri (Orchis latifolia Linn)',
    Ingredients13: 'Museli White',
    Ingredients14: 'Isgand (Indian ginseng)',
    Ingredients15: 'Amber',
    Ingredients16: 'Shilajeet',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'Not suitable for diabetic patients',
    Weight: 'Weight',
    Weight1: '265 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '250',
  },


  {
    id: 15,
    title: "Maajoon E Zaffran",
    price: 4500.0,
    img: require("./assets/Medicine/majoonezafranoutofstock.png"),
    quantity: 1,
    gram: '250 Grams',
    Availability: 'Availability',
    Availability1: ' : Out of stock',

    dis: '(For Females Only) Green Roots Maajoon E Zaffran is enriched with all natural ingredients that is an energy booster for females.',
    // Availability: 'Availability:',
    // Availability: 'Out of stock',
    SKU: 'SKU',
    SKU1: 'GR020MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'energizing, energy booster, majoone zaffran, nutrition',
    Benefits: 'Benefits',
    Benefits1: 'Enriched with all natural ingredients, Maajoon E Zaffran is an energy booster for females. It enhances energy as well has provides nutrition to the body.',
    Usage: 'Usage',
    Usage1: 'Take one teaspoon daily – anytime in the day, with milk.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Saffron',
    Ingredients2: 'Chickpeas',
    Ingredients3: 'Honey',
    Ingredients4: 'Pistachios',
    Ingredients5: 'Ginseng',
    Ingredients6: 'Badaam (almonds)',
    Ingredients7: 'Akhrot (walnuts)',
    Ingredients8: 'Kaaju (cashews)',
    Ingredients9: 'Injeer (figs)',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'Not suitable for diabetic patients',
    Weight: ' Weight',
    Weight1: '265 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '250 grams',
  },


  {
    id: 16,
    title: "Sugar 2",
    price: 1400.0,
    img: require("./assets/Medicine/sugar-2-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Sugar 2 specifically designed for diabetes (uncontrolled and controlled) and helps regulate blood sugar levels if uncontrolled.',
    SKU: 'SKU',
    SKU1: 'GR021MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' blood sugar control, diabetes control, diabetes cure, sugar 2, sugar control, sugar cure',
    Benefits: 'Benefits',
    Benefits1: 'Sugar 2 is a customized medicine, specifically designed for diabetes (uncontrolled and controlled). It helps regulate blood sugar levels if uncontrolled, and it helps eradicate diabetes (according to the patients diabetic history).',
    Usage: 'Usage',
    Usage1: 'Take one teaspoon one hour after meal with water.',
    Usage2: 'Twice a day.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Kalonji (black seeds)',
    Ingredients2: 'Methi Dana (Fenugreek)q',
    Ingredients3: 'Daarcheeni (Cinnamon)',
    Ingredients4: 'Karhi Pata (Curry leaves)',
    Ingredients5: 'Badaam (Almonds)',
    Weight: ' Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },


  {
    id: 17,
    title: "Urine Control",
    price: 900.0,
    img: require("./assets/Medicine/urine-control-greenrootspk.com_.png"),
    quantity: 1,
    gram: '175 Grams',

    dis: 'Green Roots Urine Un-Control is a customized medicine for people suffering with un controlled urine disorder.',
    SKU: 'SKU',
    SKU1: 'GR022MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'herbal cure, herbal medicine, herbal remedy, natural medicine, natural urination cure, urination issues, urine disorder cure, urine uncontrol',
    Benefits: 'Benefits',
    Benefits1: 'A customized medicine for people suffering with un controlled urine disorder.',
    Usage: 'Usage',
    Usage1: 'Half teaspoon in the morning, 1 hour after breakfast.',
    Usage2: 'Half teaspoon at night, 1 hour after dinner with water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Fenugreek (Methi Dana powder )',
    Ingredients2: 'Jambolan Powder (jamun ke beej ka powder)',
    Ingredients3: 'Sesame (Safed Til)',
    Ingredients4: 'Walnut (Akhrot)',
    Ingredients5: 'Coconut powder (narial ka powder)',
    Weight: 'Weight',
    Weight1: '180 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '175 grams',
  },


  {
    id: 18,
    title: "Maajoon E Nawal",
    price: 2800.0,
    img: require("./assets/Medicine/majoon-e-nawal-greenrootspk.com_.png"),
    quantity: 1,
    gram: '250 Grams',

    dis: 'Green Roots Maajoon E Nawal is Beneficial for stomach related issues.',
    SKU: 'SKU',
    SKU1: 'GR023MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' acidity cure, appetite remedy, herbal medicine, indigestion remedy, maajoone nawal, natural medicine, stomach cleansing, stomach cure, stomach health',
    Benefits:'Benefits',
    Benefits1: 'Beneficial for stomach issues. e.g. gases, acidity, bloating, indigestion, lack/loss of appetite, and all major stomach related issues.',
    Usage: 'Usage',
    Usage1: 'Half teaspoon in the morning, half an hour before meal.',
    Usage2: 'Half teaspoon in the evening, half an hour before meal (With water) .',
    Ingredients: 'Ingredients',
    Ingredients1: 'Honey (shehed)',
    Ingredients2: 'Mastic Gum (Mastagi Roomi)',
    Ingredients3: 'Cinnamon(Darcheeni)',
    Ingredients4: 'Valerian (Balchar)',
    Ingredients5: 'Agr-e-nafaal',
    Ingredients6: 'Black Cardamom (Elaichi Kala)',
    Ingredients7: 'Black Pepper Corns (Filfil Siyah)',
    Instructions: 'Instructions',
    Instructions1: 'Not for sugar patients directly. Can be customized as per requirement.',
    Weight: 'Weight',
    Weight1: '265 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '250 grams',
  },


  {
    id: 19,
    title: "Diabetic Cure",
    price: 3000.0,
    img: require("./assets/Medicine/diabetic-cure-greenrootspk.com_.png"),
    quantity: 1,
    Capsules: '90 Capsules',

    dis: 'Green Roots Diabetic Cure is a natural and pure herbal medicine which stabilizes blood sugar levels by increasing insulin and stimulates the pancreas to make more insulin to remove excess sugar from the body.',
    SKU: 'SKU',
    SKU1: 'GR006MD',
    Category: 'Medicine',
    Tags: ' Tags',
    Tags1: 'diabetic cure, diabetic herbal cure, diabetic herbal medicine, diabetic herbal remedy, diabetic natural cure, diabetic natural medicine, diabetic natural remedy, sugar medicine',
    Benefits: 'Benefits',
    Benefits1: 'Controls Diabetes.',
    Benefits2: 'Helps improve the insulin level in the body.',
    Benefits3: 'Helps prevent future damages caused due to diabetes.',
    Benefits4: 'Control the overall blood glucose levels produced in the body.',
    Benefits5: 'Improves blood lipid levels',
    Usage: 'Usage',
    Usage1: 'Morning capsules.- One (01) Capsule before breakfast.',
    Usage2: 'Night capsules – Two (02) Capsules after dinner.',
    Ingredients: 'Ingredients',
    Ingredients1: ' NIGHT 60 CAPSULES',
    Ingredients2: 'Cinnamon',
    Ingredients3: 'Cardamom',
    Ingredients4: 'Fenugreek Seeds',
    Ingredients5: 'Nigella Seeds',
    Ingredients6: 'Curry Leave',
    Ingredients7: 'MORNING 30 CAPSULES',
    Ingredients8: 'Sirisa',
    Ingredients9: 'SeedsNeem',
    Ingredients10: 'GumColosynthis',
    Ingredients11: 'Nigella Seeds',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: 'Weight',
    Weight1: '125 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '90 Capsules',
  },


  {
    id: 20,
    title: "Femi Care (Leucorrhea)",
    price: 1600.0,
    img: require("./assets/Medicine/femi-care-greenrootspk.com_.png"),
    quantity: 1,
    Capsules: '120 Capsules',

    dis: 'Green Roots Femi Care is a natural cure for leucorrhoea and relieves vaginal infections naturally to be used for nine months for complete treatment. Femi Care helps in relieving mucus and speeds up vaginal epithelium renewal. It helps in balancing the hormones in the body and makes them work better.',
    SKU: 'SKU',
    SKU1: 'GR007MD',
    Category: 'Medicine',
    Tags: ' Tags',
    Tags1: ' leucorrhea cure, leucorrhea herbal medicine, leucorrhea herbal remedy, leucorrhea medicine, leucorrhea natural medicine, leucorrhea natural remedy',
    Benefits: 'Benefits',
    Benefits1: 'Medicine for leucorrhoea symptoms',
    Benefits2: 'Beneficial for Back ache – Pain in the legs.',
    Benefits3: 'Good to boost appetite',
    Benefits4: 'Makes bones strong.',
    Benefits5: 'Beneficial for girls/women from age 12-30 effectively.',
    Benefits6: 'Works on women aged 30-40 as well.',
    Usage: 'Usage',
    Usage1: 'For under 15 years age – One (01) tablet in the morning and (01) tablet at night with water.',
    Usage2: 'For girls above 15 years age – Two (02) tablets in the morning and two (02) tablets at night with water.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Four Gums',
    Ingredients2: 'Water caltrop',
    Ingredients3: 'Valeria-Indica',
    Ingredients4: 'Pomegranate Flower',
    Ingredients5: 'Indian Garden Asparagus',
    Ingredients6: 'Sensitive Plant',
    Ingredients7: 'Sucrose Sugar',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'NOT SUITABLE FOR PREGNANT / NURSING MOTHERS.',
    SpecialInstructions2: 'AVOID Exposure to Moisture.',
    SpecialInstructions3: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions4: 'READ Medicine Labels and Instructions Carefully.',
    Weight: ' Weight',
    Weight1: '130 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 Capsules',
  },


  {
    id: 21,
    title: "Men’s Health Capsules",
    price: 12000.0,
    img: require("./assets/Medicine/men-health-capsules-greenrootspk.com_.png"),
    quantity: 1,
    Capsules: '60 Capsules',

    dis: 'Green Roots Men’s Health capsules is an excellent formula to boost and enhance male sexual energy, power and strength.',
    SKU: 'SKU',
    SKU1: 'GR012MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'horny goat weed extract, male performance, male potency, male strength, sea cucumber, sexual performance, tongkat ali',
    Benefits: 'Benefits',
    Benefits1: 'Supports male sexual performance.',
    Benefits2: 'Supports heart health.',
    Benefits3: 'Gives energy.',
    UsageofVIRILITYDSCapsules: 'Usage of VIRILITY-DS Capsules',
    UsageofVIRILITYDSCapsules1: 'Take One (01) capsule daily with breakfast.',
    UsageofVIGROLXL: 'Usage of VIGROL-XL',
    UsageofVIGROLXL1: 'Take One (01) capsule at night before sleep.',
    VIRILITYDSIngredients: 'VIRILITY-DS Ingredients:',
    VIRILITYDSIngredients1: 'Sea Cucumber 230 mg',
    VIRILITYDSIngredients2: 'Horny Goat Weed Extract 100mg',
    VIRILITYDSIngredients3: 'Tongkat Ali Extract 50mg',
    VIRILITYDSIngredients4: 'Vitamin D 300 IU',
    VIRILITYDSIngredients5: 'Vitamin A 3000 IU',
    VIGROLXLIngredients: 'VIGROL-XL: Ingredients:',
    VIGROLXLIngredients1: 'Aplinia Gaianga 40mg',
    VIGROLXLIngredients2: 'Amomum Subulatum 40 mg',
    VIGROLXLIngredients3: 'Anacyclus Pyrethrum 20mg',
    VIGROLXLIngredients4: 'Asparagus Adscendens 10mg',
    VIGROLXLIngredients5: 'Bambusa Spinosa 5mg',
    VIGROLXLIngredients6: 'Cinnamomum Malabathrum 7 mg',
    VIGROLXLIngredients7: 'Crocus Sativus 0.5mg',
    VIGROLXLIngredients8: 'Curculigo Orchioides 7 mg',
    VIGROLXLIngredients9: 'Hyoscyamus Niger 0.10mg',
    VIGROLXLIngredients10: 'Myristica Fragrans 2mg',
    VIGROLXLIngredients11: 'Tephrosia Purpurea 7 mg',
    VIGROLXLIngredients12: 'Zingiber Officinalis 9mg',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    Weight: ' Weight',
    Weight1: '75 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '160 Capsules',
  },


  {
    id: 22,
    title: "Piles Capsules",
    price: 6000.0,
    img: require("./assets/Medicine/piles-capsules-greenrootspk.com_.png"),
    quantity: 1,
    Capsules: '80 Capsules',

    dis: 'Pilox MB & Pilox-N Green Roots Piles Medicine Capsules is to cure hemorrhoids, bleeding mass and constipation.',
    SKU: 'SKU',
    SKU1: 'GR013MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'piles cur, epiles herbal cure, piles herbal remedy, piles medicine, piles natural cure, piles natural medicine, piles natural remedy',
    Benefits: 'Benefits',
    Benefits1: 'Its natural cure to internal and external hemorrhoids',
    Benefits2: 'It works to relieve swelling, bleeding, pain and Helps to eliminate the hemorrhoid.',
    UsageofPiloxMB: 'Usage of Pilox-MB',
    UsageofPiloxMB1: 'Take 2 bananas early morning then take 2 capsules after 10 minutes take 1 or 2 bananas and after half an hour take breakfast.',
    UsageofPiloxN: 'Usage of Pilox-N',
    UsageofPiloxN1: 'Take 2 capsules one hour after dinner.',
    PiloxMBIngredients: 'Pilox MB Ingredients',
    PiloxMBIngredients1: 'Rice powder',
    PiloxMBIngredients2: 'Potassium',
    PiloxMBIngredients3: 'Cellulose',
    PiloxNIngredients: 'Pilox N Ingredients',
    PiloxNIngredients1: 'Cellulose',
    PiloxNIngredients2: 'Phyllanthus',
    PiloxNIngrediente3: 'Emblica',
    PiloxNIngredients4: 'Curcuma',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'AVOID Exposure to Moisture.',
    SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
    SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
    Weight: ' Weight',
    Weight1: '95 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '80 Capsules',
  },


  {
    id: 23,
    title: "Haazma Hee Haazma",
    price: 750.0,
    img: require("./assets/Medicine/haazma-hee-haazma-greenrootspk.com_.png"),
    quantity: 1,
    tablet: '60 Tablets',

    dis: 'Green Roots Haazma Hee Haazma is beneficial for all stomach related problems such as acidity & constipation.',
    SKU: 'SKU',
    SKU1: 'GR024MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' acidity cure, acidity medicine, acidity remedy, constipation cure, constipation herbal medicine, constipation remedy, healthy digestion, herbal medicinein, digestion cure, natural medicine, stomach cure',
    Benefits: 'Benefits',
    Benefits1: 'Healthy digestion',
    Benefits2: 'Acidity cure',
    Benefits3: 'Constipation cure',
    Benefits4: 'Beneficial for all stomach related issues',
    Usage: 'Usage',
    Usage1: 'Take one tablet in the morning after meal',
    Usage2: 'Take one tablet in night after meal',
    Usage3: 'In case of severe conditions, two tablets can be taken',
    Usage4: 'These tablets can be taken with water',
    Usage5: 'These tablets can also be taken as dissolvable tablets',
    Ingredients: 'Ingredients',
    Ingredients1: 'Long Pepper (filfil siyah)',
    Ingredients2: 'Sange Jaharat (soaprock)',
    Ingredients3: 'Gond Katira (tragacanth)',
    Ingredients4: 'Noshadar (gum resim)',
    Ingredients5: 'Peepal Kala (peepal black)',
    Ingredients6: 'Calcium Phosphate',
    Ingredients7: 'Zanjabeel (zingiber)',
    Ingredients8: 'Zeera Safaid (cumin)',
    Ingredients9: 'Kaala Zera (black cumin)',
    Ingredients10: 'Satt Limu (citric acid)',
    Weight: 'Weight:',
    Weight1: '50 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '60 Tablets',
  },


  {
    id: 24,
    title: "Haazma Hee Haazma",
    price: 1500.0,
    img: require("./assets/Medicine/haazma-hee-haazma-greenrootspk.com_.png"),
    quantity: 1,
    tablet: '120 Tablets',

    dis: 'Green Roots Haazma Hee Haazma is beneficial for all stomach related problems such as acidity & constipation.',
    SKU: 'SKU',
    SKU1: 'GR024MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' acidity cure, acidity medicine, acidity remedy, constipation cure, constipation herbal medicine, constipation remedy, healthy digestion, herbal medicine, indigestion cure, natural medicine, stomach cure',
    Benefits: 'Benefits',
    Benefits1: 'Healthy digestion',
    Benefits2: 'Acidity cure',
    Benefits3: 'Constipation cure',
    Benefits4: 'Beneficial for all stomach related issues',
    Usage: 'Usage',
    Usage1: 'Take one tablet in the morning after meal',
    Usage2: 'Take one tablet in night after meal',
    Usage3: 'In case of severe conditions, two tablets can be taken',
    Usage4: 'These tablets can be taken with water',
    Usage5: 'These tablets can also be taken as dissolvable tablets',
    Ingredients: 'Ingredients',
    Ingredients1: 'Long Pepper (filfil siyah)',
    Ingredients2: 'Sange Jaharat (soaprock)',
    Ingredients3: 'Gond Katira (tragacanth)',
    Ingredients4: 'Noshadar (gum resim)',
    Ingredients5: 'Peepal Kala (peepal black)',
    Ingredients6: 'Calcium Phosphate',
    Ingredients7: 'Zanjabeel (zingiber)',
    Ingredients8: 'Zeera Safaid (cumin)',
    Ingredients9: 'Kaala Zera (black cumin)',
    Ingredients10: 'Satt Limu (citric acid)',
    Weight: 'Weight',
    Weight1: '90 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '120 Tablets',
  },


  {
    id: 25,
    title: "Unwanted Hair Oil",
    price: 3600.0,
    img: require("./assets/Medicine/unwanted-hair-oiloutofstock.png"),
    quantity: 1,
    ml:'15 ML',
    Availability:'Availability',
    Availability1:' : Out of stock',

    dis: 'Green Roots Unwanted hair oil is here to help you get rids of all sorts of unwanted facial hair from the face. It helps overcome the growth of unwanted facial hairs from face.',
    // Availability: 'Availability;',
    // Availability1: 'Out of stock',
    SKU: 'SKU',
    SKU1: 'GR017MD',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' facial hair remover, facial hair removing oil, scar remover, spots remover, unwanted hair oil, unwanted hair removal, unwanted hair remover',
    Benefits: 'Benefits',
    Benefits1: 'Lightens marks and spots on the skin.',
    Benefits2: 'Helps prevents acne from appearing.',
    Benefits3: 'Antibacterial.',
    Benefits4: 'Anti fungal.',
    Benefits5: 'Reduces melanin production.',
    Benefits6: 'Helps even tone skin.',
    Benefits7: 'Makes the skin clean and glowing.',
    Usage: 'Usage',
    Usage1: 'Wash face with clean water, take few drops of Unwanted Hair Oil, apply gently on required areas at night before sleeping.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Salt',
    Ingredients2: 'Tea Tree oil',
    Ingredients3: 'Aloe Vera',
    Ingredients4: 'Glycerin',
    Ingredients5: 'Costus',
    Ingredients6: 'Propylene Glycol',
    SpecialInstructions: 'Special Instructions',
    SpecialInstructions1: 'For effective results use Unwanted Hair Oil after waxing the skin.',
    SpecialInstructions2: 'DO NOT USE Unwanted Hair Oil when you have any type of skin allergy at skin surface where you want to use the oil, it is advisable to consult the doctor in such condition.',
    Weight: ' Weight',
    Weight1: '60 g',
    dicQuantity: 'Quantity',
    dicQuantity1: '15 mL',
  },


  {
    id: 26,
    title: "Ceactive",
    price: 1200.0,
    img: require("./assets/Medicine/Ceactiveoutofstock.png"),
    quantity: 1,
    Availability:'Availability',
    Availability1:' : Out of stock',

    dis: 'Green roots herbal tablet called CeActive reduces inflammation, darkness under the eyes, promotes healthy and glowing skin and improves skin texture.',
    // Availability: 'Availability:',
    // Availability1: ' Out of stock',
    SKU: 'SKU',
    SKU1: 'GR026MD',
    Category: 'Medicine',
    Benefits: 'Benefits',
    Benefits1: 'Reduces inflammation',
    Benefits2: 'Promotes collagen production',
    Benefits3: 'Reduce under eye circles',
    Benefits4: 'Improves skin texture',
    Benefits5: 'Promotes healthy, glowing skin',
    Usage: 'Usage',
    Usage1: 'For adults, take one to two capsules daily, preferably with meals.',
    Weight: 'Weight',
    Weight1: '55 g',

  },


  {
    id: 27,
    title: "Hair Food",
    price: 2400.0,
    img: require("./assets/Medicine/Hair Food.jpeg"),
    quantity: 1,

    dis: 'A natural supplement for luscious, beautiful and healthy hair.',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'Beans, Chia Seeds, Flax Seeds, Hair Food, hair grow, hairfall, Mashrooms, omega 3, Pumpkin Seeds, Sunflower Seeds, vitamin E, White Sesame Seeds',
    Benefits: 'Benefits',
    Benefits1: 'Helps hair grow faster and longer, proving nourishment to the follicles.',
    Benefits2: 'Enriched with Vitamin E and C and Omega 3.',
    Benefits3: 'Reduction in hairfall',
    Benefits4: 'Prevents dryness and frizzy hair',
    Benefits5: 'Prevents thinning of the hair',
    Benefits6: 'Strengthens hair',
    Benefits7: 'Improves blood circulation in the scalp',
    Benefits8: 'Keeps the scalp moisturised',
    Benefits9: 'The presence of White Sesame Seeds may also help avoid or prevent premature greying of the hair.',
    Ingredients: 'Ingredients',
    Ingredients1: 'Flax Seeds',
    Ingredients2: 'Chia Seeds',
    Ingredients3: 'Pumpkin Seeds',
    Ingredients4: 'Sunflower Seeds',
    Ingredients5: 'White Sesame Seeds',
    Ingredients6: 'Mashrooms',
    Ingredients7: 'Beans',
    HowtoConsume: 'How to Consume',
    HowtoConsume1: 'Half teaspoon in the morning',
    HowtoConsume2: 'Half teaspoon in the morning',
    HowtoConsume3: 'Can be taken before meals or after meals.',
    Weight: 'Weight',
    Weight1: '280 g',
  },


  {
    id: 28,
    title: "Eczema Capsules and Eczema Vaseline",
    price: 3400.0,
    img: require("./assets/Medicine/Eczema Capsules and Eczema Vaseline.jpeg"),
    quantity: 1,

    dis: 'The natural and only permanent cure for Eczema.',
    Category: 'Medicine',
    Tags: ' Tags',
    Tags1: 'cleanses Blood, collagen, dry skin Eczema Capsules, Improves skin, kin energized, scaly skin, skin oxygenated, strengthens',
    EczemaCapsules: 'Eczema Capsules',
    EczemaCapsulesBenefits: 'Benefits',
    EczemaCapsulesBenefits1: 'Purifies/cleanses Blood',
    EczemaCapsulesBenefits2: ' Improves skin conditions',
    EczemaCapsulesBenefits3: ' Improves the product of collagen and strengthens it',
    EczemaCapsulesBenefits4: 'Promotes wound healing',
    EczemaCapsulesBenefits5: 'Helps with dry, scaly skin.',
    EczemaCapsulesBenefits6: 'Antibacterial and antimicrobial',
    EczemaCapsulesBenefits7: 'Eliminates parasitic worms.',
    EczemaCapsulesBenefits8: ' Anti inflammatory',
    EczemaCapsulesBenefits9: 'Contains cooling effects',
    EczemaCapsulesBenefits10: ' Keeps the skin energized',
    EczemaCapsulesBenefits11: 'Keeps the skin oxygenated',
    EczemaCapsulesIngredients: 'Ingredients',
    EczemaCapsulesIngredients1: ' Fagonia Arabica (Dhamasa)',
    EczemaCapsulesIngredients2: 'Bitter Ginger (Narkachoor)',
    EczemaCapsulesIngredients3: 'East Indian Globe Thisle (Gul Mandi)',
    EczemaCapsulesIngredients4: ' Turmeric (Haldi)',
    EczemaCapsulesIngredients5: 'Swertia (Charaita)',
    EczemaCapsulesIngredients6: 'Mexican Sarsaparilla (Ushb E Maghribi)',
    EczemaCapsulesIngredients7: 'Jujube (Unaab)',
    Dosage: 'Dosage',
    Dosage1: 'Adults – Two capsules in the morning and two capsules at night, after meals with water.',
    Dosage2: 'Upto 12 years – One capsule in the morning and one capsule at night, after meals with water.',
    EczemaVaseline: 'Eczema Vaseline',
    EczemaVaselineBenefits: 'Benefits',
    EczemaVaselineBenefits1: 'Improves skin conditions',
    EczemaVaselineBenefits2: ' Promotes wound healing',
    EczemaVaselineBenefits3: 'Keeps the skin hydrated',
    EczemaVaselineBenefits4: 'Anti inflammatory',
    EczemaVaselineBenefits5: 'Anti bacterial',
    EczemaVaselineBenefits6: 'Contains cooking effects',
    EczemaVaselineIngredients: 'Ingredients',
    EczemaVaselineIngredients1: '– Vaseline',
    EczemaVaselineIngredients2: '– Neem oil',
    EczemaVaselineIngredients3: '-Tea tree oil',
    EczemaVaselineIngredients4: '-Eucalyptus Oil',
    EczemaVaselineUsage: 'Usage',
    EczemaVaselineUsage1: 'Apply on the effected areas as required.',
    Weight: ' Weight',
    Weight1: '425 g',
  },


  {
    id: 29,
    title: "Cholesterol Capsules",
    price: 2500.0,
    img: require("./assets/Medicine/Cholesterol Capsules.jpeg"),
    quantity: 1,

    dis: 'Provides nutritional support and help maintain a healthy Cholesterol level.',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' Capsules, Cholesterol, fiber, good cholesterol, heart disease, lipoprotein, low density, stroke, Triglycerides',
    Benefits: 'Benefits',
    Benefits1: 'Decreases inflammation',
    Benefits2: 'Raises HDL (good cholesterol)',
    Benefits3: ' Decreases triglycerides',
    Benefits4: ' Slows down LDL absorption in the intestines',
    Benefits5: 'Rich in fibre, helps keep cholesterol levels lows',
    Ingredients: 'Ingredients',
    Ingredients1: 'Darcheeni (Cinnamon)',
    Ingredients2: 'Qaste Shireen (Marine Costus)',
    Ingredients3: 'Kalonji (Black Cumin)',
    Ingredients4: 'Methi Dana (Fenugreek seeds)',
    Ingredients5: 'Zeera (White Cumin)',
    Ingredients6: ' Saunf (Fennel)',
    Ingredients7: 'Elaichi Khurd (Elettaria Cardamomum)',
    Ingredients8: 'Kishneez Khushk (Coriander Sativum)',
    Dosage: 'Dosage',
    Dosage1: 'Adults: Two capsules in the morning and night with water (after meals)',
    Dosage2: 'Upto 12 years: 1 capsule in the morning and night with water (after meals)',
    Weight: 'Weight',
    Weight1: '135 g',

  },


  {
    id: 30,
    title: "Psoriasis Capsules and Eczema Vaseline",
    price: 3400.0,
    img: require("./assets/Medicine/Psoriasis Capsules and Eczema Vaseline.jpeg"),
    quantity: 1,

    dis: 'The natural and only permanent cure for Psoriasis.',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'psoriasis, psoriasis awareness, psoriasis community, psoriasis diet, psoriasis life, psoriasis relief, psoriasis sucks, psoriasis treatment, psoriasis warrior',
    PsoriasisCapsules: 'Psoriasis Capsules',
    PsoriasisCapsulesBenefits: 'Benefits',
    PsoriasisCapsulesBenefits1: 'Reduces inflammation',
    PsoriasisCapsulesBenefits2: ' Anti bacterial',
    PsoriasisCapsulesBenefits3: 'Reduces redness',
    PsoriasisCapsulesBenefits4: ' Boosts skin cell growth',
    PsoriasisCapsulesBenefits5: ' Reduces dryness and irritation',
    PsoriasisCapsulesBenefits6: ' Treats wounds and burns',
    PsoriasisCapsulesBenefits7: 'Aids healing',
    PsoriasisCapsulesBenefits8: ' Keeps the skin hydrated',
    PsoriasisCapsulesBenefits9: ' Oxygenates the skin',
    PsoriasisCapsulesIngredients: 'Ingredients',
    PsoriasisCapsulesIngredients1: ' Indian Barberry (Simblu)',
    PsoriasisCapsulesIngredients2: 'Margosa (neem ka patta)',
    PsoriasisCapsulesIngredients3: 'Rhubarb Roots (Raiwand Khatai)',
    PsoriasisCapsulesIngredients4: ' Raw Turmeric (Amba Haldi)',
    PsoriasisCapsulesIngredients5: ' Centella (Barhami Booti)',
    PsoriasisCapsulesIngredients6: ' Indian Fumitory (Shahatra)',
    PsoriasisCapsulesIngredients7: ' Bitter Stick (Chirata Nepali)',
    PsoriasisCapsulesDosage: 'Dosage',
    PsoriasisCapsulesDosage1: 'Adults: Two capsules in the morning and two capsules at night, one hour after meals with water.',
    PsoriasisCapsulesDosage2: 'Upto 12 years: One capsule in the morning and one capsule at night, one hour after meals.',
    EczemaVaseline: 'Eczema Vaseline',
    EczemaVaselineBenefits: 'Benefits',
    EczemaVaselineBenefits1: ' Improves skin conditions',
    EczemaVaselineBenefits2: 'Promotes wound healing',
    EczemaVaselineBenefits3: ' Keeps the skin hydrated',
    EczemaVaselineBenefits4: ' Anti inflammatory',
    EczemaVaselineBenefits5: 'Anti bacterial',
    EczemaVaselineBenefits6: 'Contains cooking effects',
    EczemaVaselineIngredients: 'Ingredients',
    EczemaVaselineIngredients1: '– Vaseline',
    EczemaVaselineIngredients2: '– Neem oil',
    EczemaVaselineIngredients3: '-Tea tree oil',
    EczemaVaselineIngredients4: '-Eucalyptus Oil',
    EczemaVaselineUsage: 'Usage',
    EczemaVaselineUsage1: 'Apply on the effected areas as required.',
    Weight: ' Weight',
    Weight1: '420 g',
  },


  {
    id: 31,
    title: "Migraine Capsule",
    price: 2600.0,
    img: require("./assets/Medicine/Migraine-cap.jpeg"),
    quantity: 1,

    dis: 'A permanent and natural cure for migraines.',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' Anti inflammatory anxiety, depression, headaches, insomnia, Migraine Capsule, Relieves pain, seizures, sleep disorders,treat migraine',
    Benefits: 'Benefits',
    Benefits1: 'Anti inflammatory',
    Benefits2: 'Reduces the risks of seizures',
    Benefits3: ' Naturally sedates the nerves',
    Benefits4: 'Relieves pain naturally',
    Benefits5: ' Eases headaches',
    Benefits6: ' Supports restful sleep',
    Benefits7: 'Treats insomnia',
    Benefits8: ' Treats anxiety and depression',
    Benefits9: 'Treats sleep disorders',
    Benefits10: ' Reduces inflammation',
    Ingredients: 'Ingredients',
    Ingredients1: ' Lavandula Stoechas (Ustukhuddus)',
    Ingredients2: ' Borage Flower (Gul E Gaozaban)',
    Ingredients3: 'Violet Flower (Gul E banafsha)',
    Ingredients4: ' East Indian Globe Thistle (Gul Mandi)',
    Ingredients5: ' Fennel (saunf)',
    Ingredients6: ' Valerian (Balchar)',
    Ingredients7: ' Coriander (khishneez khushk)',
    Ingredients8: ' Chamomile (Gul E Baaboona)',
    Usage: 'Usage/Dosage',
    Usage1: 'For adults; Two capsules in the morning and two capsules at night, after meals with water',
    Usage2: 'Upto 12 years: One capsule in the morning and one at night, after meals with water.',
    Weight: ' Weight',
    Weight1: '135 g',

  },


  {
    id: 32,
    title: "Asthma Capsule",
    price: 2400.0,
    img: require("./assets/Medicine/Asthma-capsule.jpeg"),
    quantity: 1,

    dis: 'A natural and the only guaranteed cure for Asthma.',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' Asthma Capsule, Borage Flower, bronchioles, chest congestion, congestion, coughs, Fennel, Hyssop, inflammation, Liquorice, swelling, Violet Flower',
    Benefits: 'Benefits',
    Benefits1: 'Sooths the bronchioles',
    Benefits2: 'Treats coughs associated with asthma',
    Benefits3: 'Breaks up chest congestion',
    Benefits4: 'Thins mucus',
    Benefits5: 'Decreases swelling and inflammation in the airways',
    Benefits6: 'Reduces congestion',
    Benefits7: 'Clears sinuses',
    Benefits8: 'Aids upper respiratory conditions.',
    Benefits9: 'Helps relieve asthma, providing a long term, permanent riddance.Ingredients',
    Benefits10: 'Hyssop (Gul e zoofa) ',
    Benefits11: 'Parsiyon Shan',
    Benefits12: 'Violet Flower (Gul e banafsha)',
    Benefits13: 'Borage Flower (Gul e Gaozaban)',
    Benefits14: 'Fennel (saunf)',
    Benefits15: 'Liquorice Root Powder (mulethi)    ',
    Usage: 'Usage/dosage;',
    Usage1: 'For adults; Two capsules in the morning and two capsules in the night, after meals with water.',
    Usage2: 'Upto 12 years; One capsule in the morning and one at night after meals with water.',
    Weight: 'Weight',
    Weight1: '130 g',

  },


  {
    id: 33,
    title: "Thyroid Hypo Capsules",
    price: 2400.0,
    img: require("./assets/Medicine/Thyroid-Hypo-Capsules.jpeg"),
    quantity: 1,

    dis: 'Cure your Thyroid the natural way.',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'Green Roots., herbal, medicines, thyroid, thyroid awarenesst, hyroid cancer, thyroid diet, thyroid disease, thyroid healing, thyroid health, thyroid problems, thyroid weight loss',
    Benefits: 'Benefits',
    Benefits1: 'Helps treat the function of hormones',
    Benefits2: ' Improves hepatic and renal oxidative stress',
    Benefits3: 'Improves the inflammatory response in hypothyroidism',
    Benefits4: ' Stimulates weight loss',
    Benefits5: ' Reduces joint pains',
    Benefits6: ' Helps regulate hypothyroidism levels',
    Benefits7: ' Eases stress',
    Benefits8: ' Increases metabolism',
    Benefits9: ' Fenugreek helps reduce the thyroid hormone level and modify the T3/T4 ratio',
    Benefits10: ' Improves blood levels',
    Ingredients: 'Ingredients',
    Ingredients1: ' Banslochan (tabasheer)',
    Ingredients2: ' Fenugreek Seeds (methi dana)',
    Ingredients3: 'Ceylon Cinnamon (Ceylon darcheeni)',
    Ingredients4: ' Dried Indian gooseberry (amla khushk)',
    Ingredients5: 'Coriander Sativum (Kishneez Khush)',
    Ingredients6: 'Indian Ginseng (Asgand Nagori)',
    Ingredients7: 'Spearmint (Desi Pudeena)',
    Dosage: 'Dosage',
    Dosage1: 'Adults: Two capsules in the morning and two capsules at night, after meals with water.',
    Dosage2: 'Upto 12 years: One capsule in the morning and one at night, after meals with water.',
    Weight: ' Weight',
    Weight1: '140 g',

  },


  {
    id: 34,
    title: "Uric Acid Capsules",
    price: 2600.0,
    img: require("./assets/Medicine/Uric-acid-capsules.jpeg"),
    quantity: 1,

    dis: 'For the treatment of gout and excessive Uric Acid.',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' bloodstream, calcium, gout attacksGreen Roots.,herbal, inflammation, iron, medicines, phosphorus, Protects body, Reduces pain, Uric acid, Uric Acid Capsules',
    Benefits: 'Benefits',
    Benefits1: ' Reduces pain and inflammation',
    Benefits2: ' Protects body from inflammation',
    Benefits3: ' Reduces severity of gout attacks',
    Benefits4: 'Reduces serum Uric acid levels',
    Benefits5: 'Rich in zinc, iron, calcium and phosphorus helps maintain Uric acid level in the body',
    Benefits6: ' Clears out excess Uric acid in the bloodstream and breaks down bile.',
    Ingredients: 'Ingredients',
    Ingredients1: ' Suranjan shireen (colchicum lutem)',
    Ingredients2: 'Qaste Shireen (Marine costus )',
    Ingredients3: ' Kalonji (Black cumin)',
    Ingredients4: 'Methi dana (Fenugreek seeds)',
    Ingredients5: ' Zeera Safaid (White Cumin)',
    Ingredients6: ' Elaichi Khurd (Elettaria Cardamomum)',
    Ingredients7: 'Asgand Nagori (Indian Ginseng)',
    Ingredients8: 'Saunf (Fennel)',
    Dosage: 'Dosage',
    Dosage1: ' Adults: Two capsules in the morning and night with water (after meals)',
    Dosage2: 'Upto 12 years: 1 capsule in the morning and night with water. (After meals)',
    Weight: ' Weight',
    Weight1: '	135 g',

  },


  {
    id: 35,
    title: "Pilex Capsules and Pilex Oil",
    price: 3000.0,
    img: require("./assets/Medicine/Pilex-capsules.jpeg"),
    quantity: 1,

    dis: 'The complete natural solution to get rid of haemorrhoids. An oil to be used with the medicine for a natural and complete treatment for haemorrhoids.',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: ' constipation, Green Roots., Hemorrhoids Oil, herbal, Medicine, Neem Seed, Pilex Capsules, Reduces itching, Reduces pain, Reduces swelling, turmeric',
    PilexCapsules: 'Pilex Capsules',
    PilexCapsulesBenefits: 'Benefits',
    PilexCapsulesBenefits1: 'Anti Inflammatory',
    PilexCapsulesBenefits2: 'Reduces pain',
    PilexCapsulesBenefits3: ' Reduces itching',
    PilexCapsulesBenefits4: ' Reduces the probability of worsening the problem',
    PilexCapsulesBenefits5: ' Reduces swelling',
    PilexCapsulesBenefits6: ' Eases bowel movements',
    PilexCapsulesBenefits7: ' Treats constipation',
    PilexCapsulesBenefits8: 'Treats Hemorrhoids permanently.',
    PilexCapsulesIngredients: 'Ingredients',
    PilexCapsulesIngredients1: 'Turmeric (Haldi)',
    PilexCapsulesngredients2: ' Neem Seed (Nimboli)',
    PilexCapsulesIngredients3: 'Chebulic Myrobalan (Bari Harr)',
    PilexCapsulesIngredients4: ' Dried Indian Gooseberry (Amla khushk)',
    PilexCapsulesIngredients5: 'Banslochan (Tabasheer)',
    PilexCapsulesIngredients6: 'Cumin (Zeera)',
    PilexCapsulesIngredients7: ' Mango Ginger (Amba Haldi)',
    PilexCapsulesIngredients8: ' Bitter Ginger (narkachoor)',
    PilexCapsulesUsage: 'Usage/dosage',
    PilexCapsulesUsage1: 'Adults: Two capsules in the morning and two at night, after meals with water.',
    PilexCapsulesUsage2: 'Upto 12 years: One capsule in the morning and one at night, after meals with water.',
    HemorrhoidsOil: 'Hemorrhoids (Oil)',
    HemorrhoidsOilHemorrhoidsOilBenefits: 'Benefits',
    HemorrhoidsOilHemorrhoidsOilBenefits1: ' Anti inflammatory',
    HemorrhoidsOilHemorrhoidsOilBenefits2: ' Reduces swelling',
    HemorrhoidsOilHemorrhoidsOilBenefits3: ' Relieves pain',
    HemorrhoidsOilHemorrhoidsOilBenefits4: '  Kills bacteria',
    HemorrhoidsOilHemorrhoidsOilBenefits5: 'Soothes the area',
    HemorrhoidsOilHemorrhoidsOilBenefits6: ' Reduces itching',
    HemorrhoidsOilHemorrhoidsOilIngredients: 'Ingredients',
    HemorrhoidsOilHemorrhoidsOilIngredients1: 'Neem Oil',
    HemorrhoidsOilHemorrhoidsOilIngredients2: ' Sesame Oil',
    HemorrhoidsOilHemorrhoidsOilIngredients3: 'Mastic Gum (mastagi roomi)',
    HemorrhoidsOilUsage: 'Usage/dosage',
    HemorrhoidsOilUsage1: 'Apply on the effected area as required.',
    Weight: ' Weight',
    Weight1: '225 g',
  },


  {
    id: 36,
    title: "Eczema and Psoriasis Vaseline",
    price: 1400.0,
    img: require("./assets/Medicine/Eczema-and-Psoriasis-Vaseline.jpg"),
    quantity: 1,

    dis: 'The natural and only permanent cure for Eczema.',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: 'chronic disease, dermatitis, Eczema, Improves skin, inflammation, milk crust, psoriasis, psoriasis treatment, rash, Vaseline',
    Benefits: 'Benefits',
    Benefits1: 'Improves skin conditions',
    Benefits2: 'Promotes wound healing',
    Benefits3: 'Keeps the skin hydrated',
    Benefits4: ' Anti inflammatory',
    Benefits5: ' Anti bacterial',
    Benefits6: ' Contains cooking effects',
    Ingredients: 'Ingredients',
    Ingredients1: '– Vaseline',
    Ingredients2: '– Neem oil',
    Ingredients3: '-Tea tree oil',
    Ingredients4: '-Eucalyptus Oil',
    Usage: 'Usage',
    Usage1: 'Apply on the effected areas as required.',
    Weight: 'Weight',
    Weight1: '190 g',
  },


  {
    id: 37,
    title: "Pilex Oil",
    price: 1000.0,
    img: require("./assets/Medicine/pilex-oil.jpg"),
    quantity: 1,

    dis: 'A natural and complete treatment for hemorrhoids.',
    Category: 'Medicine',
    Tags: 'Tags',
    Tags1: '100% pure, constipation, Green Roots., herbal medicines, herbs, Neem Seed, Pilex Oil, pure herbs, swollen veins, turmeric',
    Benefits: 'Benefits',
    Benefits1: 'Anti inflammatory',
    Benefits2: 'Reduces swelling',
    Benefits3: ' Relieves pain',
    Benefits4: ' Kills bacteria',
    Benefits5: ' Soothes the area',
    Benefits6: 'Reduces itching',
    Ingredients: 'Ingredients',
    Ingredients1: 'Neem Oil ',
    Ingredients2: 'Sesame Oil',
    Ingredients3: 'Mastic Gum (mastagi roomi)',
    Usage: 'Usage/dosage',
    Usage1: 'Apply on the effected area as required.',
    Weight: ' Weight',
    Weight1: '80 g',

  },


]