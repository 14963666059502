export default [
    {
      id: 1,
      title: "Aloe Vera Oil",
      price: 1000.0,
      img: require("./assets/Essential Oil/aloe-vera-oil-greenrootspk.com_.png"),
      quantity: 1,
      ml:'30 ML',
      dis:'Green Roots Aloe Vera Oil helps in skin rejuvenating and repairs damaged skin cells. It is also beneficial for hair cells and healthy hair growth.',
      SKU:'SKU',
      SKU1:'GR002OL', 
      Category:'Essential Oils',
      Tags:'Tags:',
      Tags1:'aloe vera oil, anti ageing, anti dandruff, essential oil, hair growth, hair nourishment, skin care, skin cell, repair skin lightening skin nourishment',
      Benefits:'Benefits',
      Benefits1:'Helps repairing skin cells',
      Benefits2:'Has great anti-ageing properties.',
      Benefits3:'Beneficial in removing stretch marks and wrinkles',
      Benefits4:'Heals dark spot on the skin and helps in dry skin issues.',
      Benefits5:'Makes hair healthy, strong and dandruff free.',
      Usage:'Usage',
      Usage1:'By mixing Aloe Vera oil with any carrier oil, for example, with coconut or Jojoba oil and applying on skin will help treat and heal damaged skin, lightens skin tone, and help in removing fine lines.',
      Usage2:'By applying the mixture onto your hair scalp and all over the hair helps repair damaged hair.',
      Usage3:'It also prevents hair loss and dandruff and helps in healthy hair growth.',
      Weight:'Weight',
      Weight1:'80 g',
      dicQuantity:'Quantity',
      dicQuantity1:'30 ml',

    },

   
    {
        id: 2,
        title: "Black Pepper Oil",
        price: 6000.0,
        img: require("./assets/Essential Oil/black-pepper-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Black Pepper Oil.',
        SKU:'SKU',
        SKU1:'GR007OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti anxiety, black pepper oil, cancer beneficent, constipation cure, essential oil, healthy digestion, healthy metabolism, joint pain relief, muscle relaxant, nutrition absorption, weight loss.',
        Benefits:'Benefits',
        Benefits1:'Increases nutrition absorption',
        Benefits2:'Improves digestion',
        Benefits3:'Aids in weight loss',
        Benefits4:'Promotes sweating and urination',
        Benefits5:'Relieves joint & muscle pains',
        Benefits6:'Calms anxiety',
        Benefits7:'Boosts metabolism',
        Benefits8:'Prevents new fat cells from forming',
        Benefits9:'Acts as an antidepressant',
        Benefits10:'Promotes dental health',
        Benefits11:'Prevents cancer',
        Benefits12:'Relieves gas & constipation',
        UsesofBlackPepperoil:'UsesofBlackPepperoil',
        UsesofBlackPepperoil1:'Do not apply to skin undiluted',
        UsesofBlackPepperoil2:'Can be used for cooking',
        UsesofBlackPepperoil3:'Can be mixed with carrier oils for multiple skin benefits',
        UsesofBlackPepperoil4:'Can be used for massages mixed with other oils',
        UsesofBlackPepperoil5:'Can be used on chest and nose for flu and chest congestion mixed with any carrier oil',
        UsesofBlackPepperoil6:'Can be used as a diffuser and can be inhaled as well',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


//     {
//         id: 3,
//         title: "Carrot Seed Oil",
//         price: 5000.0,
//         img: require("./assets/Essential Oil/carrotseedoiloustofstock.png"),
//         quantity: 1,
//         ml:'30 ML',
//         dis:'Carrot seed essential oil has shown antibacterial, antifungal, anti-inflammatory, and antioxidant properties. What it doesn’t have are the vitamins and nutrients provided by carrots. Like other essential oils, carrot seed oil isn’t meant to be ingested.',
//         Availability:'Availability',
//         Availability1:' : Out of stock',
//         SKU:'SKU',
//         SKU1:'GR008OL', 
//         Category:'Essential Oils',
//         Tags:'Tags:',
//         Tags1:'antioxidant aromatherapy cancer beneficent carrot seed oil detox essential oil hair nourishment hair strengthening menstrual cycles exual performance stress reliever worms cure',
//         Benefits:'Benefits',
//         Benefits1:'Contains antioxidants & anti ageing properties',
//         Benefits2:'Fights infection',
//         Benefits3:'Detoxifies blood',
//         Benefits4:'Prevent acidity',
//         Benefits5:'Kills worms',
//         Benefits6:'Can be used for aromatherapy',
//         Benefits7:'Controls menstrual cycle',
//         Benefits8:'Strengthens hair & makes them shiny',
//         Benefits9:'Firms and tones skin',
//         Benefits10:'Helps cure cancer of prostate, mouth, stomach and kidneys.',
//         Benefits11:'Cures infections and prevents tetanus',
//         Benefits12:'Provides relief from stress',
//         Benefits13:'Protects from macular degeneration and sexual weaknesses',
//         Benefits14:'Expels gas from intestines',
//         Usage:'Usage',
//         Usage1:'When used for aromatherapy, you can sprinkle a few drops onto a cloth or tissue and inhale deeply, or use a commercial aromatherapy diffuser or vaporizer. Practitioners of aromatherapy often combine carrot seed oil with other therapeutic oils, such as cedar, cinnamon, grapefruit, or geranium essential oil.',
//         Usage2:'Like all essential oils, carrot seed oil must be mixed with a carrier oil before applying topically. For this reason, it cannot be used as a tanning oil. Tanning oils, including those with SPFs, attract the sun’s UVA rays to your skin. Some people use them to try to tan safely, but there is no way to get a safe tan.',
//         Usage3:'It can be added to a cream or used in a mixture with almond oil: it tones the skin and even removes wrinkles. It is usually enough to add 3-5 drops of essential oils of carrots to the basis of 10 g oil or cream. In winter and early spring, it helps to compensate for the deficiency of vitamins in the skin',
//         Usage4:'When used topically, add no more than 12 drops of carrot seed oil to 1 fluid ounce (30 milliliters) of a carrier oil, lotion, vegetable butter, or moisturizing cream. Carrot seed oil must never be used near the eyes, in the nose or ears, on the genitals, or on any other sensitive areas of skin.',
//         Weight:'Weight',
//         Weight1:'80 g',

//    },


    {
        id: 4,
        title: "Cinnamon Oil",
        price: 1600.0,
        img: require("./assets/Essential Oil/cinnamon-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Cinnamon Oil.',
        SKU:'SKU',
        SKU1:'GR010OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti bacterial, anti depressionar, thritis cure, blood purifier, cinnamon oil, diabetes control, essential oil, healthy heart, kidney protection, menstruation, cycle, pain relief.',
        Benefits:'Benefits',
        Benefits1:'Provides pain relief',
        Benefits2:'Helps with arthritis',
        Benefits3:'Helps with diabetes',
        Benefits4:'Improves digestive health',
        Benefits5:'Helps with irregular menstruation',
        Benefits6:'Boosts the immune system',
        Benefits7:'Prevents urinary infections',
        Benefits8:'Protects the kidneys',
        Benefits9:'Helps fight depression',
        Benefits10:'Cleans the air from bacteria',
        Benefits11:'Removes bad breath',
        Benefits12:'Purifies blood',
        Benefits13:'Works as a brain tonic',
        Benefits14:'Helps fight skin infections',
        Benefits15:'Helps in respiratory problems',
        Benefits16:'Beneficial for heart disorders',
        Usage:'Usage',
        Usage1:'In aromatherapy, essential oils are diffused or spritzed into the air so they can be breathed in or absorbed into the skin. Aromatherapy has been linked to many benefits, including reduced depression and anxiety, and better sleep.',
        Usage2:'Mask made of cinnamon, olive oil, and honey may be effective at promoting the growth and thickening of hair.',
        Usage3:'Cinnamon oil is used as an ingredient in toiletries, such as toothpaste, mouthwash, soap, and body lotion. It’s also used to scent room sprays, candles, and seasonal items like pine cones and garlands.',
        Usage4:'A general recommendation is to keep the concentration of cinnamon oil low if it’s going to come in contact with skin. For example, one guideline of .01 percent concentration or less is equivalent to 1 drop of cinnamon oil for 30 to 40 milliliters (mL) of carrier liquid.',
        Usage5:'To scent your home, try adding one drop of cinnamon oil to fabric sachets or dried flowers. Or you can add a couple drops to a diffuser with water',
        Usage6:'Cinnamon oil can be mixed with a carrier oil and used for massage. Mix one drop of cinnamon oil with two to three cups of a carrier oil, such as almond oil, and use for massage or to moisturize skin.',
        Usage7:'It’s very important to dilute cinnamon oil with a carrier oil, prior to use. Try using one drop of cinnamon essential oil to two or more cups of a carrier oil, such as olive, almond, jojoba, or sesame oil.',
        Usage8:'Never rub or massage cinnamon oil directly on skin unless it’s diluted with a carrier oil.',
        Usage9:'Not to apply directly on skin',
        Weight:'Weight',
        Weight1:'85 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },
    

    {
        id: 5,
        title: "Clove Oil",
        price: 1400.0,
        img: require("./assets/Essential Oil/clove-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Clove Oil.',
        SKU:'SKU',
        SKU1:'GR011OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'allergy cure, anti ageing, blood circulation, blood purifier, blood sugar control, clove oil, essential oil, healing, healthy digestion, immune booster, nausea cure, pain reliever.',
        Benefits:'Benefits',
        Benefits1:'Relieves indigestion and nausea',
        Benefits2:'Makes skin look younger',
        Benefits3:'Works for ear aches',
        Benefits4:'Boosts immune system',
        Benefits5:'Treats colds, coughs, sty and cholera',
        Benefits6:'Heals wounds, cuts and injuries',
        Benefits7:'Relieves toothaches and removes bad breath ',
        Benefits8:'Controls sugar levels in diabetes',
        Benefits9:'Relieves stress, headaches and pains',
        Benefits10:'Purifies blood',
        Benefits11:'Stimulates blood circulation',
        Usage:'Usage',
        Usage1:'To use it for a toothache, dip a clean tissue, cotton swab, or cotton ball into the oil and wipe it over the gums at the point of the pain',
        Usage2:'Can be applied to the skin mixed with carrier oils or creams and products',
        Usage3:'Using the oil as a spray is an easy way to add the spicy scent of clove to a room. Mixed with water',
        Usage4:'Cannot be used for drinking purpose',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    
    {
        id: 6,
        title: "Eucalyptus Oil",
        price: 1500.0,
        img: require("./assets/Essential Oil/euclyptus-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots eucalyptus oil is useful in a lot of ways, among many usages it heals wounds, helps reduce mental exhaustion, relieves muscle pain and treats fever.',
        SKU:'SKU',
        SKU1:'GR015OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti lice, diabetes control, essential oil, eucalyptus oil, healing, healthy teeth, intestinal germs, menstrual energy, muscle relaxant, pain reliever, respiratory issues cure.',
        Benefits:'Benefits',
        Benefits1:'Heals wounds',
        Benefits2:'Removes mental exhaustion',
        Benefits3:'Good for dental care',
        Benefits4:'Removes intestinal germs',
        Benefits5:'Treats fever',
        Benefits6:'Treats respiratory problems', 
        Benefits7:'Relieves muscle pains',
        Benefits8:'Removes lice',
        Benefits9:'Manages diabetes',
        Usage:'Usage',
        Usage1:'Can be used to take steam (few drops)',
        Usage2:'Can be used in a warm bath/shower',
        Usage3:'Can be inhaled',
        Usage4:'Can be used as a painkiller massage oil',
        Usage5:'Can be used on skin mixed with any other carrier oil (12 drops for one ounce of carrier oil)',
        Usage6:'Can be used for hair mixed with any carrier oil.',
        Weight:'Weight',
        Weight1:'75 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 7,
        title: "Wheat Germ Oil",
        price: 2400.0,
        img: require("./assets/Essential Oil/wheat-germ-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'(Vitamin E Oil) Green Roots Wheatgerm Oil, also known worldwide as vitamin E oil, richest source of Vitamin E, with multiple health benefits, Energy boosting qualities as well as helps slowing the process of ageing',
        SKU:'SKU',
        SKU1:'GR062OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti ageing, anti hair fall, birth defect prevention, blood sugar control, cholesterol control, eczema relief, energizing, essential oil, healthy brain, vitamin e oil, wheat germ oil.',
        Benefits:'Benefits',
        Benefits1:'Lowers LDL cholesterol.',
        Benefits2:'Boosts energy capacity',
        Benefits3:'Slows down ageing',
        Benefits4:'Prevents hair fall',
        Benefits5:'Reduces stress levels',
        Benefits6:'Works as a remedy for eczema',
        Benefits7:'Regulates Nervous System',
        Benefits8:'Promotes Blood Sugar Level',
        Benefits9:'Fights Fat Accumulation',
        Benefits10:'Prevents Birth Defects',
        Usage:'Usage',
        Usage1:'Massaging the oil on the body alleviates pain, pressure, hence greatly uplifting memory and positively influencing mood.',
        Usage2:'Applying wheat germ oil on hair is a magical solution to attain smooth, soft, silky tresses.',
        Usage3:'Just a few drops of this miraculous tincture is enough, to decrease free radical damage to skin cells, thus diminishing signs of ageing like wrinkles, fine lines and giving a youthful, unblemished appearance.',
        Usage4:'Add wheat germ oil to your diet to get all the benefits mentioned above. You can also add it in the following ways:',
        Usage5:'By baking in cookies, breads, waffles, etc.',
        Usage6:'By sprinkling it over ice creams, soups, yogurt, salads, or cereals.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',
       
    },

    
    {
        id: 8,
        title: "Tea Tree Oil",
        price: 1900.0,
        img: require("./assets/Essential Oil/tea-tree-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Tea Tree essential Oil is used typically for infections such as acne, fungal infections of nails, lice, scabies, and athletes foots.',
        SKU:'SKU',
        SKU1:'GR059OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti bacterial, essential oil, hair nourishment, healing, healthy scalp, inflammation remedy, ringworm cure, skin care, sore relief, tea tree oil, yeast infection cure.',
        Benefits:'Benefits',
        Benefits1:'Acts as a healing ointment',
        Benefits2:'Soothes inflamed skin',
        Benefits3:'Effective against acne',
        Benefits4:'Helps relieve sore',
        Benefits5:'Beneficial for ringworm and athlete’s feet',
        Benefits6:'Improves hair and scalp health',
        Benefits7:'Kills bacteria',
        Benefits8:'Removes yeast infections',
        Usage:'Usage',
        Usage1:'Can be used on wounds directly (few drops)',
        Usage2:'Can be added to a cup of water (2-3 drops) and used as a mouthwash',
        Usage3:'Can be applied to the skin mixed with any carrier oil',
        Usage4:'Can be applied for dandruff (5% of tea tree oil mixed with shampoo per wash)',
        Usage5:'For healthy hair, mix with any carrier oil (ratio 1-10) and apply.',
        Ingredients:'Ingredients',
        Ingredients1:'Pure Tea Tree Extract',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 9,
        title: "Sandalwood Oil",
        price: 9500.0,
        img: require("./assets/Essential Oil/sandalwood-oil-1-greenrootspk.com_.png"),
        quantity: 1,
        ml:'15 ML',
        dis:'Sandalwood oil is believed to be beneficial in treating both physical and mental disorders, including anxiety, bronchitis, diarrhea, fatigue, fever, gallbladder problems, high blood pressure, indigestion, insomnia, liver problems, low libido, sore throat, and urinary tract infections.',
        SKU:'SKU',
        SKU1:'GR054OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'alertness, anti anxiety, anti bacterial, cold cure, digestion issues cure, essential oil, gallbladder treatment, hair growth, healing, hemorrhoids cure, liver treatment, muscle issues cure, sandalwood oil, scabies cure, scalp nourishment, scar remover, skin cancer protection, skin nourishment, UTI beneficent.',
        Benefits:'Benefits',
        Benefits1:'Increase alertness.',
        Benefits2:'Manages anxiety.',
        Benefits3:'Supports wound healing.',
        Benefits4:'Guards against skin cancer.',
        Benefits5:'Fights bacteria.',
        Benefits6:'Beneficial for common cold',
        Benefits7:'Beneficial for urinary tract infections',
        Benefits8:'Treats liver and gallbladder problems',
        Benefits9:'Treats digestive problems',
        Benefits10:'Beneficial for muscle problems',
        Benefits11:'Works for mental disorders', 
        Benefits12:'Helps treat hemorrhoids',
        Benefits13:'Helps treat scabies',
        Benefits14:'Stimulates the roots, improves hair growth, and increases circulation in the scalp.',
        Benefits15:'Softens the skin',
        Benefits16:'Sandalwood reduces the appearance of bruises and scars',
        Usage:'Usage',
        Usage1:'Apply it directly to your skin',
        Usage2:'Put a few drops in your lotion',
        Usage3:'Heat it in a small kettle of water to scent your home',     
        Usage4:'Evaporate it using an essential oil diffuser',
        Usage5:'Add it to your bath water',
        Usage6:'Apply sandalwood essential oil on your face and massage gently. Leave it on overnight and wash with lukewarm water the next morning. (For skin softening)',
        Ingredients:'Ingredients',
        Ingredients1:'Pure Sandalwood Extract',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'15 ml',

    },


    {
        id: 10,
        title: "Sandalwood Oil",
        price: 18000.0,
        img: require("./assets/Essential Oil/sandalwood-oil-1-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Sandalwood oil is believed to be beneficial in treating both physical and mental disorders, including anxiety, bronchitis, diarrhea, fatigue, fever, gallbladder problems, high blood pressure, indigestion, insomnia, liver problems, low libido, sore throat, and urinary tract infections.',
        SKU:'SKU',
        SKU1:'GR054OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'alertness, anti anxiety, anti bacterial, cold cure, digestion issues cure, essential oil, gallbladder treatment, hair growth, healing, hemorrhoids cure, liver treatment, muscle issues cure, sandalwood oil, scabies cure, scalp nourishment, scar remover, skin cancer protection, skin nourishment, UTI beneficent.',
        Benefits:'Benefits',
        Benefits1:'Increase alertness.',
        Benefits2:'Manages anxiety.',
        Benefits3:'Supports wound healing.',
        Benefits4:'Guards against skin cancer.',
        Benefits5:'Fights bacteria.',
        Benefits6:'Beneficial for common cold',
        Benefits7:'Beneficial for urinary tract infections',
        Benefits8:'Treats liver and gallbladder problems',
        Benefits9:'Treats digestive problems',
        Benefits10:'Beneficial for muscle problems',
        Benefits11:'Works for mental disorders', 
        Benefits12:'Helps treat hemorrhoids',
        Benefits13:'Helps treat scabies',
        Benefits14:'Stimulates the roots, improves hair growth, and increases circulation in the scalp.',
        Benefits15:'Softens the skin',
        Benefits16:'Sandalwood reduces the appearance of bruises and scars',
        Usage:'Usage',
        Usage1:'Apply it directly to your skin',
        Usage2:'Put a few drops in your lotion',
        Usage3:'Heat it in a small kettle of water to scent your home',     
        Usage4:'Evaporate it using an essential oil diffuser',
        Usage5:'Add it to your bath water',
        Usage6:'Apply sandalwood essential oil on your face and massage gently. Leave it on overnight and wash with lukewarm water the next morning. (For skin softening)',
        Ingredients:'Ingredients',
        Ingredients1:'Pure Sandalwood Extract',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 11,
        title: "Rose Oil",
        price: 4500.0,
        img: require("./assets/Essential Oil/rose-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Pure Rose Essential Oil may be used effectively to: reduce anxiety, stress, depression, and pain. increase libido. protect against harmful bacteria and fungi. When applied directly to the skin, skin care products containing rose essential oil are purported to hydrate dry skin, clear acne, reduce signs of aging, minimize the appearance of scars, and help with conditions such as eczema and rosacea. It also has multiple health benefits',
        SKU:'SKU',
        SKU1:'GR053OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti ageing, anti anxiety, anti depression, anti inflammation, blood circulation, blood purifier, essential oil, healthy digestion, infection prevention, libido booster, liver protection, muscle relaxant, rose oil, sexual performance, skin nourishment, stomach protection ,stress reliever, uterus protection.',
        Benefits:'Benefits',
        Benefits1:'Helps fight depression',
        Benefits2:'Prevents infections',
        Benefits3:'Protects the uterus',
        Benefits4:'Stimulates circulation',
        Benefits5:'Fights inflammation',  
        Benefits6:'Good for digestion',
        Benefits7:'Strengthens gums',
        Benefits8:'Improves skin texture and health.',
        Benefits9:'Keeps the skin youthful',
        Benefits10:'Acts as a nerve tonic to bear shock',
        Benefits11:'Protects liver and stomach from infections',
        Benefits12:'Purifies blood',
        Benefits13:'Helps stimulate menstruation and boosts libido',
        Benefits14:'Cures cramps, muscle pulls',
        Benefits15:'Decreases anxiety and stress',
        Usage:'Usage',
        Usage1:'To make a rose oil bath, add 10 drops of rose essential oil to a carrier oil, then add to a warm tub. Essential oils should always be diluted in a carrier oil before adding to water.',
        Usage2:'To make a foot bath, add a few drops of diluted rose oil into the foot bath and soak your feet for 10 minutes.',
        Usage3:'To treat anxiety, stress, or depression, you can either inhale rose oil using a diffuser or dab it lightly on your chest, neck, and wrists. Be sure to blend it with a carrier oil first like coconut, almond, or jojoba oil before it touches the skin. You may also benefit from a rose oil massage, in which the rose essential oil is added to a carrier massage oil.',
        Usage4:'Can be applied to the skin and hair mixed with any carrier oil for your choice.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    
    {
        id: 12,
        title: "Rosemary Oil",
        price: 1200.0,
        img: require("./assets/Essential Oil/rosemarry-oil-greenrootspk.com.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Rosemary Oil is used as a hair oil for shiny hair and aromatic usage as well. Also has an anti-ageing benefits for skin.',
        SKU:'SKU',
        SKU1:'GR052OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti ageing, anti allergy, anti anxiety, anti marks, essential oil, hair growth, rosemary oil, skin lightening.',
        Benefits:'Benefits',
        Benefits1:'Good for hair health.',
        Benefits2:'Reduces the appearance of stretch marks.',
        Benefits3:'Reduces Anxiety.',
        Benefits4:'Treats allergy.',
        Benefits5:'Used as an anti-ageing agent.',
        Usage:'Usage',
        Usage1:'Can be applied directly on skin.',
        Usage2:'Can be inhaled for stress relief.',    
        Usage3:'Apply on hair scalp directly (5 drops).',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

   
    {
        id: 13,
        title: "Rosehip Oil",
        price: 1200.0,
        img: require("./assets/Essential Oil/rosehip-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Rosehip oil is rich in essential fatty acids and antioxidants, which are integral for tissue and cell regeneration in the skin. It is also beneficial for knee problems, helps treat diabetes, swelling, dandruff, and acne.',
        SKU:'SKU',
        SKU1:'GR051OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti ageing, anti dandruff, anti inflammation, beard oil, collagen boost, dark lips remedy, diabetes cure, essential oil, even skin tone, healing, knee pain, rosehip oil, skin brightening, skin healing, skin nourishment, skin protection.',
        Benefits:'Benefits',
        Benefits1:'Treats knee problems, diabetes & swelling',
        Benefits2:'Lightens dark lips',
        Benefits3:'Softens beard hair',
        Benefits4:'Treats dandruff',
        Benefits5:'Repairs and protects skin',
        Benefits6:'Tones skin',
        Benefits7:'Hydrates skin',
        Benefits8:'Fights acne',
        Benefits9:'Reduces signs of ageing',
        Benefits10:'Good for sun tanning and pigmentation skin',
        Benefits11:'Reduces wrinkles',
        Benefits12:'Helps in skin regeneration',
        Benefits13:'Reduces inflammation and has healing properties',
        Benefits14:'Helps brighten skin',
        Benefits15:'Boost collagen formation',
        Usage:'Usage',
        Usage1:'Apply two to three drops, warm in the palm of your hands and apply to your face.',
        Usage2:'Or add just one or two drops of it to your normal moisturizer.',
        Usage3:'For best results use Rosehip Oil morning and night. Allow 5 minutes before applying a moisturizer or sunscreen over it.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    
    {
        id: 14,
        title: "Fennel Oil",
        price: 2500.0,
        img: require("./assets/Essential Oil/fennel-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Fennel Oil works as an antiseptic agent, stimulates brain and neural activities, works wonders for the skin, helps heal hair problems and benefits in hair growth, as well as is beneficial for multiple health issues.',
        SKU:'SKU',
        SKU1:'GR016OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti ageing, anti dandruff, antiseptic, antispasmodic, brain health, chronic, cough cure, facial cleanser, hair growth, hair nourishment, intestinal diseases cure, menstrual relief, nerve health, scalp conditioner, skin care, skin nourishment.',
        Benefits:'Benefits',
        Benefits1:'Excellent antiseptic and antispasmodic agent',
        Benefits2:'Provides relief from menstrual problems',
        Benefits3:'Stimulates brain and neural activity',
        Benefits4:'Improves amount of milk in lactating mothers',
        Benefits5:'Effective in treating chronic cough',
        Benefits6:'Relieves gas and indigestion & kills intestinal worms',
        Benefits7:'Increases frequency in urination',
        Benefits8:'Controls sugar cravings & adds flavor to food',
        Benefits9:'Boosts productivity',
        Benefits10:'Supports healthy metabolism',
        Benefits11:'Maintains cholesterol and lowers high blood pressure',
        Benefits12:'Protects against harmful radiations during cancer treatment',
        Benefits13:'Delays wrinkles & acts as a facial toner & as a deep cleanser',
        Benefits14:'Fights acne, cleanses the scalp, moisturizes the hair & stimulates hair growth',
        Benefits15:'Helps in dandruff and scalp itchiness, stop hair fall & makes the skin flawless and glowing',
        Usage:'Usage',
        Usage1:'Can be used for healing wounds can be applied on the affected areas. Gives soothing relief.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 15,
        title: "Loban Oil",
        price: 2400.0,
        img: require("./assets/Essential Oil/loban-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'(Frankincense Oil) Frankincense has earned its title as “The King of Oils” because of its versatility. When used topically, frankincense touts powerful anti-aging properties. It promotes cellular function and the appearance of healthy-looking skin, evening out skin tone and minimizing blemishes. It also helps reduce arthritis, improves gut functions, improves asthma, boosts the immune system, fights certain types of cancers, reduces respiratory problems and many more health benefits.',
        SKU:'SKU',
        SKU1:'GR018OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'arthritis remedy, asthma remedy, astringent, cancer beneficent, frankincense oil, gut health, healthy digestion, immune booster, loban oil, menstrual cycle, natural oil, oral health, pure oil, respiratory issues cure.',
        Benefits:'Benefits',
        Benefits1:'May Reduce Arthritis',
        Benefits2:'May Improve Gut Function',
        Benefits3:'Improves Asthma',
        Benefits4:'Maintains Oral Health',
        Benefits5:'May Fight Certain Cancers',
        Benefits6:'Boosts immune system',
        Benefits7:'Astringent properties',
        Benefits8:'Good for the digestive system',
        Benefits9:'Reduces respiratory problems',
        Benefits10:'Regulates menstruation',
        Benefits11:'Stimulates urination',
        Benefits12:'Helps relieve stress',
        Benefits13:'Heals skin',
        Usage:'Usage',
        Usage1:'Frankincense is a mild oil and most people can apply it directly to skin, or neat, without any issues. However, if you experience any burning, tingling, or irritation, dilute the oil by rubbing a carrier oil, such as fractionated coconut oil, jojoba oil, or grapeseed oil, over the irritated area.',
        Usage2:'Fill a dark glass squirt bottle with pure water. Add 6 drops of frankincense oil per 1 ounce of water. Mix well before each use, or add 3 drops of vodka to prevent separation. Spray onto face & body whenever you want a refresher.',
        Usage3:'Frankincense is a wonderful sleep aid because of its aromatherapeutic benefits. It is said to help relieve stress and promote relaxation.',
        Usage4:'Place a few drops of oil into your hands, rub your palms together, and inhale before laying down to sleep.',
        Usage5:'Boost your skin’s radiance by massaging a drop of Frankincense into facial skin after cleansing, followed by applying your regular moisturizers.',
        Usage6:'Add a few drops to your face cream or body lotion and rub directly into skin.',
        Usage7:'Dilute with a carrier oil such as fractionated coconut oil or sweet almond oil before use. Massage into the stressed parts of your arms and feet to ease tension and bring clarity to your mind.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 16,
        title: "Lavender Oil",
        price: 1800.0,
        img: require("./assets/Essential Oil/lavendar-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Lavender Essential Oil.',
        SKU:'SKU',
        SKU1:'GR028OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'air purifier, antioxidant, aromatic oil, deep sleep, essential oil, healthy brain, immune booster, lavender oil, nerve health, peaceful sleep, relaxing oil, skin nourishment, stress reliever.',
        Benefits:'Benefits',
        Benefits1:'Relieves stress and promotes relaxation.',
        Benefits2:'Supports healthy brain functions',
        Benefits3:'Promotes deeper sleep.',
        Benefits4:'Freshens and purifies the air.',
        Benefits5:'Supports normal respiration',
        Benefits6:'Supports healthy nervous system',
        Benefits7:'Smoothens and revitalizes skin',
        Benefits8:'Reduces muscle soreness',
        Benefits9:'Supports the immune system',
        Benefits10:'Has a high antioxidant value.',
        Usage:'Usage',
        Usage1:'Added with another carrier oil, can be massaged on the skin.',
        Usage2:'Can be used in water (few drops) in the bath.',
        Usage3:'Can sprinkle on a cloth or tissue (few drops) and i hate it’s aroma',
        Usage4:'A few drops can be added to aromatherapy or diffuser.',
        Usage5:'Can be applied directly on the skin for wrinkles and dry skin',
        Usage6:'Can be used as steam for aromatherapy',      
        Usage7:'Can be applied to the wrist and forehead (few drops) for anxiety or muscle relaxation',
        Usage8:'Can be applied to hair mixed with any carrier oil',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 17,
        title: "Poppy Seed Oil",
        price: 550.0,
        img: require("./assets/Essential Oil/poppy-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'(Khus Khus Oil) Green Roots Poppy Seed Oil contains antioxidants, including naturally occurring vitamin E, which may lower the risk of conditions like cancer. It acts like a moisturizer for the skin and hair, regulates sleep, bone strength, blood pressure, thyroid levels. It’s helpful in treating diabetes, kidney stones, eczema, inflammation, headaches.',
        SKU:'SKU',
        SKU1:'GR046OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti dandfruff, anti hair fall, anti inflammation, bone health, cancer beneficent, eczema cure, essential oil, eyesight herbal remedy, female fertility, hair growth, hair nourishment, healthy digestion, healthy thyroid, khus khus oil, kidney stone cure, poppy seeds, calp conditioner, skin nourishment, sleep cycle, split ends.',
        Benefits:'Benefits',
        Benefits1:'Natural moisturizer for hair and skin.',
        Benefits2:'Stimulates hair growth',
        Benefits3:'Helps prevent hair loss, fight dandruff, dry scalp & split ends',
        Benefits4:'Treats eczema and inflammation',
        Benefits5:'Cures headache & regulates sleep',
        Benefits6:'Lowers the risks of cancers',
        Benefits7:'Boosts female fertility',
        Benefits8:'Improves bone strength, digestion & vision',
        Benefits9:'Regulates blood pressure',
        Benefits10:'Helps treat Diabetes',
        Benefits11:'Good for thyroid',
        Benefits12:'Helpful in treating kidney stones',
        Usage:'Usage',
        Usage1:'Can be used for cooking',
        Usage2:'Can be used on the skin as a moisturizer',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 18,
        title: "Pomegranate Oil",
        price: 1600.0,
        img: require("./assets/Essential Oil/pomgranate-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Pomegranate Oil.',
        SKU:'SKU',
        SKU1:'GR045OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti acne, anti inflammationan, tioxidant, arthritis relief, cancer beneficent, essential oil, hair nourishment, healthy heart, joint pain relief, pomegranate oil, skin nourishment.',
        Benefits:'Benefits',
        Benefits1:'Anti Inflammatory',
        Benefits2:'Beneficial for hair growth, skin & acne',
        Benefits3:'Makes a good hair tonic',
        Benefits4:'Antioxidant',
        Benefits5:'Lowers blood pressure',
        Benefits6:'Improves heart health',
        Benefits7:'Reduces pain in joints and arthritis',
        Benefits8:'Helps fight cancer',
        Usage:'Usage',
        Usage1:'ANTI-AGEING – Use the oil as a night treatment or as a night serum to slow down aging.',
        Usage2:'ACNE – To use this oil for acne, Use it to massage your face in the morning and also to use it as a night treatment.',
        Usage3:'To use pomegranate oil for dandruff, simply mix it in your shampoo and work it on your scalp and hair. You can also use it as a hot oil treatment; mix this oil with coconut oil 1:4, warm it and apply it on your scalp and hair. Wash off using regular shampoo after 2-3 hours.',
        Usage4:'Pomegranate oil is the unique edible oil produced from the seed of the pomegranate fruit',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 19,
        title: "Peanut Oil",
        price: 800.0,
        img: require("./assets/Essential Oil/peanut-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Peanut Oil has many benefits and several usages, it can be used for body massages, helps in improving blood flow, lowering blood pressure and helps prevent stomach problems . It improve heart health and maintain cholesterol level, reduces the risk of cancers and beneficial for arthritis.',
        SKU:'SKU',
        SKU1:'GR044OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'arthritis relief, blood pressure control, body massage, cancer beneficent, essential oil, hair nourishment, healthy heart, massage oil, peanut oil, skin nourishment, stomach protection.',
        Benefits:'Benefits',
        Benefits1:'Used for body massages',
        Benefits2:'Helps in improving blood flow, lowering blood pressure, prevent stomach problems & improve heart health',
        Benefits3:'Is used for skin care & hydrating lips',
        Benefits4:'Helps maintain cholesterol level, get rid of black moles and acne & improve hair health',
        Benefits5:'Reduces The Risk Of Cancers',
        Benefits6:'Beneficial for Arthritis',
        Usage:'Usage',
        Usage1:'Can be used as aromatherapy',
        Usage2:'Peanut oil has a high smoke point and neutral taste which makes it perfect for frying foods.',
        Usage3:'Peanut oil is used for frying, sautéing, and simply adding flavor',
        Usage4:'By including peanut oil in the diet in medium quantities, one can ensure that proper cholesterol levels are maintained and thus the heart health is restored',
        Usage5:'Peanut oil can help in massaging the body and thus provide it with energy. It helps in the treatment of aching joints and muscles. This should be used on a daily basis with a carrier oil to get maximum benefits.',
        Usage6:'Peanut oil for skin repair. Mixing it with a few drops of lemon juice and applying it helps in getting the outcomes.',
        Usage7:'Peanut oil with lemon juice and tea tree oil can be applied on the scalp and left for the next 2-3 hours before shampooing. This antiseptic way of treating dandruff is one of the easiest things to do and in a very effective manner.',
        Usage8:'Massaging the lips with peanut oil after a proper scrub procedure at home is one of the best ways to provide nourishment to the lips and to make it soft, smooth and supple. Being enriched with Vitamin E, peanut oil can lighten the lip color and restore its natural pinkness. You can even apply peanut oil as a lip balm to prevent chapping.',
        Usage9:'The inclusion of cold-pressed peanut oil in your daily diet can help in reducing the joint inflammation and alleviating pain. Vitamin E present in this oil strengthens the bones and aids in protecting them from joint disorders.',
        Usage10:'Peanut oil is enriched with Vitamin E, which can reduce and delay the signs of ageing like blemishes, dark spots, pigmentation, fine lines and patchy skin. To use peanut oil for skin, just take a few drops of cold-pressed oil and massage on skin regul',
        Usage11:'Peanut oil can bring a natural glow to your skin and hair when combined with other ingredients.',
        Usage12:'Peanut oil is wonderful for diabetic patients. It contains more unsaturated fats than saturated fats improve insulin sensitivity, which regulates blood sugar levels in the body. The way to use this oil is in wok stir-fries, low heat cooking and even as a drizzle on salads.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 20,
        title: "Peppermint Oil",
        price: 1600.0,
        img: require("./assets/Essential Oil/peppermint-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Peppermint Essential Oil treats UTIs, is good for respiration and improves blood circulation. It is also useful in improving oral hygiene, boosts immune system and improves digestion.',
        SKU:'SKU',
        SKU1:'GR043OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'blood circulation, essential oil, healthy breathing, healthy digestion, immune booster, oral hygiene, pain relief, peppermint, UTI cure.',
        Benefits:'Benefits',
        Benefits1:'Treats UTIs',
        Benefits2:'Relieves headaches',
        Benefits3:'Good for respiration',
        Benefits4:'Improves blood circulation',
        Benefits5:'Improves oral hygiene',
        Benefits6:'Reduces pains',
        Benefits7:'Boosts the immune system',
        Benefits8:'Improves digestion.',
        Usage:'Usage',
        Usage1:'Can be used as flavoring in foods and beverages',
        Usage2:'Can be used as a skin cream or ointment',
        Usage3:'Can be taken orally in dietary supplements',
        Usage4:'Can be directly applied to the skin',
        Usage5:'Can be massaged on the skin',
        Usage6:'Can be added to baths or mixed with other painkiller oils',
        Usage7:'Can be added to drinking water',
        Usage8:'Can be applied to hair mixed with any massage oil.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

   },

    
    {
        id: 21,
        title: "Orange Oil",
        price: 800.0,
        img: require("./assets/Essential Oil/orange-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Orange Oil is effective in treating spasms, helps cure depression and works as an energy tonic. It cures acne and dermatitis, helps remove toxins and relieves inflammation. Amongst many other benefits of orange oil, it boosts immunity, disinfects wounds and relaxes muscular and nervous spasms.',
        SKU:'SKU',
        SKU1:'GR040OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:' anti acne, anti anxiety, anti depression, anti dermatitis, anti inflammation, detox, energizing, essential oil, healing, immunity booster, infection prevention, libido booster, muscle spasm control, nerve relaxant, orange, sedative.',
        Benefits:'Benefits',
        Benefits1:'Treats spasms',
        Benefits2:'Helps cure depression',
        Benefits3:'Works as an energy tonic & as a sedative',
        Benefits4:'Prevents infections',
        Benefits5:'Increases libido',
        Benefits6:'Cures acne and dermatitis',
        Benefits7:'Helps remove toxins',
        Benefits8:'Relieves inflammation',
        Benefits9:'Boosts immunity',
        Benefits10:'Disinfects wounds',
        Benefits11:'Relaxes muscular and nervous spasms',
        Benefits12:'Alleviates anxiety',
        Usage:'Usage',
        Usage1:'When combined with a carrier oil, orange essential oils can be applied directly to the skin at a ratio of one to two drops of essential oil to one teaspoon of carrier oil.',
        Usage2:'Orange essential oils can also be safely inhaled by sprinkling a few drops onto a cloth or tissue, or using an aromatherapy diffuser or vaporizer.',
        Usage3:'Breathing in the scent of sweet orange essential oil may help alleviate anxiety',       
        Usage4:'Can be used on the skin for acne mixed with carrier oils',
        Usage5:'Dilute orange essential oil in a carrier oil, like coconut oil or jojoba oil. suggests using 20 drops of essential oil per ounce of carrier oil to make a massage oil with a 3 percent solution',
        Usage6:'Never inhale orange essential oil directly from the bottle. Doing so can cause nasal and throat irritation and even a mild burn if a droplet is accidentally inhaled. Like most essential oils, sweet orange essential oil is not intended for internal use.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    {
        id: 22,
        title: "Ginger Oil",
        price: 2200.0,
        img: require("./assets/Essential Oil/ginger-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Ginger Oil.',
        SKU:'SKU',
        SKU1:'GR019OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'arthritis relief, bowel syndrome cure, digestion issues cure, essential oil, ginger, hair growth, menstrual relief, muscle relaxant, respiratory issues cure, sinus cure, skin lightening.',
        Benefits:'Benefits',
        Benefits1:'Effective for arthritis',
        Benefits2:'Reduces menstrual cramps',
        Benefits3:'Cures digestive problems',
        Benefits4:'Eases frozen shoulders',
        Benefits5:'Relieves sinus congestion',
        Benefits6:'Recovers muscle fatigue',
        Benefits7:'Helps get rid of travel sickness',
        Benefits8:'Cures IBS (irritable bowel syndrome)',
        Benefits9:'Beneficial for colds, sore throats and respiratory relief & hair fall',
        Benefits10:'Improves hair growth',
        Benefits11:'Clears scars and spots',
        Usage:'Usage',
        Usage1:'Inhaling the aroma from ginger oil has been used as a way to relieve nausea.',
        Usage2:'Ginger oil or extract is sometimes included in shampoos or other hair products, as it’s believed to promote hair health and growth',
        Usage3:'Diffusers are a great way to add a pleasing scent to a room. In some cases, the essential oil may need to be diluted in water',
        Usage4:'Ginger oil in a spray can be used to freshen the air in a room.',
        Usage5:'Ginger oil can be used for steam inhalation (few drops in water)',  
        Usage6:'Ginger oil that’s being applied to the skin should always be diluted in carrier oil first. Some examples of carrier oils include almond oil, jojoba oil, coconut oil, and avocado oil.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    {
        id: 23,
        title: "Grape Seed Oil",
        price: 500.0,
        img: require("./assets/Essential Oil/grape-seed-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Grape Seed Oil.',
        SKU:'SKU',
        SKU1:'GR020OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti acne, anti dandruff, bone health, cancer prevention, cognitive function, diabetes remedy, essential oil, grape, hair growth, hair strengthening, oral health, osteoarthritis, seed, skin care.',
        Benefits:'Benefits',
        Benefits1:'Prevents cancer',
        Benefits2:'Controls blood pressure',
        Benefits3:'Improves bone health & cognitive functions',
        Benefits4:'Beneficial for skin care, oral health, osteoarthritis & diabetes',
        Benefits5:'Protects against cardiovascular diseases',
        Benefits6:'Protects the stomach',
        Benefits7:'Treats acne and pimples',
        Benefits8:'Minimizes eye circles',
        Benefits9:'Skin tightening agent',
        Benefits10:'Helps hair growth',
        Benefits11:'Combats dandruff',
        Benefits12:'Improves hair shine and strengthens hair',
        Usage:'Usage',
        Usage1:'Mixing a drop of grape seed oil in your favorite moisturizing face and body creams helps to seal the moisture into your skin.',
        Usage2:'If you’d prefer, you can heat grape seed oil slightly by putting some in your palms and rubbing your hands together. Apply a generous amount to your skin as a moisturizing mask.',
        Usage3:'Apply a small amount to the palm of your hand and rub together. Massage into your scalp gently. Grape seed oil promotes healthy hair growth and acts as a natural dandruff treatment. It can also be diluted with distilled water and placed in a spray bottle to control hair in humid temperatures.',
        Usage4:'To use grape seed oil on your face, massage several drops into clean skin before you go to bed at night. You can repeat the process in the morning, if desired. Since grape seed oil doesn’t clog pores, it’s ideal for all skin types, including oily skin that needs moisturizing.',
        Usage5:'Grape seed oil is a well-known carrier oil, which makes loose skin remarkably tight over time. It is rich in vitamin E and healthy fats, which hydrate the skin profusely and boost the production of collagen in it.',
        Usage6:'Grape seed oil reduces puffy eyes, dark circles and forming of eye-bags, and nourishes the skin from within. Moreover, it also helps in getting good sleep. How to use: Apply few drops of grape seed oil on the affected eye area and dark spots with a cotton pad.',
        Usage7:'Can be used for cooking and: 1) Stir Frying • 2. Deep Frying • 3. Sautéing • 4. Searing Meat • 5. Roasting Vegetables • 6. Salad Dressings • 7. Grilling.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    {
        id: 24,
        title: "Niaouli Oil",
        price: 2600.0,
        img: require("./assets/Essential Oil/niaouli-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Niaouli Oil.',
        SKU:'SKU',
        SKU1:'GR035OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti bacterial, anti marks, anti septicemia, arthritis cure, blood circulation, essential oil, gout cure, immune booster, intestinal worms cure, lungs congestion remedy, niaouli oil, pain relief, rheumatism cure.',
        Benefits:'Benefits',
        Benefits1:'Helps stimulate blood circulation',
        Benefits2:'Relieves rheumatism and arthritis and gout',
        Benefits3:'Protects wounds from bacteria and septicemia',
        Benefits4:'Removes acne marks and scars',
        Benefits5:'Clears congestion in lungs',
        Benefits6:'Helps lower body temperature in fever',
        Benefits7:'Eliminates intestinal worms',
        Benefits8:'Relieves headaches, toothaches, earaches, muscle pains and joint pains',
        Benefits9:'Cures skin infections',
        Benefits10:'Tones digestive system',
        Benefits11:'Stimulates growth and strengthens immune system',
        Usage:'Usage',
        Usage1:'Add one drop to your facial cleanser or toothpaste.',
        Usage2:'Add a few drops to a spray bottle with water and use to clean household surfaces.',
        Usage3:'Include as part of a soothing massage',
        Usage4:'Diffuse with Eucalyptus, Bergamot, or Lavender essential oils for a calming and pleasant aroma.',
        Usage5:'Essential oils can be applied to the crown of your head, behind the ears, to your neck, and to your temples. If the aroma of an oil is too strong, or your skin is sensitive, you can dilute it with a carrier oil.',
        Usage6:'Can be added to food.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

     },


    {
        id: 25,
        title: "Lemon Oil",
        price: 950.0,
        img: require("./assets/Essential Oil/lemon-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Lemon essential oil is used in the treatments of skin, hair and body. Also treats with health benefits.',
        SKU:'SKU',
        SKU1:'GR030OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'essential oil, hair nourishment, healthy breathing, healthy digestion, immune booster, lemon, nausea cure, oral health, skin health, stress reliever.',
        Benefits:'Benefits',
        Benefits1:'Reduces nausea & stress',
        Benefits2:'Improves concentration',
        Benefits3:'Keeps hair healthy',
        Benefits4:'Supports digestion',
        Benefits5:'Boosts oral health',
        Benefits6:'Teats skin conditions',
        Benefits7:'Improves respiratory functions',
        Benefits8:'Improves immune system functions',
        Benefits9:'Treats multiple skin conditions.',
        Usage:'Usage',
        Usage1:'Can be used for aromatherapy mixed with any carrier oil.',
        Usage2:'Reduces Nausea. Can be used as a diffuser.',
        Usage3:'Can be applied on skin with any non comedogenic oil for wrinkle free and spotless skin..',
        Usage4:'Can be mixed with any carrier oil and applied on hair for controlling hair fall.',
        Usage5:'Can be used as a diffuser',
        Usage6:'Can be used on the skin mixed with any non comedogenic oil.',
        Usage7:'Can be applied to hair mixed with any carrier oil.',
        Weight:'Weight',
        Weight1:'65 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 26,
        title: "Lemongrass Oil",
        price: 2200.0,
        img: require("./assets/Essential Oil/lemongrass-oil-greenrootspk.com_-600x480.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Lemongrass essential oil is an antifungal and antibacterial oil. Also helps in relieving headache, fever stress and hair fall.',
        SKU:'SKU',
        SKU1:'GR029OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti bacterial, anti fungal, anti hair loss, energizing, essential oil, immune booster, lemongrass, pain relief, peaceful sleep, sleep cycle, stress reliever, ulcer protection.',
        Benefits:'Benefits',
        Benefits1:'Antifungal and antibacterial',
        Benefits2:'Reduces fever',
        Benefits3:'Relieves headaches',
        Benefits4:'Boosts energy',
        Benefits5:'Reduces stomach aches',
        Benefits6:'Beneficial for hair loss',
        Benefits7:'Sleep aid and stress reducer', 
        Benefits8:'Lowers cholesterol',
        Benefits9:'Mood enhancer',
        Benefits10:'Protects stomach against ulcers',
        Benefits11:'Supports immune system',
        Usage:'Usage',
        Usage1:'Can be used for aromatherapy mixed with any other carrier oil (12 drops of lemongrass oil) . Add few drops of Lemon grass oil into your shampoo or conditioner to treat dandruff and hair loss.',
        Usage2:'Can be mixed into a warm bath (mixed with carrier oil)',
        Usage3:'Can be massaged on the skin (mixed with a carrier oil)',
        Usage4:'Can be used to take steam (add few drops to water)',
        Usage5:'Can be applied on the skin (mixed with any carrier oil)',
        Usage6:'Can be used for dandruff by mixing with shampoo and conditioner (few drops) and massage on the scalp',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 27,
        title: "Khas (Vetiver) Oil",
        price: 1800.0,
        img: require("./assets/Essential Oil/khasoiloutofstock.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Vetiver is sometimes applied directly to the skin for relieving stress, as well as for emotional traumas and shock, lice, and repelling insects. It is also used for arthritis, stings, and burns. Vetiver is sometimes inhaled as aromatherapy for nervousness, insomnia, and joint and muscle pain.',
        Availability:'Availability',
        Availability1:'Out of stock',
        SKU:'SKU',
        SKU1:'GR027OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti inflammation, arthritis cure, brain health, chemotherapy aid, essential oil, joint pain relief, khas, libido booster, sedative, stress reliever, vetiver.',
        Benefits:'Benefits',
        Benefits1:'Helps maintain and heal nerve health.',
        Benefits2:'Improves sexual desire',
        Benefits3:'Acts as a bug repellent',
        Benefits4:'Helps against the effects of chemotherapy',
        Benefits5:'Reduces skin inflammation',
        Benefits6:'Helps fade scar tissues & heal wounds',
        Benefits7:'Helps improve concentration & relieve stress',
        Benefits8:'Works as a sedative',
        Benefits9:'Can be used for joint and muscle pains.',
        Benefits10:'Beneficial for arthritis pains, stings and burns',
        Usage:'Usage',
        Usage1:'It can be inhaled (aromatherapy)to get relief from muscle pain, joint pain, insomnia and nervousness',
        Usage2:'It can be applied directly on the skin for relieving stress, as well as emotional traumas and shocks.',
        Usage3:'It can be applied directly on the skin as an insect repellent, or for lice.',
        Usage4:'Using vetiver oil in a diffuser while you sleep could help improve your breathing patterns',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    
    {
        id: 28,
        title: "Juniper Oil",
        price: 3600.0,
        img: require("./assets/Essential Oil/Juniper-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Juniper Oil.',
        SKU:'SKU',
        SKU1:'GR025OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:' anti depression, anti septic, blood circulation, detox, essential oil, fatigue reliever, healing, juniper, muscle relaxant, toothache remedy.',
        Benefits:'Benefits',
        Benefits1:'Promotes and improves blood circulation',
        Benefits2:'Helps remove gas from intestines',
        Benefits3:'Relaxes muscle cramps',
        Benefits4:'Helps cure toothaches',
        Benefits5:'Stops hair loss',
        Benefits6:'Helps overcome depression and fatigue',
        Benefits7:'Increases volume and frequency of urination',
        Benefits8:'Protects wounds from being septic',
        Benefits9:'Helps remove toxins from the body',
        Usage:'Usage',
        Usage1:'To use juniper essential oil for digestive purposes, you can add a drop or two to water or a smoothie but make sure that you are using a high grade oil before you start doing so',
        Usage2:'Juniper essential oil is used extensively for its ability to fight skin irritations, infection and rash.',
        Usage3:'Juniper oil can be used internally as a powerful cleansing and detoxifying agent and to support urinary tract and healthy kidney function.',
        Usage4:'Juniper Bath Blend: Add 2 drops juniper oil, 1 drop of rosemary and 1 drop of lavender to 1 tablespoon of fractionated coconut oil. Pour 1/4 cup of Epsom salts into mixture. Add blend to a warm bath to soothe your body.',
        Usage5:'Juniper essential oils blends well with citrus oils and Cypress, Cedarwood, Sandalwood, Lavender, Melaleuca (Tea Tree), Lemongrass, Clary Sage, and Vetiver essential oils for diffusion.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

   },


    {
        id: 29,
        title: "Jaiphal (Nutmeg) Oil",
        price: 600.0,
        img: require("./assets/Essential Oil/jaiphal-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Jaiphal (Nutmeg) Oil.',
        SKU:'SKU',
        SKU1:'GR022OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti inflammation, arthritis relief, bad breath, essential oil, gallbladder stone relief, healthy heart, healthy kidney, jaiphal, nutmeg, ulcer prevention.',
        Benefits:'Benefits',
        Benefits1:'Beneficial for arthritis',
        Benefits2:'Reduces inflammation',
        Benefits3:'Beneficial for cardiovascular diseases',
        Benefits4:'Prevents ulcers',
        Benefits5:'Beneficial for digestion problems',
        Benefits6:'Beneficial for kidney issues',
        Benefits7:'Beneficial for gallbladder stones',
        Benefits8:'Reduces fatigue',
        Benefits9:'Removes bad breath',
        Usage:'Usage',
        Usage1:'The nutmeg essential oil is used as a natural food flavouring in baked goods, syrups, beverages (e.g. Coca-Cola), sweets etc',
        Usage2:'Adding some nutmeg to warm milk can even help induce relaxation',
        Usage3:'Jaiphal oil can also be with current skin products for healthy, glowing skin.',
        Usage4:'Nutmeg oil helps prevent fine lines and wrinkles and can be used as a mask',
        Usage5:'It can also be added to your favorite shampoo and conditioner for shiny, strong, and healthy hair.',
        Usage6:'Not only can nutmeg oil help hair become stronger and shinier, it can also prevent hair fall, encourage hair growth, and even add extra volume. All you have to do is massage a couple of drops of nutmeg oil into your scalp.',
        Usage7:'It complements vegetables like cabbage, spinach, broccoli, beans onions and eggplant.',
        Usage8:'In soups it works with tomatoes, split peas and black beans',
        Usage9:'Can be used in daily routine food intake',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


  ];
  