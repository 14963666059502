export default [
    {
      id: 1,
      title: "Green Roots",
      price: 700.0,
      img: require("./assets/GR-logo.png"),
      quantity: 1,
    },
    {
        id: 2,
        title: "Green Roots",
        price: 700.0,
        img: require("./assets/GR-logo.png"),
        quantity: 1,
      },
      {
        id: 3,
        title: "Green Roots",
        price: 700.0,
        img: require("./assets/GR-logo.png"),
        quantity: 1,
      },
      {
        id: 4,
        title: "Green Roots",
        price: 700.0,
        img: require("./assets/GR-logo.png"),
        quantity: 1,
      },
      {
        id: 5,
        title: "Green Roots",
        price: 700.0,
        img: require("./assets/GR-logo.png"),
        quantity: 1,
      },
]