export default [
  {
      id: 1,
      title: "Aloe Vera Oil",
      price: 1000.0,
      img: require("./assets/Essential Oil/aloe-vera-oil-greenrootspk.com_.png"),
      quantity: 1,
      ml:'30 ML',
      dis:'Green Roots Aloe Vera Oil helps in skin rejuvenating and repairs damaged skin cells. It is also beneficial for hair cells and healthy hair growth.',
      SKU:'SKU',
      SKU1:'GR002OL', 
      Category:'Essential Oils',
      Tags:'Tags:',
      Tags1:'aloe vera oil, anti ageing, anti dandruff, essential oil, hair growth, hair nourishment, skin care, skin cell, repair skin lightening skin nourishment',
      Benefits:'Benefits',
      Benefits1:'Helps repairing skin cells',
      Benefits2:'Has great anti-ageing properties.',
      Benefits3:'Beneficial in removing stretch marks and wrinkles',
      Benefits4:'Heals dark spot on the skin and helps in dry skin issues.',
      Benefits5:'Makes hair healthy, strong and dandruff free.',
      Usage:'Usage',
      Usage1:'By mixing Aloe Vera oil with any carrier oil, for example, with coconut or Jojoba oil and applying on skin will help treat and heal damaged skin, lightens skin tone, and help in removing fine lines.',
      Usage2:'By applying the mixture onto your hair scalp and all over the hair helps repair damaged hair.',
      Usage3:'It also prevents hair loss and dandruff and helps in healthy hair growth.',
      Weight:'Weight',
      Weight1:'80 g',
      dicQuantity:'Quantity',
      dicQuantity1:'30 ml',

    },

   
    {
        id: 2,
        title: "Black Pepper Oil",
        price: 6000.0,
        img: require("./assets/Essential Oil/black-pepper-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Black Pepper Oil.',
        SKU:'SKU',
        SKU1:'GR007OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti anxiety, black pepper oil, cancer beneficent, constipation cure, essential oil, healthy digestion, healthy metabolism, joint pain relief, muscle relaxant, nutrition absorption, weight loss.',
        Benefits:'Benefits',
        Benefits1:'Increases nutrition absorption',
        Benefits2:'Improves digestion',
        Benefits3:'Aids in weight loss',
        Benefits4:'Promotes sweating and urination',
        Benefits5:'Relieves joint & muscle pains',
        Benefits6:'Calms anxiety',
        Benefits7:'Boosts metabolism',
        Benefits8:'Prevents new fat cells from forming',
        Benefits9:'Acts as an antidepressant',
        Benefits10:'Promotes dental health',
        Benefits11:'Prevents cancer',
        Benefits12:'Relieves gas & constipation',
        UsesofBlackPepperoil:'UsesofBlackPepperoil',
        UsesofBlackPepperoil1:'Do not apply to skin undiluted',
        UsesofBlackPepperoil2:'Can be used for cooking',
        UsesofBlackPepperoil3:'Can be mixed with carrier oils for multiple skin benefits',
        UsesofBlackPepperoil4:'Can be used for massages mixed with other oils',
        UsesofBlackPepperoil5:'Can be used on chest and nose for flu and chest congestion mixed with any carrier oil',
        UsesofBlackPepperoil6:'Can be used as a diffuser and can be inhaled as well',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


//     {
//         id: 3,
//         title: "Carrot Seed Oil",
//         price: 5000.0,
//         img: require("./assets/Essential Oil/carrotseedoiloustofstock.png"),
//         quantity: 1,
//         ml:'30 ML',
//         dis:'Carrot seed essential oil has shown antibacterial, antifungal, anti-inflammatory, and antioxidant properties. What it doesn’t have are the vitamins and nutrients provided by carrots. Like other essential oils, carrot seed oil isn’t meant to be ingested.',
//         Availability:'Availability',
//         Availability1:' : Out of stock',
//         SKU:'SKU',
//         SKU1:'GR008OL', 
//         Category:'Essential Oils',
//         Tags:'Tags:',
//         Tags1:'antioxidant aromatherapy cancer beneficent carrot seed oil detox essential oil hair nourishment hair strengthening menstrual cycles exual performance stress reliever worms cure',
//         Benefits:'Benefits',
//         Benefits1:'Contains antioxidants & anti ageing properties',
//         Benefits2:'Fights infection',
//         Benefits3:'Detoxifies blood',
//         Benefits4:'Prevent acidity',
//         Benefits5:'Kills worms',
//         Benefits6:'Can be used for aromatherapy',
//         Benefits7:'Controls menstrual cycle',
//         Benefits8:'Strengthens hair & makes them shiny',
//         Benefits9:'Firms and tones skin',
//         Benefits10:'Helps cure cancer of prostate, mouth, stomach and kidneys.',
//         Benefits11:'Cures infections and prevents tetanus',
//         Benefits12:'Provides relief from stress',
//         Benefits13:'Protects from macular degeneration and sexual weaknesses',
//         Benefits14:'Expels gas from intestines',
//         Usage:'Usage',
//         Usage1:'When used for aromatherapy, you can sprinkle a few drops onto a cloth or tissue and inhale deeply, or use a commercial aromatherapy diffuser or vaporizer. Practitioners of aromatherapy often combine carrot seed oil with other therapeutic oils, such as cedar, cinnamon, grapefruit, or geranium essential oil.',
//         Usage2:'Like all essential oils, carrot seed oil must be mixed with a carrier oil before applying topically. For this reason, it cannot be used as a tanning oil. Tanning oils, including those with SPFs, attract the sun’s UVA rays to your skin. Some people use them to try to tan safely, but there is no way to get a safe tan.',
//         Usage3:'It can be added to a cream or used in a mixture with almond oil: it tones the skin and even removes wrinkles. It is usually enough to add 3-5 drops of essential oils of carrots to the basis of 10 g oil or cream. In winter and early spring, it helps to compensate for the deficiency of vitamins in the skin',
//         Usage4:'When used topically, add no more than 12 drops of carrot seed oil to 1 fluid ounce (30 milliliters) of a carrier oil, lotion, vegetable butter, or moisturizing cream. Carrot seed oil must never be used near the eyes, in the nose or ears, on the genitals, or on any other sensitive areas of skin.',
//         Weight:'Weight',
//         Weight1:'80 g',

//    },


    {
        id: 4,
        title: "Cinnamon Oil",
        price: 1600.0,
        img: require("./assets/Essential Oil/cinnamon-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Cinnamon Oil.',
        SKU:'SKU',
        SKU1:'GR010OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti bacterial, anti depressionar, thritis cure, blood purifier, cinnamon oil, diabetes control, essential oil, healthy heart, kidney protection, menstruation, cycle, pain relief.',
        Benefits:'Benefits',
        Benefits1:'Provides pain relief',
        Benefits2:'Helps with arthritis',
        Benefits3:'Helps with diabetes',
        Benefits4:'Improves digestive health',
        Benefits5:'Helps with irregular menstruation',
        Benefits6:'Boosts the immune system',
        Benefits7:'Prevents urinary infections',
        Benefits8:'Protects the kidneys',
        Benefits9:'Helps fight depression',
        Benefits10:'Cleans the air from bacteria',
        Benefits11:'Removes bad breath',
        Benefits12:'Purifies blood',
        Benefits13:'Works as a brain tonic',
        Benefits14:'Helps fight skin infections',
        Benefits15:'Helps in respiratory problems',
        Benefits16:'Beneficial for heart disorders',
        Usage:'Usage',
        Usage1:'In aromatherapy, essential oils are diffused or spritzed into the air so they can be breathed in or absorbed into the skin. Aromatherapy has been linked to many benefits, including reduced depression and anxiety, and better sleep.',
        Usage2:'Mask made of cinnamon, olive oil, and honey may be effective at promoting the growth and thickening of hair.',
        Usage3:'Cinnamon oil is used as an ingredient in toiletries, such as toothpaste, mouthwash, soap, and body lotion. It’s also used to scent room sprays, candles, and seasonal items like pine cones and garlands.',
        Usage4:'A general recommendation is to keep the concentration of cinnamon oil low if it’s going to come in contact with skin. For example, one guideline of .01 percent concentration or less is equivalent to 1 drop of cinnamon oil for 30 to 40 milliliters (mL) of carrier liquid.',
        Usage5:'To scent your home, try adding one drop of cinnamon oil to fabric sachets or dried flowers. Or you can add a couple drops to a diffuser with water',
        Usage6:'Cinnamon oil can be mixed with a carrier oil and used for massage. Mix one drop of cinnamon oil with two to three cups of a carrier oil, such as almond oil, and use for massage or to moisturize skin.',
        Usage7:'It’s very important to dilute cinnamon oil with a carrier oil, prior to use. Try using one drop of cinnamon essential oil to two or more cups of a carrier oil, such as olive, almond, jojoba, or sesame oil.',
        Usage8:'Never rub or massage cinnamon oil directly on skin unless it’s diluted with a carrier oil.',
        Usage9:'Not to apply directly on skin',
        Weight:'Weight',
        Weight1:'85 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },
    

    {
        id: 5,
        title: "Clove Oil",
        price: 1400.0,
        img: require("./assets/Essential Oil/clove-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Clove Oil.',
        SKU:'SKU',
        SKU1:'GR011OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'allergy cure, anti ageing, blood circulation, blood purifier, blood sugar control, clove oil, essential oil, healing, healthy digestion, immune booster, nausea cure, pain reliever.',
        Benefits:'Benefits',
        Benefits1:'Relieves indigestion and nausea',
        Benefits2:'Makes skin look younger',
        Benefits3:'Works for ear aches',
        Benefits4:'Boosts immune system',
        Benefits5:'Treats colds, coughs, sty and cholera',
        Benefits6:'Heals wounds, cuts and injuries',
        Benefits7:'Relieves toothaches and removes bad breath ',
        Benefits8:'Controls sugar levels in diabetes',
        Benefits9:'Relieves stress, headaches and pains',
        Benefits10:'Purifies blood',
        Benefits11:'Stimulates blood circulation',
        Usage:'Usage',
        Usage1:'To use it for a toothache, dip a clean tissue, cotton swab, or cotton ball into the oil and wipe it over the gums at the point of the pain',
        Usage2:'Can be applied to the skin mixed with carrier oils or creams and products',
        Usage3:'Using the oil as a spray is an easy way to add the spicy scent of clove to a room. Mixed with water',
        Usage4:'Cannot be used for drinking purpose',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    
    {
        id: 6,
        title: "Eucalyptus Oil",
        price: 1500.0,
        img: require("./assets/Essential Oil/euclyptus-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots eucalyptus oil is useful in a lot of ways, among many usages it heals wounds, helps reduce mental exhaustion, relieves muscle pain and treats fever.',
        SKU:'SKU',
        SKU1:'GR015OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti lice, diabetes control, essential oil, eucalyptus oil, healing, healthy teeth, intestinal germs, menstrual energy, muscle relaxant, pain reliever, respiratory issues cure.',
        Benefits:'Benefits',
        Benefits1:'Heals wounds',
        Benefits2:'Removes mental exhaustion',
        Benefits3:'Good for dental care',
        Benefits4:'Removes intestinal germs',
        Benefits5:'Treats fever',
        Benefits6:'Treats respiratory problems', 
        Benefits7:'Relieves muscle pains',
        Benefits8:'Removes lice',
        Benefits9:'Manages diabetes',
        Usage:'Usage',
        Usage1:'Can be used to take steam (few drops)',
        Usage2:'Can be used in a warm bath/shower',
        Usage3:'Can be inhaled',
        Usage4:'Can be used as a painkiller massage oil',
        Usage5:'Can be used on skin mixed with any other carrier oil (12 drops for one ounce of carrier oil)',
        Usage6:'Can be used for hair mixed with any carrier oil.',
        Weight:'Weight',
        Weight1:'75 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 7,
        title: "Wheat Germ Oil",
        price: 2400.0,
        img: require("./assets/Essential Oil/wheat-germ-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'(Vitamin E Oil) Green Roots Wheatgerm Oil, also known worldwide as vitamin E oil, richest source of Vitamin E, with multiple health benefits, Energy boosting qualities as well as helps slowing the process of ageing',
        SKU:'SKU',
        SKU1:'GR062OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti ageing, anti hair fall, birth defect prevention, blood sugar control, cholesterol control, eczema relief, energizing, essential oil, healthy brain, vitamin e oil, wheat germ oil.',
        Benefits:'Benefits',
        Benefits1:'Lowers LDL cholesterol.',
        Benefits2:'Boosts energy capacity',
        Benefits3:'Slows down ageing',
        Benefits4:'Prevents hair fall',
        Benefits5:'Reduces stress levels',
        Benefits6:'Works as a remedy for eczema',
        Benefits7:'Regulates Nervous System',
        Benefits8:'Promotes Blood Sugar Level',
        Benefits9:'Fights Fat Accumulation',
        Benefits10:'Prevents Birth Defects',
        Usage:'Usage',
        Usage1:'Massaging the oil on the body alleviates pain, pressure, hence greatly uplifting memory and positively influencing mood.',
        Usage2:'Applying wheat germ oil on hair is a magical solution to attain smooth, soft, silky tresses.',
        Usage3:'Just a few drops of this miraculous tincture is enough, to decrease free radical damage to skin cells, thus diminishing signs of ageing like wrinkles, fine lines and giving a youthful, unblemished appearance.',
        Usage4:'Add wheat germ oil to your diet to get all the benefits mentioned above. You can also add it in the following ways:',
        Usage5:'By baking in cookies, breads, waffles, etc.',
        Usage6:'By sprinkling it over ice creams, soups, yogurt, salads, or cereals.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',
       
    },

    
    {
        id: 8,
        title: "Tea Tree Oil",
        price: 1900.0,
        img: require("./assets/Essential Oil/tea-tree-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Tea Tree essential Oil is used typically for infections such as acne, fungal infections of nails, lice, scabies, and athletes foots.',
        SKU:'SKU',
        SKU1:'GR059OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti bacterial, essential oil, hair nourishment, healing, healthy scalp, inflammation remedy, ringworm cure, skin care, sore relief, tea tree oil, yeast infection cure.',
        Benefits:'Benefits',
        Benefits1:'Acts as a healing ointment',
        Benefits2:'Soothes inflamed skin',
        Benefits3:'Effective against acne',
        Benefits4:'Helps relieve sore',
        Benefits5:'Beneficial for ringworm and athlete’s feet',
        Benefits6:'Improves hair and scalp health',
        Benefits7:'Kills bacteria',
        Benefits8:'Removes yeast infections',
        Usage:'Usage',
        Usage1:'Can be used on wounds directly (few drops)',
        Usage2:'Can be added to a cup of water (2-3 drops) and used as a mouthwash',
        Usage3:'Can be applied to the skin mixed with any carrier oil',
        Usage4:'Can be applied for dandruff (5% of tea tree oil mixed with shampoo per wash)',
        Usage5:'For healthy hair, mix with any carrier oil (ratio 1-10) and apply.',
        Ingredients:'Ingredients',
        Ingredients1:'Pure Tea Tree Extract',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 9,
        title: "Sandalwood Oil",
        price: 9500.0,
        img: require("./assets/Essential Oil/sandalwood-oil-1-greenrootspk.com_.png"),
        quantity: 1,
        ml:'15 ML',
        dis:'Sandalwood oil is believed to be beneficial in treating both physical and mental disorders, including anxiety, bronchitis, diarrhea, fatigue, fever, gallbladder problems, high blood pressure, indigestion, insomnia, liver problems, low libido, sore throat, and urinary tract infections.',
        SKU:'SKU',
        SKU1:'GR054OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'alertness, anti anxiety, anti bacterial, cold cure, digestion issues cure, essential oil, gallbladder treatment, hair growth, healing, hemorrhoids cure, liver treatment, muscle issues cure, sandalwood oil, scabies cure, scalp nourishment, scar remover, skin cancer protection, skin nourishment, UTI beneficent.',
        Benefits:'Benefits',
        Benefits1:'Increase alertness.',
        Benefits2:'Manages anxiety.',
        Benefits3:'Supports wound healing.',
        Benefits4:'Guards against skin cancer.',
        Benefits5:'Fights bacteria.',
        Benefits6:'Beneficial for common cold',
        Benefits7:'Beneficial for urinary tract infections',
        Benefits8:'Treats liver and gallbladder problems',
        Benefits9:'Treats digestive problems',
        Benefits10:'Beneficial for muscle problems',
        Benefits11:'Works for mental disorders', 
        Benefits12:'Helps treat hemorrhoids',
        Benefits13:'Helps treat scabies',
        Benefits14:'Stimulates the roots, improves hair growth, and increases circulation in the scalp.',
        Benefits15:'Softens the skin',
        Benefits16:'Sandalwood reduces the appearance of bruises and scars',
        Usage:'Usage',
        Usage1:'Apply it directly to your skin',
        Usage2:'Put a few drops in your lotion',
        Usage3:'Heat it in a small kettle of water to scent your home',     
        Usage4:'Evaporate it using an essential oil diffuser',
        Usage5:'Add it to your bath water',
        Usage6:'Apply sandalwood essential oil on your face and massage gently. Leave it on overnight and wash with lukewarm water the next morning. (For skin softening)',
        Ingredients:'Ingredients',
        Ingredients1:'Pure Sandalwood Extract',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'15 ml',

    },


    {
        id: 10,
        title: "Sandalwood Oil",
        price: 18000.0,
        img: require("./assets/Essential Oil/sandalwood-oil-1-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Sandalwood oil is believed to be beneficial in treating both physical and mental disorders, including anxiety, bronchitis, diarrhea, fatigue, fever, gallbladder problems, high blood pressure, indigestion, insomnia, liver problems, low libido, sore throat, and urinary tract infections.',
        SKU:'SKU',
        SKU1:'GR054OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'alertness, anti anxiety, anti bacterial, cold cure, digestion issues cure, essential oil, gallbladder treatment, hair growth, healing, hemorrhoids cure, liver treatment, muscle issues cure, sandalwood oil, scabies cure, scalp nourishment, scar remover, skin cancer protection, skin nourishment, UTI beneficent.',
        Benefits:'Benefits',
        Benefits1:'Increase alertness.',
        Benefits2:'Manages anxiety.',
        Benefits3:'Supports wound healing.',
        Benefits4:'Guards against skin cancer.',
        Benefits5:'Fights bacteria.',
        Benefits6:'Beneficial for common cold',
        Benefits7:'Beneficial for urinary tract infections',
        Benefits8:'Treats liver and gallbladder problems',
        Benefits9:'Treats digestive problems',
        Benefits10:'Beneficial for muscle problems',
        Benefits11:'Works for mental disorders', 
        Benefits12:'Helps treat hemorrhoids',
        Benefits13:'Helps treat scabies',
        Benefits14:'Stimulates the roots, improves hair growth, and increases circulation in the scalp.',
        Benefits15:'Softens the skin',
        Benefits16:'Sandalwood reduces the appearance of bruises and scars',
        Usage:'Usage',
        Usage1:'Apply it directly to your skin',
        Usage2:'Put a few drops in your lotion',
        Usage3:'Heat it in a small kettle of water to scent your home',     
        Usage4:'Evaporate it using an essential oil diffuser',
        Usage5:'Add it to your bath water',
        Usage6:'Apply sandalwood essential oil on your face and massage gently. Leave it on overnight and wash with lukewarm water the next morning. (For skin softening)',
        Ingredients:'Ingredients',
        Ingredients1:'Pure Sandalwood Extract',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 11,
        title: "Rose Oil",
        price: 4500.0,
        img: require("./assets/Essential Oil/rose-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Pure Rose Essential Oil may be used effectively to: reduce anxiety, stress, depression, and pain. increase libido. protect against harmful bacteria and fungi. When applied directly to the skin, skin care products containing rose essential oil are purported to hydrate dry skin, clear acne, reduce signs of aging, minimize the appearance of scars, and help with conditions such as eczema and rosacea. It also has multiple health benefits',
        SKU:'SKU',
        SKU1:'GR053OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti ageing, anti anxiety, anti depression, anti inflammation, blood circulation, blood purifier, essential oil, healthy digestion, infection prevention, libido booster, liver protection, muscle relaxant, rose oil, sexual performance, skin nourishment, stomach protection ,stress reliever, uterus protection.',
        Benefits:'Benefits',
        Benefits1:'Helps fight depression',
        Benefits2:'Prevents infections',
        Benefits3:'Protects the uterus',
        Benefits4:'Stimulates circulation',
        Benefits5:'Fights inflammation',  
        Benefits6:'Good for digestion',
        Benefits7:'Strengthens gums',
        Benefits8:'Improves skin texture and health.',
        Benefits9:'Keeps the skin youthful',
        Benefits10:'Acts as a nerve tonic to bear shock',
        Benefits11:'Protects liver and stomach from infections',
        Benefits12:'Purifies blood',
        Benefits13:'Helps stimulate menstruation and boosts libido',
        Benefits14:'Cures cramps, muscle pulls',
        Benefits15:'Decreases anxiety and stress',
        Usage:'Usage',
        Usage1:'To make a rose oil bath, add 10 drops of rose essential oil to a carrier oil, then add to a warm tub. Essential oils should always be diluted in a carrier oil before adding to water.',
        Usage2:'To make a foot bath, add a few drops of diluted rose oil into the foot bath and soak your feet for 10 minutes.',
        Usage3:'To treat anxiety, stress, or depression, you can either inhale rose oil using a diffuser or dab it lightly on your chest, neck, and wrists. Be sure to blend it with a carrier oil first like coconut, almond, or jojoba oil before it touches the skin. You may also benefit from a rose oil massage, in which the rose essential oil is added to a carrier massage oil.',
        Usage4:'Can be applied to the skin and hair mixed with any carrier oil for your choice.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    
    {
        id: 12,
        title: "Rosemary Oil",
        price: 1200.0,
        img: require("./assets/Essential Oil/rosemarry-oil-greenrootspk.com.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Rosemary Oil is used as a hair oil for shiny hair and aromatic usage as well. Also has an anti-ageing benefits for skin.',
        SKU:'SKU',
        SKU1:'GR052OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti ageing, anti allergy, anti anxiety, anti marks, essential oil, hair growth, rosemary oil, skin lightening.',
        Benefits:'Benefits',
        Benefits1:'Good for hair health.',
        Benefits2:'Reduces the appearance of stretch marks.',
        Benefits3:'Reduces Anxiety.',
        Benefits4:'Treats allergy.',
        Benefits5:'Used as an anti-ageing agent.',
        Usage:'Usage',
        Usage1:'Can be applied directly on skin.',
        Usage2:'Can be inhaled for stress relief.',    
        Usage3:'Apply on hair scalp directly (5 drops).',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

   
    {
        id: 13,
        title: "Rosehip Oil",
        price: 1200.0,
        img: require("./assets/Essential Oil/rosehip-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Rosehip oil is rich in essential fatty acids and antioxidants, which are integral for tissue and cell regeneration in the skin. It is also beneficial for knee problems, helps treat diabetes, swelling, dandruff, and acne.',
        SKU:'SKU',
        SKU1:'GR051OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti ageing, anti dandruff, anti inflammation, beard oil, collagen boost, dark lips remedy, diabetes cure, essential oil, even skin tone, healing, knee pain, rosehip oil, skin brightening, skin healing, skin nourishment, skin protection.',
        Benefits:'Benefits',
        Benefits1:'Treats knee problems, diabetes & swelling',
        Benefits2:'Lightens dark lips',
        Benefits3:'Softens beard hair',
        Benefits4:'Treats dandruff',
        Benefits5:'Repairs and protects skin',
        Benefits6:'Tones skin',
        Benefits7:'Hydrates skin',
        Benefits8:'Fights acne',
        Benefits9:'Reduces signs of ageing',
        Benefits10:'Good for sun tanning and pigmentation skin',
        Benefits11:'Reduces wrinkles',
        Benefits12:'Helps in skin regeneration',
        Benefits13:'Reduces inflammation and has healing properties',
        Benefits14:'Helps brighten skin',
        Benefits15:'Boost collagen formation',
        Usage:'Usage',
        Usage1:'Apply two to three drops, warm in the palm of your hands and apply to your face.',
        Usage2:'Or add just one or two drops of it to your normal moisturizer.',
        Usage3:'For best results use Rosehip Oil morning and night. Allow 5 minutes before applying a moisturizer or sunscreen over it.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    
    {
        id: 14,
        title: "Fennel Oil",
        price: 2500.0,
        img: require("./assets/Essential Oil/fennel-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Fennel Oil works as an antiseptic agent, stimulates brain and neural activities, works wonders for the skin, helps heal hair problems and benefits in hair growth, as well as is beneficial for multiple health issues.',
        SKU:'SKU',
        SKU1:'GR016OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti ageing, anti dandruff, antiseptic, antispasmodic, brain health, chronic, cough cure, facial cleanser, hair growth, hair nourishment, intestinal diseases cure, menstrual relief, nerve health, scalp conditioner, skin care, skin nourishment.',
        Benefits:'Benefits',
        Benefits1:'Excellent antiseptic and antispasmodic agent',
        Benefits2:'Provides relief from menstrual problems',
        Benefits3:'Stimulates brain and neural activity',
        Benefits4:'Improves amount of milk in lactating mothers',
        Benefits5:'Effective in treating chronic cough',
        Benefits6:'Relieves gas and indigestion & kills intestinal worms',
        Benefits7:'Increases frequency in urination',
        Benefits8:'Controls sugar cravings & adds flavor to food',
        Benefits9:'Boosts productivity',
        Benefits10:'Supports healthy metabolism',
        Benefits11:'Maintains cholesterol and lowers high blood pressure',
        Benefits12:'Protects against harmful radiations during cancer treatment',
        Benefits13:'Delays wrinkles & acts as a facial toner & as a deep cleanser',
        Benefits14:'Fights acne, cleanses the scalp, moisturizes the hair & stimulates hair growth',
        Benefits15:'Helps in dandruff and scalp itchiness, stop hair fall & makes the skin flawless and glowing',
        Usage:'Usage',
        Usage1:'Can be used for healing wounds can be applied on the affected areas. Gives soothing relief.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 15,
        title: "Loban Oil",
        price: 2400.0,
        img: require("./assets/Essential Oil/loban-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'(Frankincense Oil) Frankincense has earned its title as “The King of Oils” because of its versatility. When used topically, frankincense touts powerful anti-aging properties. It promotes cellular function and the appearance of healthy-looking skin, evening out skin tone and minimizing blemishes. It also helps reduce arthritis, improves gut functions, improves asthma, boosts the immune system, fights certain types of cancers, reduces respiratory problems and many more health benefits.',
        SKU:'SKU',
        SKU1:'GR018OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'arthritis remedy, asthma remedy, astringent, cancer beneficent, frankincense oil, gut health, healthy digestion, immune booster, loban oil, menstrual cycle, natural oil, oral health, pure oil, respiratory issues cure.',
        Benefits:'Benefits',
        Benefits1:'May Reduce Arthritis',
        Benefits2:'May Improve Gut Function',
        Benefits3:'Improves Asthma',
        Benefits4:'Maintains Oral Health',
        Benefits5:'May Fight Certain Cancers',
        Benefits6:'Boosts immune system',
        Benefits7:'Astringent properties',
        Benefits8:'Good for the digestive system',
        Benefits9:'Reduces respiratory problems',
        Benefits10:'Regulates menstruation',
        Benefits11:'Stimulates urination',
        Benefits12:'Helps relieve stress',
        Benefits13:'Heals skin',
        Usage:'Usage',
        Usage1:'Frankincense is a mild oil and most people can apply it directly to skin, or neat, without any issues. However, if you experience any burning, tingling, or irritation, dilute the oil by rubbing a carrier oil, such as fractionated coconut oil, jojoba oil, or grapeseed oil, over the irritated area.',
        Usage2:'Fill a dark glass squirt bottle with pure water. Add 6 drops of frankincense oil per 1 ounce of water. Mix well before each use, or add 3 drops of vodka to prevent separation. Spray onto face & body whenever you want a refresher.',
        Usage3:'Frankincense is a wonderful sleep aid because of its aromatherapeutic benefits. It is said to help relieve stress and promote relaxation.',
        Usage4:'Place a few drops of oil into your hands, rub your palms together, and inhale before laying down to sleep.',
        Usage5:'Boost your skin’s radiance by massaging a drop of Frankincense into facial skin after cleansing, followed by applying your regular moisturizers.',
        Usage6:'Add a few drops to your face cream or body lotion and rub directly into skin.',
        Usage7:'Dilute with a carrier oil such as fractionated coconut oil or sweet almond oil before use. Massage into the stressed parts of your arms and feet to ease tension and bring clarity to your mind.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 16,
        title: "Lavender Oil",
        price: 1800.0,
        img: require("./assets/Essential Oil/lavendar-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Lavender Essential Oil.',
        SKU:'SKU',
        SKU1:'GR028OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'air purifier, antioxidant, aromatic oil, deep sleep, essential oil, healthy brain, immune booster, lavender oil, nerve health, peaceful sleep, relaxing oil, skin nourishment, stress reliever.',
        Benefits:'Benefits',
        Benefits1:'Relieves stress and promotes relaxation.',
        Benefits2:'Supports healthy brain functions',
        Benefits3:'Promotes deeper sleep.',
        Benefits4:'Freshens and purifies the air.',
        Benefits5:'Supports normal respiration',
        Benefits6:'Supports healthy nervous system',
        Benefits7:'Smoothens and revitalizes skin',
        Benefits8:'Reduces muscle soreness',
        Benefits9:'Supports the immune system',
        Benefits10:'Has a high antioxidant value.',
        Usage:'Usage',
        Usage1:'Added with another carrier oil, can be massaged on the skin.',
        Usage2:'Can be used in water (few drops) in the bath.',
        Usage3:'Can sprinkle on a cloth or tissue (few drops) and i hate it’s aroma',
        Usage4:'A few drops can be added to aromatherapy or diffuser.',
        Usage5:'Can be applied directly on the skin for wrinkles and dry skin',
        Usage6:'Can be used as steam for aromatherapy',      
        Usage7:'Can be applied to the wrist and forehead (few drops) for anxiety or muscle relaxation',
        Usage8:'Can be applied to hair mixed with any carrier oil',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 17,
        title: "Poppy Seed Oil",
        price: 550.0,
        img: require("./assets/Essential Oil/poppy-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'(Khus Khus Oil) Green Roots Poppy Seed Oil contains antioxidants, including naturally occurring vitamin E, which may lower the risk of conditions like cancer. It acts like a moisturizer for the skin and hair, regulates sleep, bone strength, blood pressure, thyroid levels. It’s helpful in treating diabetes, kidney stones, eczema, inflammation, headaches.',
        SKU:'SKU',
        SKU1:'GR046OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti dandfruff, anti hair fall, anti inflammation, bone health, cancer beneficent, eczema cure, essential oil, eyesight herbal remedy, female fertility, hair growth, hair nourishment, healthy digestion, healthy thyroid, khus khus oil, kidney stone cure, poppy seeds, calp conditioner, skin nourishment, sleep cycle, split ends.',
        Benefits:'Benefits',
        Benefits1:'Natural moisturizer for hair and skin.',
        Benefits2:'Stimulates hair growth',
        Benefits3:'Helps prevent hair loss, fight dandruff, dry scalp & split ends',
        Benefits4:'Treats eczema and inflammation',
        Benefits5:'Cures headache & regulates sleep',
        Benefits6:'Lowers the risks of cancers',
        Benefits7:'Boosts female fertility',
        Benefits8:'Improves bone strength, digestion & vision',
        Benefits9:'Regulates blood pressure',
        Benefits10:'Helps treat Diabetes',
        Benefits11:'Good for thyroid',
        Benefits12:'Helpful in treating kidney stones',
        Usage:'Usage',
        Usage1:'Can be used for cooking',
        Usage2:'Can be used on the skin as a moisturizer',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 18,
        title: "Pomegranate Oil",
        price: 1600.0,
        img: require("./assets/Essential Oil/pomgranate-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Pomegranate Oil.',
        SKU:'SKU',
        SKU1:'GR045OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti acne, anti inflammationan, tioxidant, arthritis relief, cancer beneficent, essential oil, hair nourishment, healthy heart, joint pain relief, pomegranate oil, skin nourishment.',
        Benefits:'Benefits',
        Benefits1:'Anti Inflammatory',
        Benefits2:'Beneficial for hair growth, skin & acne',
        Benefits3:'Makes a good hair tonic',
        Benefits4:'Antioxidant',
        Benefits5:'Lowers blood pressure',
        Benefits6:'Improves heart health',
        Benefits7:'Reduces pain in joints and arthritis',
        Benefits8:'Helps fight cancer',
        Usage:'Usage',
        Usage1:'ANTI-AGEING – Use the oil as a night treatment or as a night serum to slow down aging.',
        Usage2:'ACNE – To use this oil for acne, Use it to massage your face in the morning and also to use it as a night treatment.',
        Usage3:'To use pomegranate oil for dandruff, simply mix it in your shampoo and work it on your scalp and hair. You can also use it as a hot oil treatment; mix this oil with coconut oil 1:4, warm it and apply it on your scalp and hair. Wash off using regular shampoo after 2-3 hours.',
        Usage4:'Pomegranate oil is the unique edible oil produced from the seed of the pomegranate fruit',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 19,
        title: "Peanut Oil",
        price: 800.0,
        img: require("./assets/Essential Oil/peanut-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Peanut Oil has many benefits and several usages, it can be used for body massages, helps in improving blood flow, lowering blood pressure and helps prevent stomach problems . It improve heart health and maintain cholesterol level, reduces the risk of cancers and beneficial for arthritis.',
        SKU:'SKU',
        SKU1:'GR044OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'arthritis relief, blood pressure control, body massage, cancer beneficent, essential oil, hair nourishment, healthy heart, massage oil, peanut oil, skin nourishment, stomach protection.',
        Benefits:'Benefits',
        Benefits1:'Used for body massages',
        Benefits2:'Helps in improving blood flow, lowering blood pressure, prevent stomach problems & improve heart health',
        Benefits3:'Is used for skin care & hydrating lips',
        Benefits4:'Helps maintain cholesterol level, get rid of black moles and acne & improve hair health',
        Benefits5:'Reduces The Risk Of Cancers',
        Benefits6:'Beneficial for Arthritis',
        Usage:'Usage',
        Usage1:'Can be used as aromatherapy',
        Usage2:'Peanut oil has a high smoke point and neutral taste which makes it perfect for frying foods.',
        Usage3:'Peanut oil is used for frying, sautéing, and simply adding flavor',
        Usage4:'By including peanut oil in the diet in medium quantities, one can ensure that proper cholesterol levels are maintained and thus the heart health is restored',
        Usage5:'Peanut oil can help in massaging the body and thus provide it with energy. It helps in the treatment of aching joints and muscles. This should be used on a daily basis with a carrier oil to get maximum benefits.',
        Usage6:'Peanut oil for skin repair. Mixing it with a few drops of lemon juice and applying it helps in getting the outcomes.',
        Usage7:'Peanut oil with lemon juice and tea tree oil can be applied on the scalp and left for the next 2-3 hours before shampooing. This antiseptic way of treating dandruff is one of the easiest things to do and in a very effective manner.',
        Usage8:'Massaging the lips with peanut oil after a proper scrub procedure at home is one of the best ways to provide nourishment to the lips and to make it soft, smooth and supple. Being enriched with Vitamin E, peanut oil can lighten the lip color and restore its natural pinkness. You can even apply peanut oil as a lip balm to prevent chapping.',
        Usage9:'The inclusion of cold-pressed peanut oil in your daily diet can help in reducing the joint inflammation and alleviating pain. Vitamin E present in this oil strengthens the bones and aids in protecting them from joint disorders.',
        Usage10:'Peanut oil is enriched with Vitamin E, which can reduce and delay the signs of ageing like blemishes, dark spots, pigmentation, fine lines and patchy skin. To use peanut oil for skin, just take a few drops of cold-pressed oil and massage on skin regul',
        Usage11:'Peanut oil can bring a natural glow to your skin and hair when combined with other ingredients.',
        Usage12:'Peanut oil is wonderful for diabetic patients. It contains more unsaturated fats than saturated fats improve insulin sensitivity, which regulates blood sugar levels in the body. The way to use this oil is in wok stir-fries, low heat cooking and even as a drizzle on salads.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 20,
        title: "Peppermint Oil",
        price: 1600.0,
        img: require("./assets/Essential Oil/peppermint-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Peppermint Essential Oil treats UTIs, is good for respiration and improves blood circulation. It is also useful in improving oral hygiene, boosts immune system and improves digestion.',
        SKU:'SKU',
        SKU1:'GR043OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'blood circulation, essential oil, healthy breathing, healthy digestion, immune booster, oral hygiene, pain relief, peppermint, UTI cure.',
        Benefits:'Benefits',
        Benefits1:'Treats UTIs',
        Benefits2:'Relieves headaches',
        Benefits3:'Good for respiration',
        Benefits4:'Improves blood circulation',
        Benefits5:'Improves oral hygiene',
        Benefits6:'Reduces pains',
        Benefits7:'Boosts the immune system',
        Benefits8:'Improves digestion.',
        Usage:'Usage',
        Usage1:'Can be used as flavoring in foods and beverages',
        Usage2:'Can be used as a skin cream or ointment',
        Usage3:'Can be taken orally in dietary supplements',
        Usage4:'Can be directly applied to the skin',
        Usage5:'Can be massaged on the skin',
        Usage6:'Can be added to baths or mixed with other painkiller oils',
        Usage7:'Can be added to drinking water',
        Usage8:'Can be applied to hair mixed with any massage oil.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

   },

    
    {
        id: 21,
        title: "Orange Oil",
        price: 800.0,
        img: require("./assets/Essential Oil/orange-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Orange Oil is effective in treating spasms, helps cure depression and works as an energy tonic. It cures acne and dermatitis, helps remove toxins and relieves inflammation. Amongst many other benefits of orange oil, it boosts immunity, disinfects wounds and relaxes muscular and nervous spasms.',
        SKU:'SKU',
        SKU1:'GR040OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:' anti acne, anti anxiety, anti depression, anti dermatitis, anti inflammation, detox, energizing, essential oil, healing, immunity booster, infection prevention, libido booster, muscle spasm control, nerve relaxant, orange, sedative.',
        Benefits:'Benefits',
        Benefits1:'Treats spasms',
        Benefits2:'Helps cure depression',
        Benefits3:'Works as an energy tonic & as a sedative',
        Benefits4:'Prevents infections',
        Benefits5:'Increases libido',
        Benefits6:'Cures acne and dermatitis',
        Benefits7:'Helps remove toxins',
        Benefits8:'Relieves inflammation',
        Benefits9:'Boosts immunity',
        Benefits10:'Disinfects wounds',
        Benefits11:'Relaxes muscular and nervous spasms',
        Benefits12:'Alleviates anxiety',
        Usage:'Usage',
        Usage1:'When combined with a carrier oil, orange essential oils can be applied directly to the skin at a ratio of one to two drops of essential oil to one teaspoon of carrier oil.',
        Usage2:'Orange essential oils can also be safely inhaled by sprinkling a few drops onto a cloth or tissue, or using an aromatherapy diffuser or vaporizer.',
        Usage3:'Breathing in the scent of sweet orange essential oil may help alleviate anxiety',       
        Usage4:'Can be used on the skin for acne mixed with carrier oils',
        Usage5:'Dilute orange essential oil in a carrier oil, like coconut oil or jojoba oil. suggests using 20 drops of essential oil per ounce of carrier oil to make a massage oil with a 3 percent solution',
        Usage6:'Never inhale orange essential oil directly from the bottle. Doing so can cause nasal and throat irritation and even a mild burn if a droplet is accidentally inhaled. Like most essential oils, sweet orange essential oil is not intended for internal use.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    {
        id: 22,
        title: "Ginger Oil",
        price: 2200.0,
        img: require("./assets/Essential Oil/ginger-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Ginger Oil.',
        SKU:'SKU',
        SKU1:'GR019OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'arthritis relief, bowel syndrome cure, digestion issues cure, essential oil, ginger, hair growth, menstrual relief, muscle relaxant, respiratory issues cure, sinus cure, skin lightening.',
        Benefits:'Benefits',
        Benefits1:'Effective for arthritis',
        Benefits2:'Reduces menstrual cramps',
        Benefits3:'Cures digestive problems',
        Benefits4:'Eases frozen shoulders',
        Benefits5:'Relieves sinus congestion',
        Benefits6:'Recovers muscle fatigue',
        Benefits7:'Helps get rid of travel sickness',
        Benefits8:'Cures IBS (irritable bowel syndrome)',
        Benefits9:'Beneficial for colds, sore throats and respiratory relief & hair fall',
        Benefits10:'Improves hair growth',
        Benefits11:'Clears scars and spots',
        Usage:'Usage',
        Usage1:'Inhaling the aroma from ginger oil has been used as a way to relieve nausea.',
        Usage2:'Ginger oil or extract is sometimes included in shampoos or other hair products, as it’s believed to promote hair health and growth',
        Usage3:'Diffusers are a great way to add a pleasing scent to a room. In some cases, the essential oil may need to be diluted in water',
        Usage4:'Ginger oil in a spray can be used to freshen the air in a room.',
        Usage5:'Ginger oil can be used for steam inhalation (few drops in water)',  
        Usage6:'Ginger oil that’s being applied to the skin should always be diluted in carrier oil first. Some examples of carrier oils include almond oil, jojoba oil, coconut oil, and avocado oil.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    {
        id: 23,
        title: "Grape Seed Oil",
        price: 500.0,
        img: require("./assets/Essential Oil/grape-seed-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Grape Seed Oil.',
        SKU:'SKU',
        SKU1:'GR020OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti acne, anti dandruff, bone health, cancer prevention, cognitive function, diabetes remedy, essential oil, grape, hair growth, hair strengthening, oral health, osteoarthritis, seed, skin care.',
        Benefits:'Benefits',
        Benefits1:'Prevents cancer',
        Benefits2:'Controls blood pressure',
        Benefits3:'Improves bone health & cognitive functions',
        Benefits4:'Beneficial for skin care, oral health, osteoarthritis & diabetes',
        Benefits5:'Protects against cardiovascular diseases',
        Benefits6:'Protects the stomach',
        Benefits7:'Treats acne and pimples',
        Benefits8:'Minimizes eye circles',
        Benefits9:'Skin tightening agent',
        Benefits10:'Helps hair growth',
        Benefits11:'Combats dandruff',
        Benefits12:'Improves hair shine and strengthens hair',
        Usage:'Usage',
        Usage1:'Mixing a drop of grape seed oil in your favorite moisturizing face and body creams helps to seal the moisture into your skin.',
        Usage2:'If you’d prefer, you can heat grape seed oil slightly by putting some in your palms and rubbing your hands together. Apply a generous amount to your skin as a moisturizing mask.',
        Usage3:'Apply a small amount to the palm of your hand and rub together. Massage into your scalp gently. Grape seed oil promotes healthy hair growth and acts as a natural dandruff treatment. It can also be diluted with distilled water and placed in a spray bottle to control hair in humid temperatures.',
        Usage4:'To use grape seed oil on your face, massage several drops into clean skin before you go to bed at night. You can repeat the process in the morning, if desired. Since grape seed oil doesn’t clog pores, it’s ideal for all skin types, including oily skin that needs moisturizing.',
        Usage5:'Grape seed oil is a well-known carrier oil, which makes loose skin remarkably tight over time. It is rich in vitamin E and healthy fats, which hydrate the skin profusely and boost the production of collagen in it.',
        Usage6:'Grape seed oil reduces puffy eyes, dark circles and forming of eye-bags, and nourishes the skin from within. Moreover, it also helps in getting good sleep. How to use: Apply few drops of grape seed oil on the affected eye area and dark spots with a cotton pad.',
        Usage7:'Can be used for cooking and: 1) Stir Frying • 2. Deep Frying • 3. Sautéing • 4. Searing Meat • 5. Roasting Vegetables • 6. Salad Dressings • 7. Grilling.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    {
        id: 24,
        title: "Niaouli Oil",
        price: 2600.0,
        img: require("./assets/Essential Oil/niaouli-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Niaouli Oil.',
        SKU:'SKU',
        SKU1:'GR035OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti bacterial, anti marks, anti septicemia, arthritis cure, blood circulation, essential oil, gout cure, immune booster, intestinal worms cure, lungs congestion remedy, niaouli oil, pain relief, rheumatism cure.',
        Benefits:'Benefits',
        Benefits1:'Helps stimulate blood circulation',
        Benefits2:'Relieves rheumatism and arthritis and gout',
        Benefits3:'Protects wounds from bacteria and septicemia',
        Benefits4:'Removes acne marks and scars',
        Benefits5:'Clears congestion in lungs',
        Benefits6:'Helps lower body temperature in fever',
        Benefits7:'Eliminates intestinal worms',
        Benefits8:'Relieves headaches, toothaches, earaches, muscle pains and joint pains',
        Benefits9:'Cures skin infections',
        Benefits10:'Tones digestive system',
        Benefits11:'Stimulates growth and strengthens immune system',
        Usage:'Usage',
        Usage1:'Add one drop to your facial cleanser or toothpaste.',
        Usage2:'Add a few drops to a spray bottle with water and use to clean household surfaces.',
        Usage3:'Include as part of a soothing massage',
        Usage4:'Diffuse with Eucalyptus, Bergamot, or Lavender essential oils for a calming and pleasant aroma.',
        Usage5:'Essential oils can be applied to the crown of your head, behind the ears, to your neck, and to your temples. If the aroma of an oil is too strong, or your skin is sensitive, you can dilute it with a carrier oil.',
        Usage6:'Can be added to food.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

     },


    {
        id: 25,
        title: "Lemon Oil",
        price: 950.0,
        img: require("./assets/Essential Oil/lemon-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Lemon essential oil is used in the treatments of skin, hair and body. Also treats with health benefits.',
        SKU:'SKU',
        SKU1:'GR030OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'essential oil, hair nourishment, healthy breathing, healthy digestion, immune booster, lemon, nausea cure, oral health, skin health, stress reliever.',
        Benefits:'Benefits',
        Benefits1:'Reduces nausea & stress',
        Benefits2:'Improves concentration',
        Benefits3:'Keeps hair healthy',
        Benefits4:'Supports digestion',
        Benefits5:'Boosts oral health',
        Benefits6:'Teats skin conditions',
        Benefits7:'Improves respiratory functions',
        Benefits8:'Improves immune system functions',
        Benefits9:'Treats multiple skin conditions.',
        Usage:'Usage',
        Usage1:'Can be used for aromatherapy mixed with any carrier oil.',
        Usage2:'Reduces Nausea. Can be used as a diffuser.',
        Usage3:'Can be applied on skin with any non comedogenic oil for wrinkle free and spotless skin..',
        Usage4:'Can be mixed with any carrier oil and applied on hair for controlling hair fall.',
        Usage5:'Can be used as a diffuser',
        Usage6:'Can be used on the skin mixed with any non comedogenic oil.',
        Usage7:'Can be applied to hair mixed with any carrier oil.',
        Weight:'Weight',
        Weight1:'65 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 26,
        title: "Lemongrass Oil",
        price: 2200.0,
        img: require("./assets/Essential Oil/lemongrass-oil-greenrootspk.com_-600x480.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Lemongrass essential oil is an antifungal and antibacterial oil. Also helps in relieving headache, fever stress and hair fall.',
        SKU:'SKU',
        SKU1:'GR029OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti bacterial, anti fungal, anti hair loss, energizing, essential oil, immune booster, lemongrass, pain relief, peaceful sleep, sleep cycle, stress reliever, ulcer protection.',
        Benefits:'Benefits',
        Benefits1:'Antifungal and antibacterial',
        Benefits2:'Reduces fever',
        Benefits3:'Relieves headaches',
        Benefits4:'Boosts energy',
        Benefits5:'Reduces stomach aches',
        Benefits6:'Beneficial for hair loss',
        Benefits7:'Sleep aid and stress reducer', 
        Benefits8:'Lowers cholesterol',
        Benefits9:'Mood enhancer',
        Benefits10:'Protects stomach against ulcers',
        Benefits11:'Supports immune system',
        Usage:'Usage',
        Usage1:'Can be used for aromatherapy mixed with any other carrier oil (12 drops of lemongrass oil) . Add few drops of Lemon grass oil into your shampoo or conditioner to treat dandruff and hair loss.',
        Usage2:'Can be mixed into a warm bath (mixed with carrier oil)',
        Usage3:'Can be massaged on the skin (mixed with a carrier oil)',
        Usage4:'Can be used to take steam (add few drops to water)',
        Usage5:'Can be applied on the skin (mixed with any carrier oil)',
        Usage6:'Can be used for dandruff by mixing with shampoo and conditioner (few drops) and massage on the scalp',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },


    {
        id: 27,
        title: "Khas (Vetiver) Oil",
        price: 1800.0,
        img: require("./assets/Essential Oil/khasoiloutofstock.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Vetiver is sometimes applied directly to the skin for relieving stress, as well as for emotional traumas and shock, lice, and repelling insects. It is also used for arthritis, stings, and burns. Vetiver is sometimes inhaled as aromatherapy for nervousness, insomnia, and joint and muscle pain.',
        Availability:'Availability',
        Availability1:'Out of stock',
        SKU:'SKU',
        SKU1:'GR027OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti inflammation, arthritis cure, brain health, chemotherapy aid, essential oil, joint pain relief, khas, libido booster, sedative, stress reliever, vetiver.',
        Benefits:'Benefits',
        Benefits1:'Helps maintain and heal nerve health.',
        Benefits2:'Improves sexual desire',
        Benefits3:'Acts as a bug repellent',
        Benefits4:'Helps against the effects of chemotherapy',
        Benefits5:'Reduces skin inflammation',
        Benefits6:'Helps fade scar tissues & heal wounds',
        Benefits7:'Helps improve concentration & relieve stress',
        Benefits8:'Works as a sedative',
        Benefits9:'Can be used for joint and muscle pains.',
        Benefits10:'Beneficial for arthritis pains, stings and burns',
        Usage:'Usage',
        Usage1:'It can be inhaled (aromatherapy)to get relief from muscle pain, joint pain, insomnia and nervousness',
        Usage2:'It can be applied directly on the skin for relieving stress, as well as emotional traumas and shocks.',
        Usage3:'It can be applied directly on the skin as an insect repellent, or for lice.',
        Usage4:'Using vetiver oil in a diffuser while you sleep could help improve your breathing patterns',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

    
    {
        id: 28,
        title: "Juniper Oil",
        price: 3600.0,
        img: require("./assets/Essential Oil/Juniper-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Juniper Oil.',
        SKU:'SKU',
        SKU1:'GR025OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:' anti depression, anti septic, blood circulation, detox, essential oil, fatigue reliever, healing, juniper, muscle relaxant, toothache remedy.',
        Benefits:'Benefits',
        Benefits1:'Promotes and improves blood circulation',
        Benefits2:'Helps remove gas from intestines',
        Benefits3:'Relaxes muscle cramps',
        Benefits4:'Helps cure toothaches',
        Benefits5:'Stops hair loss',
        Benefits6:'Helps overcome depression and fatigue',
        Benefits7:'Increases volume and frequency of urination',
        Benefits8:'Protects wounds from being septic',
        Benefits9:'Helps remove toxins from the body',
        Usage:'Usage',
        Usage1:'To use juniper essential oil for digestive purposes, you can add a drop or two to water or a smoothie but make sure that you are using a high grade oil before you start doing so',
        Usage2:'Juniper essential oil is used extensively for its ability to fight skin irritations, infection and rash.',
        Usage3:'Juniper oil can be used internally as a powerful cleansing and detoxifying agent and to support urinary tract and healthy kidney function.',
        Usage4:'Juniper Bath Blend: Add 2 drops juniper oil, 1 drop of rosemary and 1 drop of lavender to 1 tablespoon of fractionated coconut oil. Pour 1/4 cup of Epsom salts into mixture. Add blend to a warm bath to soothe your body.',
        Usage5:'Juniper essential oils blends well with citrus oils and Cypress, Cedarwood, Sandalwood, Lavender, Melaleuca (Tea Tree), Lemongrass, Clary Sage, and Vetiver essential oils for diffusion.',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

   },


    {
        id: 29,
        title: "Jaiphal (Nutmeg) Oil",
        price: 600.0,
        img: require("./assets/Essential Oil/jaiphal-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml:'30 ML',
        dis:'Green Roots Jaiphal (Nutmeg) Oil.',
        SKU:'SKU',
        SKU1:'GR022OL', 
        Category:'Essential Oils',
        Tags:'Tags:',
        Tags1:'anti inflammation, arthritis relief, bad breath, essential oil, gallbladder stone relief, healthy heart, healthy kidney, jaiphal, nutmeg, ulcer prevention.',
        Benefits:'Benefits',
        Benefits1:'Beneficial for arthritis',
        Benefits2:'Reduces inflammation',
        Benefits3:'Beneficial for cardiovascular diseases',
        Benefits4:'Prevents ulcers',
        Benefits5:'Beneficial for digestion problems',
        Benefits6:'Beneficial for kidney issues',
        Benefits7:'Beneficial for gallbladder stones',
        Benefits8:'Reduces fatigue',
        Benefits9:'Removes bad breath',
        Usage:'Usage',
        Usage1:'The nutmeg essential oil is used as a natural food flavouring in baked goods, syrups, beverages (e.g. Coca-Cola), sweets etc',
        Usage2:'Adding some nutmeg to warm milk can even help induce relaxation',
        Usage3:'Jaiphal oil can also be with current skin products for healthy, glowing skin.',
        Usage4:'Nutmeg oil helps prevent fine lines and wrinkles and can be used as a mask',
        Usage5:'It can also be added to your favorite shampoo and conditioner for shiny, strong, and healthy hair.',
        Usage6:'Not only can nutmeg oil help hair become stronger and shinier, it can also prevent hair fall, encourage hair growth, and even add extra volume. All you have to do is massage a couple of drops of nutmeg oil into your scalp.',
        Usage7:'It complements vegetables like cabbage, spinach, broccoli, beans onions and eggplant.',
        Usage8:'In soups it works with tomatoes, split peas and black beans',
        Usage9:'Can be used in daily routine food intake',
        Weight:'Weight',
        Weight1:'80 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 ml',

    },

  //   starting
  {
      id: 30,
      title: "Ajwain Seeds",
      price: 90.0,
      img: require("./assets/Herbs & Spices/ajwain-seeds-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Green Roots Ajwain (Bishops weed) is a herb which deals with many health issues and skin issues. Can fight against bacteria and fungus.',
      SKU: 'SKU',
      SKU1: 'GR002HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' ajwain, cholesterol control, healthy breathing, healthy metabolism, menstruation cycle natural remedy, seed, sweight loss',
      Benefits: 'Benefits',
      Benefits1: 'Prevents coughing and opens respiratory passage',
      Benefits2: 'Induces menstrual cycle and relieves pain ',
      Benefits3: 'Fights against ulcer and constipation',
      Benefits4: 'Improves cholesterol level',
      Benefits5: 'Lowers Blood pressure',
      Benefits6: 'Helps in weight loss',
      Benefits7: 'Improves metabolism',
      Usage:'Usage',
      Usage1:'Drinking tea of Ajwain helps relieve in menstrual cramps and relieves cough.',
      Usage2:'Daily intake of 1 teaspoon of Ajwain helps in improving metabolism, lowers cholesterol, helps maintain weight and helps against ulcer and constipation.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '55 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },

  {
      id: 31,
      title: "Ajwain Seeds",
      price: 180.0,
      img: require("./assets/Herbs & Spices/ajwain-seeds-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Ajwain (Bishops weed) is a herb which deals with many health issues and skin issues. Can fight against bacteria and fungus.',
      SKU: 'SKU',
      SKU1: 'GR002HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' ajwain, cholesterol control, healthy breathing, healthy metabolism, menstruation cycle natural remedy, seed, sweight loss',
      Benefits: 'Benefits',
      Benefits1: 'Prevents coughing and opens respiratory passage',
      Benefits2: 'Induces menstrual cycle and relieves pain ',
      Benefits3: 'Fights against ulcer and constipation',
      Benefits4: 'Improves cholesterol level',
      Benefits5: 'Lowers Blood pressure',
      Benefits6: 'Helps in weight loss',
      Benefits7: 'Improves metabolism',
      Usage:'Usage',
      Usage1:'Drinking tea of Ajwain helps relieve in menstrual cramps and relieves cough.',
      Usage2:'Daily intake of 1 teaspoon of Ajwain helps in improving metabolism, lowers cholesterol, helps maintain weight and helps against ulcer and constipation.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '55 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },


  {
      id: 32,
      title: "Amla Powder (Indian Gooseberry)",
      price: 200.0,
      img: require("./assets/Herbs & Spices/amla-powder-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      
      dis: 'Amla powder is made from the ground-up leaves of the Indian gooseberry. Itâ€™s been used in Ayurvedic medicine for centuries to treat everything from diarrhea to jaundice. Also, beneficial for eyesight, skin health, constipation, diabetes, anaemia, nervous system health and prevention and cure from a lot of other diseases and conditions.',
      SKU: 'SKU',
      SKU1: 'GR003HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' amla, diabetes cure, hair strengthening, heart health, immune booster, indian gooseberry, nerve health, powder, skin care',
      Benefits: 'Benefits',
      Benefits1: 'Improves eye vision',
      Benefits2: 'Improves skin health',
      Benefits3: 'Prevents ageing',
      Benefits4: 'Increases immunity',
      Benefits5: 'Improves hair health',
      Benefits6: 'Prevents constipation',
      Benefits7: 'Improves nervous system health',
      Benefits8: 'Improves memory and brain health',
      Benefits9: 'Protects and cures diabetes',
      Benefits10: 'Improves cardiovascular health',
      Benefits11: 'Prevents anemia',
      Usage:'Usage',
      Usage1:'By mixing 2 teaspoons of Amla Powder with 2 teaspoons of honey will help you get immediate results with sore throat and cold.',
      Usage2:'Take a cup of water and mix it with half teaspoon of Amla Powder can heal mouth ulcer and pain by gargling with this mixture.',
      Usage3:'Mix Amla Powder in Henna and apply on the hair makes your hair nourish and helps slowing down greying of hair, also it gives shine to your hairs.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      Ingredients:'Ingredients',
      Ingredients1:'Amla',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '120 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 33,
      title: "Asgandh Nagori Powder",
      price: 800.0,
      img: require("./assets/Herbs & Spices/asgandh-nagori-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: '(Indian Ginseng Powder) Asgandh Nagori Powder (Indian ginseng powder) is effective in regulating sleep, easing stress and combating depression. Boosts testosterone in men , helps in recovering of joint pains and boosts anti-ageing.',
      SKU: 'SKU',
      SKU1: 'GR004HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' anti ageing, anti anxiety, anti depression, asgandh nagori, fertility booster, immune booster, indian ginseng powder, nerve health, sleep cycle, testosterone booster',
      Benefits: 'Benefits',
      Benefits1: 'Calms the nervous system',
      Benefits2: 'Regulates sleep',
      Benefits3: 'Eases stress',
      Benefits4: 'Helps overcome laziness',
      Benefits5: 'Helps boost fertility',
      Benefits6: 'Helps combat depression',
      Benefits7: 'Reduces anxiety',
      Benefits8: 'Boosts testosterone in men',
      Benefits9: 'Boosts immunity',
      Benefits10: 'Boosts recovery of joint pains',
      Benefits11: 'Boosts anti-ageing',
      Usage:'Usage',
      Usage1:'It can be consumed directly with water by taking one (01) teaspoon of the Asgandh Nagori powder. Or by converting it into capsules.',
      Usage2:'TFor joints and muscles pain take one (01) teaspoon Asgandh Nagori powder daily with milk.',
      Usage3:'For diabetes, take one (01) or half (1/2) teaspoon of Asgandh Nagori powder.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 34,
      title: "Chia Seeds",
      price: 400.0,
      img: require("./assets/Herbs & Spices/chia-seeds-herbs-50-1-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      
      dis: 'Green Roots Chia seeds contain large amounts of fiber and omega 3 fatty acids, plenty of high quality protein and several essential minerals and antioxidants. Which are good for skin, and health.',
      SKU: 'SKU',
      SKU1: 'GR005HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' anti inflammation, bone health, chia, heart health, seeds, skin tightening, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Lowers risk of heart diseases',
      Benefits2: 'Helps in weight loss',
      Benefits3: 'Helps reduce inflammation',
      Benefits4: 'Good for skin tightening',
      Benefits5: 'Gives nutrition to the bones',
      Benefits6: 'FRIENDLY TO USE IN DAILY DIET',
      Usage:'Usage',
      Usage1:'In a glass of water add 1 tablespoon of chia seeds, leave it overnight drink early morning. Same procedure applied for night use, which helps reduce fats from body.',
      Usage2:'Adding in protein shakes or healthy meals gives energy to body and bones.',
      Usage3:'Mixing two tablespoons of chia seeds with a half cup of coconut oil and a tablespoon of lemon juice, let this mask be on your face for 10 to 112 minutes, it will form a gel-like layer, follow this with massaging your face in circular motion for few minutes, which helps to exfoliate your skin and wash with Lukewarm water.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },

  

  {
      id: 35,
      title: "Chia Seeds",
      price: 800.0,
      img: require("./assets/Herbs & Spices/chia-seeds-herbs-50-1-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Chia seeds contain large amounts of fiber and omega 3 fatty acids, plenty of high quality protein and several essential minerals and antioxidants. Which are good for skin, and health.',
      SKU: 'SKU',
      SKU1: 'GR005HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' anti inflammation, bone health, chia, heart health, seeds, skin tightening, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Lowers risk of heart diseases',
      Benefits2: 'Helps in weight loss',
      Benefits3: 'Helps reduce inflammation',
      Benefits4: 'Good for skin tightening',
      Benefits5: 'Gives nutrition to the bones',
      Benefits6: 'FRIENDLY TO USE IN DAILY DIET',
      Usage:'Usage',
      Usage1:'In a glass of water add 1 tablespoon of chia seeds, leave it overnight drink early morning. Same procedure applied for night use, which helps reduce fats from body.',
      Usage2:'Adding in protein shakes or healthy meals gives energy to body and bones.',
      Usage3:'Mixing two tablespoons of chia seeds with a half cup of coconut oil and a tablespoon of lemon juice, let this mask be on your face for 10 to 112 minutes, it will form a gel-like layer, follow this with massaging your face in circular motion for few minutes, which helps to exfoliate your skin and wash with Lukewarm water.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 36,
      title: "Dhamasa Booti",
      price: 120.0,
      img: require("./assets/Herbs & Spices/dhamsa-booti-herbs-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: '(Fagonia Arabica) Dhamasa Booti is a single herbal medicine and very effective on general health issues i.e cancers, hepatitis, heart disorders. Along with that it also works on curing asthma, managing weight, skin problems, gum problems, allergies and blood purification.',
      SKU: 'SKU',
      SKU1: 'GR008HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti allergy, antioxidant, blood purifier, cancer beneficent, dhamsa booti, fertility booster, gum health, hepatitis cure, painkiller, skin health, smallpox cure',
      Benefits: 'Benefits',
      Benefits1: 'Beneficial for cancers, specially blood cancer and liver cancer',
      Benefits2: 'Cures hepatitis, smallpox, reproductive issues',
      Benefits3: 'Beneficial for heart, skin, gums, kidneys',
      Benefits4: 'Works as a painkiller',
      Benefits5: 'Helps fight against allergies, blood pressure',
      Benefits6: 'Antioxidant',
      Benefits7: 'Helps cure asthma',
      Benefits8: 'Helps manage weight.',
      Usage:'Usage',
      Usage1:'Prepare a cup of Dhamasa tea by putting one teaspoon in a cup of water and boil for couple of minutes util its color turns light brown.',
      Usage2:'Taking it twice a day helps in curing breast cancer and pain relieve.',
      Usage3:'Soak 3 tablespoons of Dhamasa into 2 glass of water leave it overnight. Boil it in the morning to reduce half of the liquid and drink it as tea, twice a day for cancer preventing and pain relieving.',
      Usage4:'For dizziness, consume 3 tablespoons of Dhamasa Booti with ghee twice a day.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      Ingredients:'Ingredients',
      Ingredients1:'Dhamsa',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 37,
      title: "Flax Seeds",
      price: 90.0,
      img: require("./assets/Herbs & Spices/flax-seed-50-gram-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Green Roots Flax Seeds (alsi) is full of nutrients and high in proteins. It is high in omega 3, reduces the risk of cancer and helps improves cholesterol. Helps control blood sugar and maintaining and losing weight. Flax seeds can be mixed with anything easily to add in daily use.',
      SKU: 'SKU',
      SKU1: 'GR009HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' blood pressure natural medicin, ecancer beneficent, cholesterol control, diabetes cotrol, fiber boostflax, natural protein, omega 3, seeds, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Full of nutrients',
      Benefits2: 'High in omega 3',
      Benefits3: 'Reduces the risk of cancer',
      Benefits4: 'Rich in dietary fiber',
      Benefits5: 'Helps improves cholesterol',
      Benefits6: 'Reduces blood pressure',
      Benefits7: 'High in proteins',
      Benefits8: 'Helps control blood sugar',
      Benefits9: 'Helps in maintaining and losing weight',
      Benefits10: 'Can be mixed with anything easily to add in daily use.',
      Usage:'Usage',
      Usage1:'To make a weight loss drink out of flax seeds, take one (01) teaspoon of flax seed powder and mix it in a glass of boiled water, add a little amount of fresh lemon and drink daily for visible results.',
      Usage2:'To make a healthy supplement for hair and skin take one (01) teaspoon of Flax Seeds Powder and one (01) teaspoon of Pumpkin Seeds powder â€“ take about one (01) teaspoon of the mixture daily with milk or water.',
      Usage3:'Flax seeds are filled with protein and can be sprinkled on smoothies or fruit salads.',
      Usage4:'By taking one (01) or half (1/2) teaspoon of Flax Seeds powder with water helps in diabetes control',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
     
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',  
  },

  {
      id: 38,
      title: "Flax Seeds",
      price: 180.0,
      img: require("./assets/Herbs & Spices/flax-seed-50-gram-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      
      dis: 'Green Roots Flax Seeds (alsi) is full of nutrients and high in proteins. It is high in omega 3, reduces the risk of cancer and helps improves cholesterol. Helps control blood sugar and maintaining and losing weight. Flax seeds can be mixed with anything easily to add in daily use.',
      SKU: 'SKU',
      SKU1: 'GR009HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' blood pressure natural medicin, ecancer beneficent, cholesterol control, diabetes cotrol, fiber boostflax, natural protein, omega 3, seeds, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Full of nutrients',
      Benefits2: 'High in omega 3',
      Benefits3: 'Reduces the risk of cancer',
      Benefits4: 'Rich in dietary fiber',
      Benefits5: 'Helps improves cholesterol',
      Benefits6: 'Reduces blood pressure',
      Benefits7: 'High in proteins',
      Benefits8: 'Helps control blood sugar',
      Benefits9: 'Helps in maintaining and losing weight',
      Benefits10: 'Can be mixed with anything easily to add in daily use.',
      Usage:'Usage',
      Usage1:'To make a weight loss drink out of flax seeds, take one (01) teaspoon of flax seed powder and mix it in a glass of boiled water, add a little amount of fresh lemon and drink daily for visible results.',
      Usage2:'To make a healthy supplement for hair and skin take one (01) teaspoon of Flax Seeds Powder and one (01) teaspoon of Pumpkin Seeds powder â€“ take about one (01) teaspoon of the mixture daily with milk or water.',
      Usage3:'Flax seeds are filled with protein and can be sprinkled on smoothies or fruit salads.',
      Usage4:'By taking one (01) or half (1/2) teaspoon of Flax Seeds powder with water helps in diabetes control',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      Ingredients:'Ingredients',
      Ingredients1:'Dhamsa',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 39,
      title: "Gond Katira",
      price: 400.0,
      img: require("./assets/Herbs & Spices/gond-katira-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: '(Tragacanth Gum) Gond Katira is a natural gum obtained from the dried sap of several species of Middle Eastern legumes of the genus Astragalus. Gondh Katira serves for several health benefits, such as; prevent heart strokes, Good for pregnant women, urinary lewdness, health benefits for men, health benefits for women, provides energy, Good for Lactating mothers, Benefits for skin and beauty, good for constipation.',
      SKU: 'SKU',
      SKU1: 'GR010HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'blood circulation, blood sugar, constipation cure, gond katira, healthy heart, herb, tragacanth gum',
      Benefits: 'Benefits',
      Benefits1: 'Prevents heart strokes',
      Benefits2: 'Beneficial for lactating mothers',
      Benefits3: 'Beneficial for skin & beauty',
      Benefits4: 'Beneficial for pregnant women',
      Benefits5: 'Beneficial for men and women',
      Benefits6: 'Cures constipation',
      Benefits7: 'Fights against urinary infections (UTIs)',
      Benefits8: 'Reduces acne and tightens skin',
      Benefits9: 'Acts like a cooking agent in summers',
      Benefits10: 'Regulates blood sugar levels',
      Benefits11: 'Improves blood circulation',
      Usage:'Usage',
      Usage1:'Gond Katira is an excellent antiaging supplement for skin. Take half teaspoon, add in water and mix it. Apply the mixture on your face neck leave for 10 to 15 minute and wash.',
      Usage2:'By taking half teaspoon Gond Katira daily can help relieves constipation and performs function that helps in improving digestive function.',
      Usage3:'Take one teaspoon daily with milk once in a day or twice helps in relieving joints pain and makes bones stronger.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 40,
      title: "Gul Mandi",
      price: 260.0,
      img: require("./assets/Herbs & Spices/gul-mandi-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: '(East Indian Globe Thistle) East Indian Globe Thistle/Gul Mandi (Ú¯Ù„ Ù…Ù†Ø¯ÛŒ) also known as Sphaeranthus indicus is a medicinal plant that is widely found in Southeast Asia. It is beneficial for treating indigestion, tuberculosis, bronchitis, spleen infections, anemia, piles, skin infections and other similar health conditions.',
      SKU: 'SKU',
      SKU1: 'GR011HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'asthma cure, bronchitis cure, east indian globe thistle, gul mandi, healthy digestion, herbskin disease cure, tuberculosis cure, vaginal pain cure, vomit control',
      Benefits: 'Benefits',
      Benefits1: 'Treats tuberculosis, asthma, bronchitis',
      Benefits2: 'Treats indigestion',
      Benefits3: 'Treats spleen diseases',
      Benefits4: 'Treats anemia, piles',
      Benefits5: 'Beneficial for vaginal & uterus pains',
      Benefits6: 'Treats parasitic worms',
      Benefits7: 'Treats skin infections',
      Benefits8: 'It used to control dysentery and vomiting',
      Usage:'Usage',
      Usage1:'Gul Mandi helps in treating with Acne Vulgaris, constipation, blood Impurities, Jaundice. By consuming the half teaspoon of 1 teaspoon of this herb or by making it as a tea.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
      Ingredients:'Ingredients',
      Ingredients1:'Gul Mandi',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 41,
      title: "Mehndi (Henna Plant)",
      price: 175.0,
      img: require("./assets/Herbs & Spices/mehndi-henna-plant-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',


      dis: 'Pudina also known as mentha is an energizing herb. Mint is used as mint oil, tooth pastes, mouthwashes and flavoring agents in many dishes. It works as a painkiller, cures multiple health problems, highly beneficial for digestion, works on the skin and respiratory disorders as well.',
      SKU: 'SKU',
      SKU1: 'GR007HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti allergy, anti depression, healthy breathing, healthy digestion, memory booster, mint, pudin, aweight loss',
      Benefits: 'Benefits',
      Benefits1: 'Strengthens hair',
      Benefits2: 'Acts as natural hair color',
      Benefits3: 'Nourishes the hair',
      Benefits4: 'Makes hair shinny',
      Benefits5: 'Contains energy healing',
      Benefits6: 'Covers grey hair',
      Benefits7: 'Makes hair thicker',
      Benefits8: 'Improves digestive health',
      Benefits9: 'Improves blood circulation',
      Benefits10: 'Reduces anxiety and stress',
      Benefits11: 'Prevents diabetes',
      Benefits12: 'Anti inflammatory activities',
      Benefits13: 'Good for wound treatments',
      Benefits14: 'Prevents the risk of cancer',
      Benefits15: 'Improves skin health',
      Ingredients:'Ingredients',
      Ingredients1:'Podina',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 42,
      title: "Moringa Powder (Suhanjna)",
      price: 450.0,
      img: require("./assets/Herbs & Spices/moringa-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Moringa is taken by mouth for â€œtired bloodâ€ (anemia), arthritis and other joint pain (rheumatism), asthma, cancer, constipation, diabetes, diarrhea, seizures, stomach pain, stomach and intestinal ulcers, intestinal spasms, headache, heart problems, high blood pressure, kidney stones, symptoms of menopause, thyroid.',
      SKU: 'SKU',
      SKU1: 'GR017HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' eyesight cure, hair nourishment, healthy bones, healthy heart, herb, high blood pressure, moringa, skin care, skin nourishment, suhanjna',
      Benefits: 'Benefits',
      Benefits1: 'Protects and nourishes hair and skin.',
      Benefits2: 'Protects the liver & heart',
      Benefits3: 'Prevents and treats cancer',
      Benefits4: 'Helps treat stomach disorders & heals wounds',
      Benefits5: 'Makes bones healthier & treats anemia',
      Benefits6: 'Treats mood disorders, asthma & diabetes',
      Benefits7: 'Protects against kidney disorders',
      Benefits8: 'Reduces high blood pressure',
      Benefits9: 'Improve eye health',
      Usage:'Usage',
      Usage1:'(For Adults) One tablespoon Moringa Powder, mix it with milk and take once in a day .',
      Usage2:'(For Children above 10 years age) One teaspoon Moringa Powder to be added in one cup milk or water for once in a day only.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 43,
      title: "Orange Peel Powder",
      price: 140.0,
      img: require("./assets/Herbs & Spices/orange-peel-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

       
      dis: '(Keenu Ke Chilke Ka Powder) Considered as a boon for face and skincare, orange peel powder helps to cure blackheads, dead cells, acne, pores, blemishes, dark circles, dry skin and brightens your face. It can also be applied with milk or curd for that extra glow or for removing tan. Also, rub your nails with its peel for shine. Along with that it also prevents viral infections, helps fight cancer, beneficial for the kidney and many more health related problems',
      SKU: 'SKU',
      SKU1: 'GR019HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'bone health, cancer beneficent, healthy heart, herb, high blood pressure, kidney health, orange peel, skin nourishment, virus protection',
      Benefits: 'Benefits',
      Benefits1: 'Whitens skin & teeth',
      Benefits2: 'Fights acne',
      Benefits3: 'Delays wrinkles',
      Benefits4: 'Removes dead skin cells & scars',
      Benefits5: 'Works against dandruff and scalp problems',
      Benefits6: 'Aids in weight loss',
      Benefits7: 'Balances natural oils',
      Benefits8: 'Reduces inflammation',
      Benefits9: 'Keeps bones and teeth strong',
      Benefits10: 'Prevents viral infections prevents arthritis',
      Benefits11: 'Improves blood circulation',
      Benefits12: 'Eliminates bad breath',
      Benefits13: 'Helps fight cancer & regulate high blood pressure',
      Benefits14: 'Improves heart health',
      Benefits15: 'Good for kidneys',
      Usage:'Usage',
      Usage1:'Orange Peel Powder is used as a beauty agent. For brightening of skin and removing dark spots, add 1/2 teaspoon of Orange Peel Powder and 1/2 teaspoon Sandalwood Powder, mix it with milk or yogurt and apply on face and neck and hands for 20 to 25 minutes then gently wash your skin.',
      Usage2:'For reducing acne, add one teaspoon of Orange Peel Powder and two teaspoon of Kaolin Clay, mix in green tea or rose water and apply on your face, let it get dried then wash your skin gently. Same can be done twice a week for effective results.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      Ingredients:'Ingredients',
      Ingredients1:'Orange Peel',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 44,
      title: "Qast E Shireen",
      price: 180.0,
      img: require("./assets/Herbs & Spices/qaste-shireen-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: '(Marine Costus) Costus root is used for treating worm (nematode) infections. Costus oil is used for asthma, cough, gas, and severe intestinal diseases such as dysentery and cholera. It is also used as a tonic and to stimulate digestion.',
      SKU: 'SKU',
      SKU1: 'GR020HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' blood purifier, detox, healing, healthy digestion, herb, intestinal diseases cure, liver detox, marine costus, qaste shireen, regulates blood pressure, skin nourishment',
      Benefits: 'Benefits',
      Benefits1: 'Purifies the blood',
      Benefits2: 'Treats respiratory diseases, worm infections, cough, gas, asthma & severe intestinal disease',
      Benefits3: 'Stimulates digestion',
      Benefits4: 'Helps improve skin health',
      Benefits5: 'Detoxes the liver',
      Benefits6: 'Maintains sexual health in men',
      Benefits7: 'Heals wounds',
      Benefits8: 'Regulates blood pressure',
      Usage:'Usage',
      Usage1:'Qust E Shireen herb helps in treating skin allergies, eczema, analgesic and others. For treating skin allergies, take three (03) teaspoons of Qust E shireen powder, one (01) teaspoon of Choti Elaichi (cardamom) powder and eight (08) teaspoons of boiled water. Put it in a container, cover it for half an hour and take one ounce of it whenever required..',
      Usage2:'By taking one (01) gram Qust E Shireen powder daily will help in treating paralysis.',
      Usage3:'For treating cough, cold and fever, take one (01) teaspoon of Qust E Shirren mix it with one (01) tablespoon of honey to make paste of it. Take half (1/2) or one (01) teaspoon of the mixture daily for as long as it is required for an instant relieve.',
      Usage4:'For treating Varicose veins take equal quantity of Qust E shireen powder and vegetable oil to make fine paste (as much required for effected area), apply on the affected areas helps in blood circulation and strengthens the weak nerves.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 45,
      title: "Shikakai",
      price: 200.0,
      img: require("./assets/Herbs & Spices/shikakai-herbs-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: '(Acacia Concinna) Shikakai Powder acts as a natural astringent for hair enhancing its binding properties. It clears. dandruff and cleans the dirt accumulated on the scalp. It is also beneficial for hair lice, hairfall, bad cholesterol, glucose levels and aids in eczema and psoriasis.',
      SKU: 'SKU',
      SKU1: 'GR024HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' acacia concinna, dry hair, eczema relief, hair conditions, hair nourishment, herb, oily hair, psoriasis relief, shikakai, skin care, skin nourishment',
      Benefits: 'Benefits',
      Benefits1: 'Makes hair soft and shiny',
      Benefits2: 'Treats dandruff',
      Benefits3: 'Conditions dry hair',
      Benefits4: 'Beneficial for oily scalps',
      Benefits5: 'Controls hair fall',
      Benefits6: 'Prevents pre-ageing of the hair',
      Benefits7: 'Makes natural hair color long lasting',
      Benefits8: 'Helps in removing hair lice, healing scalp wounds, quick hair growth',
      Benefits9: 'Helps soothe side effects of eczema and psoriasis.',
      Benefits10: 'Nourishes & detangles hair',
      Benefits11: 'Repairs and prevents split ends',
      Benefits12: 'Boosts good cholesterol and reduces prevents bad cholesterol',
      Benefits13: 'Helps keep blood glucose level in control',
      Benefits14: 'Hydrates dry skin',
      Benefits15: 'Gives the skin a natural glow',
      Usage:'Usage',
      Usage1:'By mixing Shikakai Powder with yogurt and applying onto the hairs for 20 minutes will give nourishment to the hairs and make them shiny and silky.',
      Usage2:'Make a mixture by adding two (02) teaspoons of Shikakai Powder with one (01) teaspoon of Reetha Powder, one (01) teaspoon of Amla and adding two (02) egg whites to apply on hairs will help control Hair fall, controls dandruff and stimulates hair follicles.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      Ingredients:'Ingredients',
      Ingredients1:'Shikai',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },


  {
      id: 46,
      title: "Simblu (Indian Barberry)",
      price: 200.0,
      img: require("./assets/Herbs & Spices/simblu-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'The Powder of Simblu Root (also known as Sumbloo) is good for healing cancer patients. It also works on bronchitis, asthma, controlling muscle spasms, aids in weight loss and has antibacterial properties.',
      SKU: 'SKU',
      SKU1: 'GR029HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'antibacterial, asthma cure, bronchitis cure, cancer beneficent, herb, indian barberry, muscle spasm control, simblu, stress reliever, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Cures bronchitis & asthma',
      Benefits2: 'Controls muscle spasms',
      Benefits3: 'Creates relaxation',
      Benefits4: 'Helps treat fatal diseases',
      Benefits5: 'Aids weight loss',
      Benefits6: 'Antibacterial',
      Benefits7: 'Anti cancer',
      Benefits8: 'Helps fight various kinds of cancers',
      Usage:'Usage',
      Usage1:'By taking half (1/2) teaspoon of Simblu Powder daily with water helps treat with acne and allergy.',
      Usage2:'By making a paste of Simblu Powder and applying on effected skin will help reducing inflammation in body.',
      Usage3:'Add one (01) teaspoon of Simblu Powder in a glass of water and leave it overnight. Gargle the next morning with this mixture helps throat infection and clears voice.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      Ingredients:'Ingredients',
      Ingredients1:'Indian Barberry',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 47,
      title: "Tukhm-e-Jaman",
      price: 120.0,
      img: require("./assets/Herbs & Spices/tukhme-jaman-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: '(Jambolan Seeds) Tukhm-e-Jaman is also known as Jambolan. It is full of essential nutrients and contains many vitamins and minerals. There are many health benefits as well, such as relief from fatigue, pains, digestion disorders, and diabetes.',
      SKU: 'SKU',
      SKU1: 'GR030HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'diabetes cure, fatigue reliever, healthy digestion, herb, immune booster, jambolan seeds, jamun, pain reliever, skin nourishment, tukhme jaman',
      Benefits: 'Benefits',
      Benefits1: 'Gives relief from fatigue and pain.',
      Benefits2: 'Helps in digestion',
      Benefits3: 'Cures digestion disorders',
      Benefits4: 'Improves the immune system',
      Benefits5: 'Treats diabetes',
      Benefits6: 'Itâ€™s good for skin and prevents blemishes.',
      Usage:'Usage',
      Usage1:'By consuming Tukhm E Jaman powder directly with water will regulate blood sugar level, boosts stomach health, posts immunity, helps in weight loss,',
      Usage2:'For urine control, take half (1/2) teaspoon of Tukhm E Jaman powder and half (1/2) teaspoon of Methi Dana Powder, mix it properly and take one (01) teaspoon (once in a day) with water. Half (1/2) teaspoon can also be given to the kids (once in a day).',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      Ingredients:'Ingredients',
      Ingredients1:'Jamun Seeds',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 48,
      title: "Tukhm E Balanga",
      price: 700.0,
      img: require("./assets/Herbs & Spices/tukhme-balanga-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: '(Basil Seeds) Green Roots Tukh malanga seeds have a mild flavour of basil and have been used in Ayurvedic and Chinese medicines for centuries. It has multiple health benefits, as well as is very beneficial for the hair. Tukhme Balanga also aids in weight loss.',
      SKU: 'SKU',
      SKU1: 'GR031HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' antioxidant, basil seeds, body coolant, constipation curedet, oxflu curehair nourishment, healthy breathing, healthy digestion, healthy metabolism, liver protection, tukhme balanga, vitamin a, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Boosts digestion',
      Benefits2: 'Improves metabolism & respiratory system',
      Benefits3: 'Protects the liver',
      Benefits4: 'Rich in vitamin A',
      Benefits5: 'Strengthens hair',
      Benefits6: 'Soothes nerves',
      Benefits7: 'Antioxidant',
      Benefits8: 'Cures constipation',
      Benefits9: 'Fights off flu',
      Benefits10: 'Reduces body heat',
      Benefits11: 'Removes toxins from the body',
      Benefits12: 'Helps in weight loss',
      Usage:'Usage',
      Usage1:'Tukhm E Balanga is used in soft drinks by adding half (1/2) teaspoon in normal water or milk. Drink once or twice a day.',
      Usage2:'For immunity boosting and weakness make a supplement by adding one (01) tablespoon of Tukhm E Balanga with one (01) tablespoon of Gond Kateera and one (01) tablespoon of White Musli powder, mix all three herbs and take one (01) teaspoon daily with milk or water helps in making bones strong and gives strength to body.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 49,
      title: "White Musli Powder",
      price: 1500.0,
      img: require("./assets/Herbs & Spices/white-musli-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      
      dis: 'Green roots White Musli powder are rich source of vitamin D and calcium, which helps in arthritis, joints pain, diabetes, boosting vitality, provides energy to body, helps in infertility.',
      SKU: 'SKU',
      SKU1: 'GR032HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' ED cure, fertility booster, herb, impotence cure, joint pain relief, premature ejaculation cure, sexual performance, sperm count booster, weight loss, hite musli',
      Benefits: 'Benefits',
      Benefits1: 'Beneficial for reproduction',
      Benefits2: 'Beneficial for low sperm counts',
      Benefits3: 'Beneficial for premature ejaculation and impotence',
      Benefits4: 'Beneficial during pregnancy and to increase breast milk flow',
      Benefits5: 'Beneficial for arthritis and joint pains',
      Benefits6: 'Beneficial for weakness and illness',
      Benefits7: 'Beneficial to improve sexual performances',
      Benefits8: 'Beneficial for weight loss',
      Usage:'Usage',
      Usage1:'Take 1/2 teaspoon of White Musli powder with yogurt or milk for the deficiency of vitamin D and joints pain.',
      Usage2:'Take 1/2 teaspoon twice a day with milk helps in boosting vitality, gives strength, helps in infertility.',
      Usage3:'Or used in any other herbal remedies.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      Ingredients:'Ingredients',
      Ingredients1:'Indian Barberry',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },

  {
      id: 50,
      title: "White Musli Powder",
      price: 3000.0,
      img: require("./assets/Herbs & Spices/white-musli-50-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green roots White Musli powder are rich source of vitamin D and calcium, which helps in arthritis, joints pain, diabetes, boosting vitality, provides energy to body, helps in infertility.',
      SKU: 'SKU',
      SKU1: 'GR032HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' ED cure, fertility booster, herb, impotence cure, joint pain relief, premature ejaculation cure, sexual performance, sperm count booster, weight loss, hite musli',
      Benefits: 'Benefits',
      Benefits1: 'Beneficial for reproduction',
      Benefits2: 'Beneficial for low sperm counts',
      Benefits3: 'Beneficial for premature ejaculation and impotence',
      Benefits4: 'Beneficial during pregnancy and to increase breast milk flow',
      Benefits5: 'Beneficial for arthritis and joint pains',
      Benefits6: 'Beneficial for weakness and illness',
      Benefits7: 'Beneficial to improve sexual performances',
      Benefits8: 'Beneficial for weight loss',
      Usage:'Usage',
      Usage1:'Take 1/2 teaspoon of White Musli powder with yogurt or milk for the deficiency of vitamin D and joints pain.',
      Usage2:'Take 1/2 teaspoon twice a day with milk helps in boosting vitality, gives strength, helps in infertility.',
      Usage3:'Or used in any other herbal remedies.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      Ingredients:'Ingredients',
      Ingredients1:'Indian Barberry',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 51,
      title: "Haldi (Turmeric)",
      price: 125.0,
      img: require("./assets/Herbs & Spices/haldi-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Turmeric is a spice that comes from the turmeric plant. It is commonly used in Asian food. You probably know turmeric as the main spice in curry. It has a warm, bitter taste and is frequently used to flavor or color curry powders, mustards, butters, and cheeses. It has much more to it, like preventing cancers, detoxing the liver, promoting weight loss, boosting muscle and joint health, great for the skin and hair, beneficial for diabetes, mood health, wounds, and arthritis as well.',
      SKU: 'SKU',
      SKU1: 'GR012HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, cancer beneficent, hair nourishment, haldi, heals wounds, healthy digestion, healthy heart, immune booster, joint pain reliever, liver detox, skin lightening, skin nourishment, turmeric',
      Benefits: 'Benefits',
      Benefits1: 'Prevents cancer',
      Benefits2: 'Detoxes the liver',
      Benefits3: 'Improves skin health & digestion',
      Benefits4: 'Helps heal wounds, control diabetes & prevent arthritis',
      Benefits5: 'Anti inflammatory properties',
      Benefits6: 'Promotes weight loss, healthy mood balance',
      Benefits7: 'Boosts immune system',
      Benefits8: 'Support cardiovascular functions, joint & muscle health',
      Benefits9: 'Moisturizes skin',
      Benefits10: 'Prevents acne',
      Benefits11: 'Lightens dark circles',
      Benefits12: 'Aids in healthy hair growth',
      Usage:'Usage',
      Usage1:'Take Haldi Powder with a glass of milk and drink as it helps in Osteoarthritis and internal pain.',
      Usage2:'By consuming Haldi powder helps lower down bad cholesterol level in body and helps in reducing fever.',
      Usage3:'Can be applied on skin by mixing with milk or tougher helps in removing scars from skin.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',

  },

  {
      id: 52,
      title: "Haldi (Turmeric)",
      price: 250.0,
      img: require("./assets/Herbs & Spices/haldi-50-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Turmeric is a spice that comes from the turmeric plant. It is commonly used in Asian food. You probably know turmeric as the main spice in curry. It has a warm, bitter taste and is frequently used to flavor or color curry powders, mustards, butters, and cheeses. It has much more to it, like preventing cancers, detoxing the liver, promoting weight loss, boosting muscle and joint health, great for the skin and hair, beneficial for diabetes, mood health, wounds, and arthritis as well.',
      SKU: 'SKU',
      SKU1: 'GR012HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, cancer beneficent, hair nourishment, haldi, heals wounds, healthy digestion, healthy heart, immune booster, joint pain reliever, liver detox, skin lightening, skin nourishment, turmeric',
      Benefits: 'Benefits',
      Benefits1: 'Prevents cancer',
      Benefits2: 'Detoxes the liver',
      Benefits3: 'Improves skin health & digestion',
      Benefits4: 'Helps heal wounds, control diabetes & prevent arthritis',
      Benefits5: 'Anti inflammatory properties',
      Benefits6: 'Promotes weight loss, healthy mood balance',
      Benefits7: 'Boosts immune system',
      Benefits8: 'Support cardiovascular functions, joint & muscle health',
      Benefits9: 'Moisturizes skin',
      Benefits10: 'Prevents acne',
      Benefits11: 'Lightens dark circles',
      Benefits12: 'Aids in healthy hair growth',
      Usage:'Usage',
      Usage1:'Take Haldi Powder with a glass of milk and drink as it helps in Osteoarthritis and internal pain.',
      Usage2:'By consuming Haldi powder helps lower down bad cholesterol level in body and helps in reducing fever.',
      Usage3:'Can be applied on skin by mixing with milk or tougher helps in removing scars from skin.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },


  {
      id: 53,
      title: "Husn E Yousuf",
      price: 300.0,
      img: require("./assets/Herbs & Spices/Husne-Yousuf-50-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',


      dis: '(Kaolin Clay) Kaolin clay is a cosmetic clay rich in silica and with a neutral pH. Itâ€™s often called White Clay or China Clay and of all the cosmetic clays, kaolin clay is the mildest. It works wonders for the skin, making it glow, healthy, tight, and bright. It helps in preventing wrinkles as well.',
      SKU: 'SKU',
      SKU1: 'GR013HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, anti ageing, herb, husne yusuf, kaolin clay, skin glow, skin lightening, skin nourishment',
      Benefits: 'Benefits',
      Benefits1: 'Makes skin healthy & glow',
      Benefits2: 'Helps in skin tightening',
      Benefits3: 'Reduces acne',
      Benefits4: 'Brightens the skin',
      Benefits5: 'Prevents wrinkles',
      Usage:'Usage',
      Usage1:'Husn e Yousuf herb can be used in many beauty related remedies and herbal face masks. One of which is making a face mask that glows your skin and works as antiageing agent â€“ take a little amount of Husn e Yousuf herb and mix it with rose water, milk or yougurt make a paste and apply on skin for 20 to 25 minutes, rinse with water or wash with soft hands.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
      Ingredients:'Ingredients',
      Ingredients1:'Kaolin Clay ',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',  
  },

  {
      id: 54,
      title: "Husn E Yousuf",
      price: 600.0,
      img: require("./assets/Herbs & Spices/Husne-Yousuf-100-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: '(Kaolin Clay) Kaolin clay is a cosmetic clay rich in silica and with a neutral pH. Itâ€™s often called White Clay or China Clay and of all the cosmetic clays, kaolin clay is the mildest. It works wonders for the skin, making it glow, healthy, tight, and bright. It helps in preventing wrinkles as well.',
      SKU: 'SKU',
      SKU1: 'GR013HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, anti ageing, herb, husne yusuf, kaolin clay, skin glow, skin lightening, skin nourishment',
      Benefits: 'Benefits',
      Benefits1: 'Makes skin healthy & glow',
      Benefits2: 'Helps in skin tightening',
      Benefits3: 'Reduces acne',
      Benefits4: 'Brightens the skin',
      Benefits5: 'Prevents wrinkles',
      Usage:'Usage',
      Usage1:'Husn e Yousuf herb can be used in many beauty related remedies and herbal face masks. One of which is making a face mask that glows your skin and works as antiageing agent â€“ take a little amount of Husn e Yousuf herb and mix it with rose water, milk or yougurt make a paste and apply on skin for 20 to 25 minutes, rinse with water or wash with soft hands.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
      Ingredients:'Ingredients',
      Ingredients1:'Kaolin Clay ',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 55,
      title: "Kalonji Seeds",
      price: 175.0,
      img: require("./assets/Herbs & Spices/kalonji-50-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Green Roots Kalonji Seeds, (also known as Black caraway, Black cumin, Nigella, Kalojeera, kalonji or kalanji), is a very useful herb that is useful in lot of ways, it fights Acne. and other skin problems, keeps a check on diabetes, increases memory and Alleviates Asthma, Aids weight loss, eases Joint pain, controls blood pressure and protects the kidney from infections.',
      SKU: 'SKU',
      SKU1: 'GR014HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, asthma cure, blood pressure control, herb, joint pain relief, kalonji seeds, kidney health, memory booster, skin nourishment, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Fights Acne. and other skin problems',
      Benefits2: 'Keeps a Check on Diabetes',
      Benefits3: 'Increases Memory and Alleviates Asthma',
      Benefits4: 'Gets Rid of Headaches',
      Benefits5: 'Aids Weight Loss',
      Benefits6: 'Eases Joint Pain',
      Benefite7: 'Controls Blood Pressure',
      Benefits8: 'Protects the Kidney',
      Usage:'Usage',
      Usage1:'Among many known ways of using Kalonji Seeds, one of the most famous is consuming 3 to 4 seeds with water (only once in a day) will protect you from many commonly found diseases as Kalonji Seeds are filled with many dietary fibers, calcium, iron that cures instantly over the period of time.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',  
  },

  {
      id: 56,
      title: "Kalonji Seeds",
      price: 350.0,
      img: require("./assets/Herbs & Spices/kalongi-100-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Kalonji Seeds, (also known as Black caraway, Black cumin, Nigella, Kalojeera, kalonji or kalanji), is a very useful herb that is useful in lot of ways, it fights Acne. and other skin problems, keeps a check on diabetes, increases memory and Alleviates Asthma, Aids weight loss, eases Joint pain, controls blood pressure and protects the kidney from infections.',
      SKU: 'SKU',
      SKU1: 'GR014HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, asthma cure, blood pressure control, herb, joint pain relief, kalonji seeds, kidney health, memory booster, skin nourishment, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Fights Acne. and other skin problems',
      Benefits2: 'Keeps a Check on Diabetes',
      Benefits3: 'Increases Memory and Alleviates Asthma',
      Benefits4: 'Gets Rid of Headaches',
      Benefits5: 'Aids Weight Loss',
      Benefits6: 'Eases Joint Pain',
      Benefite7: 'Controls Blood Pressure',
      Benefits8: 'Protects the Kidney',
      Usage:'Usage',
      Usage1:'Among many known ways of using Kalonji Seeds, one of the most famous is consuming 3 to 4 seeds with water (only once in a day) will protect you from many commonly found diseases as Kalonji Seeds are filled with many dietary fibers, calcium, iron that cures instantly over the period of time.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 57,
      title: "Methi Dana",
      price: 100.0,
      img: require("./assets/Herbs & Spices/methi-50-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Green Roots Methi Dana contains a good amount of fiber, minerals, proteins and iron etc. Which helps in hair, skin and health problems.',
      SKU: 'SKU',
      SKU1: 'GR016HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'hair nourishment, herb, kidney health, liver health, menstrual pain reliever, methi dana, pain relief, pain reliever, skin nourishment',
      Benefits: 'Benefits',
      Benefits1: 'Maintains liver and kidney health',
      Benefits2: 'Reduces fever',
      Benefits3: 'Soothes muscle pain',
      Benefits4: 'Helps in weight loss',
      Benefits5: 'Improves insulin activity',
      Benefits6: 'Multiple benefits for hair and skin',
      Benefits7: 'Reduces menstrual pain',
      Usage:'Usage',
      Usage1:'Mix 1 or 2 tablespoon of methi dana into your hair oil helps in hair growth.',
      Usage2:'Take few seeds (3,4) of methi dana early morning with water helps in diabetes, cholesterol, reduces fats.',
      Usage3:'Take half teaspoon of methi dana in a cup of water cook for few minutes until it turns into a fine paste. Let it cool down then apply the paste on skin scars and pores, massage gently for 5 minutes and leave it dried down then wash with water.',
      Usage4:'Or can be used in any other herbal remedies.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',  
  },

  {
      id: 58,
      title: "Methi Dana",
      price: 200.0,
      img: require("./assets/Herbs & Spices/methi-100-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Methi Dana contains a good amount of fiber, minerals, proteins and iron etc. Which helps in hair, skin and health problems.',
      SKU: 'SKU',
      SKU1: 'GR016HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'hair nourishment, herb, kidney health, liver health, menstrual pain reliever, methi dana, pain relief, pain reliever, skin nourishment',
      Benefits: 'Benefits',
      Benefits1: 'Maintains liver and kidney health',
      Benefits2: 'Reduces fever',
      Benefits3: 'Soothes muscle pain',
      Benefits4: 'Helps in weight loss',
      Benefits5: 'Improves insulin activity',
      Benefits6: 'Multiple benefits for hair and skin',
      Benefite7: 'Reduces menstrual pain',
      Usage:'Usage',
      Usage1:'Mix 1 or 2 tablespoon of methi dana into your hair oil helps in hair growth.',
      Usage2:'Take few seeds (3,4) of methi dana early morning with water helps in diabetes, cholesterol, reduces fats.',
      Usage3:'Take half teaspoon of methi dana in a cup of water cook for few minutes until it turns into a fine paste. Let it cool down then apply the paste on skin scars and pores, massage gently for 5 minutes and leave it dried down then wash with water.',
      Usage4:'Or can be used in any other herbal remedies.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 59,
      title: "Mulethi (Licorice)",
      price: 100.0,
      img: require("./assets/Herbs & Spices/mulethi-50-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Mulethi is found in many regions of Europe and Asia and is also used for this naturally sweet taste in different cuisines. Consuming the essence of this root is beneficial for numerous aspects of our health, from being antiseptic, anti-diabetic to having antioxidant properties and fighting respiratory infections.',
      SKU: 'SKU',
      SKU1: 'GR018HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' cancer sores cure, healthy breathing, healthy digestion, herb, hormone regulation, immunity booster, licorice, liver protect, ionmemory boost, mulethi, skin nourishment, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Combats digestive issues',
      Benefits2: 'Treats cancer sores',
      Benefits3: 'Helps in weight reduction',
      Benefits4: 'Improves immunity',
      Benefits5: 'Combats respiratory infections',
      Benefits6: 'Protects the liver ',
      Benefits7: 'Promotes oral health',
      Benefits8: 'Memory improvement',
      Benefits9: 'Regulates hormones',
      Benefits10: 'Beneficial for the skin',
      Usage:'Usage',
      Usage1:'For sore throat and coughing, drink a cup of Mulethi tea by adding 1/2 teaspoon of Mulethi Powder in a cup of boiled water and take twice a day daily for relief.',
      Usage2:'Mulethi Powder is also used as face mask by adding it in milk and making paste and apply on face to removes dark spots blemishes.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',  
  },

  {
      id: 60,
      title: "Mulethi (Licorice)",
      price: 200.0,
      img: require("./assets/Herbs & Spices/mulethi-100-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Mulethi is found in many regions of Europe and Asia and is also used for this naturally sweet taste in different cuisines. Consuming the essence of this root is beneficial for numerous aspects of our health, from being antiseptic, anti-diabetic to having antioxidant properties and fighting respiratory infections.',
      SKU: 'SKU',
      SKU1: 'GR018HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' cancer sores cure, healthy breathing, healthy digestion, herb, hormone regulation, immunity booster, licorice, liver protect, ionmemory boost, mulethi, skin nourishment, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Combats digestive issues',
      Benefits2: 'Treats cancer sores',
      Benefits3: 'Helps in weight reduction',
      Benefits4: 'Improves immunity',
      Benefits5: 'Combats respiratory infections',
      Benefits6: 'Protects the liver ',
      Benefits7: 'Promotes oral health',
      Benefits8: 'Memory improvement',
      Benefits9: 'Regulates hormones',
      Benefits10: 'Beneficial for the skin',
      Usage:'Usage',
      Usage1:'For sore throat and coughing, drink a cup of Mulethi tea by adding 1/2 teaspoon of Mulethi Powder in a cup of boiled water and take twice a day daily for relief.',
      Usage2:'Mulethi Powder is also used as face mask by adding it in milk and making paste and apply on face to removes dark spots blemishes.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 61,
      title: "Sabit Ispaghol",
      price: 400.0,
      img: require("./assets/Herbs & Spices/sabit-ispaghol-100-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      
      dis: 'Green Roots Sabit Ispaghol are a rich source of nutrients of fibre, protein, carbohydrates. Rich in minerals of iron, calcium and fats / fatty acids.',
      SKU: 'SKU',
      SKU1: 'GR021HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' anal fissure, constipation cure, diarrhea cure, fiber, herb, ispaghol, sabit ispaghol, stomach cleansing, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Benefits for the symptoms of anal fissure.',
      Benefits2: 'Acts as a remedy for weight loss.',
      Benefits3: 'Clears toxins from the stomach.',
      Benefits4: 'Rich supplement of fiber.',
      Benefits5: 'Treat constipation.',
      Benefits6: 'Treat Diarrhea.',
      Usage:'Usage',
      Usage1:'By mixing Sabit Ispaghol of 1 teaspoon with warm water and add a few drop of lemon in it, which helps reduce fat from body and clears toxin from body.',
      Usage2:'Or in any other herbal remedies.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110  g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 62,
      title: "Sandal (Chandanis)",
      price: 1250.0,
      img: require("./assets/Herbs & Spices/sandal-herb-50-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      
      dis: 'Sandalwood is a virtual ware house of goodness and has antiseptic, astringent, anti inflammatory and disinfectant properties. The antiseptic quality of sandalwood benefits to treat rashes, acne, blackheads and other skin eruptions. Its germicidal quality inhibits the growth of bacteria. Itâ€™s highly beneficial for the liver, heart, gallbladder, ulcers and more health related issues.',
      SKU: 'SKU',
      SKU1: 'GR022HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, anti ageing, chandanis, eczema relief, eye rejuvenating, gallbladder cure, healthy heart, herb, liver disease cure, muscle relaxant, psoriasis relief, sandal, skin brightening, stress reliever, ulcer cure',
      Benefits: 'Benefits',
      Benefits1: 'Treats eczema, psoriasis and dermatitis',
      Benefits2: 'Anti ageing properties',
      Benefits3: 'Removes scars & acne',
      Benefits4: 'Softens skin',
      Benefits5: 'Anti tanning properties',
      Benefits6: 'Saves skin from prickly heat',
      Benefits7: 'Cooling properties',
      Benefits8: 'Treats puffy eyes and dark circles',
      Benefits9: 'Relaxes the body',
      Benefits10: 'Soothes urinary system',
      Benefits11: 'Heals ulcers',
      Benefits12: 'Reduces stress and hypertension',
      Benefits13: 'Relaxes nerves and muscles',
      Benefits14: 'Prevents natural teeth loss',
      Benefits15: 'Treats liver diseases',
      Benefits16: 'Treats heart diseases',
      Benefits17: 'Treats gallbladder problems',
      Benefits18: 'Beneficial in cardiovascular diseases',
      Usage:'Usage',
      Usage1:'For removing acne and blackheads mix one (01) teaspoon of Sandalwood Powder, one (01) teaspoon of Aloe Vera gel and two teaspoons of yogurt to form a fine paste. Apply the paste on the affected areas and let it get dried for 30 minutes and rinse well with water. It helps eliminating bacteria that cause acne.',
      Usage2:'To make a fairer skin complexion, take one (01) teaspoon of Sandalwood Powder one (01) teaspoon of Besan (Gram Flour) and one (01) teaspoon of Haldi) Turmeric powder â€“ mix all three and make paste with little amount of Rose water and apply on the skin. Let it be applied on your skin and leave it for 15 to 20 minutes, gently wash it with water.',
      Usage3:'By taking a pinch of Sandal Powder with water helps in treating anti inflammation.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      Ingredients:'Ingredients',
      Ingredients1:'Sandal Stick ',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',  
  },


  {
      id: 63,
      title: "Sandal (Chandanis)",
      price: 2500.0,
      img: require("./assets/Herbs & Spices/sandal-herb-100-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Sandalwood is a virtual ware house of goodness and has antiseptic, astringent, anti inflammatory and disinfectant properties. The antiseptic quality of sandalwood benefits to treat rashes, acne, blackheads and other skin eruptions. Its germicidal quality inhibits the growth of bacteria. Itâ€™s highly beneficial for the liver, heart, gallbladder, ulcers and more health related issues.',
      SKU: 'SKU',
      SKU1: 'GR022HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, anti ageing, chandanis, eczema relief, eye rejuvenating, gallbladder cure, healthy heart, herb, liver disease cure, muscle relaxant, psoriasis relief, sandal, skin brightening, stress reliever, ulcer cure',
      Benefits: 'Benefits',
      Benefits1: 'Treats eczema, psoriasis and dermatitis',
      Benefits2: 'Anti ageing properties',
      Benefits3: 'Removes scars & acne',
      Benefits4: 'Softens skin',
      Benefits5: 'Anti tanning properties',
      Benefits6: 'Saves skin from prickly heat',
      Benefits7: 'Cooling properties',
      Benefits8: 'Treats puffy eyes and dark circles',
      Benefits9: 'Relaxes the body',
      Benefits10: 'Soothes urinary system',
      Benefits11: 'Heals ulcers',
      Benefits12: 'Reduces stress and hypertension',
      Benefits13: 'Relaxes nerves and muscles',
      Benefits14: 'Prevents natural teeth loss',
      Benefits15: 'Treats liver diseases',
      Benefits16: 'Treats heart diseases',
      Benefits17: 'Treats gallbladder problems',
      Benefits18: 'Beneficial in cardiovascular diseases',
      Usage:'Usage',
      Usage1:'For removing acne and blackheads mix one (01) teaspoon of Sandalwood Powder, one (01) teaspoon of Aloe Vera gel and two teaspoons of yogurt to form a fine paste. Apply the paste on the affected areas and let it get dried for 30 minutes and rinse well with water. It helps eliminating bacteria that cause acne.',
      Usage2:'To make a fairer skin complexion, take one (01) teaspoon of Sandalwood Powder one (01) teaspoon of Besan (Gram Flour) and one (01) teaspoon of Haldi) Turmeric powder â€“ mix all three and make paste with little amount of Rose water and apply on the skin. Let it be applied on your skin and leave it for 15 to 20 minutes, gently wash it with water.',
      Usage3:'By taking a pinch of Sandal Powder with water helps in treating anti inflammation.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      Ingredients:'Ingredients',
      Ingredients1:'Sandal Stick ',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },


  {
      id: 64,
      title: "Sesame White Seeds",
      price: 200.0,
      img: require("./assets/Herbs & Spices/seasame-white-seeds-50-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Green Roots Sesame seeds are a good source of fiber, vitamin B. Which helps in health and beauty.',
      SKU: 'SKU',
      SKU1: 'GR023HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti ageing, blood pressure control, bone health, energizing, hair growth, hair nourishment, seeds, sesame white seeds, skin nourishment, teeth health',
      Benefits: 'Benefits',
      Benefits1: 'Improves hair growth',
      Benefits2: 'Contains anti-ageing properties',
      Benefits3: 'Boosts skin health',
      Benefits4: 'Boosts dental health',
      Benefits5: 'Rich source of energy',
      Benefits6: 'Stabilizes blood pressure',
      Benefits7: 'Boosts bone health',
      Usage:'Usage',
      Usage1:'Take pumpkin seeds and sesame seeds at an equal quantity, mix them to form as fine powder, take 1 teaspoon daily with milk, which will help in hair, skin and physical health.',
      Usage2:'Or used in other herbal remedies.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',  
  },

  {
      id: 65,
      title: "Sesame White Seeds",
      price: 400.0,
      img: require("./assets/Herbs & Spices/seasame-white-seeds-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Sesame seeds are a good source of fiber, vitamin B. Which helps in health and beauty.',
      SKU: 'SKU',
      SKU1: 'GR023HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti ageing, blood pressure control, bone health, energizing, hair growth, hair nourishment, seeds, sesame white seeds, skin nourishment, teeth health',
      Benefits: 'Benefits',
      Benefits1: 'Improves hair growth',
      Benefits2: 'Contains anti-ageing properties',
      Benefits3: 'Boosts skin health',
      Benefits4: 'Boosts dental health',
      Benefits5: 'Rich source of energy',
      Benefits6: 'Stabilizes blood pressure',
      Benefits7: 'Boosts bone health',
      Usage:'Usage',
      Usage1:'Take pumpkin seeds and sesame seeds at an equal quantity, mix them to form as fine powder, take 1 teaspoon daily with milk, which will help in hair, skin and physical health.',
      Usage2:'Or used in other herbal remedies.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 66,
      title: "Singhara Powder",
      price: 220.0,
      img: require("./assets/Herbs & Spices/singhara-powder-50-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Singhara is a herb which is rich in calcium, fibers, potassium and minerals.',
      SKU: 'SKU',
      SKU1: 'GR025HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'antioxidant, bone health, cholesterol control, energizing, fiber, healthy brain, herb, potassium, singhara, water retention, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Good for energy',
      Benefits2: 'Powerhouse of antioxidants and minerals',
      Benefits3: 'Helps in weight loss',
      Benefits4: 'Rich in potassium',
      Benefits5: 'Helps water retention in the body',
      Benefits6: 'Beneficial for diabetes',
      Benefits7: 'Rich in fiber',
      Benefits8: 'Rich in vitamins',
      Benefits9: 'Increases brain function',
      Benefits10: 'Reduces cholesterol and blood sugar levels',
      Benefits11: 'Makes the bones stronger',
      Benefits12: 'Gluten free',
      Benefits13: 'Reduces the risk of diseases.',
      Usage:'Usage',
      Usage1:'(For energy and brain function) â€“ take 1 teaspoon once in a day with milk.',
      Usage2:'(For water Retention in the body and Diabetes) â€“ take 1 teaspoon once in a day with water.',
      Usage3:'Or used in other herbal remedies.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',  
  },

  {
      id: 67,
      title: "Singhara Powder",
      price: 450.0,
      img: require("./assets/Herbs & Spices/singhara-powder-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Singhara is a herb which is rich in calcium, fibers, potassium and minerals.',
      SKU: 'SKU',
      SKU1: 'GR025HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'antioxidant, bone health, cholesterol control, energizing, fiber, healthy brain, herb, potassium, singhara, water retention, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Good for energy',
      Benefits2: 'Powerhouse of antioxidants and minerals',
      Benefits3: 'Helps in weight loss',
      Benefits4: 'Rich in potassium',
      Benefits5: 'Helps water retention in the body',
      Benefits6: 'Beneficial for diabetes',
      Benefits7: 'Rich in fiber',
      Benefits8: 'Rich in vitamins',
      Benefits9: 'Increases brain function',
      Benefits10: 'Reduces cholesterol and blood sugar levels',
      Benefits11: 'Makes the bones stronger',
      Benefits12: 'Gluten free',
      Benefits13: 'Reduces the risk of diseases.',
      Usage:'Usage',
      Usage1:'(For energy and brain function) â€“ take 1 teaspoon once in a day with milk.',
      Usage2:'(For water Retention in the body and Diabetes) â€“ take 1 teaspoon once in a day with water.',
      Usage3:'Or used in other herbal remedies.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },

  {
      id: 68,
      title: "Sonth Powder",
      price: 300.0,
      img: require("./assets/Herbs & Spices/sonth-50-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Green Roots sonth powder is extracted from the ginger root. Which is widely used in kitchen to add flavor and aroma. Also has many benefits in health issues and skin issues.',
      SKU: 'SKU',
      SKU1: 'GR027HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, blood circulation, bowel cleansing, cough cure, detoxginger, healthy digestion, herb, kidney cleansing, migraine relief, skin nourishment, sonth, UTI beneficent',
      Benefits: 'Benefits',
      Benefits1: 'Cures indigestion',
      Benefits2: 'Treats nausea',
      Benefits3: 'Stimulates blood circulation',
      Benefits4: 'Nourishes the skin',
      Benefits5: 'Cleans bowel and kidney',
      Benefits6: 'Removes toxin from the body',
      Benefits7: 'Beneficial for cough, sore throat and flu',
      Benefits8: 'Beneficial for headache & migraine',
      Benefits9: 'Good for urine infection',
      Benefits10: 'Beneficial for acne',
      Usage:'Usage',
      Usage1:'Drink a cup of sonth tea daily, which helps in headache, migraine, removes toxin, treats acne, burns fat, treat cough, flu, and sore throats.',
      Usage2:'Or used in other herbal remedies.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      Ingredients:'Ingredients',
      Ingredients1:'Dry Ginger',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',  
  },

  {
      id: 69,
      title: "Sonth Powder",
      price: 600.0,
      img: require("./assets/Herbs & Spices/sonth-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots sonth powder is extracted from the ginger root. Which is widely used in kitchen to add flavor and aroma. Also has many benefits in health issues and skin issues.',
      SKU: 'SKU',
      SKU1: 'GR027HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, blood circulation, bowel cleansing, cough cure, detoxginger, healthy digestion, herb, kidney cleansing, migraine relief, skin nourishment, sonth, UTI beneficent',
      Benefits: 'Benefits',
      Benefits1: 'Cures indigestion',
      Benefits2: 'Treats nausea',
      Benefits3: 'Stimulates blood circulation',
      Benefits4: 'Nourishes the skin',
      Benefits5: 'Cleans bowel and kidney',
      Benefits6: 'Removes toxin from the body',
      Benefits7: 'Beneficial for cough, sore throat and flu',
      Benefits8: 'Beneficial for headache & migraine',
      Benefits9: 'Good for urine infection',
      Benefits10: 'Beneficial for acne',
      Usage:'Usage',
      Usage1:'Drink a cup of sonth tea daily, which helps in headache, migraine, removes toxin, treats acne, burns fat, treat cough, flu, and sore throats.',
      Usage2:'Or used in other herbal remedies.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
      Ingredients:'Ingredients',
      Ingredients1:'Dry Ginger',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',  
  },


  {
      id: 70,
      title: "German Chamomile",
      price: 0.0,
      img: require("./assets/Herbs & Spices/germanchamomiloutofstock.png"),
      quantity: 1,
      gram: '50 Grams',
      Availability:'Availability',
      Availability1:' : Out of stock',

      dis: 'Green Roots German Chamomile is effective in diarrhea and indigestion. Used for hemorrhoid, insomnia and mouth sores.',
      SKU: 'SKU',
      SKU1: 'GR044HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'ADHD remedy, anti anxiety, anti inflammation, cold cure, dermatitis cure, diarrhea remedy, eczema cure, german chamomile, healing, heartburn remedy, hemorrhoids cure, herbindigestion remedy, insomnia cure, menstrual cramps remedy, mouth sore remedy, vaginal infection remedy',
      Benefits: 'Benefits',
      Benefits1: 'Beneficial for diarrhea',
      Benefits2: 'Beneficial for indigestion',
      Benefits3: 'Beneficial for anxiety, and excessive crying in kids (colic)',
      Benefits4: 'Used for hemorrhoid',
      Benefits5: 'Used for insomnia',
      Benefits6: 'Used for mouth sores',
      Benefits7: 'Beneficial for attention deficit-hyperactivity disorder (ADHD)',
      Benefits8: 'Beneficial for stomach and intestinal spasms, stomach and intestinal inflammation, stomach ulcers',
      Benefits9: 'Beneficial for menstrual cramps',
      Benefits10: 'Beneficial for intestinal gas',
      Benefits11: 'Works on heartburn',
      Benefits12: 'Beneficial for Skin irritation (dermatitis).',
      Benefits13: 'Cures common cold',
      Benefits14: 'Beneficial for gum diseases',
      Benefits15: 'Works on Inflamed or irritated skin (eczema)',
      Benefits16: 'Beneficial for Vaginal infection (vaginitis)',
      Benefits17: 'Aids in wound healing',
      Weight: ' Weight',
      Weight1: '50 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },

  {
      id: 71,
      title: "Rose Leaves",
      price: 80.0,
      img: require("./assets/Herbs & Spices/rose-leaves-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Green Roots Rose Leaves purifies blood, anti inflammatory, anti septic and anti bacterial. Effective in intestinal ulcers and in diarrhea.',
      SKU: 'SKU',
      SKU1: 'GR045HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti bacterial, anti inflammation, antiseptic, blood pressure control, blood purifier, colic remedy, constipation cure, diarrhea relief, healthy heart, insomnia remedy, intestinal ulcer relief, skin nourishment',
      Benefits: 'Benefits',
      Benefits1: 'Blood purification',
      Benefits2: 'Anti inflammatory, anti septic and anti bacterial',
      Benefits3: 'Effective in intestinal ulcers',
      Benefits4: 'Effective in diarrhea',
      Benefits5: 'Improves high blood pressure',
      Benefits6: 'Beneficial for heart health',
      Benefits7: 'Useful in eye problems',
      Benefits8: 'Useful in constipation',
      Benefits9: 'Cures insomnia',
      Benefits10: 'Beneficial for colic problems',
      Benefits11: 'Good for dry skin',
      Benefits12: 'Good for sore throats',
      Weight: ' Weight',
      Weight1: '50 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },

  {
      id: 72,
      title: "Neem Leaves",
      price: 100.0,
      img: require("./assets/Herbs & Spices/neem-leaves-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      
      dis: 'Green Roots Neem Leaves are used for leprosy and beneficial for eye disorders, intestinal worms, diseases of the heart and blood vessels (cardiovascular disease) and beneficial for diabetes.',
      SKU: 'SKU',
      SKU1: 'GR046HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'appetite remedy, diabetes remedy, eye disorder remedy, gum disease remedy, healthy heart, intestinal worms cure, leaves, leprosy remedy,  liver health, neem, skin ulcer remedy',
      Benefits: 'Benefits',
      Benefits1: 'Used for leprosy',
      Benefits2: 'Beneficial for eye disorders',
      Benefits3: 'Beneficial for intestinal worms',
      Benefits4: 'Beneficial for diseases of the heart and blood vessels (cardiovascular disease)',
      Benefits5: 'Beneficial for diabetes',
      Benefits6: 'Cures fevers',
      Benefits7: 'Beneficial for gum disease (gingivitis)',
      Benefits8:'Beneficial for liver problems',
      Benefits9:'Also used for birth control',
      Benefits10:'Beneficial for bloody nose and skin ulcers',
      Benefits11:'Beneficial for loss of appetite',
      Weight: ' Weight',
      Weight1: '50 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },

  {
      id: 73,
      title: "Gul E Nilofer Leaves",
      price: 200.0,
      img: require("./assets/Herbs & Spices/gule-nilofer-leaves-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: '(Water Lilly Flower) Green Roots Gul E Nilofer Leaves ( Water Lilly Flower) act as a cardiac tonic and refrigerant and is Antiseptic. Treats kidney pains, congestion, sore throats. Helps in curing uterine cancer and works as a sedative and alleviates pain.',
      SKU: 'SKU',
      SKU1: 'GR047HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'antiseptic, cardiac tonic, congestion cure, diarrhea cure, dysentery cure, gastrointestinal cure, gul e nilofer leaves, IBS cure, kidney pain relief, lungs disorder cure, sedativesore throat cure, uterine cancer cure, water lily flower leaves',
      Benefits: 'Benefits',
      Benefits1: 'Act as a cardiac tonic and refrigerant',
      Benefits2: 'Antiseptic',
      Benefits3: 'Treats kidney pains',
      Benefits4: 'Treats congestion',
      Benefits5: 'Treats sore throats',
      Benefits6: 'Acts as a sedative',
      Benefits7: 'Alleviates pain',
      Benefits8: 'Cures uterine cancer',
      Benefits9: 'Cures dysentery, IBS (irritable bowel syndrome)',
      Benefits10: 'Cures diarrhea',
      Benefits11: 'Treats lung disorders',
      Benefits12: 'Treats gastrointestinal conditions and skin conditions.',
      Weight: ' Weight',
      Weight1: '50 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },

  {
      id: 74,
      title: "Hibiscus Petals",
      price: 700.0,
      img: require("./assets/Herbs & Spices/hibiscus-petals-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Green roots Hibiscus Petals Helps reduces Blood Pressure and helps reduce Blood Fat Levels. Boosts Liver Health, and promotes weight Loss. Contains Compounds that may help prevent Cancer. Helps fight Bacteria, and purifies skin (breaks down skin cells and increases cell turnover).',
      SKU: 'SKU',
      SKU1: 'GR048HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, anti ageing, anti bacterial, antioxidant, blood pressure control, cancer prevention, healthy liver, hibiscus petals, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Packed With Antioxidants.',
      Benefits2: 'Helps reduce Blood Pressure.',
      Benefits3: 'Helps reduce Blood Fat Levels.',
      Benefits4: 'Boosts Liver Health.',
      Benefits5: 'Promotes Weight Loss.',
      Benefits6: 'Contains Compounds That May Help Prevent Cancer.',
      Benefits7: 'Helps Fight Bacteria.',
      Benefits8: 'Flavorful and Easy to Make',
      Benefits9: 'Purifies skin (breaks down skin cells and increases cell turnover)',
      Benefits10: 'Helps control acne breakouts',
      Benefits11: 'Helps with age spots',
      Benefits12: 'Makes the skin overall fresher, younger and smoother complexion.',
      Benefits13: 'Increases breast milk supply',
      Weight: ' Weight',
      Weight1: '50 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },

  // {
  //     id: 75,
  //     title: "Ajwain (Carom) Leaves",
  //     price: 0.0,
  //     img: require("./assets/Herbs & Spices/ajwainleavesoutofstock.png"),
  //     quantity: 1,
  //     gram: '50 Grams',
  //     Availability:'Availability',
  //     Availability1:' : Out of stock',
      
  //     dis: 'Green Roots Ajwain Leaves (Carom Leaves) cures stomach pain, improves digestion, cures common colds and improves cholesterol levels.',
  //     SKU: 'SKU',
  //     SKU1: 'GR049HS',
  //     Category: 'Herbs & Spices',
  //     Tags: 'Tags',
  //     Tags1: ' asthma cure, cholesterol control, cold cure, diabetes cure, food flavoring, healthy digestion, kidney stone cure, stomach pain relief, weight loss',
  //     Benefits: 'Benefits',
  //     Benefits1: 'Cures stomach pains',
  //     Benefits2: 'Improves digestion',
  //     Benefits3: 'Cures common colds',
  //     Benefits4: 'Improves cholesterol levels',
  //     Benefits5: 'Gives flavor to foods',
  //     Benefits6: 'Cures asthma',
  //     Benefits7: 'Cures acidity',
  //     Benefits8: 'Helps get rid of alcohol',
  //     Benefits9: 'Cures kidney stones',
  //     Benefits10: 'Helps in weight loss',
  //     Benefits11: 'Helps cure diabetes',
  //     Weight: ' Weight',
  //     Weight1: '50 g',
  //     dicQuantity: 'Quantity',
  //     dicQuantity1: '50 grams',
  // },

  {
      id: 76,
      title: "Tulsi (Holy Basil) Leaves",
      price: 600.0,
      img: require("./assets/Herbs & Spices/tulsi-leaves-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Tulsi Leaves (Holy Basil) lowers blood pressure and cholesterol levels, reduces the risk of heart attacks, heart strokes, and other heart related diseases.',
      SKU: 'SKU',
      SKU1: 'GR050HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti anxiety, anti bacterial, anti depression, anti viral, antiseptic, blood pressure control, cholesterol control, headache relief, low heart attack risk, peaceful sleep, vitamin c',
      Benefits: 'Benefits',
      Benefits1: 'Lowers blood pressure levels',
      Benefits2: 'Lowers cholesterol levels',
      Benefits3: 'Reduces the risk of heart attacks, heart strokes, and other heart related diseases',
      Benefits4: 'Promotes better sleep',
      Benefits5: 'Works for anxiety and depression',
      Benefits6: 'Work on headaches',
      Benefits7: 'Rich in vitamin C, anti bacterial, anti viral, anti septic.',
      Weight: ' Weight',
      Weight1: '105 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 77,
      title: "Mehndi Leaves",
      price: 150.0,
      img: require("./assets/Herbs & Spices/mehndi-leaves-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'GGreen Roots Mehndi Leaves cures wounds, anti bacterial, anti fungal, improves hair health, works as a harm free hair dye and eases arthritis pains.',
      SKU: 'SKU',
      SKU1: 'GR051HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' antibacterial, antifungal, arthritis relief, blood pressure control, fever relief, hair dye, hair growth, healin, gmehndi leaves',
      Benefits: 'Benefits',
      Benefits1: 'Cures wounds',
      Benefits2: 'Anti bacterial, anti fungal',
      Benefits3: 'Improves hair health',
      Benefits4: 'Works as a harm free hair dye',
      Benefits5: 'Eases arthritis pains',
      Benefits6: 'Draws out fever gives relief from headaches',
      Benefits7: 'Regulates blood pressure',
      Weight: ' Weight',
      Weight1: '50 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },

  {
      id: 78,
      title: "Stevia Leaves",
      price: 1000.0,
      img: require("./assets/Herbs & Spices/stevia-leaves-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Green Roots Stevia (leaf/powder) is safe and healthy substitute for sugar, healthy alternate for refined sugar. It reduces calorie intake, blood sugar levels and reduces risk of dental cavities.',
      SKU: 'SKU',
      SKU1: 'GR052HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'allergy prevention, blood pressure control, blood sugar control, calorie reduction, healthy sweet, healthy teeth, pancreatic cancer prevention, sugar substitut, eweight control',
      Benefits: 'Benefits',
      Benefits1: 'Safe and healthy substitute for sugar',
      Benefits2: 'Healthy alternate for refined sugar',
      Benefits3: 'Reduces calorie intake',
      Benefits4: 'Reduces blood sugar levels',
      Benefits5: 'Reduces risk of dental cavities',
      Benefits6: 'Helps in weight control',
      Benefits7: 'Reduces risk of pancreatic cancer',
      Benefits8:'Reduces blood pressure',
      Benefits9:'Food to add in childrenâ€™s diet (low calorie and harmless sugar alternate)',
      Benefits10:'Helps prevent allergies',
      Weight: ' Weight',
      Weight1: '55 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },


  {
      id: 79,
      title: "Olive Leaves",
      price: 1000.0,
      img: require("./assets/Herbs & Spices/olive-leaves-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',

      dis: 'Green Roots Olive Leaves are beneficial is gastroprotective (protects digestive system), neuroprotective (protects central nervous system and antimicrobial (inhibits microorganism growth).',
      SKU: 'SKU',
      SKU1: 'GR053HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti inflammation, anticancer, antimicrobial, antioxidant, blood pressure control, diabetes type 2 cure, digestive system protection, herpes remedy, immunity boost, nervous system protection, pain relief, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Gastroprotective (protects digestive system)',
      Benefits2: 'Neuroprotective (protects central nervous system)',
      Benefits3: 'Antimicrobial (inhibits microorganism growth)',
      Benefits4: 'Anticancer (reduces risk of cancer)',
      Benefits5: 'Anti-inflammatory (reduces risk of inflammation)',
      Benefits6: 'Reduces pain stimuli',
      Benefits7: 'Antioxidant (prevents oxidation or cell damage)',
      Benefits8:'Reduces cardiovascular risk, like atherosclerosis',
      Benefits9:'Lowers blood pressure',
      Benefits10:'Helps treats type 2 diabetes',
      Benefits11:'Supports weight loss',
      Benefits12:'Eliminates free radicals',
      Benefits13:'Boosts immunity',
      Benefits14:'Fights herpes',
      Weight: ' Weight',
      Weight1: '50 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },

  {
      id: 80,
      title: "Moringa Leaves",
      price: 180.0,
      img: require("./assets/Herbs & Spices/moriga-leaves-greenrootspk.com_.png"),
      quantity: 1,
      gram: '50 Grams',


      dis: 'Green Roots Moringa Leaves protects and nourishes the hair, protects the liver, prevents and treats cancer and treats stomach disorders.',
      SKU: 'SKU',
      SKU1: 'GR054HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti bacterial, blood pressure control, cancer cure, cancer prevention, cardiovascular protection, diabetes cure, hair nourishment, liver protection, mood disorder control, moringa leaves',
      Benefits: 'Benefits',
      Benefits1: 'Protects and nourishes the hair.',
      Benefits2: 'Protects the liver',
      Benefits3: 'Prevents and treats cancer',
      Benefits4: 'Treats stomach disorders',
      Benefits5: 'Makes bones healthier',
      Benefits6: 'Protects the cardiovascular system',
      Benefits7: 'Treats Diabetes',
      Benefits8:'Reduces high blood pressure',
      Benefits9:'Treats mood disorders',
      Benefits10:'Fights against bacterial diseases',
      Weight: ' Weight',
      Weight1: '50 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50 grams',
  },

  {
      id: 81,
      title: "Pumpkin Seeds",
      price: 950.0,
      img: require("./assets/Herbs & Spices/pumpkin-seed-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Pumpkin Seeds are full valuable nutrients, high in Antioxidants, reduces risk of certain Cancers and improves Prostate and Bladder Health',
      SKU: 'SKU',
      SKU1: 'GR055HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'antioxidant, blood sugar control, cancer beneficent, cholesterol control, fertility booster, fiber, healthy heart, immune booster, magnesium, menstrual cycle, peaceful sleep, pumpkin, seeds, sperm quality, weight loss, zinc',
      Benefits: 'Benefits',
      Benefits1: 'Full of valuable nutrients',
      Benefits2: 'High in Antioxidants',
      Benefits3: 'Reduces Risk of Certain Cancers',
      Benefits4: 'Improves Prostate and Bladder Health',
      Benefits5: 'Very High in Magnesium',
      Benefits6: 'May Improve Heart Health',
      Benefits7: 'Can Lower Blood Sugar Levels',
      Benefits8: 'High in Fiber',
      Benefits9: 'May Improve Sperm Quality',
      Benefits10: 'May Help Improve Sleep',
      Benefits11: 'Easy to Add to Your Diet',
      Benefits12: 'Aids in weight loss.',
      Benefits13: 'Works for fertility issues in men and women',
      Benefits14: 'Lowers cholesterol',
      Benefits15: 'High in Zinc which helps in the menstrual cycle phase.',
      Benefits16: 'Boosts the immune system',
      Usage:'Usage',
      Usage1:'Can be eaten orally.',
      Usage2:'Can add them to smoothies',
      Usage3:'Can be made in meals by sprinkling them into salads, soups or cereals',
      Usage4:'Can be used in baking',
      Weight: ' Weight',
      Weight1: '100  g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 82,
      title: "Laal Methi",
      price: 1600.0,
      img: require("./assets/Herbs & Spices/lal-methi-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Laal Methi (Red Fenugreek).  ',
      SKU: 'SKU',
      SKU1: 'GR056HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti dandruff, anti hair fall, blood sugar control, cholesterol control, hair nourishment, healthy digestion, herb, increase breast milk, laal methi, menstrual cramps remedy, pain relief, red fenugreek, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Reduces blood sugar level',
      Benefits2: 'Reduces menstrual cramps',
      Benefits3: 'Increases breast milk supply',
      Benefits4: 'Reduces pains such as nausea, fatigue, headaches',
      Benefits5: 'Prevents heart attacks',
      Benefits6: 'Reduces cholesterol',
      Benefits7: 'Aids digestion',
      Benefits8: 'Helps in weight loss',
      Benefits9: 'Treats hair fall and dandruff',
      Usage:'Usage',
      Usage1:'Can be chewed',
      Usage2:'Can be soaked in water overnight and consumed',
      Usage3:'Can be taken orally like a medicine',
      Usage4:'Can be grinded and later made into a paste to apply on the scalp and roots of the hair',
      Usage5:'Can be taken on an empty stomach',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },


  {
      id: 83,
      title: "Fungus Powder",
      price: 400.0,
      img: require("./assets/Herbs & Spices/fungus-powder-greenrootspk.com_.png"),
      quantity: 1,
      gram: '140 Grams',

      dis: 'Green Roots Fungus Powder.',
      SKU: 'SKU',
      SKU1: 'GR057HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti dandruff, anti fungal, fungus powder, hair nourishment, herb, scalp nourishment',
      Benefits: 'Benefits',
      Benefits1: 'Beneficial for dandruff and fungus in the hair.',
      Usage:'Usage',
      Usage1:'Take an amount enough to apply in the hair. Soak the powder in Apple Cider Vinegar at night. Apply it the next morning, half an hour before shower.',
      Ingredients:'Ingredients',
      Ingredients1:'Lakh dana (Shellac)',
      Ingredients2:'Mehndi (Henna)',
      Ingredients3:'Methi dana (Fenugreek)',
      Ingredients4:'Sanna Makki (Senna Makki)',
      Weight: ' Weight',
      Weight1: '150 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '140 grams',
  },

  {
      id: 84,
      title: "Multani Mitti",
      price: 120.0,
      img: require("./assets/Herbs & Spices/multani-mitti-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Multani Mitti â€“ Fullerâ€™s Earth.',
      SKU: 'SKU',
      SKU1: 'GR058HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti acne, anti inflammation, antiseptic, blood circulation, even skin tone, hair nourishment, herb, multani mitti, pigmentation, skin brightening, skin cleansing, skin glow, skin nourishment, sunburn soothing',
      Benefits: 'Benefits',
      Benefits1: 'Multani Mitti is known to fight acne and pimples.',
      Benefits2: 'Removes excess sebum and oil.',
      Benefits3: 'Tightens the skin',
      Benefits4: 'Deep cleanses skin removing dirt, sweat and impurities.',
      Benefits5: 'Evens out skin tone and brightens complexion.',
      Benefits6: 'Treats tanning and pigmentation.',
      Benefits7: 'Effective in treating sunburn, skin rashes and infections (multani mitti is an effective cooling agent)',
      Benefits8: 'Facilitates blood circulation, leading to radiant, glowing skin',
      Benefits9: 'Can be used in cold compresses to treat skin inflammation and insect bites',
      Benefits10: 'Fights and helps in keeping away blackheads/whiteheads, blemishes, freckles, pimple/acne spots',
      Benefits11: 'Has antiseptic properties',
      Benefits12: 'Cleansing properties for the hair',
      Benefits13: 'Its naturally absorbent properties allow it to cleanse your hair of oil while remaining gentle on your skin',
      Usage:'Usage',
      Usage1:'Can be made into a hair mask to clean and condition your hair.',
      Usage2:'Can be mixed with rose water and lemon juice and other herbs and ingredients to make a paste for the face, hair and body',
      Usage3:'Can be applied daily or twice a week',
      Usage4:'Can be used as a body scrub',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 85,
      title: "Unaab (Jujube)",
      price: 250.0,
      img: require("./assets/Herbs & Spices/unaab-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Unaab (Jujube).',
      SKU: 'SKU',
      SKU1: 'GR059HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: ' anti acne, anti ageing, anti anxiety, asthma remedy, blood pressure control, blood sugar control, cancer cure, cancer prevention, herb, immune booster, insomnia cure, nerve relaxant, skin glowing, skin nourishment, stress reliever, unaab, vitamin b1, vitamin b12, vitamin c, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Anti-cancer properties',
      Benefits2: 'Promotes Weight loss.',
      Benefits3: 'Calms Your Nervous System.',
      Benefits4: 'Strengthens Immune System.',
      Benefits5: 'Used for Anti-ageing.',
      Benefits6: 'Beneficial for Bones and Muscles.',
      Benefits7: 'Regulates Blood Pressure.',
      Benefits8: 'Treats Insomnia.',
      Benefits9: 'Reduces stress and anxiety',
      Benefits10: 'Protects the heart and brain',
      Benefits11: 'Offers protection against cancers',
      Benefits12: 'Beneficial for the skin',
      Benefits13: 'Rich in vitamin C',
      Benefits14: 'Helps fight acne',
      Benefits15: 'Makes the skin glowing and fresh',
      Benefits16: 'This fruit is a rich source of vitamin C, B1 and B2.',
      Benefits17: 'Lowers blood sugar levels',
      Benefits18: 'Helps control asthma',
      Usage:'Usage',
      Usage1:'Can be taken orally with water',
      Usage2:'Can be used to make green tea',
      Usage3:'Can be eaten as a fruit (raw form)',
      Usage4:'Can be added in milk and taken as a drink',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 86,
      title: "Triphala",
      price: 350.0,
      img: require("./assets/Herbs & Spices/triphala-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Triphala.',
      SKU: 'SKU',
      SKU1: 'GR060HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti bacterial, anti dandruff, anti inflammation, cancer prevention, cholesterol control, constipation cure, hair growth, healthy digestion, healthy teeth, herb, immunity booster, skin nourishment, triphala, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Anti inflammatory',
      Benefits2: 'Can prevent certain types of cancers',
      Benefits3: 'Helps treat constipation',
      Benefits4: 'Resolve dental problems',
      Benefits5: 'Promotes weight loss',
      Benefits6: 'Lowers cholestero',
      Benefits7: 'Stimulates hair growth',
      Benefits8: 'Treats dandruff',
      Benefits9: 'Prevents viral and bacterial infections',
      Benefits10: 'Rejuvenates skin',
      Benefits11: 'Aids digestion',
      Benefits12: 'Boosts immunity',
      Usage:'Usage',
      Usage1:'It is suggested to take triphala on an empty stomach between meals.',
      Usage2:'Powdered versions can be mixed with warm water and honey and taken before meals.',
      Usage3:'Recommended doses range from 500 mg to one gram per day, though larger amounts can be used to treat symptoms like constipation',
      Usage4:'Ideally, the best time to drink triphala water is around 4:00am to 5:00am in the morning',
      Usage5:'Triphala powder paste could be applied to face to clear pigmentation, acne causing bacteria and dry skin. (Gently warm 2 tablespoons of coconut oil until melted and add 2 tsp of Triphala powder to it. Let the mixture cool. Apply the paste evenly on your face and neck.)',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 87,
      title: "Indigo",
      price: 400.0,
      img: require("./assets/Herbs & Spices/indigooutofstock.png"),
      quantity: 1,
      gram: '100 Grams',
      Availability:'Availability',
      Availability1:' : Out of stock',

      dis: 'Green Roots Indigo.',
      SKU: 'SKU',
      SKU1: 'GR061HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'alopecia cure, anti dandruff, hair dye, hair growth, hair nourishment, hair strengthening, premature hair greying, scalp nourishment',
      Benefits: 'Benefits',
      Benefits1: 'It treats and prevents premature greying.',
      Benefits2: 'It can lead to new hair growth and treat baldness.',
      Benefits3: 'It treats dandruff and conditions dry hair.',
      Benefits4: 'It prevents scalp infections and soothes the scalp.',
      Benefits5: 'It smoothens the tangles, makes your hair thicker, more manageable and lustrous.',
      Benefits6: 'Acts like a natural hair dye',
      Benefits7: 'Nourishes the hair follicles',
      Benefits8: 'Enhances natural hair color',
      Usage:'Usage',
      Usage1:'Mix the Indigo Powder (100 grams for short hair, 200 grams for shoulder-length hair, 300 grams for long hair) in warm water to prepare a paste. Apply on your hair, using gloves if applying with your hands, or a spatula. Leave on for 45 minutes, or more, depending on preferred intensity.',
      Usage2:'Add a pinch of salt with the indigo powder paste to release the dye faster and better.',
      Usage3:'Always apply indigo hair dye on clean hair.',
      Usage4:'Using indigo only will give you a cool toned brown color. For a black color mix it with Henna',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },

  {
      id: 88,
      title: "Ispaghol Chilka",
      price: 800.0,
      img: require("./assets/Herbs & Spices/ispagol-chilka-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Green Roots Ispaghol Chilka (Psyllium Husk).',
      SKU: 'SKU',
      SKU1: 'GR062HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'blood sugar control, chilka, cholesterol control, constipation cure, diarrhea cure, healthy heart, herb, ispaghol, laxative, prebiotics, psyllium husk, satiety boost, weight loss',
      Benefits: 'Benefits',
      Benefits1: 'Psyllium relieves constipation. Psyllium is a bulk forming laxative.',
      Benefits2: 'It may help treat diarrhea.',
      Benefits3: 'It can lower blood sugar levels.',
      Benefits4: 'It may boost satiety and aid weight loss.',
      Benefits5: 'It can lower cholesterol levels.',
      Benefits6: 'It seems to be good for the heart.',
      Benefits7: 'It has prebiotic effects.',
      Usage:'Usage',
      Usage1:'Can consume Isabgol for healthy bowel movement.',
      Usage2:'Can be taken orally with water.',
      Usage3:'As a bulk laxative supplement, 5 g with a glass of water 3 times per day is a common starting point. People can increase this gradually if they find it tolerable. It depends on the product how many grams are in 1 teaspoon or tablespoon, but 1 tablespoon is a common recommendation as a serving for psyllium husk.',
      Usage4:'Using indigo only will give you a cool toned brown color. For a black color mix it with Henna',
      Usage5:'The best time to consume Isabgol for weight loss is on an empty stomach in the morning or at night before going to bed',
      Usage6:'Psyllium usually produces a bowel movement within 12 to 72 hours. Do not take psyllium for longer than 7 days in a row without a doctorâ€™s advice.',
      Usage7:'Mix isabgol in warm water and add a few drops of lemon in it. You can drink the mixture right before your meal. Also, you can drink this mixture first thing in the morning. By drinking it early in the morning helps in losing weight.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },


  {
      id: 89,
      title: "Desi Pudina (Mint)",
      price: 200.0,
      img: require("./assets/Herbs & Spices/desi-pudina-greenrootspk.com_.png"),
      quantity: 1,
      gram: '100 Grams',

      dis: 'Pudina also known as mentha is an energizing herb. Mint is used as mint oil, tooth pastes, mouthwashes and flavoring agents in many dishes. It works as a painkiller, cures multiple health problems, highly beneficial for digestion, works on the skin and respiratory disorders as well.',
      SKU: 'SKU',
      SKU1: 'GR007HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti allergy, anti depression, healthy breathing, healthy digestion, memory booster, mint, pudin, aweight loss',
      Benefits: 'Benefits',
      Benefits1: 'Helps treat Asthma',
      Benefits2: 'Keeps respiratory disorders low',
      Benefits3: 'Prevents allergies',
      Benefits4: 'Improves oral hygiene',
      Benefits5: 'Boost skin health',
      Benefits6: 'Helps in the process of digestion',
      Benefits7: 'Aids in weight loss',
      Benefits8: 'Strengthens the liver',
      Benefits9: 'Prevents memory loss',
      Benefits10: 'Prevents depression',
      Usage:'Usage',
      Usage1:'By consuming half teaspoon of Desi Pudina powder with water before or after taking meal helps you in digestion of food.',
      Usage2:'Take half teaspoon of Desi Pudina, add in water, take about 5 ml to 10 ml and mix with honey helps relieve in cough and sore throat.',
      Usage3:'Drinking a cup of Desi Pudina tea will help relieve stomach pain and bloating.',
      Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
      Ingredients:'Ingredients',
      Ingredients1:'Podina',
      SpecialInstructions:'Special Instructions',
      SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
      SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
      Weight: ' Weight',
      Weight1: '110 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '100 grams',
  },



  {
      id: 90,
      title: "Shilajit",
      price: 1800.0,
      img: require("./assets/newgreen  Web Products 1500 x1200px-05/Shilajit.jpg"),
      quantity: 1,
      gram: '50  Grams',

      dis: 'ntroducing our newly launched Shilajit – Redefining the herbal standard for a balanced mind, body, and soul.',
      SKU: 'SKU',
      SKU1: 'GR007HS',
      Category: 'Herbs & Spices',
      Tags: 'Tags',
      Tags1: 'anti allergy, anti depression, healthy breathing, healthy digestion, memory booster, mint, pudin, aweight loss',
      Benefits: 'Benefits',
      Benefits1: 'Anti inflammatory ',
      Benefits2: 'Enhances memory ',
      Benefits3: 'Beneficial for asthma ',
      Benefits4: 'Boosts heart health ',
      Benefits5: 'Boosts liver health ',
      Benefits6: 'Hydrates skin ',
      Benefits7: 'Boosts energy',
      Weight: ' Weight',
      Weight1: '50 g',
      dicQuantity: 'Quantity',
      dicQuantity1: '50  grams',
  },


  // 
  {
      id: 91,
      title: "Acne Clear Face Wash",
      price: 1000.0,
      img: require("./assets/Health & Beauty/acne-clear-face-wash-greenrootspk.com_.png"),
      quantity: 1,
      ml:'200 ML',
      dis:'Green Roots Acne Clear Face Wash not only removes dirt and oil but also helps in treating acne from the first wash. It deeply cleanses the skin and prevents future breakouts, leaving the skin whiter and brighter. Skin becomes clear with continuous use.',
      SKU:'SKU',
      SKU1:'GR001HB',
      Category:'Health & Beauty',
      Tags:'Tags',
      Tags1:'acne facewashanti acne facewashanti  bacterial facewashfacewashoily skin careskin nourishing facewash',
      Benefits:'Benefits',
      Benefits1:'Suitable for oily skin.',
      Benefits2:'Helps fight against Acne.',
      Benefits3:'Reduces production of bacteria on the skin.',
      Benefits4:'Prevents the skin from drying and peeling.',
      Benefits5:'Speeds healing.',
      Benefits6:'Decreases the formation of pimples.',
      Usage:' Usage',
      Usage1:'Wash your face twice a day. (To be used daily)',
      Ingredients:'Ingredients',
      Ingredients1:'Fuller’s earth clay',
      Ingredients2:'Orange Peel',
      Ingredients3:'Wild Turmeric',
      Ingredients4:'Rose Whitener',
      Ingredients5:'Glycerin',
      Ingredients6:'Vitamin E',
      Ingredients7:'Tea Tree Oil',
      Weight:'Weight',
      Weight1:'245 g',
      dicQuantity1:'200 mL',
      dicQuantity:'Quantity',
    },
  
  
    {
        id: 92,
        title: "Acne Regime",
        price: 3600.0,
        img: require("./assets/Health & Beauty/acne-regime-greenrootspk.com_-600x480.png"),
        quantity: 1,
        // ml: '200 ML',
        dis:'Green Roots Acne Regime is a complete bundle for getting rid of acne.',
        SKU:' GR028HB',
        Category:'Health & Beauty',
        Tags:'acne creamacne facewashacne teaanti acneanti acne regimeanti bacterialskin nourishingskincare',
        Benefits:'Benefits',
        Benefits1:'Suitable for oily skin.',
        Benefits2:'Helps fight against Acne.',
        Benefits3:'Reduces production of bacteria on the skin.',
        Benefits4:'Prevents the skin from drying and peeling.',
        Benefits5:'Speeds healing.',
        Benefits6:'Decreases the formation of pimples.',
        Benefits7:'Help get rid of acne marks.',
        Benefits8:'Helps clean and purify blood.',
        Benefits9:'Helps prevent acne.',
        Benefits10:'Helps against the sign of ageing.',
        Benefits11:'Cures inflammation.',
        Benefits12:'Fights with infectious pimples and prevent them in the future.',
        Benefits13:'Fights boils and acne vulgaris',
        Benefits14:'Prevents itching.',
        Benefits15:'Reduces the production of sebum.',
        Benefits16:'Soothes inflammation.',
        Benefits17:'Speeds the process of healing acne.',
        Benefits18:'Works as a skin protectant.',
        Benefits19:'Fights bacteria and fungus on the skin.',
        Benefits20:'Prevents skin from breaking out.',
        Benefits21:'Has antiseptic qualities.',
        Benefits22:'Dries out pimples and reduces acne marks',
        UsageofAcneClear :'Usage of Acne Clear Facewash',
        UsageofAcneClear1:'Wash your face twice a day. (To be used daily)',
        UsageofAcneDiffuser:'Usage of Acne Diffuser Herbal Tea',
        UsageofAcneDiffuser1:'Take on and half cup / glass of water, add on tablespoon of the powder and cook / boil. Cook / boil enough for one cup / glass of water to be left behind and consume once a day. (Anytime in the day or night).',
        UsageofAntiAcne:'Usage of Anti Acne Night Cream',
        UsageofAntiAcne1:'Apply on your face daily at night before sleeping.',
        Ingredients:'Collective Ingredients',
        Ingredients1:'Fuller’s earth clay',
        Ingredients2:'Orange Peel',
        Ingredients3:'Wild Turmeric',
        Ingredients4:'Rose Whitener',
        Ingredients5:'Glycerin',
        Ingredients6:'Vitamin E.',
        Ingredients7:'Tea Tree Oil',
        Ingredients8:'Unaab (Jujube)',
        Ingredients9:'Gul E Mandi (East Indian Globe Thistle)',
        Ingredients10:'Mezereum',
        Ingredients11:'Zinc Oxide',
        Ingredients12:'Pomegranate Flower',
        Ingredients13:'Calamine Powder',
        Ingredients14:'Milk Thistle',
        Ingredients15:'Sphaeranthus indicus',
        Ingredients16:'Chinaberry',
        Ingredients17:'Tamarix Galicia',
        Weight:'Weight',
        Weight1:'550 g',
        Facewash:'Facewash Quantity',
        Facewash1:'200 mL',
        HerbalTeaQuantity:'Herbal Tea Quantity',
        HerbalTeaQuantity1:'160 grams',
        NightCream:'Night Cream Quantity',
        NightCream1:'30 grams',
  
      },
      {
        id: 93,
        title: "Age Reverse Oil",
        price: 9500.0,
        img: require("./assets/Health & Beauty/age-reverse-oil-greenrootspk.com_.png"),
        quantity: 1,
        ml: '30 ML',
        dis:'Green Roots Age Reversing Oil is the compound mixture of essential oils with Almond oil as a base. Which helps out making you look younger, removes wrinkles from face. Gives a natural glow to skin.',
        SKU:'GR004HB',
        Category:'Health & Beauty',
        Tags:'age reverse oilanti ageingeye rejuvenatingface oilskin glowing oilskin tone lightening oil',
        Benefits:'Benefits',
        Benefits1:'Removes fine lines from under Eyes.',
        Benefits2:'Removes wrinkles from Face and neck.',
        Benefits3:'Beneficial for skin glow.',
        Benefits4:'Fairs the complexion.',
        Usage:'Usage',
        Usage1:'Apply few drops of Age Reversing Oil all over your face and neck at night before sleeping',
        Ingredients:'Ingredients',
        Ingredients1:'Almond oil',
        Ingredients2:'Rose oil',
        Ingredients3:'Sandal oil',
        Ingredients4:'Rosemary oil',
        Ingredients5:'Lavender oil',
        Ingredients6:'Tea tree oil',
        Ingredients7:'Lemon oil',
        Ingredients8:'Niaouli',
        Ingredients9:'Carrot oil',
        Ingredients10:'Frankincense oil',
        Ingredients11:'Fennel oil.',
        Weight:'Weight',
        Weight1:'	50 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 mL',
  
      },
  
      
      {
        id: 94,
        title: "Aloe Vera & Neem Face Wash",
        price: 1000.0,
        img: require("./assets/Health & Beauty/aloevera-neem-facewash-greenrootspk.com_.png"),
        quantity: 1,
        ml:'200 ML',
        dis:'Green Roots Aloe Vera and Neem face wash helps purifies and deeply cleanses the skin. Lightens marks and spots on the skin. Also has antifungal properties.',
        SKU:' GR005HB',
        Category:'Health & Beauty',
        Tags:'aloe vera neem facewashanti fungaleven skin tone facewashskin cleansingskin glowingskin lightening facewash',
        Benefits:'Benefits',
        Benefits1:'Lightens marks and spots on the skin.',
        Benefits2:'Helps prevents acne from appearing.',
        Benefits3:'Antibacterial.',
        Benefits4:'Anti fungal.',
        Benefits5:'Reduces melanin production.',
        Benefits6:'Helps even tone skin.',
        Benefits7:'Makes the skin clean and glowing.',
        Usage:'Usage',
        Usage1:'Wash your face twice a day. (For daily use)',
        Ingredients:'Ingredients',
        Ingredients1:'Melia',
        Ingredients2:'Chinaberry',
        Ingredients3:'Aloe Vera',
        Ingredients4:'Jojoba',
        Ingredients5:'Zinc oxide',
        Ingredients6:'White sandal',
        Weight:'Weight',
        Weight1:'245 g',
        dicQuantity:'diaQuantity',
        dicQuantity1:'200 mL',
  
      },
  
  
      {
        id: 95,
        title: "Aloe Vera Face Wash",
        price: 1000.0,
        img: require("./assets/Health & Beauty/aloe-vera-facewash-greenrootspk.com_.png"),
        quantity: 1,
        ml:'200 ML',
        dis:'Green Roots Aloe Vera Face Wash contains Aloe Vera extracts in it. It easily rinses away makeup, impurities, leaving no residue of dirt behind. It leaves the skin fresh, cool and calm. It works as a good moisturizer which makes the skin smooth.',
        SKU:'GR006HB',
        Category:'Health & Beauty',
        Tags:'anti ageingantibacterialskin lighteningskin lightening facewashskin moisturizingsunburn soothing',
        Benefits:'Benefits',
        Benefits1:'Helps soothe sunburn.',
        Benefits2:'Moisturizes the skin',
        Benefits3:'Fights skin ageing',
        Benefits4:'Lightens blemishes on the skin',
        Benefits5:'Antibacterial',
        Usage:'Usage',
        Usage1:'Wash your face twice a day. (For daily use)',
        Ingredients:'Ingredients',
        Ingredients1:'German Base',
        Ingredients2:'Aloe Vera Gel',
        Ingredients3:'Rose water',
        Ingredients4:'Vitamin E',
        Ingredients5:'Glycerin',
        Ingredients6:'Cleansing milk',
        Weight:'Weight',
        Weight1:'245 g',
        dicQuantity:'Quantity',
        dicQuantity1:'200 mL',
  
      },
  
      
      {
        id: 96,
        title: "Hair Repair Shampoo",
        price: 2200.0,
        img: require("./assets/Health & Beauty/hair-repair-shampoo-greenrootspk.com_.png"),
        quantity: 1,
        ml:'500 ML',
        dis:'(Coconut Shampoo) Made with all natural ingredients to give your hair shine, volume and health.',
        SKU:'SKU',
        SKU1:'GR012HB',
        Category:'Health & Beauty',
        Tags:'Tags',
        Tags1:'anti hair loss, damaged hair, damaged hair, shampoo dry hair shampoo, hair repair hair repair shampoo hair, strengthening shampoo.',
        Benefits:'Benefits',
        Benefits1:'High in vitamins C, E, B-1, B-3, B-5, and B-6.',
        Benefits2:'High in protein',
        Benefits3:'Restores dry hair and scalp.',
        Benefits4:'Restores moisture and strength',
        Benefits5:'Slows hair loss.',
        Benefits6:'Restores damaged follicles',
        Benefits7:'Makes hair stronger, thicker and healthier',
        Benefits8:'Tames dry and frizzy hair',
        Benefits9:'Combats dandruff',
        Benefits10:'Leaves hair more manageable and de tangled.',
        Usage:'Usage',
        Usage1:'Wash your hair daily.',
        Ingredients:'Ingredients',
        Ingredients1:'Coconut',
        Ingredients2:'Amla',
        Ingredients3:'Moringa',
        Ingredients4:'Valerian',
        Ingredients5:'Parmelia perlata',
        Ingredients6:'Air Creeper',
        Ingredients7:'Soap Nut (soap berries)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'NOT SUITABLE FOR DANDRUFF PRONE HAIR',
        Weight:'Weight',
        Weight1:'560 g',
        dicQuantity:'Quantity',
        dicQuantity1:'500 mL',
  
      },
  
  
      {
        id: 97,
        title: "Length & Strength Shampoo",
        price: 1600.0,
        img: require("./assets/Health & Beauty/length-strength-shampoo-greenrootspk.com_.png"),
        quantity: 1,
        ml:'500 ML',
        dis:'Green Roots Length & Strength Shampoo is made with all natural ingredients to make your hair longer, shinier & thicker.',
        SKU:'SKU',
        SKU1:'GR016HB',
        Category:'Health & Beauty',
        Tags:'Tags',
        Tags1:'anti dandruff, anti hair fall, hair nourishment, hair volume booster, length and strength shampoo, length shampoo, scalp conditioner, strength shampoo.',
        Benefits:'Benefits',
        Benefits1:'Conditions the scalp',
        Benefits2:'Boosts volume',
        Benefits3:'Reduces the appearance of dandruff',
        Benefits4:'Controls hair fall',
        Benefits5:'Promotes hair growth',
        Benefits6:'Adds shine and softness',
        Benefits7:'Gives stronger, thicker hair',
        Benefits8:'Prevents dry scalp',
        Usage:'Usage',
        Usage1:'Wash your hair daily.',
        Ingredients:'Ingredients',
        Ingredients1:'Amla',
        Ingredients2:'Areetha',
        Ingredients3:'Shikakai',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'NOT SUITABLE FOR PEOPLE HAVING DRY HAIR',
        Weight:'Weight',
        Weight1:'550 g',
        dicQuantity:'Quantity',
        dicQuantity1:'500 mL',
  
      },
  
  
      {
        id: 98,
        title: "Milky Face Wash",
        price: 1000.0,
        img: require("./assets/Health & Beauty/milky-facewash-greenrootspk.com_.png"),
        quantity: 1,
        ml:'200 ML',
        dis:'Green Roots Milky White Face Wash is designed for all skin types to make the skin fresh and comfortable. It naturally sets the stage for a healthy, beautiful complexion. It leaves skin feeling soft, smooth and perfectly refreshed. It also keeps the skin moisturized.',
        SKU:'SKU',
        SKU1:'GR017HB',
        Category:'Health & Beauty',
        Tags:'Tags',
        Tags1:'milky facewash, sensitive skin facewash, skin nourishment, skin soothing, sunburn protection.',
        Benefits:'Benefits',
        Benefits1:'Beneficial for sensitive skin.',
        Benefits2:'Beneficial for dry skin.',
        Benefits3:'Keeps the skin hydrated.',
        Benefits4:'Helps protect against sunburn for sensitive skin.',
        Benefits5:'Enhances face texture.',
        Benefits6:'Leaves skin feeling soft and smooth.',
        Usage:'Usage',
        Usage1:'Wash your face with Milky Face Wash twice a day (for daily use).',
        Ingredients:'Ingredients',
        Ingredients1:'Rose Whitener',
        Ingredients2:'Aloe Vera water',
        Ingredients3:'Goat Milk Powder',
        Ingredients4:'Vitamin E',
        Ingredients5:'Almond Oil',
        Ingredients6:'Glycerin',
        Weight:'Weight',
        Weight1:'	245 g',
        dicQuantity:'Quantity',
        dicQuantity1:'200 mL',
  
      },
  
  
      {
        id: 99,
        title: "Aloe Vera Gel",
        price: 750.0,
        img: require("./assets/Health & Beauty/aloe-vera-gel-greenrootspk.com_.png"),
        quantity: 1,
        ml:'285 ML',
        dis:'Green Roots Aloe Vera Gel is used for many skin problems like scars, acne and is used to make frizzy hair soft. Aloe Vera Gel makes your hair straight and volumized',
        SKU:'SKU:',
        SKU1:'GR031HB',
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'aloe vera extract, aloe vera gel, anti marks, anti scars, hair strengthening, pure aloe vera, skin care, skin lightening, skin nourishing.',
        Benefits:'Benefits',
        Benefits1:'Good to remove acne and acne scars',
        Benefits2:'Works well in case of allergy and redness on face, arms and legs',
        Benefits3:'To remove fizziness of hairs and to increase the volume of hairs',
        Benefits4:'Makes hairs soft, silky and shinny',
        Usage:'Usage',
        Usage1:'For skin, take a small amount of Aloe Vera gel and apply it on required areas until dried and rinse with water.',
        Usage2:'For hairs, Aloe Vera gel can be directly applied on hairs or by mixing it with any hair oil and a small amount of honey to apply on the hairs for 30 minutes before washing your hairs as it works as protein for your hair.',
        Ingredients:'Ingredients',
        Ingredients1:'Aloe Vera Gel',
        Weight:'Weight',
        Weight1:'	300 g',
        dicQuantity:'Quantity',	
        dicQuantity1:'285 mL',
      },
  
  
      {
        id: 100,
        title: "Pigmentation Regime",
        price: 6400.0,
        img: require("./assets/Health & Beauty/pigmentation-regime-greenrootspk.com.png"),
        quantity: 1,
        // ml:'30 ML',
        dis:'Green Roots Pigmentation Regime with night cream, sun block, serum and face-wash makes you get completely rid of Pigmentation from face. Helps to even tone the skin, leaves the skin clean and spot free.',
        SKU:'SKU:',
        SKU1:'GR021HB',
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'facial serum, pigmentation, skin care, skin glowing, skin nourishing, skin whitening.',
        BenefitsofPigmentationNightCream:'Benefits of Pigmentation Night Cream',
        BenefitsofPigmentationNightCream1:'Works in removing pigmentation from the skin',
        BenefitsofPigmentationSunBlock:'Benefits of Pigmentation Sun Block',
        BenefitsofPigmentationSunBlock1:'Protects from harmful sun rays.',
        BenefitsofPigmentationSunBlock2:'Protects from sunburn.',
        BenefitsofPigmentationSunBlock3:'Protects the skin from stove heat (kitchen stove heat).',
        BenefitsofPigmentationSunBlock4:'Protects from dust and dirt',
        BenefitsofPigmentationSerum:'Benefits of Pigmentation Serum',
        BenefitsofPigmentationSerum1:'Removes pigmentation from the skin.',
        BenefitsofPigmentationSerum2:'Clears all sorts of marks from the skin.',
        BenefitsofPigmentationSerum3:'Enhances complexion.',
        BenefitsofPigmentationSerum4:'Improves the collagen level.',
        BenefitsofPigmentationSerum5:'Protects the skin from aging early',
        BenefitsofPigmentationOrangeFacewash:'Benefits of Pigmentation Orange Face wash',
        BenefitsofPigmentationOrangeFacewash1:'A part of Regime for washing the face daily.',
        BenefitsofPigmentationOrangeFacewash2:'Helps clear the skin.',
        BenefitsofPigmentationOrangeFacewash3:'Brightens the skin.',
        BenefitsofPigmentationOrangeFacewash4:'Keeps the skin hydrated.',
        BenefitsofPigmentationOrangeFacewash5:'Helps eradicate pigmentation.',
        Usage:'Usage',
        Usage1:'Pigmentation face wash for Daily use, twice a day in the morning and at night.',
        Usage2:'Pigmentation serum to be used in the morning after face wash, apply all over your face.',
        Usage3:'Pigmentation sun block to be applied on your face, after the serum is absorbed in your skin.',
        Usage4:'Pigmentation night cream will be used after washing the face with pigmentation face wash at night on the affected areas of your face.',
        NightCreamIngredient:'Night Cream Ingredient:',
        NightCreamIngredient1:'Herpes Monnieri',
        NightCreamIngredient2:'Sphaeranthus Inclucus',
        NightCreamIngredient3:'Wild Turmeric',
        NightCreamIngredient4:'White Organ Oil',
        NightCreamIngredient5:'Wheat Oil',
        NightCreamIngredient6:'Tree Oil',
        NightCreamIngredient7:'Camela Powder',
        NightCreamIngredient8:'Zinc Oxide',
        NightCreamIngredient9:'Zincum Metelicum',
        NightCreamIngredient10:'Golonum Xanthocorpum Salt',
        NightCreamIngredient11:'Kristie Salt',
        SunBlockIngredients:'Sun Block Ingredients:',
        SunBlockIngredients1:'Golomun Xanthocarpum',
        SunBlockIngredients2:'Kristie Salt',
        SunBlockIngredients3:'Coconut',
        SunBlockIngredients4:'Sandal White',
        SunBlockIngredients5:'Mica White',
        SunBlockIngredients6:'Zinc Oxide',
        SunBlockIngredients7:'Calamine',
        SunBlockIngredients8:'Alum White',
        SunBlockIngredients9:'Mica Black',
        SunBlockIngredients10:'Pomegranate Flower',
        SerumIngredients:'Serum Ingredients:',
        SerumIngredients1:'Vitamin E Oil',
        SerumIngredients2:'Argan Oil',
        SerumIngredients3:'Butter Almond Oil',
        SerumIngredients4:'Wheat Oil',
        SerumIngredients5:'Liquid Paraffin',
        SerumIngredients6:'Herpes Monnicri',
        SerumIngredients7:'Golomun Xanthocarpum',
        SerumIngredients8:'Kristie Metelicum',
        SerumIngredients9:'Camela Powder',
        FaceWashIngredients:'Face Wash Ingredients:',
        FaceWashIngredients1:'Orange Peel',
        FaceWashIngredients2:'Lemon Peel',
        FaceWashIngredients3:'Herpes Monnieri',
        FaceWashIngredients4:'Spaerantus Inclucus',
        FaceWashIngredients5:'Barassica Compound',
        FaceWashIngredients6:'Diatomaceous Earth',
        FaceWashIngredients7:'White Sandal',
        FaceWashIngredients8:'White Turmeric',
        Weight:'Weight', 
        Weight1:'530 g', 
        NightCream:'Night Cream',
        NightCream1:'30 mL',
        SunBlock:'Sun Block',
        SunBlock1:'30 mL',
        Serum:'Serum',
        Serum1:'15 mL',
        Facewash:'Facewash',
        Facewash1:'200 mL',
  
      },
  
  
      {
        id: 101,
        title: "Skin Glowing Cream",
        price: 1600.0,
        img: require("./assets/Health & Beauty/skin-glowing-cream-greenrootspk.com.png"),
        quantity: 1,
        ml: '50 ML',
        dis:'Green Roots Skin Glowing Cream helps Nourishes and whitens the skin, leaving it soft, clear and glowing naturally. It can also be used on body to even tone the skin',
        SKU:'SKU:',
        SKU1:'GR026HB',
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'even skin tone, skin care, skin cleansing, skin glowing, skin lightening, skin whitening.',
        Benefits:'Benefits',
        Benefits1:'Makes the skin glow',
        Benefits2:'Makes the skin clean and fair',
        Benefits3:'Beneficial for two toned skin',
        Benefits4:'Makes the skin fresh and removes dullness',
        Benefits5:'Reduces pigmentation, brown spots and other skin blemishes',
        Benefits6:'Reduces the appearance of stretch marks',
        Benefits7:'Brightens skin tone',
        Benefits8:'Make your skin soft and smooth',
        Usage:'Usage',
        Usage1:'Apply day and night on your face daily.',
        Ingredients:'Ingredients',
        Ingredients1:'Saffron',
        Ingredients2:'Zinc Oxide',
        Ingredients3:'Bitter Almond',
        Ingredients4:'Coconut Milk',
        Ingredients5:'Symplocos Racemosa Roxb',
        Ingredients6:'Husn e Yousuf',
        Ingredients7:'Camilla Powder',
        Ingredients8:'Stone Flower',
        Weight:'Weight',
        Weight1:'225 g',
        dicQuantity:'Quantity',	
        dicQuantity1:'50 mL',
  
      },
  
  
      {
        id: 102,
        title: "Pigmentation Face Wash",
        price: 1200.0,
        img: require("./assets/Health & Beauty/pigmentation-facewash-greenrootspk.com_.png"),
        quantity: 1,
        ml:'200 ML',
        dis:'Green Roots Pigmentation Face Wash makes you get completely rid of Pigmentation from face. Helps to even tone the skin, leaves the skin clean and spot free.',
        SKU:'SKU:',
        SKU1:'GR035HB',
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'even skin tone, facewash, pigmentation, skin brightening, skin cleansing, skin lightening.',
        Benefits:'Benefits',
        Benefits1:'A part of Regime for washing the face daily.',
        Benefits2:'Helps clear the skin.',
        Benefits3:'Brightens the skin.',
        Benefits4:'Keeps the skin hydrated.',
        Benefits5:'Helps eradicate pigmentation.',
        Usage:'Usage',
        Usage1:'Wash your face twice a day.',
        Ingredients:'Ingredients',
        Ingredients1:'Orange Peel',
        Ingredients2:'Lemon Peel',
        Ingredients3:'Herpes Monnieri',
        Ingredients4:'Spaerantus Inclucus',
        Ingredients5:'Barassica Compound',
        Ingredients6:'Diatomaceous Earth',
        Ingredients7:'White Sandal',
        Ingredients8:'White Turmeric',
        Weight:'Weight',
        Weight1:'245 g',
        dicQuantity:'Quantity',	
        dicQuantity1:'200 mL',
  
      },
  
  
      {
        id: 103,
        title: "Pigmentation Serum",
        price: 2000.0,
        img: require("./assets/Health & Beauty/pigmentation-serum-greenrootspk.com_.png"),
        quantity: 1,
        ml:'15 ML',
        dis:'Green Roots Pigmentation Serum helps you get completely rid of Pigmentation from face. Helps to even tone the skin, leaves the skin clean and spot free.',
        SKU:'SKU:',
        SKU1:'GR037HB',
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'pigmentation, serum, skin brightening, skin care, skin lightening, skin nourishing.',
        Benefits:'Benefits',
        Benefits1:'Removes pigmentation from the skin.. Clears all sorts of marks from the skin.',
        Benefits2:'Enhances complexion.',
        Benefits3:'Improves the collagen level.',
        Benefits4:'Protects the skin from aging early',
        Usage:'Usage',
        Usage1:'Take few drops of Pigmentation serum and apply on the skin at day time daily.',
        SerumIngredients:'Serum Ingredients:',
        SerumIngredients1:'Vitamin E Oil',
        SerumIngredients2:'Argan Oil',
        SerumIngredients3:'Butter Almond Oil',
        SerumIngredients4:'Wheat Oil',
        SerumIngredients5:'Liquid Paraffin',
        SerumIngredients6:'Herpes Monnicri',
        SerumIngredients7:'Golomun Xanthocarpum',
        SerumIngredients8:'Kristie Metelicum',
        SerumIngredients9:'Camela Powder',
        Weight:'Weight',
        Weight1:'50 g',
        dicQuantity:'Quantity',	
        dicQuantity1:'15 mL',
  
      },
  
  
      {
        id: 104,
        title: "Pigmentation Night Cream",
        price: 2000.0,
        img: require("./assets/Health & Beauty/pigmentation-cream-greenrootspk.com_.png"),
        quantity: 1,
        ml: '30 ML',
        dis:'Green Roots Pigmentation Night Cream helps you get completely rid of Pigmentation from face. Helps to even tone the skin, leaves the skin clean and spot free.',
        SKU:'SKU:',
        SKU1:'GR036HB',
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:' cream, pigmentation, skin brightening, skin care, skin lightening, skin nourishing.',
        Benefits:'Benefits',
        Benefits1:'Works in removing pigmentation from the skin',
        Usage:'Usage',
        Usage1:'Apply on the pigmented areas of the skin at night daily.',
        Ingredients:'Ingredients',
        Ingredients1:'Herpes Monnieri',
        Ingredients2:'Sphaeranthus Inclucus',
        Ingredients3:'Wild Turmeric',
        Ingredients4:'White Organ Oil',
        Ingredients5:'Wheat Oil',
        Ingredients6:'Tree Oil',
        Ingredients7:'Camela Powder',
        Ingredients8:'Zinc Oxide',
        Ingredients9:'Zincum Metelicum',
        Ingredients10:'Golonum Xanthocorpum Salt',
        Ingredients11:'Kristie Salt',
        Weight:'Weight',
        Weight1:'285 g',
        dicQuantity:'Quantity',	
        dicQuantity1:'30 mL',
  
      },
  
  
      {
        id: 105,
        title: "Pigmentation Sun Block",
        price: 1200.0,
        img: require("./assets/Health & Beauty/pigmentation-sun-block-greenrootspk.com.png"),
        quantity: 1,
        ml: '30 ML',
        dis:'Green Roots Pigmentation Regime with night cream, sun block, serum and face-wash makes you get completely rid of Pigmentation from face. Helps to even tone the skin, leaves the skin clean and spot free.',
        SKU:'SKU:',
        SKU1:'GR038HB',
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'cream, pigmentation, skin lightening, skin protection, sun block, sunburn protection, UV protection.',
        Benefits:'Benefits',
        Benefits1:'Protects from harmful sun rays.',
        Benefits2:'Protects from sunburn.',
        Benefits3:'Protects the skin from stove heat (kitchen stove heat).',
        Benefits4:'Protects from dust and dirt',
        Usage:'Usage',
        Usage1:'Apply on the face daily at day time before leaving the house or going into the kitchen',
        Ingredients:'Ingredients',
        Ingredients1:'Golomun Xanthocarpum',
        Ingredients2:'Kristie Salt',
        Ingredients3:'Coconut',
        Ingredients4:'Sandal White',
        Ingredients5:'Mica White',
        Ingredients6:'Zinc Oxide',
        Ingredients7:'Calamine',
        Ingredients8:'Alum White',
        Ingredients9:'Mica Black',
        Ingredients10:'Pomegranate Flower',
        Weight:'Weight',
        Weight1:'285 g',
        dicQuantity:'Quantity',	
        dicQuantity1:'30 mL',
  
      },
  
  
      {
        id: 106,
        title: "Rose Water Bottle",
        price: 550.0,
        img: require("./assets/Health & Beauty/rose-water-bottle-greenrootspk.com.png"),
        quantity: 1,
        ml: '500 ML',
        dis:'Green Roots Rose Water captures the essence of roses and originates its equity from ‘Rose’. Our Rose Water has been extracted from best quality fresh roses. Rose Water is effective for heart, specifically for cardiac problems. It is also works as coolant for eyes, gets eyes clean and cool. With regular use of Green Roots Rose Water, your skin gets a rose-like glow. Green Roots Rose Water can also be used for mild skin care conditions.',
        SKU:'SKU:',
        SKU1:'GR022HB',
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'100% pure, arqe ghulab, even skin tone, eye health, fresh skin, healthy heart, rejuvenating, rose, rose waterskin, hydrating.',
        Benefits:'Benefits',
        Benefits1:'100% pure',
        Benefits2:'Keep the skin hydrated',
        Benefits3:'Keeps the skin fresh',
        Benefits4:'Works well as a cleanser',
        Benefits5:'Useful for the eyes',
        Benefits6:'Works as a natural skin toner',
        Benefits7:'Refreshes and cools',
        Benefits8:'Beneficial for cardiac problems',
        Benefits9:'Rejuvenating agent.',
        Usage:'Usage',
        Usage1:'Take 1/4 of Rose Water in a glass of plain water, drink early morning with some black resins (3,4).',
        Weight:'Weight',
        Weight1:'550 g',
        dicQuantity:'Quantity',	
        dicQuantity1:'500 mL',
  
      },
  
  
      {
        id: 107,
        title: "Rose Water Spray",
        price: 180.0,
        img: require("./assets/Health & Beauty/rose-spray-greenrootspk.com_.png"),
        quantity: 1,
        ml: '100 ML',
        dis:'Green Roots Rose Water Spray Bottle makes it easier for you to get benefits of rose water by just spraying it on the face, eyes or skin. Rose Water has been extracted from best quality fresh roses. By spraying on face it gives a fresh look and removes dirt from the face. it is also a good toner for the face. Spraying it on the hair can also help eradicate dandruff.',
        SKU:'SKU:',
        SKU1:'GR023HB',
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'100% pure rose water, arqe ghulab, cleanser, eye health, rejuvenating, rose spray, rose water, skin hydrating.',
        Benefits:'Benefits',
        Benefits1:'100% pure',
        Benefits2:'Keep the skin hydrated',
        Benefits3:'Keeps the skin fresh',
        Benefits4:'Works well as a cleanser',
        Benefits5:'Useful for the eyes',
        Benefits6:'Works as a natural skin toner',
        Benefits7:'Refreshes and cools',
        Benefits8:'Beneficial for redness of eyes.',
        Benefits9:'Rejuvenating agent',
        Usage:'Usage',
        Usage1:'Just spray all over your face for daily use. You can also spray on hair scalp.',
        Weight:'Weight',
        Weight1:'165 g',
        dicQuantity:'Quantity',	
        dicQuantity1:'100 mL',
  
      },
  
  
      {
        id: 108,
        title: "Acne Diffuser (Herbal Tea)",
        price: 600.0,
        img: require("./assets/Health & Beauty/acne-diffuser-greenrootspk.com_.png"),
        quantity: 1,
        gram: '160 Grams',
        dis:'Green Roots Acne Diffuser Herbal Tea is very effective for treating acne marks, signs of ageing, cures inflammation and prevent itching.',
        SKU:'SKU',
        SKU1:'GR002HB',
        Category:'Health & Beauty',
        Tags:'Tags',
        Tags1:'acne diffuser, acne herbal cure, acne natural cure, anti acne, east indian globe thistle, herbal tea, jujube, unaab.',
        Benefits:'Benefits',
        Benefits1:'Help get rid of acne marks.',
        Benefits2:'Helps clean and purify blood.',
        Benefits3:'Helps prevent acne.',
        Benefits4:'Helps against the sign of ageing.',
        Benefits5:'Cures inflammation.',
        Benefits6:'Fights with infectious pimples and prevent them in the future.',
        Benefits7:'Fights boils and acne vulgaris.',
        Benefits8:'Prevents itching.',
        Usage:'Usage',
        Usage1:'Take on and half cup / glass of water, add on tablespoon of the powder and cook / boil. Cook / boil enough for one cup / glass of water to be left behind and consume once a day. (Anytime in the day or night).',
        Ingredients:'Ingredients',
        Ingredients1:'Unaab (Jujube)',
        Ingredients2:'Gul E Mandi (East Indian Globe Thistle)',
        Weight:'Weight',
        Weight1:'175 g',
        dicQuantity:'Quantity',	
        dicQuantity1:'100 mL',
       
  
      },
  
  
      {
        id: 109,
        title: "Activated Charcoal Face Mask",
        price: 1100.0,
        img: require("./assets/Health & Beauty/activatedOutofstock.png"),
        quantity: 1,
        gram: '70 Grams',
        dis:'Green Roots Activated Charcoal Face Mask deeply cleanses, exfoliates and minimizes the pores from the skin. Makes skin clean and clear removes dead skin.',
        Availability:'Availability',
        Availability1:'Out of stock',
        SKU:'SKU',
        SKU1:'GR003HB',
        Category:'Health & Beauty',
        Tags:'Tags',
        Tags1:'activated charcoal, charcoal face mask, clear skin, oily skin care, skin lightening, skin nourishing facewash.',
        Benefits:'Benefits',
        Benefits1:'Helps draw impurities and dirt from your skin.',
        Benefits2:'Reduces acne breakouts.',
        Benefits3:'Helps reduce oil on the skin.',
        Benefits4:'Reduces and minimize pores.',
        Benefits5:'Softens the skin.',
        Benefits6:'Removes dead surface of the skin cells.',
        Benefits7:'Helps reduces acne scars and blemishes.',
        Benefits8:'Helps lighten up the complexion',
        Usage:'Usage',
        Usage1:'Take the amount of powder required to cover the area of application. Mix with rose water and apply evenly all over the face. Leave for about 20-25 minutes and rinse off with water',
        Ingredients:'Ingredients',
        Ingredients1:'Charcoal powder',
        Weight:'Weight',
        Weight1:'260 g',
        dicQuantity:'Quantity',
        dicQuantity1:'70 grams',
  
      },
  
  
      {
        id: 110,
        title: "Anti Acne Night Cream",
        price: 2000.0,
        img: require("./assets/Health & Beauty/anti-acne-night-cream-greenrootspk.com_.png"),
        quantity: 1,
        gram: '30 Grams',
        dis:'Green Roots Anti Acne Night Cream is a herbal healing treatment which deals with all types of acne and pimple problems. By fighting against bacteria and fungus which produces acne.',
        SKU:'SKU:',
        SKU1:'GR007HB', 
        Category:'Health & Beauty', 
        Tags:'Tags:',
        Tags1:'ant bacterial, anti acne, anti acne cream, anti fungal, anti inflammation creaman, tiseptic, sebum reduction, skin lightening, skin protection.',
        Benefits:'Benefits',
        Benefits1:'Reduces the production of sebum.',
        Benefits2:'Soothes inflammation..',
        Benefits3:'Speeds the process of healing acne.',
        Benefits4:'Works as a skin protectant.',
        Benefits5:'Fights bacteria and fungus on the skin.',
        Benefits6:'Prevents skin from breaking out.',
        Benefits7:'Has antiseptic qualities..',
        Benefits8:'Dries out pimples and reduces acne marks',
        Usage:'Usage',
        Usage1:'Apply on your face daily at night before sleeping.',
        Ingredients:'Ingredients',
        Ingredients1:'Mezereum',
        Ingredients2:'Zinc Oxide',
        Ingredients3:'Pomegranate Flower',
        Ingredients4:'Calamine Powder',
        Ingredients5:'Milk Thistle',
        Ingredients6:'Sphaeranthus indicus',
        Ingredients7:'Chinaberry',
        Ingredients8:'Tamarix Galicia',
        Weight:'Weight',
        Weight1:'	125 g',
        dicQuantity:'Quantity',
        dicQuantity1:'30 grams',
  
      },
  
  
      {
        id: 111,
        title: "Coco & Saffron Mask",
        price: 1600.0,
        img: require("./assets/Health & Beauty/coco-saffron-mask-greenrootspk.com_.png"),
        quantity: 1,
        gram: '70 Grams',
        dis:'Green Roots Coco and saffron Mask with the goodness of saffron, Almond, Chocolate and Barley is helpful to make your face clean from all sorts of spots, scars, pigmentation. Helps to slow down the fine lines from the skin',
        SKU:'SKU:',
        SKU1:'GR008HB',  
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'coco saffron mask, cocoa, cocoa saffron, pigmentation, saffron, skin lightening, skin nourishing, skin protection, skin whitening.',
        Benefits:'Benefits',
        Benefits1:'Whitens the skin tone..',
        Benefits2:'Reduces pigmentation, brown spots and blemishes.',
        Benefits3:'Gives skin elasticity and structure.',
        Benefits4:'Makes the skin soft.',
        Benefits5:'Helps slow down the signs of aging.',
        Benefits6:'Nourishes the skin.',
        Benefits7:'Cocoa helps filter UV rays.',
        Usage:'Usage',
        Usage1:'Take the amount required to cover the area of application.',
        Usage2:'Can be mixed with milk or yogurt. Apply evenly throughout the area of application and leave for 20-25 minutes. Rinse with water.',
        Ingredients:'Ingredients',
        Ingredients1:'Flour',
        Ingredients2:'Chocolate powder',
        Ingredients3:'Almond',
        Ingredients4:'Saffron',
        Weight:'Weight',
        Weight1:'275 g',
        dicQuantity:'Quantity',
        dicQuantity1:'70 grams',
      },
  
  
      {
        id: 112,
        title: "Detox Orange",
        price: 12000.0,
        img: require("./assets/Health & Beauty/detox-orange-greenrootspk.com_.png"),
        quantity: 1,
        gram: '640 Grams',
        dis:'Green Roots Detox Orange Probiotic fiber is the best herbal formula for losing weight faster. Not only does it make you slim it also makes you, look younger and improves the digestive system.Note: Not to be used by Diabetic Patients',
        SKU:'SKU',
        SKU1:'GR009HB', 
        Category:'Health & Beauty',
        Tags:'Tags',
        Tags1:'detox, healthy digestion, metabolism fix, orange extract, quick weight loss, skin care, weight loss.',
        Benefits:'Benefits',
        Benefits1:'Lose Weight (minimum 5-8 kg in a month)',
        Benefits2:'Best detox drink for male & female.',
        Benefits3:'Helps lose weight effectively.',
        Benefits4:'Promotes skin care.',
        Benefits5:'Improves bowel movement.',
        Benefits6:'Improves the digestive system',
        Usage:'Usage',
        Usage1:'Take 500ml water and put one tablespoon of Detox Orange early in the morning. On an empty stomach',
        Usage2:'Take 200ml water and put one tablespoon of Detox Orange before dinner. On an empty stomach.',
        UsageNotice:'(Detox Orange may effect certain people having specific medical or physical conditions – it is highly advisable to STOP USING IT IMMEDIATELY if it affects you in any way and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Fiber Blend',
        Ingredients2:'Arabinogalactan',
        Ingredients3:'Organic psyllium',
        Ingredients4:'Husk (Plantago Ovata)',
        Ingredients5:'Psyllium Seed',
        Ingredients6:'Flaxseed Oil',
        Ingredients7:'Inulin (FOS)',
        Ingredients8:'Colon Blend',
        Ingredients9:'Rhubarb Root',
        Ingredients10:'Marshmallow Root',
        Ingredients11:'Slippery Elm Bark',
        Ingredients12:'Cape Aloe (Aloe Ferox)',
        Ingredients13:'Fennel Seed',
        Ingredients14:'Fennel Seed',
        Ingredients15:'Mineral Blend',
        Ingredients16:'Fermented Goat Milk Mineral Whey',
        Ingredients17:'Magnesium Citrate',
        Ingredients18:'Beet Root',
        Ingredients19:'Botanical Blend',
        Ingredients20:'Milk Thistle Extract',
        Ingredients21:'Hyssop Leaf',
        Ingredients22:'Ginger Root',
        Ingredients23:'Yucca Root',
        Ingredients24:'Roseman Leaf',
        Ingredients25:'Turmeric Root Extract',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'NOT SUITABLE FOR DIABETIC PATIENTS (Please consult our doctor in case of diabetic customized Detox Orange))',
        SpecialInstructions2:'– NOT SUITABLE FOR PREGNANT / NURSING MOTHERS.',
        SpecialInstructions3:'– Keep it airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions4:'– Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight:'Weight',
        Weight1:'	690 g',
        dicQuantity:'Quantity',
        dicQuantity1:'640 grams',
  
      },
  
  
      {
        id: 113,
        title: "Fit Aura-7",
        price: 3000.0,
        img: require("./assets/Health & Beauty/fit-aura-7-medicine-greenrootspk.com_.png"),
        quantity: 1,
        gram: '175 Grams',
        dis:'Green Roots Fit Aura – 7 serves as one of the best herbal sources that reduces excessive fats from the body and tones it down in the most ideal shape. It also helps in relieving constipation.',
        SKU:'SKU',
        SKU1:'GR010HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'constipation curedetoxquick weight lossweight lossweight reducer',
        Benefits:'Benefits',
        Benefits1:'Helps reduce weight.',
        Benefits2:'3-4 Kgs per month',
        Benefits3:'Helps relieves constipation',
        Benefits4:'Detoxes the excessive the body.',
        Benefits5:'Reduces weight from belly and thighs.',
        Usage:'Usage',
        Usage1:'For Adults: 1 (one) teaspoon before breakfast and 1 (one) teaspoon before sleeping at night. Both to be consumed with Lukewarm water.',
        Usage2:'For Children: (10-18): 1/2 (half) teaspoon before breakfast and 1 (one) teaspoon before sleeping. Both to be consumed with Lukewarm water.',
        UsageNotice:'(Fit Aura 7 may effect certain people having specific medical or physical conditions – it is highly advisable to STOP USING IT IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Senna leaves',
        Ingredients2:'Fenugreek Seeds',
        Ingredients3:'Salicha',
        Ingredients4:'Lak Maqsol',
        Ingredients5:'Cinquefoils',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'NOT SUITABLE FOR PREGNANT / NURSING MOTHERS.',
        SpecialInstructions2:'Keep it airtight after opening the seal (at all times).',
        Weight:'Weight',
        Weight1:'190 g',
        dicQuantity:'Quantity',
        dicQuantity1:'175 grams',
  
      },
  
  
      {
        id: 114,
        title: "Fit Aura - Lite",
        price: 1500.0,
        img: require("./assets/Health & Beauty/fit-aura-greenrootspk.com_.png"),
        quantity: 1,
        gram: '160 Grams',
        dis:'Green Roots FIT – AURA LITE is a source to reduce the excessive fat from the body and tone it down in the best shape.',
        SKU:'SKU',
        SKU1:'GR011HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'fitness regime, physical fitness, physical fitness natural medicine, physical fitness natural remedy.',
        Benefits:'Benefits',
        Benefits1:'This 3 month fitness program will give your body a beautiful shape automatically and a sense to stay healthy with no side-effects.',
        Usage:'Usage',
        Usage1:'Two teaspoons a day. One teaspoon in the morning before breakfast and one teaspoon in the evening, both to be consume with half cup Arq-e- Mehzal or Lukewarm water.',
        Ingredients:'Ingredients',
        Ingredients1:'Bishop’s Weed',
        Ingredients2:'Dried Ginger',
        Ingredients3:'Hill Mint',
        Ingredients4:'Lac seed',
        Ingredients5:'Senna',
        Ingredients6:'Mastic Gum',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'NOT SUITABLE FOR PREGNANT / NURSING MOTHERS.',
        SpecialInstructions2:'Keep it airtight after opening the seal (at all times).',
        Weight:'Weight',
        Weight1:'175 g',
        dicQuantity:'Quantity',
        dicQuantity1:'160 grams',
  
      },
  
  
      {
        id: 115,
        title: "Herbal Tea",
        price: 1600.0,
        img: require("./assets/Health & Beauty/herbal-tea-greenrootspk.com_.png"),
        quantity: 1,
        gram: '175 Grams',
        dis:'Green Roots Herbal Tea is not only used to help with cough and flu but also is best to treat all types of allergies, asthma and blood infections.',
        SKU:'SKU',
        SKU1:'GR013HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'anti allergy, cough cure, flu reliever, healthy breathing, herbal tea, immune booster, lungs detox.',
        Benefits:'Benefits',
        Benefits1:'Works on dust allergy',
        Benefits2:'Beneficial to relieve flu',
        Benefits3:'Beneficial for colds and sneezing',
        Benefits4:'Keeps the lungs clean',
        Benefits5:'Beneficial for dry cough.',
        Benefits6:'Beneficial for congestion and mucus prone cough.',
        Usage:'Usage',
        Usage1:'(For Children) 1/2 (half) tablespoon of Herbal Tea and mix it in a half cup of boiling water, serve it twice a day. (Sugar or honey can also be add as per taste or need)',
        Usage2:'(For Adults) Take 01 (one) tablespoon of Herbal Tea mix it in a cup of boiling water, drink thrice a day. (Sugar or honey can also be add as per taste or need).',
        Ingredients:'Ingredients',
        Ingredients1:'Unaab',
        Ingredients2:'Sounf',
        Ingredients3:'Mulethi',
        Ingredients4:'Gul e banafsha',
        Ingredients5:'Gul e gaozuban',
        Weight:'Weight',
        Weight1:'200 g',
        dicQuantity:'Quantity',
        dicQuantity1:'175 grams',
  
     },
  
  
     {
        id: 116,
        title: "Husn E Yusuf Cream",
        price: 1200.0,
        img: require("./assets/Health & Beauty/husne-yusuf-cream-greenrootspk.com_.png"),
        quantity: 1,
        gram: '210 Grams',
        dis:'Green Roots Husn-e-Yousuf beauty night cream make your skin moisturized, Also helps in fairness, removes dark spots and scars. Good for all skin types.',
        SKU:'SKU',
        SKU1:'GR015HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'anti acne, skin brightening, skin care, skin hydrating, skin moisturizing, skin soothing.',
        Benefits:'Benefits',
        Benefits1:'Keeps the skin hydrated.',
        Benefits2:'Keeps the skin moisture.',
        Benefits3:'Beneficial for dry skin.',
        Benefits4:'Beneficial for combination skin, it also makes the skin smooth and soft.',
        Benefits5:'Removes acne marks.',
        Benefits6:'Reduces freckles and dark spots.',
        Benefits7:'Whitens and brightens skin tone.',
        Usage:'Usage',
        Usage1:'Apply gently on your face at night before sleeping.',
        Ingredients:'Ingredients',
        Ingredients1:'Milk base',
        Ingredients2:'Husn e Yousuf',
        Ingredients3:'Vitamin E',
        Ingredients4:'Alpha whitening agent',
        Ingredients5:'Kojic acid',
        Weight:'Weight',
        Weight1:'230 g',
        dicQuantity:'Quantity',
        dicQuantity1:'210 grams',
        
     },
  
  
      {
        id: 117,
        title: "Moisturizing Soap",
        price: 600.0,
        img: require("./assets/Health & Beauty/moisturizingsoapoutofstock.png"),
        quantity: 1,
        gram: '100 Grams',
        dis:'Green Roots Herbal Moisturizing soap is used to make your skin moisturized and gives glow, and is and anti-aging aid as well.',
        Availability:'Availability',
        Availability1:'Out of stock',
        SKU:'SKU',
        SKU1:'GR018HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:' anti ageing, anti ageing soap, moisturizing soap, skin moisturizing, skin nourishment.',
        Benefits:'Benefits',
        Benefits1:'Helps in moisturizing skin.',
        Benefits2:'Helps in anti-ageing.',
        Usage:'Usage',
        Usage1:'Wash your face and body daily with Moisturizing Soap to keep it moisturized.',
        Ingredients:'Ingredients',
        Ingredients1:'Glycerin',
        Ingredients2:'Coconut Extract',
        Ingredients3:'Olive Extract',
        Weight:'Weight',
        Weight1:'100 g',
        dicQuantity:'Quantity',
        dicQuantity1:'100 grams',
  
      },
  
  
      {
        id: 118,
        title: "Whitening Soap",
        price: 600.0,
        img: require("./assets/Health & Beauty/whiteningsoapoutofstock.png"),
        quantity: 1,
        gram: '95 Grams',
        dis:'Green Roots Herbal whitening soap is natural way to remove dullness and tan from the skin and body.',
        Availability:'Availability',
        Availability1:'Out of stock',
        SKU:'SKU',
        SKU1:'GR034HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'skin care, skin moisturizing, skin tone lightening, skin whitening.',
        Benefits:'Benefits',
        Benefits1:'Beneficial for skin whitening.',
        Benefits2:'Beneficial to remove dullness.',
        Benefits3:'Beneficial to remove spots.',
        Usage:'Usage',
        Usage1:'Wash your face and body with this soap daily.',
        Ingredients:'Ingredients',
        Ingredients1:'Papaya Extracts',
        Ingredients2:'Rosemary Extract',
        Ingredients3:'Licorice Extract',
        Weight:'Weight',
        Weight1:'95 g',
        dicQuantity:'Quantity',
        dicQuantity1:'95 grams',
        
     },
  
  
      {
        id: 119,
        title: "Moroccan Rose Moisturizer Cream",
        price: 2100.0,
        img: require("./assets/Health & Beauty/moroccan-rose-moisturizer-cream-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',
        dis:'Green Roots Moroccan Rose Moisturizer Cream not only moisturizes your skin but the powerful Argan oil enriched with Vitamin A and E, and Rose oil also fights against fine lines and aging of the skin. By leaving the skin looking soft, smooth and younger looking',
        SKU:'SKU',
        SKU1:' GR019HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'anti ageing, anti wrinkles, skin lightening, skin moisturizing, skin nourishment, skin soothing, skin toning.',
        Benefits:'Benefits',
        Benefits1:'Keeps the skin soft and smooth',
        Benefits2:'Works as an anti aging agent.',
        Benefits3:'Keeps the skin moisturized.',
        Benefits4:'Helps reduce wrinkles.',
        Benefits5:'Lightens marks and scars.',
        Benefits6:'Nourishes dry skin.',
        Benefits7:'Beneficial for two toned skin.',
        Benefits8:'Lightens fine lines from skin and reduces the appearance in future',
        Usage:'Usage',
        Usage1:'Apply on you skin, neck, hands after washing to moisturize the skin. Use at day and night.',
        Ingredients:'Ingredients',
        Ingredients1:'Rose Oil',
        Ingredients2:'Argan Oil',
        Ingredients3:'Cocoa Butter',
        Ingredients4:'Caramel',
        Ingredients5:'Almonds',
        Ingredients6:'Aqua',
        Ingredients7:'Glycerin',
        Weight:'Weight',
        Weight1:'330 g',
        dicQuantity:'Quantity',
        dicQuantity1:'100 grams',
       
      },
  
  
      {
        id: 120,
        title: "Ubtan",
        price: 2100.0,
        img: require("./assets/Health & Beauty/greenrootspk.com-ubtan.png"),
        quantity: 1,
        gram: '140 Grams',
        dis:'Green Roots Ubtan is best to remove acne, acne scars, brightens dull skin, improves complexion and make your skin even tone.',
        SKU:'SKU',
        SKU1:'GR030HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'skin care, skin lightening, skin nourishing, skin soothing, ubtan.',
        Benefits:'Benefits',
        Benefits1:'Makes skin smooth.',
        Benefits2:'Makes skin shine and glow.',
        Benefits3:'Clears acne and acne marks.',
        Benefits4:'Very beneficial for people with dull skin',
        Benefits5:'Helps fight with two tone skin.',
        Benefits6:'Helps make skin fairer.',
        Usage:'Usage',
        Usage1:'Use this powder with milk, yogurt, and rose water (whatever is suitable) make a paste and apply it. when completely dried, rinse with water.',
        UsageNotice:'(This remedy may effect certain people having specific medical or physical conditions – it is highly advisable to STOP USING THE REMEDY IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Peeli Sarsu',
        Ingredients2:'Charongi',
        Ingredients3:'Haldi',
        Ingredients4:'Besan',
        Ingredients5:'Zafran',
        Weight:'Weight',
        Weight1:'165 g',
        dicQuantity:'Quantity',
        dicQuantity1:'140 grams',
  
      },
  
  
      {
        id: 121,
        title: "Skin Glowing Mask",
        price: 2000.0,
        img: require("./assets/Health & Beauty/skin-glowing-mask-greenrootspk.com.png"),
        quantity: 1,
        gram: '140 Grams',
        dis:'Green Roots Skin Glow Mask is a best natural way to remove blackheads, whiteheads and dirt from the skin. Makes your skin glowing and fresh.',
        SKU:'SKU',
        SKU1:'GR027HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'even skin tone, skin care, skin cleanser, skin glowing mask, skin lightening, skin nourishing',
        Benefits:'Benefits',
        Benefits1:'Actively removes dust absorbed in the skin.',
        Benefits2:'Efficient in removing black heads and white heads.',
        Benefits3:'Improves skin health and glow.',
        Benefits4:'Also beneficial for two toned skin.',
        Usage:'Usage',
        Usage1:'Use this powder with milk, yogurt, and rose water (whatever is suitable for your skin), make a paste and apply it for 30 minutes and rinse. ',
        Ingredients:'Ingredients',
        Ingredients1:'Saffron',
        Ingredients2:'Zinc oxide',
        Ingredients3:'Bitter almond',
        Ingredients4:'Coconut milk',
        Ingredients5:'Symplocos racemosa roxb',
        Ingredients6:'Husn e Yousuf',
        Ingredients7:'Camilla powder',
        Ingredients8:'Stone flower',
        Weight:'Weight',
        Weight1:'175 g',
        dicQuantity:'Quantity',
        dicQuantity1:'140 grams',
      
      },
  
  
      {
        id: 122,
        title: "Pigmentation Mask",
        price: 2800.0,
        img: require("./assets/Health & Beauty/pigmentation-mask-greenrootspk.com_.png"),
        quantity: 1,
        gram: '175 Grams',
        dis:'Green Roots Pigmentation Mask is a complete cure to remove pigmentation from skin and makes your skin fair. Also removes dark spots.',
        SKU:'SKU',
        SKU1:'GR020HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'anti marks, pigmentation, skin care, skin glowing, skin lightening, skin whitening.',
        Benefits:'Benefits',
        Benefits1:'Removes pigmentation from the face.',
        Benefits2:'Makes skin fairer and glowing.',
        Benefits3:'Removes dark spots.',
        Benefits4:'Makes skin clearer and smooth.',
        Usage:'Usage',
        Usage1:'Take this powder as per your need and make a fine paste with honey (not so thick or thin) apply it on your skin, leave it for an hour and wash with water.',
        Ingredients:'Ingredients',
        Ingredients1:'Baljhar',
        Ingredients2:'Nishasta',
        Ingredients3:'Maghz e badam',
        Ingredients4:'Husn e yousuf',
        Ingredients5:'Orange Peel',
        Ingredients6:'Zafran fried powder',
        Weight:'Weight',
        Weight1:'190 g',
        dicQuantity:'Quantity',
        dicQuantity1:'175 grams',
  
    },
  
  
      {
        id: 123,
        title: "Saffron Scrub",
        price: 1500.0,
        img: require("./assets/Health & Beauty/saffron-scrub-greenrootspk.com_.png"),
        quantity: 1,
        gram: '75 Grams',
        dis:'Green Roots Saffron Face Scrub enriched with Almonds, rice and Saffron deals with all kind of skin scar, pigmentation, spots etc.',
        SKU:'SKU',
        SKU1:'GR024HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'anti ageing, even skin tone, pigmentation, saffron, skin lightening, skin nourishing, skin soothing.',
        Benefits:'Benefits',
        Benefits1:'Nourishes the skin, making is soft and smooth.',
        Benefits2:'Helps slow down the process of ageing.',
        Benefits3:'Helps reduces brown spots and pigmentation.',
        Benefits4:'Lightens skin tone.',
        Benefits5:'Reduces dark patches.',
        Usage:'Usage',
        Usage1:'Take the amount of the powder required to cover the area of application. Can be mixed with milk or yogurt. Scrub gently on the skin in circular motion. Leave for 20-25 minutes and rinse off with water.',
        Ingredients:'Ingredients',
        Ingredients1:'Rice',
        Ingredients2:'Almonds',
        Ingredients3:'Saffron',
        Weight:'Weight',
        Weight1:'280 g',
        dicQuantity:'Quantity',
        dicQuantity1:'75 grams',
  
      },
  
  
      {
        id: 124,
        title: "Sandal Orange Scrub",
        price: 900.0,
        img: require("./assets/Health & Beauty/sandal-orange-scrub-greenrootspk.com_.png"),
        quantity: 1,
        gram: '75 Grams',
        dis:'Green Roots Sandal & Orange Face Scrub is rich in Vitamin C, Zinc and Iron. It whitens the skin, works as an anti-ageing agent and aids in skin exfoliation and helps to dry out acne.',
        SKU:'SKU',
        SKU1:'GR025HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:' anti acne, anti ageing, even skin tone, orange, sandal, sandal orange, scrub, skin care, skin lightening, skin nourishing, skin whitening.',
        Benefits:'Benefits',
        Benefits1:'Whitens the skin tone',
        Benefits2:'Works as an anti-tan agent',
        Benefits3:'Prevents pimples, acne and sores from developing.',
        Benefits4:'Prevents exposure to dust and dirt can cause bacterial growth on your skin.',
        Benefits5:'The richness of orange slows down the production of wrinkles, and premature ageing.',
        Benefits6:'Orange aids in skin exfoliation and helps to dry out acne, improving the overall look of your skin.',
        Benefits7:'Saffron extracts acts as an excellent natural ingredient for reducing pigmentation, brown spots and other skin blemishes.',
        Benefits8:'Works on skin lightening.',
        Benefits9:'Barley extracts work as collagen and gives skin structure and elasticity.',
        Benefits10:'It also contains zinc, B vitamins, and iron, all of which are essential for promoting the health of skin.',
        Benefits11:'Barley also contains selenium, a mineral that doubles as a powerful antioxidant, which helps protect skin cells from free radical damage.',
        Howtouse:'How to use',
        Howtouse1:'Take the amount of powder required to cover the area that needs application. Can be mixed with milk or yoghurt. Scrub gently on the skin and wash off with water after 20-25 minutes.',
        Ingredients:'Ingredients',
        Ingredients1:'Sandal Powder',
        Ingredients2:'Orange Peel Powder',
        Ingredients3:'Joh',
        Weight:'Weight',
        Weight1:'285 g',
        dicQuantity:'Quantity',
        dicQuantity1:'75 grams',
        
      },
  
  
      {
        id: 125,
        title: "Honey",
        price: 1200.0,
        img: require("./assets/Health & Beauty/honeyoutofstock.png"),
        quantity: 1,
        gram: '250 Grams',
        dis:'Green Roots Honey is a cure for many problems of the body, hair and skin.',
        Availability:'Availability',
        Availability1:'Out of stock',
        SKU:'SKU',
        SKU1:'GR014HB',
        Category:'Health & Beauty',
        Tags:'Tags',
        Tags1:'100% organic honey, antibacterial, antifungal, antioxidants, cold cure, diarrhea cure, energizing, healing, healthy digestion, heartburn remedy, honey, immunity booster, infection cure, peaceful sleep, pure honey, shehed, sore throat cure.',
        Benefits:'Benefits',
        Benefits1:'Good source of antioxidants',
        Benefits2:'Heals wounds',
        Benefits3:'Antibacterial',
        Benefits4:'Antifungal',
        Benefits5:'Helps in digestive issues',
        Benefits6:'Helps soothe sore throats',
        Benefits7:'Boosts immunity',
        Benefits8:'Cures diarrhea',
        Benefits9:'Helps reduce heartburn',
        Benefits10:'Helps fight infections in the body',
        Benefits11:'Helps relieve cough and flu symptoms',
        Benefits12:'Induces good sleep',
        Benefits13:'Helps overcome weakness.',
        Usage:'Usage',
        Usage1:'Take 1 table of Honey with water daily to boost immunity, helps in digestion.',
        Usage2:'Apply on Skin to remove scars, and gives glow to skin..',
        Usage3:'Applying on hair makes Hair soft and thick',
        Weight:'Weight',
        Weight1:'350 g',
        dicQuantity:'Quantity',
        dicQuantity1:'250 grams',
       
      },
      {
        id: 126,
        title: "Tea Tree Soap",
        price: 700.0,
        img: require("./assets/Health & Beauty/tea-tree-soap-greenrootspk.com.png"),
        quantity: 1,
        gram: '100 Grams',
        dis:'Green Roots Tea-Tree Herbal soap is best and natural way to combat all types of acne prone and allergic skin.',
        SKU:'SKU',
        SKU1:'GR029HB', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'anti acneanti inflammationantibacterialeczema cureinflection curenatural soapoily skinorganic soappsoriasis reliefskin healthskin immunityskin nourishmenttea treetea tree soap',
        Benefits:'Benefits',
        Benefits1:'Benefits of Tea Tree for the skin.',
        Benefits2:'Tea tree can help soothe dry skin by reducing itching and irritation.',
        Benefits3:'Helps combat oily skin.',
        Benefits4:'Helps treat Eczema.',
        Benefits5:'Helps heal infections.',
        Benefits6:'Anti-Inflammatory.',
        Benefits7:'Reduces swelling and redness',
        Benefits8:'Antibacterial',
        Benefits9:'Treats acne.',
        Benefits10:'Boost skin immunity.',
        Benefits11:'Helps fight psoriasis.',
        Usage:'Usage',
        Usage1:'Wash your face and body daily',
        Ingredients:'Ingredients',
        Ingredients1:'Tea Tree Oil Extract',
        Ingredients2:'Chloroxylenol',
        Weight:'Weight',
        Weight1:'500 g',
        dicQuantity:'Quantity',
        dicQuantity1:'100 grams',
  
      },
  
  
  
      {
        id: 127,
        title: "Vitamin C Serum Cream ",
        price: 1200.0,
        img: require("./assets/newgreen  Web Products 1500 x1200px-05/vitaminserum.jpg"),
        quantity: 1,
        ml: '15 ML',
        dis:'Enhance your skin care routine with our newly launched Vitamin C Serum Cream.',
        SKU:'SKU',
        SKU1:'GR037HB-1', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'pigmentation serumskin brightening skin care skin lightening skin nourishing',
        Benefits:'Benefits',
        Benefits1:'Works on discoloration and uneven skin tone.',
        Benefits2:'Enhances the skin texture.',
        Benefits3:'Leaves the skin glowing .',
        Benefits4:'Thin & nonsticky texture.',
        Benefits5:'Lightweight to use .',
        Benefits6:'Works on scars and marks.',
      
       
        Weight:'Weight',
        Weight1:'30 g',
        dicQuantity:'Quantity',
        dicQuantity1:'15  ML',
  
      },
  
  
      {
        id: 128,
        title: "Rosey - Lip and Cheek Tint ",
        price: 1600.0,
        img: require("./assets/newgreen  Web Products 1500 x1200px-05/Roseylip.jpg"),
        quantity: 1,
        ml: '15 ML',
        dis:'Enhance your skin care routine with our newly launched Rosey Lip & Cheek tint.',
        SKU:'SKU',
        SKU1:'GR037HB-1-1', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'pigmentation serum skin brightening skin care skin lightening skin nourishing',
        Howtouse:'How to use',
        Howtouse1:'Take one or two drops, as per required on your fingertips and apply on the lips, and cheeks.',
      
       
        Weight:'Weight',
        Weight1:'30 g ',
        dicQuantity:'Quantity',
        dicQuantity1:'15  ML',
  
      },
  
  
  
  
  
      // {
      //   id: 129,
      //   title: "Saffron – Secret to your best self ",
      //   price: 900.0,
      //   img: require("./assets/newgreen  Web Products 1500 x1200px-05/saffron.jpg"),
      //   quantity: 1,
      //   gram: '1 gram ',
      //   dis:'Introducing our newly launched Saffron – Secret to your best self .',
      //   SKU:'SKU',
      //   SKU1:'GR029HB', 
      //   Category:'Health & Beauty',
      //   Tags:'Tags:',
      //   Tags1:'anti acneanti inflammationantibacterialeczema cureinflection curenatural soapoily skinorganic soappsoriasis reliefskin healthskin immunityskin nourishmenttea treetea tree soap',
      //   Benefits:'Benefits',
      //   Benefits1:'Powerful Antioxidant .',
      //   Benefits2:'Contains cancer fighting properties .',
      //   Benefits3:'Helps reduce PMS symptoms  .',
      //   Benefits4:'Helps reduce appetite and aids weight loss .',
      //   Benefits5:'Improves mood  .',
      //   Benefits6:'Treats depressive symptoms .',
      
      //   Benefits7:'Improves skin tone  .',
      //   Benefits8:'Helps reduces access sun tan  .',
      //   Benefits9:'Provides a natural radiance to the skin  .',
      //   Benefits10:'Smoothens skin texture   .',
      //   Benefits11:'Helps treat hyperpigmentation, acne, acne scars .',
       
      //   Weight:'Weight',
      //   Weight1:'1 g',
      //   dicQuantity:'Quantity',
      //   dicQuantity1:'1 grams',
  
      // },
  
      {
        id: 130,
        title: "Pure Saffron ",
        price: 900.0,
        img: require("./assets/newgreen  Web Products 1500 x1200px-05/saffron.jpg"),
        quantity: 1,
        ml: '15 ML ',
        dis:'Introducing our newly launched Saffron – Secret to your best self .',
        SKU:'SKU',
        SKU1:'GR037HB-1-1-1', 
        Category:'Health & Beauty',
        Tags:'Tags:',
        Tags1:'pigmentation serumskin brightening skin careskin lightening skin nourishing',
        Benefits:'Benefits',
        Benefits1:'Powerful Antioxidant .',
        Benefits2:'Contains cancer fighting properties .',
        Benefits3:'Helps reduce PMS symptoms  .',
        Benefits4:'Helps reduce appetite and aids weight loss .',
        Benefits5:'Improves mood  .',
        Benefits6:'Treats depressive symptoms .',
      
        Benefits7:'Improves skin tone  .',
        Benefits8:'Helps reduces access sun tan  .',
        Benefits9:'Provides a natural radiance to the skin  .',
        Benefits10:'Smoothens skin texture   .',
        Benefits11:'Helps treat hyperpigmentation, acne, acne scars .',
       
        Weight:'Weight',
        Weight1:'1 g',
        dicQuantity:'Quantity',
        dicQuantity1:'15 ML',
  
      },

      // 
      {
          id: 131,
          title: "Almond Oil",
          price: 1600.0,
          img: require("./assets/Natural Oil/almond-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Almond Oil is a good source to give nourishment to the hair, helps in hair growth. Almond oil is a good moisturizer for skin and body. Also good for anti Aging.',
          SKU: 'SKU',
          SKU1: 'GR001OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' almond oil, constipation cure, hair nourishment, hair repair, natural oil, skin lightening, skin nourishment, skin whitening, vitamin E',
          Benefits: 'Benefits',
          Benefits1: 'Rich in vitamin E which helps in whitening of skin.',
          Benefits2: 'Good moisturizer for skin.',
          Benefits3: 'Gives nourishment to damaged hairs.',
          Benefits4: 'Helps in relieving constipation by taking once in a day with milk. ',
          Usage: 'Usage',
          Usage1: 'Apply on your hair on alternate days for good nourishment of the hair.',
          Usage2: 'Apply on face and body after wash and also at night before sleep.',
          Weight: ' Weight',
          Weight1: '210 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 132,
          title: "Almond Oil",
          price: 2600.0,
          img: require("./assets/Natural Oil/almond-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '200 ML',
      
          dis: 'Green Roots Almond Oil is a good source to give nourishment to the hair, helps in hair growth. Almond oil is a good moisturizer for skin and body. Also good for anti Aging.',
          SKU: 'SKU',
          SKU1: 'GR001OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' almond oil, constipation cure, hair nourishment, hair repair, natural oil, skin lightening, skin nourishment, skin whitening, vitamin E',
          Benefits: 'Benefits',
          Benefits1: 'Rich in vitamin E which helps in whitening of skin.',
          Benefits2: 'Good moisturizer for skin.',
          Benefits3: 'Gives nourishment to damaged hairs.',
          Benefits4: 'Helps in relieving constipation by taking once in a day with milk. ',
          Usage: 'Usage',
          Usage1: 'Apply on your hair on alternate days for good nourishment of the hair.',
          Usage2: 'Apply on face and body after wash and also at night before sleep.',
          Weight: ' Weight',
          Weight1: '210 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '200 mL',
        },
        {
          id: 133,
          title: "Amla Oil (Indian Gooseberry)",
          price: 650.0,
          img: require("./assets/Natural Oil/amla-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Amla Oil (Indian gooseberry oil) is to make hair soft and shiny prevents hair loss gives volume to Hair. Is good to relax your mind by massaging on hair scalp',
          SKU: 'SKU',
          SKU1: 'GR003OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'amla oil, hair growth, hair nourishment, hair repair, indian gooseberry, natural oil, stress reliever',
          Benefits: 'Benefits',
          Benefits1: 'Makes hair soft.',
          Benefits2: 'Helps in growth of hair.',
          Benefits3: 'Used to relax mind when massaged in the hair',
          Usage: 'Usage',
          Usage1: 'Apply on the hair at night wash it in morning.',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 134,
          title: "Anti Lice Oil",
          price: 1500.0,
          img: require("./assets/Natural Oil/anti-lice-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Anti Lice Hair Oil helps you get rid of lice and dandruff. It also makes your hair strong and prevents hair loss.',
          SKU: 'SKU',
          SKU1: 'GR005OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' anti lice, anti nits, natural oil, scalp protection',
          Benefits: 'Benefits',
          Benefits1: 'Removes lice',
          Benefits2: 'Removes nits',
          Benefits3: 'Finishes the development of the eggs which transform into lice and nits in the scalp.',
          Benefits4: 'Prevents lice and nits from being initially born in the hair.',
          Usage: 'Usage',
          Usage1: 'Use Green Roots Anti Lice Oil thrice a week and let it be in your hairs for at least two hours.',
          Ingredients:'Ingredients',
          Ingredients1:'Neem Oil (Margosa Leaves Oil)',
          Ingredients2:'Arugula Oil',
          Ingredients3:'Eucalyptus Oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 135,
          title: "Argan Oil",
          price: 7000.0,
          img: require("./assets/Natural Oil/argan-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Argan Oil is rich in Vitamin E, which helps in Anti-Aging and also used as a hair serum regrowth that makes hair shiny, smooth and strong.',
          SKU: 'SKU',
          SKU1: 'GR006OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti ageing, argan oil, hair nourishment, hair repair, hair strengthening, natural oil, skin care',
          Benefits: 'Benefits',
          Benefits1: 'Makes hair long and thick.',
          Benefits2: 'Protects from sun damage.',
          Benefits3: 'Promotes healthy skin.',
          Benefits4: 'Anti-ageing for skin. ',
          Usage: 'Usage',
          Usage1: 'Apply on the skin massage a little at night and wash in the morning.',
          Usage2: 'Apply on the hair at night wash in the morning.',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 136,
          title: "Castor Oil",
          price: 400.0,
          img: require("./assets/Natural Oil/castor-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Castor Oil helps in hair regrowth. It also works for low density hair by increasing hair volume. Castor Oil relieves constipation.',
          SKU: 'SKU',
          SKU1: 'GR009OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'castor oil, constipation remedy, hair growth, hair nourishment, natural oil, pure oil',
          Benefits: 'Benefits',
          Benefits1: 'Helps in growth of hair.',
          Benefits2: 'Helps in the growth of lashes and eyebrows hair.',
          Benefits3: 'Helps in relieving constipation.',
          Usage: 'Usage',
          Usage1: 'Apply on hair at night wash in morning.',
          Usage2: 'Apply on eyelashes and eyebrows at night before sleep.',
          Usage3: 'Take 1 teaspoon mix with a glass of warm milk before sleep.',
          Weight: ' Weight',
          Weight1: '210 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 137,
          title: "Castor Oil",
          price: 600.0,
          img: require("./assets/Natural Oil/castor-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '200 ML',
      
          dis: 'Green Roots Castor Oil helps in hair regrowth. It also works for low density hair by increasing hair volume. Castor Oil relieves constipation.',
          SKU: 'SKU',
          SKU1: 'GR009OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'castor oil, constipation remedy, hair growth, hair nourishment, natural oil, pure oil',
          Benefits: 'Benefits',
          Benefits1: 'Helps in growth of hair.',
          Benefits2: 'Helps in the growth of lashes and eyebrows hair.',
          Benefits3: 'Helps in relieving constipation.',
          Usage: 'Usage',
          Usage1: 'Apply on hair at night wash in morning.',
          Usage2: 'Apply on eyelashes and eyebrows at night before sleep.',
          Usage3: 'Take 1 teaspoon mix with a glass of warm milk before sleep.',
          Weight: ' Weight',
          Weight1: '210 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '200 mL',
        },
        {
          id: 138,
          title: "Coconut Oil",
          price: 450.0,
          img: require("./assets/Natural Oil/coconut-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Coconut Oil offers natural nourishment to your hair. Is a good Moisturizer to skin and body.',
          SKU: 'SKU',
          SKU1: 'GR012OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'coconut oil, hair fall, hair nourishment, hair repair, hair strength, natural oil, pure oil, skin care, skin moisturizing, skin nourishment',
          Benefits: 'Benefits',
          Benefits1: 'Nourishes your hairs.',
          Benefits2: 'Makes your hair shiny and smooth.',
          Benefits3: 'It is a good moisturizer for the skin and body.',
          Benefits4: 'Beneficial for treating hair fall. ',
          Benefits5: 'Usage Apply on hair for treating hair fall',
          Benefits6: 'Apply on skin and body at night to moisturize.',
          Usage: 'Usage',
          Usage1: 'Apply on hair for treating hair fall',
          Usage2: 'Apply on skin and body at night to moisturize.',
          Weight: ' Weight',
          Weight1: '210 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 139,
          title: "Coconut Oil",
          price: 750.0,
          img: require("./assets/Natural Oil/coconut-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '200 ML',
      
          dis: 'Green Roots Coconut Oil offers natural nourishment to your hair. Is a good Moisturizer to skin and body.',
          SKU: 'SKU',
          SKU1: 'GR012OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'coconut oil, hair fall, hair nourishment, hair repair, hair strength, natural oil, pure oil, skin care, skin moisturizing, skin nourishment',
          Benefits: 'Benefits',
          Benefits1: 'Nourishes your hairs.',
          Benefits2: 'Makes your hair shiny and smooth.',
          Benefits3: 'It is a good moisturizer for the skin and body.',
          Benefits4: 'Beneficial for treating hair fall. ',
          Benefits5: 'Usage Apply on hair for treating hair fall',
          Benefits6: 'Apply on skin and body at night to moisturize.',
          Usage: 'Usage',
          Usage1: 'Apply on hair for treating hair fall',
          Usage2: 'Apply on skin and body at night to moisturize.',
          Weight: ' Weight',
          Weight1: '210 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '200 mL',
        },
        {
          id: 140,
          title: "Dry Apricot Oil",
          price: 1500.0,
          img: require("./assets/Natural Oil/dry-apricot-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          
          dis: 'Green Roots Dry Apricot Oil is for rejuvenating skin. The Oil can also be used for making hair long and strong. Dry Apricot Oil is very effective when used on the skin for removing dryness.',
          SKU: 'SKU',
          SKU1: 'GR013OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'apricot oilconstipation curedry apricoteven skin tonehair growthhealthy bonesnatural oilpure oilskin nourishment',
          Benefits: 'Benefits',
          Benefits1: 'Improves skin tone.',
          Benefits2: 'Lowers appearance of fine lines.',
          Benefits3: 'Enhance bones health.',
          Benefits4: 'Treats constipation. ',
          Benefits5:'Helps in the growth of hair.',
          Usage: 'Usage',
          Usage1: 'Apply on the hair at night before sleep wash in morning for best results.',
          Usage2: 'Apply a little amount of Apricot oil on skin at night wash your face in morning.',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 141,
          title: "Tumba Oil",
          price: 2000.0,
          img: require("./assets/Natural Oil/tumba-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Tumba Oil is effective in controlling diabetes, good for nerve pain in diabetic patients, works for high blood pressure and relieving constipation.',
          SKU: 'SKU',
          SKU1: 'GR060OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'constipation cure, diabetes control, high blood pressure, natural oil, nerve pain relief, pain relief, pure oil, tumba oil',
          Benefits: 'Benefits',
          Benefits1: 'Helps control diabetes',
          Benefits2: 'Good for nerve pain in diabetic patients',
          Benefits3: 'Works for high blood pressure',
          Benefits4: 'Works for constipation',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 142,
          title: "Taramira Oil",
          price: 500.0,
          img: require("./assets/Natural Oil/taramira-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Taramira is used for hair loss, baldness of the hair..',
          SKU: 'SKU',
          SKU1: 'GR058OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti dandruff, anti lice, baldness protection, natural oil, pure oil, taramira oil',
          Benefits: 'Benefits',
          Benefits1: 'Reduces dandruff and lice',
          Benefits2: 'Reduces stress',
          Benefits3: 'Prevents from Baldness',
          Usage: 'Usage',
          Usage1: 'Apply on to hair scalp at night or 2 hours before washing hair.',
          Ingredients:'Ingredients',
          Ingredients1:'Bitter Mustard',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 143,
          title: "Shikakai Oil (Hair Fruit)",
          price: 800.0,
          img: require("./assets/Natural Oil/shikakai-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: '(Acacia Concinna) Green Roots Shikakai Oil is used to make hair shiny, strong and long. Shikakai Oil is also very effective for hair growth.',
          SKU: 'SKU',
          SKU1: 'GR056OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'hair care, hair fruit, hair nourishment, hair strengthening, natural oil, premature hair greying, pure oi, lshikakai oil',
          Benefits: 'Benefits',
          Benefits1: 'Moisturizes the hair',
          Benefits2: 'Prevents split ends',
          Benefits3: 'Reduces hair fall',
          Benefits4: 'Makes hair soft and tangled free',
          Benefits5: 'Prevents hair from greying',
          Usage: 'Usage',
          Usage1: 'Apply on the hair roots and all over the hairs at night or 2 hours before washing hairs.',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 144,
          title: "Sesame Oil",
          price: 550.0,
          img: require("./assets/Natural Oil/sesame-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Sesame Oil 100% pure, lightly filtered, retaining sesame’s aroma, rich flavor and nutrients.',
          SKU: 'SKU',
          SKU1: 'GR055OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'cooking oil, hair growth, hair strength, natural oil, pain relief, pure oil, sesame oil',
          Benefits: 'Benefits',
          Benefits1: 'Makes hair strong and helps in growth.',
          Benefits2: 'Works as a pain reliever oil.',
          Benefits3: 'Used for cooking.',
          Usage: 'Usage',
          Usage1: 'Apply on hair roots at night before sleep.',
          Usage2: 'Massage onto your body to relieve in joint pains',
          Weight: ' Weight',
          Weight1: '215 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 145,
          title: "Sesame Oil",
          price: 900.0,
          img: require("./assets/Natural Oil/sesame-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '200 ML',
      
          dis: 'Green Roots Sesame Oil 100% pure, lightly filtered, retaining sesame’s aroma, rich flavor and nutrients.',
          SKU: 'SKU',
          SKU1: 'GR055OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'cooking oil, hair growth, hair strength, natural oil, pain relief, pure oil, sesame oil',
          Benefits: 'Benefits',
          Benefits1: 'Makes hair strong and helps in growth.',
          Benefits2: 'Works as a pain reliever oil.',
          Benefits3: 'Used for cooking.',
          Usage: 'Usage',
          Usage1: 'Apply on hair roots at night before sleep.',
          Usage2: 'Massage onto your body to relieve in joint pains',
          Weight: ' Weight',
          Weight1: '215 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '200 mL',
        },
        {
          id: 146,
          title: "Relaxing Massage Oil",
          price: 2000.0,
          img: require("./assets/Natural Oil/relaxing-massage-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Relaxing Massage Oil is the mixture of pure Olive Oil and essential Lavender Oil, which is used as the best healing therapy to relax your body and mind. It simply relieving the stress level of body by calming down the nerves by stabilizing the blood circulation.',
          SKU: 'SKU',
          SKU1: 'GR050OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' massage oil, muscle relaxant, natural oil, nerve relaxant, pain relief, pain reliever, pure oil, relaxant, relaxing oil',
          Benefits: 'Benefits',
          Benefits1: 'Beneficial in relaxing your body.',
          Benefits2: 'Beneficial in relaxing your mind.',
          Benefits3: 'Beneficial in relieving body pain.',
          Usage: 'Usage',
          Usage1: 'Massage on body for 10 to 15 minutes at night.',
          Usage2: 'Can be applied on body and head for 10 to 15 minutes.',
          Ingredients:'Ingredients',
          Ingredients1:'Olive oil',
          Ingredients2:'Lavender oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 147,
          title: "Regrowth Hair Oil",
          price: 2200.0,
          img: require("./assets/Natural Oil/regrowth-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Regrowth Hair Oil.',
          SKU: 'SKU',
          SKU1: 'GR049OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' anti bacterial, anti fungal, anti hair fall, hair growth, hair lengthening, hair repair, hair strengthening, natural oil, pure oil, regrowth oil, scalp nourishment, silky hair',
          Benefits: 'Benefits',
          Benefits1: 'Makes the hair long',
          Benefits2: 'Makes the hair thick',
          Benefits3: 'Increases the volume of the hair',
          Benefits4: 'Increases elasticity in the hair',
          Benefits5: 'Prevents hair falling',
          Benefits6: 'Boosts new hair growth',
          Benefits7: 'Keeps the scalp nourished & hydrated',
          Benefits8: 'Keeps the hair soft and silky',
          Benefits9: 'Anti fungal and anti bacterial properties.',
          Usage: 'Usage',
          Usage1: 'Apply onto the hair scalp leave it overnight or you can also apply two (02) hours before washing hair. Same can be done two or three times in a week for visible results.',
          Ingredients:'Ingredients',
          Ingredients1:'Roghane asthma',
          Ingredients2:'Sahil e nabatati',
          Ingredients3:'Pumpkin seed oil',
          Ingredients4:'Barhami booti',
          Ingredients5:'Bheri ke patey',
          Ingredients6:'Nagar motha',
          Ingredients7:'Sesame oil coconut oil',
          Ingredients8:'Balchar',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 148,
          title: "Premature Hair Greying Oil",
          price: 1700.0,
          img: require("./assets/Natural Oil/premature-hair-grey-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Premature Greying Hair Oil is the mixture of staff Tree Oil, bitter Apple Oil and Ginkgo Biloba Oil, which helps in the treatment of premature greying of hairs. It also nourishes the scalp.',
          SKU: 'SKU',
          SKU1: 'GR047OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti hair greying, hair nourishment, natural oil, premature hair greying, pure oil',
          Benefits: 'Benefits',
          Benefits1: 'Beneficial for reducing the growth of premature grey hair',
          Benefits2: 'Nourishes the hair',
          Usage: 'Usage',
          Usage1: 'Green Roots Premature Greying Hair Oil can be used thrice a week.',
          Ingredients:'Ingredients',
          Ingredients1:'Staff tree oil',
          Ingredients2:'Bitter apple oil',
          Ingredients3:'Ginkgo Biloba oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 149,
          title: "Pumpkin Seed Oil",
          price: 1700.0,
          img: require("./assets/Natural Oil/pumpkin-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Pumpkin seeds Oil is used for hair fall and hair growth. Used as an anti-ageing oil. Improves better sleep',
          SKU: 'SKU',
          SKU1: 'GR048OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti hair fall, hair growth, migraine relief, natural oil, pain reliever, peaceful sleep, pumpkin seed oil, pure oil, skin nourishment',
          Benefits: 'Benefits',
          Benefits1: 'Improves skin tone.',
          Benefits2: 'Treats hair loss.',
          Benefits3: 'Helps in hair growth.',
          Benefits4: 'Improves better sleep.',
          Benefits5: 'Helps in reducing migraine.',
          Usage: 'Usage',
          Usage1: 'Apply little amount of pumpkin seeds oil on your face at night before sleeping',
          Usage2: 'Massage pumpkin seeds oil on forehead to help with migraine..',
          Usage3:'Apply onto your hair roots at night before sleep and wash in the morning.',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 150,
          title: "Flax Seed Oil",
          price: 950.0,
          img: require("./assets/Natural Oil/flax-seed-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: '(Alsi Oil) Green Roots Flaxseed Oil (Alsi Seed Oil) is a great source of Omega 3 Fatty Acids, which are vital to a person’s physical and mental health. Used for skin pigmentation. Helps in regrowth of hair and makes hair healthy.',
          SKU: 'SKU',
          SKU1: 'GR017OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti ageing, flax seed, hair growth, natural oil, nerve health, omega 3, pure oil',
          Benefits: 'Benefits',
          Benefits1: 'Rich in omega 3 which helps in physical and mental health.',
          Benefits2: 'Used to make volume to the hairs.',
          Benefits3: 'Good for anti-ageing for skin.',
          Usage: 'Usage',
          Usage1: 'Apply on the hair at night wash in the morning.',
          Usage2: 'Apply on the skin at night wash in the morning.',
          Usage3: 'Take 1 teaspoon daily for healthy skin, hair and body.',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 151,
          title: "Organic Virgin Coconut Oil",
          price: 2300.0,
          img: require("./assets/Natural Oil/Organic-Virgin-Coconut-Oil.jpeg"),
          quantity: 1,
          ml: '260 ML',
      
          dis: 'Green Roots Organic Virgin Coconut Oil is used for healthy cooking, keeps your weight maintained, and makes hair shiny and strong. It also helps in getting skin allergy reduced. ',
          SKU: 'SKU',
          SKU1: 'GR041OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'alzheimers disease, cholesterol control, coconut oil, hair care, heart health, hunger control, natural oil, omega 3, organic virgin coconut oil, pure oil, seizure control, skin care, teeth care',
          Benefits: 'Benefits',
          Benefits1: 'Contains healthy fatty acids',
          Benefits2: 'Boosts heart health',
          Benefits3: 'Encourages fat burning',
          Benefits4: 'Reduces hunger',
          Benefits5: 'Reduces seizures',
          Benefits6: 'Raises HDL (good) cholesterol',
          Benefits7: 'Protects your skin, hair, and teeth',
          Benefits8: 'Boosts brain function in Alzheimer’s disease',
          Benefits9: 'Helps reduce harmful abdominal fat. ',
          Usage: 'Usage',
          Usage1: 'Used as a cooking oil, add 1 teaspoon in your tea, coffee or water drink in morning to maintain your weight.',
          Usage2: 'Apply on the allergic area of skin helps heal allergy and itching.',
          Weight: ' Weight',
          Weight1: '480 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '260 mL',
        },
        {
          id: 152,
          title: "Pain Killer Massage Oil",
          price: 2000.0,
          img: require("./assets/Natural Oil/pain-killer-massage-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Pain Killer Massage Oil is the mixture of pure Olive and Lemon Grass Oil, which is the best remedy to treat muscle and joints pain. It also relaxes the body and quickly heals the pain.',
          SKU: 'SKU',
          SKU1: 'GR042OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'joint pain relief, massage oil, muscle relaxant, natural oil, pain killer, pain relief, pure oil',
          Benefits: 'Benefits',
          Benefits1: 'Helps in relieving joints and muscles pain.',
          Benefits2: 'It relaxes the body',
          Benefits3: 'Quickly heals the pain.',
          Usage: 'Usage',
          Usage1: 'Massage on body at night before sleep.',
          Ingredients:'Ingredients',
          Ingredients1:'Olive Oil',
          Ingredients2:'Lemongrass Oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 153,
          title: "Onion Oil",
          price: 3600.0,
          img: require("./assets/Natural Oil/onion-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Onion oil is rich in sulfur which prevents breakage, split ends and thinning of hair. Onion oil will also ensure that you have a shiny and healthy hair. Onion oil nourishes your scalp and can be used as a conditioner, before your shampoo. It acts as a natural conditioner that prevents dryness and controls frizz. It’s highly beneficial for premature greying of the hair, as well as prevents certain types of cancers and is beneficial for diabetes, allergies, brain health and other health related problems.',
          SKU: 'SKU',
          SKU1: 'GR039OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'alopecia cure, baldness remedy, blood circulation, blood sugar control, brain health, eye health, hair nourishment, healthy breathing, natural oil, onion oil, oral health, premature hair greyin, gpure oil, skin nourishment, vitamin a, vitamin c, vitamin E',
          Benefits: 'Benefits',
          Benefits1: 'Prevents and treats baldness',
          Benefits2: 'Prevents breakage, split ends and thinning of hair & premature greying',
          Benefits3: 'Good for oily and acne prone skin',
          Benefits4: 'Rich in vitamin A, C and E',
          Benefits5: 'Improves blood circulation',
          Benefits6: 'Fights dandruff',
          Benefits7: 'Nourishes hair follicles',
          Benefits8: 'Prevents neck and head cancer',
          Benefits9: 'Promotes respiratory health',
          Benefits10: 'Good for oral health',
          Benefits11: 'Enhances brain health',
          Benefits12: 'Lowers blood sugar levels',
          Benefits13: 'Enhances eye health',
          Benefits14: 'Prevents inflammation and allergies.',
          Benefits15: 'Beneficial for alopecia & asthma',
          Usage: 'Usage',
          Usage1: 'You can warm onion oil mixed with coconut oil and massage it all over your scalp and length. Keep it overnight and then rinse it well with organic shampoo.',
          Usage2:'For faster results, onion hair oil should be used twice daily (once in the morning and once in the evening) for several weeks',
          Usage3:'It’s great for flavoring scrambled eggs, cooked meats, salad dressings',
          Usage4:'Applying onion juice to the hair and scalp could increase blood supply to hair follicles, which in turn improves hair growth.',
          Usage5:'Applying gel containing onion extract to the skin, alone or with other ingredients, for at least 10 weeks improves the appearance of scars.',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 154,
          title: "Olive Oil",
          price: 750.0,
          img: require("./assets/Natural Oil/olive-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Olive Oil can be used for hair, body and face and also by taking Olive Oil constipation can be relieved. Olive Oil works as one of the best massage oil, which helps in relieving body pain and stress.',
          SKU: 'SKU',
          SKU1: 'GR038OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'constipation cure, massage oil, natural oil, olive oil, pure oil, skin care, skin nourishment',
          Benefits: 'Benefits',
          Benefits1: 'Is a great natural moisturizer for body and skin.',
          Benefits2: 'Helps relieve constipation.',
          Benefits3: 'It works as a best massage oil.',
          Usage: 'Usage',
          Usage1: 'Mix few drops of lemongrass essential oil with pure olive oil and massage on body will help reduce muscles and joints pain.',
          Usage2: 'Take 1 teaspoon of olive oil with warm milk at night helps you relieve in constipation.',
          Usage3: 'Apply on hair to nourish hair.',
          Weight: ' Weight',
          Weight1: '215 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 155,
          title: "Olive Oil",
          price: 1250.0,
          img: require("./assets/Natural Oil/olive-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '200 ML',
      
          dis: 'Green Roots Olive Oil can be used for hair, body and face and also by taking Olive Oil constipation can be relieved. Olive Oil works as one of the best massage oil, which helps in relieving body pain and stress.',
          SKU: 'SKU',
          SKU1: 'GR038OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'constipation cure, massage oil, natural oil, olive oil, pure oil, skin care, skin nourishment',
          Benefits: 'Benefits',
          Benefits1: 'Is a great natural moisturizer for body and skin.',
          Benefits2: 'Helps relieve constipation.',
          Benefits3: 'It works as a best massage oil.',
          Usage: 'Usage',
          Usage1: 'Mix few drops of lemongrass essential oil with pure olive oil and massage on body will help reduce muscles and joints pain.',
          Usage2: 'Take 1 teaspoon of olive oil with warm milk at night helps you relieve in constipation.',
          Usage3: 'Apply on hair to nourish hair.',
          Weight: ' Weight',
          Weight1: '215 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '200 mL',
        },
        {
          id: 156,
          title: "Hair Growth Oil",
          price: 1400.0,
          img: require("./assets/Natural Oil/hair-growth-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '250 ML',
      
          dis: 'Green Roots Hair Growth Oil is the perfect mixture of few of the best treatment oils that helps in getting the hair growth maintained. It also makes your hair long, healthy and strong. Hair Growth Oil also makes hair silky, shiny and smooth while at the same time prevents hair loss.',
          SKU: 'SKU',
          SKU1: 'GR021OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' anti dandruff, anti hair fall, anti hair loss, hair growth, hair growth oil, hair nourishment, hair repair, hair strength, natural oil, pure oil, scalp conditioner, scalp nourishment',
          Benefits: 'Benefits',
          Benefits1: 'Stimulates new hair growth and makes hair long & healthy.',
          Benefits2: 'Stops hair breakage & hair fall and restores hair from root to tip.',
          Benefits3: 'Makes hair shiny & smooth.',
          Benefits4: 'Prevents premature gray hair and reduces baldness.',
          Benefits5: 'Complete scalp treatment',
          Benefits6: 'Eliminates dandruff, flaky scalp & dryness.',
          Usage: 'Usage',
          Usage1: 'Green Roots Hair Growth Oil should be used at night at least thrice a week.',
          Ingredients:'Ingredients',
          Ingredients1:'Pumpkin Seed Oil',
          Ingredients2:'Jasmine Oil',
          Ingredients3:'Coconut Oil',
          Weight: ' Weight',
          Weight1: '250 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '250 mL',
        },
        {
          id: 157,
          title: "Kalonji (Nigella Sativa) Oil",
          price: 1200.0,
          img: require("./assets/Natural Oil/kalonji-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          
          dis: 'Green Roots Kalonji Oil is the solution to many hair, skin and body problems. As it is said by the Prophet Muhammad (peace be upon him) that ‘there is healing in black cumin (Kalonji) for all diseases except death’.(Sahih Bukhari).',
          SKU: 'SKU',
          SKU1: 'GR026OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti hair fall, cancer prevention, ear pain relief, fever relief, hair growth, immune booster, kalonji, natural oil, nigella sativa, pure oil',
          Benefits: 'Benefits',
          Benefits1: 'Beneficial for hair growth.',
          Benefits2: 'Beneficial for hair fall.',
          Benefits3: 'Beneficial for the pain in Ears.',
          Benefits4: 'Beneficial for controlling fever',
          Benefits5: 'Cancer preventing',
          Benefits6: 'Boosters immune system',
          Usage: 'Usage',
          Usage1: 'Mix Kalonji oil with Olive oil and Castor oil for hair growth, Apply at night before sleep massage gently on scalp wash in morning..',
          Usage2: 'Take 1 tablespoon of Kalonji oil add 1,2 drops of lemon juice drink once in a day it helps to control fever and high body temperature.',
          Usage3:'Put few 2,3 drops of Kalonji oil into your ear to relieve the pain in ears.',
          Weight: ' Weight',
          Weight1: '210 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 158,
          title: "Kalonji (Nigella Sativa) Oil",
          price: 1900.0,
          img: require("./assets/Natural Oil//kalonji-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '200 ML',
      
          dis: 'Green Roots Kalonji Oil is the solution to many hair, skin and body problems. As it is said by the Prophet Muhammad (peace be upon him) that ‘there is healing in black cumin (Kalonji) for all diseases except death’.(Sahih Bukhari).',
          SKU: 'SKU',
          SKU1: 'GR026OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti hair fall, cancer prevention, ear pain relief, fever relief, hair growth, immune booster, kalonji, natural oil, nigella sativa, pure oil',
          Benefits: 'Benefits',
          Benefits1: 'Beneficial for hair growth.',
          Benefits2: 'Beneficial for hair fall.',
          Benefits3: 'Beneficial for the pain in Ears.',
          Benefits4: 'Beneficial for controlling fever',
          Benefits5: 'Cancer preventing',
          Benefits6: 'Boosters immune system',
          Usage: 'Usage',
          Usage1: 'Mix Kalonji oil with Olive oil and Castor oil for hair growth, Apply at night before sleep massage gently on scalp wash in morning..',
          Usage2: 'Take 1 tablespoon of Kalonji oil add 1,2 drops of lemon juice drink once in a day it helps to control fever and high body temperature.',
          Usage3:'Put few 2,3 drops of Kalonji oil into your ear to relieve the pain in ears.',
          Weight: ' Weight',
          Weight1: '210 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '200 mL',
        },
        {
          id: 159,
          title: "Neem (Margosa) Oil",
          price: 1000.0,
          img: require("./assets/Natural Oil/neem-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Neem oil (Margosa Oil) is perfect cure for healing fungus present in skin and hair. It also helps in preventing hair loss. It can also be used to heal the wounds.',
          SKU: 'SKU',
          SKU1: 'GR034OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti dandruff, anti fungal, eczema cure, margosa, natural oil, neem, pure oil, scar remover',
          Benefits: 'Benefits',
          Benefits1: 'Anti-fungal properties.',
          Benefits2: 'Beneficial in removing acne scars.',
          Benefits3: 'Heals in Eczema.',
          Benefits4: 'Treats hair loss.',
          Benefits5: 'Treats dandruff.',
          Usage: 'Usage',
          Usage1: 'Put Neem oil on hair scalp 2 hours before washing hair helps treat hair loss and dandruff.',
          Usage2: 'Apply little amount of Neem oil on a cotton pad apply on acne scar will help remove scars.',
          Usage3: 'Applied on the part of body and hair where you have fungus or eczema to heal.',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 160,
          title: "Mustard Oil",
          price: 350.0,
          img: require("./assets/Natural Oil/mustard-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Mustard Oil is the purest extracts of finest quality mustard seeds with variety of uses beneficial for hair and skin care. Mustard Oil also enhances the taste of food when used in cooking.',
          SKU: 'SKU',
          SKU1: 'GR033OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' anti inflammation, hair nourishment, healthy heart, mustard, natural oil, pain relief, pure oil, skin nourishment',
          Benefits: 'Benefits',
          Benefits1: 'Supports heart health.',
          Benefits2: 'Alleviate pain.',
          Benefits3: 'Promotes skin and hair health.',
          Benefits4: 'Helps in treating cold symptoms.',
          Benefits5: 'Reduces inflammation. ',
          Usage: 'Usage',
          Usage1: 'Applying Mustard Oil on hair helps in growth of hair and it prevents hair loss as well.',
          Usage2: 'Applying Mustard Oil on skin helps in improving fine lines and wrinkles.',
          Usage3: 'Massaging Mustard Oil on body relieves pain and inflammation.',
          Usage4: 'Cooking with Mustard Oil helps reduces bad cholesterol level and supports in getting the overall health of heart.',
          Weight: ' Weight',
          Weight1: '210 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 161,
          title: "Mustard Oil",
          price: 500.0,
          img: require("./assets/Natural Oil/mustard-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '200 ML',
      
          dis: 'Green Roots Mustard Oil is the purest extracts of finest quality mustard seeds with variety of uses beneficial for hair and skin care. Mustard Oil also enhances the taste of food when used in cooking.',
          SKU: 'SKU',
          SKU1: 'GR033OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' anti inflammation, hair nourishment, healthy heart, mustard, natural oil, pain relief, pure oil, skin nourishment',
          Benefits: 'Benefits',
          Benefits1: 'Supports heart health.',
          Benefits2: 'Alleviate pain.',
          Benefits3: 'Promotes skin and hair health.',
          Benefits4: 'Helps in treating cold symptoms.',
          Benefits5: 'Reduces inflammation. ',
          Usage: 'Usage',
          Usage1: 'Applying Mustard Oil on hair helps in growth of hair and it prevents hair loss as well.',
          Usage2: 'Applying Mustard Oil on skin helps in improving fine lines and wrinkles.',
          Usage3: 'Massaging Mustard Oil on body relieves pain and inflammation.',
          Usage4: 'Cooking with Mustard Oil helps reduces bad cholesterol level and supports in getting the overall health of heart.',
          Weight: ' Weight',
          Weight1: '210 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '200 mL',
        },
        {
          id: 162,
          title: "Jasmine Oil",
          price: 700.0,
          img: require("./assets/Natural Oil/jasmine-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          
          dis: 'The aroma of Green Roots Jasmine Oil is used to relax stress level and depression. Jasmine Oil can also be used as an antiseptic fluid. By using Jasmine Oil you can also improve hair strength by making them healthy while by using it on skin can reveal a better healthy and shiny skin surface',
          SKU: 'SKU',
          SKU1: 'GR023OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' anti allergy, antiseptic, eczema relief, hair nourishment, hair strengthening, jasmine, natural oil, pure oil, scar remover, skin nourishment',
          Benefits: 'Benefits',
          Benefits1: 'It is an antiseptic oil which helps in Allergy problems of skin.',
          Benefits2: 'Strengthens the roots of hair.',
          Benefits3: 'Provides moisture to the hair.',
          Benefits4: 'Lighten scars',
          Benefits5: 'Helps dry skin and Eczema',
          Usage: 'Usage',
          Usage1: 'Apply Jasmine oil directly to hair or mix with any other hair oil for hair growth.',
          Usage2:'Apply on the skin at night to lighten scars.',
          Usage3:'Inhale Jasmine oil to ease and relieve depression and stress.',
          Ingredients:'Ingredients',
          Ingredients1:'Jasmine Leaves Extract',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 163,
          title: "Malkangni Oil",
          price: 2300.0,
          img: require("./assets/Natural Oil/malkangni-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Malkangni Oil (Celastrus Oil) is best to cure with brain disorders, which highly enhances brain memory. Malkangni Oil also helps to treat with premature greying of hair and controls the hair color to keep it maintained and make it black. Helpful in body pain.',
          SKU: 'SKU',
          SKU1: 'GR031OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' arthritis relief, healing, malkangni, natural oil, paralysis relief, premature hair greying relief, pure oil, sciatica pain relief',
          Benefits: 'Benefits',
          Benefits1: 'Beneficial for sciatica pain.',
          Benefits2: 'Beneficial for paralysis.',
          Benefits3: 'Beneficial for arthritis.',
          Benefits4: 'Beneficial for blackening of the premature grey hairs.',
          Benefits5: 'Beneficial in healing wounds.',
          Usage: 'Usage',
          Usage1: 'Massaging of Malkangni oil on body gives relief in body pain..',
          Usage2: 'Apply Malkangni oil on hair scalp and all over hair for treating premature grey hair',
          Weight: ' Weight',
          Weight1: '	135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 164,
          title: "Miracle Oil",
          price: 1600.0,
          img: require("./assets/Natural Oil/miracle-oil-greenrootspk.com_Front.png"),
          quantity: 1,
          ml: '150 ML',
      
          dis: 'Green Roots Miracle Oil reduces all body pain and stiffness. It cures Arthritis (joint inflammation) and heals frozen joints. It also helps in relieving paralysis patients.',
          SKU: 'SKU',
          SKU1: 'GR032OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'cervical pain relief, frozen shoulder relief, joint pain relief, knee pain relief, miracle oil, muscle relaxant, muscular pain relief, natural oil, pain relief, paralysis relief, pure oil',
          Benefits: 'Benefits',
          Benefits1: 'Beneficial for knee pain.',
          Benefits2: 'Beneficial for joint pain.',
          Benefits3: 'Beneficial for cervical pain.',
          Benefits4: 'Beneficial for Frozen shoulder.',
          Benefits5: 'Beneficial for muscular pain.',
          Benefits6: 'Beneficial for all joints and bones in the body.',
          Benefits7: 'Beneficial for massage purpose in paralysis.',
          Usage: 'Usage',
          Usage1: 'Applying Green Roots Miracle Oil on the affected areas by lightly rubbing for 10 to 15 minutes at night before sleep.',
          Weight: ' Weight',
          Weight1: '170 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '150 mL',
        },
        {
          id: 165,
          title: "Neem & Mustard Oil",
          price: 1300.0,
          img: require("./assets/Natural Oil/neem-mustard-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
        
          dis: 'Green Roots Neem & Mustard Oil is very effective for hair fall and dandruff.',
          SKU: 'SKU',
          SKU1: 'GR063OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti dandruff, anti hair fall, mustard, natural oil, neem, neem mustard, pure oil',
          Benefits: 'Benefits',
          Benefits1: 'This oil is a mixture of neem and mustard oil, beneficial for hair fall and dandruff.',
          Usage: 'Usage',
          Usage1: 'Can be applied overnight and washed in the morning, or can be applied 2 hours before washing the hair/shower.',
          Ingredients:'Ingredients',
          Ingredients1:'Neem oil',
          Ingredients2:'Mustard oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 166,
          title: "Neem & Virgin Coconut Oil",
          price: 1400.0,
          img: require("./assets/Natural Oil/neem-virgin-coconut-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Neem & Virgin Coconut Oil is beneficial for hair loss and hair fall.',
          SKU: 'SKU',
          SKU1: 'GR064OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti hair fall, anti hair loss, hair nourishment, natural oil, neem neem virgin coconut, pure oil, scalp nourishment, virgin coconut',
          Benefits: 'Benefits',
          Benefits1: 'This oil is a mixture of neem and virgin coconut oil, beneficial for hair loss/hair fall. Boosts hair growth and moisturizes the scalp.',
          Usage: 'Usage',
          Usage1: 'Can be applied overnight and washed in the morning, or can be applied 2 hours before washing the hair/shower)',
          Ingredients:'Ingredients',
          Ingredients1:'Neem oil',
          Ingredients2:'Virgin coconut oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 167,
          title: "Argan Jojoba Oil",
          price: 4300.0,
          img: require("./assets/Natural Oil/argan-jojoba-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Argan-Jojoba Oil makes the hair thicker and stronger, repairs split ends and fights dandruff.',
          SKU: 'SKU',
          SKU1: 'GR065OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti acne, anti dandruff, argan, argan jojoba, even skin tone, hair nourishmen, thair repair, hair strengthening, jojoba, natural oil, pure oil, skin lightening, skin nourishment',
          Benefits: 'Benefits',
          Benefits1: 'Makes the hair thicker and stronger, repairs split ends and fights dandruff.',
          Benefits2: 'Skin benefits; regulates sebum, treated acne, smooths complexion, lightens the skin.',
          Usage: 'Usage',
          Usage1: '(For hair) Before showering, layer onto the dry hair, massage or comb in thoroughly, and leave in for 15-20 minutes, and then wash. It can also be used to apply on the ends to repairs and prevent split ends. Can also be used as a serum post hair wash.',
          Usage2:'(For skin) Can be applied directly to the skin on all parts of the body, particularly face, hands, and legs.',
          Ingredients:'Ingredients',
          Ingredients1:'Argan oil',
          Ingredients2:'Jojoba oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 168,
          title: "Paralysis Oil",
          price: 4500.0,
          img: require("./assets/Natural Oil/paralysis-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Paralysis Oil.',
          SKU: 'SKU',
          SKU1: 'GR067OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' natural oil, sparalysis cure, paralysis herbal remedy, paralysis natural cure, paralysis natural remedy, paralysis oil, pure oils',
          Benefits: 'Benefits',
          Benefits1: 'An oil specially made for people suffering from paralysis.',
          Ingredients:'Ingredients',
          Ingredients1:'Roghan Alsi (flaxseed oil)',
          Ingredients2:'Roghan Zaitoon (olive oil)',
          Ingredients3:'Sammulfar (red, yellow, black and white) (kushta sammulfar )',
          Usage: 'Usage',
          Usage1: 'Warm the oil to a lukewarm temperature and then use on the effected parts of the body.',
          Usage2:'The best use of this oil is to be applied while the patient is standing next to a wall (increases blood circulation)',
          Usage3:'Massage thoroughly with this oil everyday, two times for at least 30 minutes. Massage with a heavy pressure so the hands and body get warm in the process.',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 169,
          title: "Anti Dandruff & Hair Fall Oil",
          price: 1250.0,
          img: require("./assets/Natural Oil/anti-dandruff-hair-fall-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Anti Dandruff and Hair Fall oil is to prevent hair fall and dandruff completely within one month. Also makes hair roots strong.',
          SKU: 'SKU',
          SKU1: 'GR004OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' anti dandruff, anti hair fall, anti hair loss, dandruff prevention, hair care, hair nourishment, natural oil, pure oil, scalp nourishment',
          Benefits: 'Benefits',
          Benefits1: 'Helps finish existing dandruff in the hair.',
          Benefits2: 'Prevents dandruff occurrence.',
          Benefits3: 'Beneficial for hair fall.',
          Usage: 'Usage',
          Usage1: 'Put Anti Dandruff and Hair fall Oil on the hair scalp at night before sleep for best results. Wash in the morning. Use the Oil on alternate days.',
          Ingredients:'Ingredients',
          Ingredients1:'Mustard oil',
          Ingredients2:'Neem oil',
          Ingredients3:'Lemon oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 170,
          title: "Egg Oil",
          price: 2800.0,
          img: require("./assets/Natural Oil/egg-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Egg oil is an excellent natural solution to prevent hair loss, treat dandruff, prevent hair from greying, moisture the scalp and promote new healthy hair growth. Egg oil is extracted from egg yolks which are rich in triglycerides with cholesterol and Phospholipids.',
          SKU: 'SKU',
          SKU1: 'GR014OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'antibacterial, blood circulation, cell growth, cuticle softener, egg oil, hair follicles regeneration, hair growth, hair regrowth, hair repair, healing, natural oil, omega 3, proteins, pure oil, skin nourishment, yolk strength',
          Benefits: 'Benefits',
          Benefits1: 'Rich in proteins',
          Benefits2: 'Rich source of omega 3',
          Benefits3: 'Enhances blood circulation',
          Benefits4: 'Enhances cell growth ',
          Benefits5: 'Regenerates hair follicles',
          Benefits6: 'Acts as a moisturizer for the skin',
          Benefits7: 'Can be used as a cuticle softener',
          Benefits8: 'Conditions the skin ',
          Benefits9: 'Antibacterial',
          Benefits10: 'Treats hair loss.',
          Benefits11: 'Regrows hair',
          Benefits12: 'Heals dry and damaged skin',
          Benefits13: 'Repairs damaged hair',
          Benefits14: 'Nourishes the hair roots',
          Benefits15: 'Helps in regrowth of the hair',
          Usage: 'Usage',
          Usage1: 'Apply the oil and massage gently over the hair scalp. Leave for at least 2 hours before washing or overnight.',
          Weight: ' Weight',
          Weight1: '140 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 171,
          title: "Jojoba Oil",
          price: 1700.0,
          img: require("./assets/Natural Oil/jojoba-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Jojoba Oil is extracted from the best Jojoba seeds to help in many problems of skin and hair.',
          SKU: 'SKU',
          SKU1: 'GR024OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti ageing, damaged hair, dry hair, hair growth, hair nourishment, hair repair, jojoba, makeup remover, natural oil, pure oil, scalp nourishment, scar remover',
          Benefits: 'Benefits',
          Benefits1: 'Beneficial for repairing dry and damage hair.',
          Benefits2: 'Moisturizes the hair scalp.',
          Benefits3: 'Helps to grow hair..',
          Benefits4: 'Gives volume to the hair.',
          Benefits5: 'Beneficial to fight against fine lines and wrinkles',
          Benefits6: 'Removes acne scars',
          Benefits7: 'Used as a moisturizer.',
          Benefits8: 'Used as a makeup remover',
          Usage: 'Usage',
          Usage1: 'Apply on the hair scalp and overall hair, massage gently at night or 2 hours before hair wash.',
          Usage2:'Apply on the skin at night before sleep.',
          Usage3:'For removing makeup apply little amount of Jojoba oil on a cotton pad and wipe all over your face.',
          Ingredients:'Ingredients',
          Ingredients1:'Pure Jojoba Oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 172,
          title: "Oil for Dry Hair",
          price: 1800.0,
          img: require("./assets/Natural Oil/dry-hair-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Oil for Dry Hair is mixture of Egg, Coconut Oil, Olive Oil and Lemon Oil which can be used to moisture the scalp to help preventing fizziness of hair by leaving it soft and shiny.',
          SKU: 'SKU',
          SKU1: 'GR036OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'coconut, dry hair oil, egg oil, hair balance, hair growth, hair nourishment, lemon oil, olive',
          Benefits: 'Benefits',
          Benefits1: 'Helps keep hair nourished.',
          Benefits2: 'Balances the oil levels of the hair.',
          Usage: 'Usage',
          Usage1: 'Green Roots Oil for Dry Hair can be used thrice a week.',
          Ingredients:'Ingredients',
          Ingredients1:'Coconut Oil',
          Ingredients2:'Olive Oil',
          Ingredients3:'Lemon Oil',
          Ingredients4:'Egg Oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 173,
          title: "Oil for Oily Hair",
          price: 1500.0,
          img: require("./assets/Natural Oil/oily-hair-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Oil for Oily Hair is an effective formula that helps in avoiding inflammation, scalp infections, reducing sweat on the scalp, hair loss and dandruff.',
          SKU: 'SKU',
          SKU1: 'GR037OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: ' hair growth, hair nourishment, healthy brain, healthy digestion, healthy respiration, immune booster, natural oil, nausea cure, oily hair, oral health, pure oil, skin disease cure, skin nourishment, stress reliever',
          Benefits: 'Benefits',
          Benefits1: 'Reduces stress',
          Benefits2: 'Reduces nausea',
          Benefits3: 'Improves concentration',
          Benefits4: 'Keeps hair healthy',
          Benefits5: 'Supports digestion',
          Benefits6: 'Boosts oral health',
          Benefits7: 'Treats skin conditions',
          Benefits8: 'Improves respiratory functions',
          Benefits9: 'Improves immune system functions',
          Benefits10: 'Treats multiple skin conditions.',
          Usage: 'Usage',
          Usage1: 'Green Roots Oil for Oily Hairs can be used thrice a week.',
          Ingredients:'Ingredients',
          Ingredients1:'Coconut Oil',
          Ingredients2:'Almond Oil',
          Ingredients3:'Jojoba Oil',
          Ingredients4:'Tea Tree Oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 174,
          title: "Snake Oil",
          price: 4800.0,
          img: require("./assets/Natural Oil/sesame-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Snake Oil makes hairs extremely strong and protects them falling. Another important quality of Snake Oil is that it regrows hairs, in addition to this, it also treats dandruff and reduces it to limits.',
          SKU: 'SKU',
          SKU1: 'GR057OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti dandruff, anti hair fall, damaged hair, hair growth, hair nourishment, hair regrowth, hair repair, natural oil, pure oil, snake oil',
          Benefits: 'Benefits',
          Benefits1: 'Prevents hair falling.',
          Benefits2: 'Prevents dandruff.',
          Benefits3: 'Helps in hair growth faster.',
          Benefits4: 'Repairs damaged hairs. ',
          Usage: 'Usage',
          Usage1: 'Apply on the hair roots 2 hours before washing your hair.',
          Ingredients:'Ingredients',
          Ingredients1:'Snake Liver Oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },
        {
          id: 175,
          title: "Water Damaged & Hair Fall Oil",
          price: 2500.0,
          img: require("./assets/Natural Oil/water-damaged-hair-fall-oil-greenrootspk.com_.png"),
          quantity: 1,
          ml: '120 ML',
      
          dis: 'Green Roots Water Damage & Hair Fall Oil is a rich source of minerals and vitamins. The oil prevents hair fall and improves hairs damaged from water.',
          SKU: 'SKU',
          SKU1: 'GR061OL',
          Category: 'Natural Oils',
          Tags: 'Tags',
          Tags1: 'anti hair fall, anti hair loss, damaged hair, hair fall oil, hair nourishmen, thair repair, water damaged oil',
          Benefits: 'Benefits',
          Benefits1: 'Prevents hair loss.',
          Benefits2: 'Repairs damaged hair.',
          Benefits3: 'Nourishes the hair.',
          Usage: 'Usage',
          Usage1: 'Green Roots Water Damage & Hair Fall Oil can be used thrice a week.',
          Ingredients:'Ingredients',
          Ingredients1:'Mustard oil',
          Ingredients2:'Olive oil',
          Ingredients3:'Sesame oil',
          Ingredients4:'Castor oil',
          Ingredients5:'Lemon oil',
          Ingredients6:'Almond oil',
          Ingredients7:'Jojoba oil',
          Weight: ' Weight',
          Weight1: '135 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 mL',
        },

      //   
      {
          id: 176,
          title: "Allergy Cure",
          price: 2000.0,
          img: require("./assets/Medicine/allergy-cure-greenrootspk.com_.png"),
          quantity: 1,
          gram: '140 Grams',
      
          dis: 'Green Roots Allergy Cure is an effective medicine for most commonly found allergy problems, including skin allergy, blood allergy, and asthma. It also treats acne and pimples.',
          SKU: 'SKU',
          SKU1: 'GR002MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' allergy herbal cureallergy herbal medicineallergy medicineallergy natural cureallergy natural medicineallergy remedy',
          Benefits: 'Benefits',
          Benefits1: 'Helps fight all sorts of blood allergies.',
          Benefits2: 'Helps purify blood.',
          Benefits3: 'Helps clear acne and acne related problems',
          Usage: 'Usage',
          Usage1: 'Take 1/2 teaspoon after breakfast and dinner with water.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Ingredients',
          Ingredients2: 'Gul gaozaban',
          Ingredients3: 'Gul mandi',
          Ingredients4: 'Saunf',
          Ingredients5: 'Mulethi',
          Ingredients6: 'Unaab',
          Ingredients7: 'Gul e benafsha',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'AVOID Exposure to Moisture.',
          SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place.',
          SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
          Weight: 'Weight',
          Weight1: '175 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '140 Grams',
        },
      
        {
          id: 177,
          title: "Acidity Medicine",
          price: 1800.0,
          img: require("./assets/Medicine/acidity-medicine-greenrootspk.com_.png"),
          quantity: 1,
          gram: '140 Grams',
      
          dis: 'Green Roots Acidity is a solution to all stomach problems.',
          SKU: 'SKU',
          SKU1: 'GR001MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'acidity cure, acidity herbal cure, acidity herbal remedy, acidity medicine, acidity natural cure, acidity natural medicine',
          Benefits: 'Benefits',
          Benefits1: 'Helps cure H-Pylori.',
          Benefits2: 'Prevents bloating.',
          Benefits3: 'Helps cure ulcers.',
          Benefits4: 'Helps fight gases',
          Benefits5: 'Helps fight all sort of stomach problems.',
          Benefits6: 'Helps against burning in the chest.',
          Benefits7: 'Helps cure acidity.',
          Usage: 'Usage',
          Usage1: 'Take 1/2 teaspoon before breakfast and before dinner with plain water.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Choti Elaichi',
          Ingredients2: 'Mint',
          Ingredients3: 'Saunf',
          Ingredients4: 'Zeera Safed',
          Ingredients5: 'Tripla',
          Ingredients6: 'Dhania',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'AVOID Exposure to Moisture.',
          SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
          SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
          Weight: ' Weight',
          Weight1: '175 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '140 grams',
        },
      
      
        {
          id: 178,
          title: "Cardium 90",
          price: 1400.0,
          img: require("./assets/Medicine/cardium-90-greenrootspk.com_.png"),
          quantity: 1,
          gram: '175 Grams',
      
          dis: 'Green Roots Cardium-90 is a herbal medicine for blood pressure patients that controls excessive stress level and makes blood vessels strong.',
          SKU: 'SKU',
          SKU1: 'GR003MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' blood pressure natural medicine, BP herbal cure, bp natural cure, bp natural medicine, high blood pressure, high blood pressure natural remedy',
          Benefits: 'Benefits',
          Benefits1: 'Medicine for high Blood Pressure.',
          Benefits2: 'Not to be taken without Doctor’s prescription.',
          Benefits3: 'Controls high blood pressure.',
          Benefits4: '100% natural.',
          Usage: 'Usage',
          Usage1: 'For normal patients: 1/2 teaspoon before breakfast and 1/2 teaspoon before dinner. Both to be consumed with Lukewarm water.',
          Usage2: 'For acute patients: Two (02) teaspoon before breakfast, Half (1/2) teaspoon before lunch and dinner. All to be consumed with lukewarm water.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Rauwolfia',
          Ingredients2: 'Fennel seeds',
          Ingredients3: 'Knot grass',
          Ingredients4: 'Senna leaves',
          Ingredients5: 'Saffron',
          Ingredients6: 'Burada aaj',
          Ingredients7: 'Mirabilis Jalapa',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'AVOID Exposure to Moisture.',
          SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
          SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
          Weight: 'Weight',
          Weight1: '180 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '175 Grams',
        },
      
      
        {
          id: 179,
          title: "Clear Vision",
          price: 1400.0,
          img: require("./assets/Medicine/clear-vision-greenrootspk.com_.png"),
          quantity: 1,
          gram: '175 Grams',
      
          dis: 'Green Roots Clear Vision improves eyesight and helps in reducing dryness, itching and excessive watering of eyes. It prevents increased cloudiness of eyes by improving the vision. It also soothes eyes hence eliminating irritation generally produced due to repeated usage of cellphones, computers and Television.',
          SKU: 'SKU',
          SKU1: 'GR004MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' eyesight cure, eyesight herbal cure, eyesight herbal remedy, eyesight natural medicine, eyesight natural remedy, vision cure',
          Benefits: 'Benefits',
          Benefits1: 'Beneficial for kids, adults, and old people for improving eyesight.',
          Benefits2: 'Beneficial for people who use glasses.',
          Benefits3: 'Enhances weak eyesight.',
          Benefits4: 'Beneficial to protect eyesight from decreasing with age.',
          Benefits5: 'Beneficial for people with weak eyesight as well as normal eyesight.',
          Benefits6: 'Helps with watery eyes.',
          Benefits7: 'Helps clear eye vision.',
          Benefits8: 'Prevents “Motiya” production.',
          Usage: 'Usage',
          Usage1: '(For Children) Half Teaspoon before/after meal in the morning and night with milk',
          Usage2: '(For Adults) One Teaspoon before/after meal in the morning and night with milk',
          Ingredients: 'Ingredients',
          Ingredients1: 'Sucrose',
          Ingredients2: 'Coriander seeds',
          Ingredients3: 'Fennel (Saunf)',
          Ingredients4: 'Almonds',
          Ingredients5: 'Cumin seeds',
          Ingredients6: 'Black pepper',
          Special: 'Special Instructions',
          Special1: 'NOT SUITABLE FOR DIABETIC AND PREGNANT / NURSING MOTHERS.',
          Special2: 'AVOID Exposure to Moisture.',
          Special3: 'STORE Medicines In a Cool, Dry Place',
          Special4: 'READ Medicine Labels and Instructions Carefully.',
          Weight: ' Weight',
          Weight1: '180 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '175 grams',
        },
      
      
        {
          id: 180,
          title: "Cycle Disorder",
          price: 1200.0,
          img: require("./assets/Medicine/cycle-disorder-greenrootspk.com_.png"),
          quantity: 1,
          gram: '175 Grams',
      
          dis: 'Green Roots Cycle Disorder is helpful in issues related with Menstruation (or periods).',
          SKU: 'SKU',
          SKU1: 'GR005MD',
          Category: 'Medicine',
          Tags: ' Tags',
          Tags1: ' menstruation cycle herbal cure, menstruation cycle natural medicine, menstruation cycle natural remedy, mensuration issuesperiod cycle cure, period cycle natural medicine, period cycle natural remedy, period issues',
          Benefits: 'Benefits',
          Benefits1: 'Helpful for irregularities in the period cycle',
          Benefits2: 'Helpful for women suffering with PCOs',
          Benefits3: 'Helpful for hormonal imbalances.',
          Usage: 'Usage',
          Usage1: 'Take 1 teaspoon with water before breakfast. In case of discontinued periods from 4 to 6 months, it should also be taken at night as well before sleeping.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Kalonji',
          Ingredients2: 'Ajwain',
          Ingredients3: 'Methi dana',
          Ingredients4: 'Sabut Ispaghol',
          Ingredients5: 'Mint',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'AVOID Exposure to Moisture.',
          Weight: ' Weight',
          Weight1: '180 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '175 grams',
        },
      
      
        {
          id: 181,
          title: "Height Plus",
          price: 1200.0,
          img: require("./assets/Medicine/height-plus-greenrootspk.com_.png"),
          quantity: 1,
          gram: '175 Grams',
      
          dis: 'Green Roots Height Plus Medicine is the best formula to increase height at an ideal point.',
          SKU: 'SKU',
          SKU1: 'GR008MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' height enhancement medicine, height herbal medicine, height increase medicine, height medicine, height natural medicine, short height cure, short height remedy',
          Benefits: 'Benefits',
          Benefits1: 'Beneficial for girls from 10 years till 24 years',
          Benefits2: 'Beneficial for boys from 10 years till 24 years.',
          Benefits3: 'Helps strengthen bones',
          Benefits4: 'Provides calcium and Vitamin D.',
          Benefits5: 'Helps overcome weaknesses.',
          Usage: 'Usage',
          Usage1: 'Take 1/2 (half) teaspoon twice a day with milk or plain water.(morning and night).',
          Ingredients: 'Ingredients',
          Ingredients1: 'Black sesame',
          Ingredients2: 'Almonds',
          Ingredients3: 'Misri',
          Ingredients4: 'Asgand nagori',
          Ingredients5: 'Qast e shireen',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'NOT SUITABLE FOR PEOPLE OVER 24 YEARS.',
          SpecialInstructions2: 'AVOID Exposure to Moisture.',
          SpecialInstructions3: 'STORE Medicines In a Cool, Dry Place',
          SpecialInstructions4: 'READ Medicine Labels and Instructions Carefully.',
          Weight: 'Weight',
          Weight1: '230 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '175 grams',
        },
      
      
        {
          id: 182,
          title: "Insulinia Plus",
          price: 1200.0,
          img: require("./assets/Medicine/insulina-plus-greenrootspk.com_.png"),
          quantity: 1,
          gram: '175 Grams',
      
          dis: 'Green Roots Insulinia Plus maintains your blood sugar level and treats glucose tolerance well. The most special herb used in this medicine called Gymnema Sylvestre (known as sugar destroyer) that increases the beta-cell function especially among type-2 sugar patients.',
          SKU: 'SKU',
          SKU1: 'GR009MD',
          Category: 'Medicine',
          Tags: ' Tags',
          Tags1: ' blood sugar, insulin cure, insulin herbal cure, insulin herbal medicine, insulin herbal remedy, insulin natural cure, insulin natural medicine, insulin natural remedy',
          Benefits: 'Benefits',
          Benefits1: 'Helps to maintain the blood sugar level..',
          Benefits2: 'Increases the beta-cell functions in the body.',
          Usage: 'Usage',
          Usage1: 'For Type 1: Take One (01) teaspoons before breakfast and One (01) tablespoon before dinner.',
          Usage2: 'For Type 2: Take One (01) teaspoon three times a day before meal.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Gymnema sylvestre',
          Ingredients2: 'Cinquefoils',
          Ingredients3: 'Bitter Oleander',
          Ingredients4: 'Butterstick',
          Ingredients5: 'Gentian',
          Ingredients6: 'Black plum seeds.',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'AVOID Exposure to Moisture.',
          Weight: 'Weight',
          Weight1: '180 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '175 grams',
        },
      
      
        {
          id: 183,
          title: "Jawarish E Sakoon",
          price: 1800.0,
          img: require("./assets/Medicine/jawarish-e-sukoon-greenrootspk.com_.png"),
          quantity: 1,
          gram: '250 Grams',
      
      
          dis: 'Green Roots Jawarish-e-Sakoon is a natural cure for problems like ulcer, piles, constipation, acidity, irregular heartbeat and gastric problems.',
          SKU: 'SKU',
          SKU1: 'GR010MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'constipation remedy, piles cure, stomach health, stomach ulcer, ulcer treatment',
          Benefits: 'Benefits',
          Benefits1: 'Treatment for Ulcers',
          Benefits2: 'Treatment for Piles',
          Benefits3: 'Beneficial for constipation.',
          Usage: 'Usage',
          Usage1: 'One (01) tablespoon with hot milk or water before sleeping at night',
          Ingredients: 'Ingredients',
          Ingredients1: 'Harh Murabba',
          Ingredients2: 'Gajar Murabba',
          Ingredients3: 'Saib Murabba',
          Ingredients4: 'Amla Murabba',
          Ingredients5: 'Gule Surkh',
          Ingredients6: 'Elaichi Khurd',
          Ingredients7: 'Sonth (zanjabeel)',
          Ingredients8: 'Sana Makhi',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'NOT SUITABLE FOR PREGNANT / NURSING MOTHERS.',
          Weight: 'Weight',
          Weight1: '265 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '250 grams',
        },
      
      
        {
          id: 184,
          title: "Joint Cure",
          price: 3400.0,
          img: require("./assets/Medicine/joint-cure-greenrootspk.com_.png"),
          quantity: 1,
          gram: '250 Grams',
      
          dis: 'Green Roots Joint Cure strengthen your bones and possesses Anti inflammatory properties that is a complete cure to the back and joint pains. It can also be used for muscles pain and arthritis',
          SKU: 'SKU',
          SKU1: 'GR011MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' arthritis cure, arthritis herbal cure, arthritis natural cure, bone strength, calcium supplement, muscle strength, vitamin d',
          Benefits: 'Benefits',
          Benefits1: 'Strengthen bones and muscles.',
          Benefits2: 'Treats arthritis.',
          Benefits3: 'Makes the bones strong.',
          Benefits4: 'Treats the deficiency of Vitamin D in bones.',
          Benefits5: 'Gives Calcium to bones.',
          Benefits6: 'Used for all types of muscles and bones pain.',
          Usage: 'Usage',
          Usage1: 'Half (1/2) teaspoon before breakfast and half (1/2) teaspoon after dinner with plain water or milk.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Asgandh Nagori',
          Ingredients2: 'Musli Safaid',
          Ingredients3: 'Qast E Shireen',
          Ingredients4: 'Sonth',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'AVOID Exposure to Moisture.',
          SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
          SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
          Weight: 'Weight',
          Weight1: '175 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '140 grams',
        },
      
      
        {
          id: 185,
          title: "Premature Hair Greying Medicine",
          price: 1500.0,
          img: require("./assets/Medicine/premature-hair-greying-medicine-greenrootspk.com_.jpg"),
          quantity: 1,
          gram: '140 Grams',
      
          dis: 'Green Roots Premature Hair Greying Medicine is used to provide zinc to body. Helps support in mental health, also helps the hair grow black.',
          SKU: 'SKU',
          SKU1: 'GR014MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'hair greying herbal cure, hair greying medicine, hair greying natural cure, hair greying natural medicine, hair greying natural remedy, premature hair greying medicine',
          Benefits: 'Benefits',
          Benefits1: 'Helps cover Zinc deficiency in the body.',
          Benefits2: 'Beneficial to overcome mental weakness.',
          Benefits3: 'Helps in preventing premature greying of the hair.',
          Benefits4: 'Helps in restoring premature grey hair back to its original color. ',
          Benefits5: 'This medicine is not usable for the people above 30 years age.',
          Usage: 'Usage',
          Usage1: 'Take 1/2 or 1 teaspoon in the morning 1 hour after breakfast or once in a day with milk.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Bhangra siyah',
          Ingredients2: 'Amla',
          Ingredients3: 'Badam oil',
          Ingredients4: 'Post baheera',
          Ingredients5: 'Bari har chilka',
          Ingredients6: 'Choti har',
          Ingredients7: 'Methi dana',
          Ingredients8: 'Maghz e badam',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'NOT SUITABLE FOR PEOPLE OVER 30 YEARS.',
          SpecialInstructions2: 'AVOID Exposure to Moisture.',
          SpecialInstructions3: 'STORE Medicines In a Cool, Dry Place',
          SpecialInstructions4: 'READ Medicine Labels and Instructions Carefully.',
          Weight: ' Weight',
          Weight1: '175 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '140 grams',
        },
      
      
        {
          id: 186,
          title: "Sleeping Disorder",
          price: 900.0,
          img: require("./assets/Medicine/sleeping-disorder-greenrootspk.com_.png"),
          quantity: 1,
          gram: '90 Grams',
      
          dis: 'Green Roots Sleeping Disorder is the natural treatment for the people fighting with the disorder of not sleeping properly having stress and depression.',
          SKU: 'SKU',
          SKU1: 'GR015MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' anti anxiety, peaceful sleep, sleep cycle remedy, sleep disorder cure, stress reliever',
          Benefits: 'Benefits',
          Benefits1: 'Helps fight anxiety.',
          Benefits2: 'Helps relieve stress.',
          Benefits3: 'Benefits in peaceful sleeping without medications.',
          Usage: 'Usage',
          Usage1: 'Take One (01) teaspoon daily at night before sleeping with plain water.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Gul e gaow Zaban',
          Ingredients2: 'Gul e Banafsha',
          Ingredients3: 'Magz e kadu',
          Ingredients4: 'Khash Khaash',
          Ingredients5: 'Magz e tarbooz',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'AVOID Exposure to Moisture.',
          SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
          SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
          Weight: ' Weight',
          Weight1: '175 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '90 grams',
        },
      
        {
          id: 187,
          title: "Sleeping Disorder",
          price: 1800.0,
          img: require("./assets/Medicine/sleeping-disorder-greenrootspk.com_.png"),
          quantity: 1,
          gram: '175 Grams',
      
          dis: 'Green Roots Sleeping Disorder is the natural treatment for the people fighting with the disorder of not sleeping properly having stress and depression.',
          SKU: 'SKU',
          SKU1: 'GR015MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' anti anxiety, peaceful sleep, sleep cycle remedy, sleep disorder cure, stress reliever',
          Benefits: 'Benefits',
          Benefits1: 'Helps fight anxiety.',
          Benefits2: 'Helps relieve stress.',
          Benefits3: 'Benefits in peaceful sleeping without medications.',
          Usage: 'Usage',
          Usage1: 'Take One (01) teaspoon daily at night before sleeping with plain water.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Gul e gaow Zaban',
          Ingredients2: 'Gul e Banafsha',
          Ingredients3: 'Magz e kadu',
          Ingredients4: 'Khash Khaash',
          Ingredients5: 'Magz e tarbooz',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'AVOID Exposure to Moisture.',
          SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
          SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
          Weight: ' Weight',
          Weight1: '175 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '175 grams',
        },
        {
          id: 188,
          title: "Tooth Powder",
          price: 1200.0,
          img: require("./assets/Medicine/tooth-powder-medicine-greenrootspk.com_.jpg"),
          quantity: 1,
          gram: '160 Grams',
      
          dis: 'Green Roots Tooth Powder is an excellent product that offers all time solution to tooth problems.',
          SKU: 'SKU',
          SKU1: 'GR016MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'bad breath remedy, bleeding gums cure, pyorrhea cure, teeth whitener, tooth ache relief, tooth powder',
          Benefits: 'Benefits',
          Benefits1: 'Helps in Toothache.',
          Benefits2: 'Helps cure Pyorrhea.',
          Benefits3: 'Helps control bleeding of gums.',
          Benefits4: 'Helps to control bad breath of mouth.',
          Benefits5: 'Helps in teeth whitening.',
          Usage: 'Usage',
          Usage1: 'Use twice a day for better results.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Samandari Jhaag',
          Ingredients2: 'Sang e jarahat',
          Ingredients3: 'Phitkari safad',
          Ingredients4: 'Laung',
          Ingredients5: 'Mastag e roomi',
          Ingredients6: 'Suhaga',
          Ingredients7: 'Black salt',
          Weight: ' Weight',
          Weight1: '175 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '160 grams',
        },
      
      
        {
          id: 189,
          title: "Sperm Count 2",
          price: 7000.0,
          img: require("./assets/Medicine/sperm-count-2-1-greenrootspk.com_.png"),
          quantity: 1,
          gram: '175 Grams',
      
          dis: 'Green Roots Sperm Count 2 is helpful in fertility issues.',
          SKU: 'SKU',
          SKU1: 'GR018MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' fertility booster, sperm count, perm herbal medicine, sperm natural cure',
          Benefits: 'Benefits',
          Benefits1: 'Sperm Count 2 is a customized medicine, made for men who suffer from fertility issues such as low sperm count or no sperm count.',
          Usage: 'Usage',
          Usage1: 'Take half a teaspoon of the medicine, one hour after breakfast and half a teaspoon one hour after dinner. Can be taken with milk or water.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Tukhme Saras (lebbek tree seeds)',
          Ingredients2: 'Geru (Gairika (Red Ochre))',
          Ingredients3: 'Musli India',
          Ingredients4: 'Majuphal (Quercus infectoria)',
          Ingredients5: 'Mai',
          Ingredients6: 'Badaam (almonds)',
          Ingredients7: 'Chilghoza (pine nuts)',
          Ingredients8: 'Salab Panja (Himalayan Marsh Orchid)',
          Weight: ' Weight',
          Weight1: '180 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '175 grams',
        },
      
      
        {
      
          id: 190,
          title: "Maajoon E Qaboos",
          price: 18000.0,
          img: require("./assets/Medicine/majoon-e-qaboos-greenrootspk.com_.png"),
          quantity: 1,
          gram: '250 Grams',
      
          dis: '(For Men Only) Green Roots Maajoon e Qaaboos helps overcome male sexual weaknesses, boosts overall body functions and increase energy levels.',
          SKU: 'SKU',
          SKU1: 'GR019MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tag1: ': energizing, fertility booster, majoon e qaboos, sexual performance, sexual weakness',
          Benefits: 'Benefits',
          Benefits1: 'Maajoon e Qaaboos is a supplement for men. It helps overcome sexual weaknesses, boosts overall body functions and energy, provided nutrition to the body.',
          Usage: 'Usage',
          Usage1: 'Take one teaspoon – daily anytime in the day, with milk.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Mastic Gum (mastagi roomi)',
          Ingredients2: 'Marvari (Pearl herb)',
          Ingredients3: 'Kharateen',
          Ingredients4: 'Regmahi (sand fish)',
          Ingredients5: 'Saffron',
          Ingredients6: 'Kholanjaan (galingale)',
          Ingredients7: 'Bisbasa (Myristica fragrans Houtt)',
          Ingredients8: 'Jauz (cornflower)',
          Ingredients9: 'Karanphool (star anise)',
          Ingredients10: 'Jadwaar (delphinium denudatum)',
          Ingredients11: 'Wark nakra',
          Ingredients12: 'Salab Misri (Orchis latifolia Linn)',
          Ingredients13: 'Museli White',
          Ingredients14: 'Isgand (Indian ginseng)',
          Ingredients15: 'Amber',
          Ingredients16: 'Shilajeet',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'Not suitable for diabetic patients',
          Weight: 'Weight',
          Weight1: '265 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '250',
        },
      
      
        {
          id: 191,
          title: "Maajoon E Zaffran",
          price: 4500.0,
          img: require("./assets/Medicine/majoonezafranoutofstock.png"),
          quantity: 1,
          gram: '250 Grams',
          Availability: 'Availability',
          Availability1: ' : Out of stock',
      
          dis: '(For Females Only) Green Roots Maajoon E Zaffran is enriched with all natural ingredients that is an energy booster for females.',
          // Availability: 'Availability:',
          // Availability: 'Out of stock',
          SKU: 'SKU',
          SKU1: 'GR020MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'energizing, energy booster, majoone zaffran, nutrition',
          Benefits: 'Benefits',
          Benefits1: 'Enriched with all natural ingredients, Maajoon E Zaffran is an energy booster for females. It enhances energy as well has provides nutrition to the body.',
          Usage: 'Usage',
          Usage1: 'Take one teaspoon daily – anytime in the day, with milk.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Saffron',
          Ingredients2: 'Chickpeas',
          Ingredients3: 'Honey',
          Ingredients4: 'Pistachios',
          Ingredients5: 'Ginseng',
          Ingredients6: 'Badaam (almonds)',
          Ingredients7: 'Akhrot (walnuts)',
          Ingredients8: 'Kaaju (cashews)',
          Ingredients9: 'Injeer (figs)',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'Not suitable for diabetic patients',
          Weight: ' Weight',
          Weight1: '265 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '250 grams',
        },
      
      
        {
          id: 192,
          title: "Sugar 2",
          price: 1400.0,
          img: require("./assets/Medicine/sugar-2-greenrootspk.com_.png"),
          quantity: 1,
          gram: '175 Grams',
      
          dis: 'Green Roots Sugar 2 specifically designed for diabetes (uncontrolled and controlled) and helps regulate blood sugar levels if uncontrolled.',
          SKU: 'SKU',
          SKU1: 'GR021MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' blood sugar control, diabetes control, diabetes cure, sugar 2, sugar control, sugar cure',
          Benefits: 'Benefits',
          Benefits1: 'Sugar 2 is a customized medicine, specifically designed for diabetes (uncontrolled and controlled). It helps regulate blood sugar levels if uncontrolled, and it helps eradicate diabetes (according to the patients diabetic history).',
          Usage: 'Usage',
          Usage1: 'Take one teaspoon one hour after meal with water.',
          Usage2: 'Twice a day.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Kalonji (black seeds)',
          Ingredients2: 'Methi Dana (Fenugreek)q',
          Ingredients3: 'Daarcheeni (Cinnamon)',
          Ingredients4: 'Karhi Pata (Curry leaves)',
          Ingredients5: 'Badaam (Almonds)',
          Weight: ' Weight',
          Weight1: '180 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '175 grams',
        },
      
      
        {
          id: 193,
          title: "Urine Control",
          price: 900.0,
          img: require("./assets/Medicine/urine-control-greenrootspk.com_.png"),
          quantity: 1,
          gram: '175 Grams',
      
          dis: 'Green Roots Urine Un-Control is a customized medicine for people suffering with un controlled urine disorder.',
          SKU: 'SKU',
          SKU1: 'GR022MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'herbal cure, herbal medicine, herbal remedy, natural medicine, natural urination cure, urination issues, urine disorder cure, urine uncontrol',
          Benefits: 'Benefits',
          Benefits1: 'A customized medicine for people suffering with un controlled urine disorder.',
          Usage: 'Usage',
          Usage1: 'Half teaspoon in the morning, 1 hour after breakfast.',
          Usage2: 'Half teaspoon at night, 1 hour after dinner with water.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Fenugreek (Methi Dana powder )',
          Ingredients2: 'Jambolan Powder (jamun ke beej ka powder)',
          Ingredients3: 'Sesame (Safed Til)',
          Ingredients4: 'Walnut (Akhrot)',
          Ingredients5: 'Coconut powder (narial ka powder)',
          Weight: 'Weight',
          Weight1: '180 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '175 grams',
        },
      
      
        {
          id: 194,
          title: "Maajoon E Nawal",
          price: 2800.0,
          img: require("./assets/Medicine/majoon-e-nawal-greenrootspk.com_.png"),
          quantity: 1,
          gram: '250 Grams',
      
          dis: 'Green Roots Maajoon E Nawal is Beneficial for stomach related issues.',
          SKU: 'SKU',
          SKU1: 'GR023MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' acidity cure, appetite remedy, herbal medicine, indigestion remedy, maajoone nawal, natural medicine, stomach cleansing, stomach cure, stomach health',
          Benefits:'Benefits',
          Benefits1: 'Beneficial for stomach issues. e.g. gases, acidity, bloating, indigestion, lack/loss of appetite, and all major stomach related issues.',
          Usage: 'Usage',
          Usage1: 'Half teaspoon in the morning, half an hour before meal.',
          Usage2: 'Half teaspoon in the evening, half an hour before meal (With water) .',
          Ingredients: 'Ingredients',
          Ingredients1: 'Honey (shehed)',
          Ingredients2: 'Mastic Gum (Mastagi Roomi)',
          Ingredients3: 'Cinnamon(Darcheeni)',
          Ingredients4: 'Valerian (Balchar)',
          Ingredients5: 'Agr-e-nafaal',
          Ingredients6: 'Black Cardamom (Elaichi Kala)',
          Ingredients7: 'Black Pepper Corns (Filfil Siyah)',
          Instructions: 'Instructions',
          Instructions1: 'Not for sugar patients directly. Can be customized as per requirement.',
          Weight: 'Weight',
          Weight1: '265 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '250 grams',
        },
      
      
        {
          id: 195,
          title: "Diabetic Cure",
          price: 3000.0,
          img: require("./assets/Medicine/diabetic-cure-greenrootspk.com_.png"),
          quantity: 1,
          Capsules: '90 Capsules',
      
          dis: 'Green Roots Diabetic Cure is a natural and pure herbal medicine which stabilizes blood sugar levels by increasing insulin and stimulates the pancreas to make more insulin to remove excess sugar from the body.',
          SKU: 'SKU',
          SKU1: 'GR006MD',
          Category: 'Medicine',
          Tags: ' Tags',
          Tags1: 'diabetic cure, diabetic herbal cure, diabetic herbal medicine, diabetic herbal remedy, diabetic natural cure, diabetic natural medicine, diabetic natural remedy, sugar medicine',
          Benefits: 'Benefits',
          Benefits1: 'Controls Diabetes.',
          Benefits2: 'Helps improve the insulin level in the body.',
          Benefits3: 'Helps prevent future damages caused due to diabetes.',
          Benefits4: 'Control the overall blood glucose levels produced in the body.',
          Benefits5: 'Improves blood lipid levels',
          Usage: 'Usage',
          Usage1: 'Morning capsules.- One (01) Capsule before breakfast.',
          Usage2: 'Night capsules – Two (02) Capsules after dinner.',
          Ingredients: 'Ingredients',
          Ingredients1: ' NIGHT 60 CAPSULES',
          Ingredients2: 'Cinnamon',
          Ingredients3: 'Cardamom',
          Ingredients4: 'Fenugreek Seeds',
          Ingredients5: 'Nigella Seeds',
          Ingredients6: 'Curry Leave',
          Ingredients7: 'MORNING 30 CAPSULES',
          Ingredients8: 'Sirisa',
          Ingredients9: 'SeedsNeem',
          Ingredients10: 'GumColosynthis',
          Ingredients11: 'Nigella Seeds',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'AVOID Exposure to Moisture.',
          SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
          SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
          Weight: 'Weight',
          Weight1: '125 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '90 Capsules',
        },
      
      
        {
          id: 196,
          title: "Femi Care (Leucorrhea)",
          price: 1600.0,
          img: require("./assets/Medicine/femi-care-greenrootspk.com_.png"),
          quantity: 1,
          Capsules: '120 Capsules',
      
          dis: 'Green Roots Femi Care is a natural cure for leucorrhoea and relieves vaginal infections naturally to be used for nine months for complete treatment. Femi Care helps in relieving mucus and speeds up vaginal epithelium renewal. It helps in balancing the hormones in the body and makes them work better.',
          SKU: 'SKU',
          SKU1: 'GR007MD',
          Category: 'Medicine',
          Tags: ' Tags',
          Tags1: ' leucorrhea cure, leucorrhea herbal medicine, leucorrhea herbal remedy, leucorrhea medicine, leucorrhea natural medicine, leucorrhea natural remedy',
          Benefits: 'Benefits',
          Benefits1: 'Medicine for leucorrhoea symptoms',
          Benefits2: 'Beneficial for Back ache – Pain in the legs.',
          Benefits3: 'Good to boost appetite',
          Benefits4: 'Makes bones strong.',
          Benefits5: 'Beneficial for girls/women from age 12-30 effectively.',
          Benefits6: 'Works on women aged 30-40 as well.',
          Usage: 'Usage',
          Usage1: 'For under 15 years age – One (01) tablet in the morning and (01) tablet at night with water.',
          Usage2: 'For girls above 15 years age – Two (02) tablets in the morning and two (02) tablets at night with water.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Four Gums',
          Ingredients2: 'Water caltrop',
          Ingredients3: 'Valeria-Indica',
          Ingredients4: 'Pomegranate Flower',
          Ingredients5: 'Indian Garden Asparagus',
          Ingredients6: 'Sensitive Plant',
          Ingredients7: 'Sucrose Sugar',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'NOT SUITABLE FOR PREGNANT / NURSING MOTHERS.',
          SpecialInstructions2: 'AVOID Exposure to Moisture.',
          SpecialInstructions3: 'STORE Medicines In a Cool, Dry Place',
          SpecialInstructions4: 'READ Medicine Labels and Instructions Carefully.',
          Weight: ' Weight',
          Weight1: '130 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 Capsules',
        },
      
      
        {
          id: 197,
          title: "Men’s Health Capsules",
          price: 12000.0,
          img: require("./assets/Medicine/men-health-capsules-greenrootspk.com_.png"),
          quantity: 1,
          Capsules: '60 Capsules',
      
          dis: 'Green Roots Men’s Health capsules is an excellent formula to boost and enhance male sexual energy, power and strength.',
          SKU: 'SKU',
          SKU1: 'GR012MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'horny goat weed extract, male performance, male potency, male strength, sea cucumber, sexual performance, tongkat ali',
          Benefits: 'Benefits',
          Benefits1: 'Supports male sexual performance.',
          Benefits2: 'Supports heart health.',
          Benefits3: 'Gives energy.',
          UsageofVIRILITYDSCapsules: 'Usage of VIRILITY-DS Capsules',
          UsageofVIRILITYDSCapsules1: 'Take One (01) capsule daily with breakfast.',
          UsageofVIGROLXL: 'Usage of VIGROL-XL',
          UsageofVIGROLXL1: 'Take One (01) capsule at night before sleep.',
          VIRILITYDSIngredients: 'VIRILITY-DS Ingredients:',
          VIRILITYDSIngredients1: 'Sea Cucumber 230 mg',
          VIRILITYDSIngredients2: 'Horny Goat Weed Extract 100mg',
          VIRILITYDSIngredients3: 'Tongkat Ali Extract 50mg',
          VIRILITYDSIngredients4: 'Vitamin D 300 IU',
          VIRILITYDSIngredients5: 'Vitamin A 3000 IU',
          VIGROLXLIngredients: 'VIGROL-XL: Ingredients:',
          VIGROLXLIngredients1: 'Aplinia Gaianga 40mg',
          VIGROLXLIngredients2: 'Amomum Subulatum 40 mg',
          VIGROLXLIngredients3: 'Anacyclus Pyrethrum 20mg',
          VIGROLXLIngredients4: 'Asparagus Adscendens 10mg',
          VIGROLXLIngredients5: 'Bambusa Spinosa 5mg',
          VIGROLXLIngredients6: 'Cinnamomum Malabathrum 7 mg',
          VIGROLXLIngredients7: 'Crocus Sativus 0.5mg',
          VIGROLXLIngredients8: 'Curculigo Orchioides 7 mg',
          VIGROLXLIngredients9: 'Hyoscyamus Niger 0.10mg',
          VIGROLXLIngredients10: 'Myristica Fragrans 2mg',
          VIGROLXLIngredients11: 'Tephrosia Purpurea 7 mg',
          VIGROLXLIngredients12: 'Zingiber Officinalis 9mg',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'AVOID Exposure to Moisture.',
          Weight: ' Weight',
          Weight1: '75 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '160 Capsules',
        },
      
      
        {
          id: 198,
          title: "Piles Capsules",
          price: 6000.0,
          img: require("./assets/Medicine/piles-capsules-greenrootspk.com_.png"),
          quantity: 1,
          Capsules: '80 Capsules',
      
          dis: 'Pilox MB & Pilox-N Green Roots Piles Medicine Capsules is to cure hemorrhoids, bleeding mass and constipation.',
          SKU: 'SKU',
          SKU1: 'GR013MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'piles cur, epiles herbal cure, piles herbal remedy, piles medicine, piles natural cure, piles natural medicine, piles natural remedy',
          Benefits: 'Benefits',
          Benefits1: 'Its natural cure to internal and external hemorrhoids',
          Benefits2: 'It works to relieve swelling, bleeding, pain and Helps to eliminate the hemorrhoid.',
          UsageofPiloxMB: 'Usage of Pilox-MB',
          UsageofPiloxMB1: 'Take 2 bananas early morning then take 2 capsules after 10 minutes take 1 or 2 bananas and after half an hour take breakfast.',
          UsageofPiloxN: 'Usage of Pilox-N',
          UsageofPiloxN1: 'Take 2 capsules one hour after dinner.',
          PiloxMBIngredients: 'Pilox MB Ingredients',
          PiloxMBIngredients1: 'Rice powder',
          PiloxMBIngredients2: 'Potassium',
          PiloxMBIngredients3: 'Cellulose',
          PiloxNIngredients: 'Pilox N Ingredients',
          PiloxNIngredients1: 'Cellulose',
          PiloxNIngredients2: 'Phyllanthus',
          PiloxNIngrediente3: 'Emblica',
          PiloxNIngredients4: 'Curcuma',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'AVOID Exposure to Moisture.',
          SpecialInstructions2: 'STORE Medicines In a Cool, Dry Place',
          SpecialInstructions3: 'READ Medicine Labels and Instructions Carefully.',
          Weight: ' Weight',
          Weight1: '95 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '80 Capsules',
        },
      
      
        {
          id: 199,
          title: "Haazma Hee Haazma",
          price: 750.0,
          img: require("./assets/Medicine/haazma-hee-haazma-greenrootspk.com_.png"),
          quantity: 1,
          tablet: '60 Tablets',
      
          dis: 'Green Roots Haazma Hee Haazma is beneficial for all stomach related problems such as acidity & constipation.',
          SKU: 'SKU',
          SKU1: 'GR024MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' acidity cure, acidity medicine, acidity remedy, constipation cure, constipation herbal medicine, constipation remedy, healthy digestion, herbal medicinein, digestion cure, natural medicine, stomach cure',
          Benefits: 'Benefits',
          Benefits1: 'Healthy digestion',
          Benefits2: 'Acidity cure',
          Benefits3: 'Constipation cure',
          Benefits4: 'Beneficial for all stomach related issues',
          Usage: 'Usage',
          Usage1: 'Take one tablet in the morning after meal',
          Usage2: 'Take one tablet in night after meal',
          Usage3: 'In case of severe conditions, two tablets can be taken',
          Usage4: 'These tablets can be taken with water',
          Usage5: 'These tablets can also be taken as dissolvable tablets',
          Ingredients: 'Ingredients',
          Ingredients1: 'Long Pepper (filfil siyah)',
          Ingredients2: 'Sange Jaharat (soaprock)',
          Ingredients3: 'Gond Katira (tragacanth)',
          Ingredients4: 'Noshadar (gum resim)',
          Ingredients5: 'Peepal Kala (peepal black)',
          Ingredients6: 'Calcium Phosphate',
          Ingredients7: 'Zanjabeel (zingiber)',
          Ingredients8: 'Zeera Safaid (cumin)',
          Ingredients9: 'Kaala Zera (black cumin)',
          Ingredients10: 'Satt Limu (citric acid)',
          Weight: 'Weight:',
          Weight1: '50 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '60 Tablets',
        },
      
      
        {
          id: 200,
          title: "Haazma Hee Haazma",
          price: 1500.0,
          img: require("./assets/Medicine/haazma-hee-haazma-greenrootspk.com_.png"),
          quantity: 1,
          tablet: '120 Tablets',
      
          dis: 'Green Roots Haazma Hee Haazma is beneficial for all stomach related problems such as acidity & constipation.',
          SKU: 'SKU',
          SKU1: 'GR024MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' acidity cure, acidity medicine, acidity remedy, constipation cure, constipation herbal medicine, constipation remedy, healthy digestion, herbal medicine, indigestion cure, natural medicine, stomach cure',
          Benefits: 'Benefits',
          Benefits1: 'Healthy digestion',
          Benefits2: 'Acidity cure',
          Benefits3: 'Constipation cure',
          Benefits4: 'Beneficial for all stomach related issues',
          Usage: 'Usage',
          Usage1: 'Take one tablet in the morning after meal',
          Usage2: 'Take one tablet in night after meal',
          Usage3: 'In case of severe conditions, two tablets can be taken',
          Usage4: 'These tablets can be taken with water',
          Usage5: 'These tablets can also be taken as dissolvable tablets',
          Ingredients: 'Ingredients',
          Ingredients1: 'Long Pepper (filfil siyah)',
          Ingredients2: 'Sange Jaharat (soaprock)',
          Ingredients3: 'Gond Katira (tragacanth)',
          Ingredients4: 'Noshadar (gum resim)',
          Ingredients5: 'Peepal Kala (peepal black)',
          Ingredients6: 'Calcium Phosphate',
          Ingredients7: 'Zanjabeel (zingiber)',
          Ingredients8: 'Zeera Safaid (cumin)',
          Ingredients9: 'Kaala Zera (black cumin)',
          Ingredients10: 'Satt Limu (citric acid)',
          Weight: 'Weight',
          Weight1: '90 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '120 Tablets',
        },
      
      
        {
          id: 201,
          title: "Unwanted Hair Oil",
          price: 3600.0,
          img: require("./assets/Medicine/unwanted-hair-oiloutofstock.png"),
          quantity: 1,
          ml:'15 ML',
          Availability:'Availability',
          Availability1:' : Out of stock',
      
          dis: 'Green Roots Unwanted hair oil is here to help you get rids of all sorts of unwanted facial hair from the face. It helps overcome the growth of unwanted facial hairs from face.',
          // Availability: 'Availability;',
          // Availability1: 'Out of stock',
          SKU: 'SKU',
          SKU1: 'GR017MD',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' facial hair remover, facial hair removing oil, scar remover, spots remover, unwanted hair oil, unwanted hair removal, unwanted hair remover',
          Benefits: 'Benefits',
          Benefits1: 'Lightens marks and spots on the skin.',
          Benefits2: 'Helps prevents acne from appearing.',
          Benefits3: 'Antibacterial.',
          Benefits4: 'Anti fungal.',
          Benefits5: 'Reduces melanin production.',
          Benefits6: 'Helps even tone skin.',
          Benefits7: 'Makes the skin clean and glowing.',
          Usage: 'Usage',
          Usage1: 'Wash face with clean water, take few drops of Unwanted Hair Oil, apply gently on required areas at night before sleeping.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Salt',
          Ingredients2: 'Tea Tree oil',
          Ingredients3: 'Aloe Vera',
          Ingredients4: 'Glycerin',
          Ingredients5: 'Costus',
          Ingredients6: 'Propylene Glycol',
          SpecialInstructions: 'Special Instructions',
          SpecialInstructions1: 'For effective results use Unwanted Hair Oil after waxing the skin.',
          SpecialInstructions2: 'DO NOT USE Unwanted Hair Oil when you have any type of skin allergy at skin surface where you want to use the oil, it is advisable to consult the doctor in such condition.',
          Weight: ' Weight',
          Weight1: '60 g',
          dicQuantity: 'Quantity',
          dicQuantity1: '15 mL',
        },
      
      
        {
          id: 202,
          title: "Ceactive",
          price: 1200.0,
          img: require("./assets/Medicine/Ceactiveoutofstock.png"),
          quantity: 1,
          Availability:'Availability',
          Availability1:' : Out of stock',
      
          dis: 'Green roots herbal tablet called CeActive reduces inflammation, darkness under the eyes, promotes healthy and glowing skin and improves skin texture.',
          // Availability: 'Availability:',
          // Availability1: ' Out of stock',
          SKU: 'SKU',
          SKU1: 'GR026MD',
          Category: 'Medicine',
          Benefits: 'Benefits',
          Benefits1: 'Reduces inflammation',
          Benefits2: 'Promotes collagen production',
          Benefits3: 'Reduce under eye circles',
          Benefits4: 'Improves skin texture',
          Benefits5: 'Promotes healthy, glowing skin',
          Usage: 'Usage',
          Usage1: 'For adults, take one to two capsules daily, preferably with meals.',
          Weight: 'Weight',
          Weight1: '55 g',
      
        },
      
      
        {
          id: 203,
          title: "Hair Food",
          price: 2400.0,
          img: require("./assets/Medicine/Hair Food.jpeg"),
          quantity: 1,
      
          dis: 'A natural supplement for luscious, beautiful and healthy hair.',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'Beans, Chia Seeds, Flax Seeds, Hair Food, hair grow, hairfall, Mashrooms, omega 3, Pumpkin Seeds, Sunflower Seeds, vitamin E, White Sesame Seeds',
          Benefits: 'Benefits',
          Benefits1: 'Helps hair grow faster and longer, proving nourishment to the follicles.',
          Benefits2: 'Enriched with Vitamin E and C and Omega 3.',
          Benefits3: 'Reduction in hairfall',
          Benefits4: 'Prevents dryness and frizzy hair',
          Benefits5: 'Prevents thinning of the hair',
          Benefits6: 'Strengthens hair',
          Benefits7: 'Improves blood circulation in the scalp',
          Benefits8: 'Keeps the scalp moisturised',
          Benefits9: 'The presence of White Sesame Seeds may also help avoid or prevent premature greying of the hair.',
          Ingredients: 'Ingredients',
          Ingredients1: 'Flax Seeds',
          Ingredients2: 'Chia Seeds',
          Ingredients3: 'Pumpkin Seeds',
          Ingredients4: 'Sunflower Seeds',
          Ingredients5: 'White Sesame Seeds',
          Ingredients6: 'Mashrooms',
          Ingredients7: 'Beans',
          HowtoConsume: 'How to Consume',
          HowtoConsume1: 'Half teaspoon in the morning',
          HowtoConsume2: 'Half teaspoon in the morning',
          HowtoConsume3: 'Can be taken before meals or after meals.',
          Weight: 'Weight',
          Weight1: '280 g',
        },
      
      
        {
          id: 204,
          title: "Eczema Capsules and Eczema Vaseline",
          price: 3400.0,
          img: require("./assets/Medicine/Eczema Capsules and Eczema Vaseline.jpeg"),
          quantity: 1,
      
          dis: 'The natural and only permanent cure for Eczema.',
          Category: 'Medicine',
          Tags: ' Tags',
          Tags1: 'cleanses Blood, collagen, dry skin Eczema Capsules, Improves skin, kin energized, scaly skin, skin oxygenated, strengthens',
          EczemaCapsules: 'Eczema Capsules',
          EczemaCapsulesBenefits: 'Benefits',
          EczemaCapsulesBenefits1: 'Purifies/cleanses Blood',
          EczemaCapsulesBenefits2: ' Improves skin conditions',
          EczemaCapsulesBenefits3: ' Improves the product of collagen and strengthens it',
          EczemaCapsulesBenefits4: 'Promotes wound healing',
          EczemaCapsulesBenefits5: 'Helps with dry, scaly skin.',
          EczemaCapsulesBenefits6: 'Antibacterial and antimicrobial',
          EczemaCapsulesBenefits7: 'Eliminates parasitic worms.',
          EczemaCapsulesBenefits8: ' Anti inflammatory',
          EczemaCapsulesBenefits9: 'Contains cooling effects',
          EczemaCapsulesBenefits10: ' Keeps the skin energized',
          EczemaCapsulesBenefits11: 'Keeps the skin oxygenated',
          EczemaCapsulesIngredients: 'Ingredients',
          EczemaCapsulesIngredients1: ' Fagonia Arabica (Dhamasa)',
          EczemaCapsulesIngredients2: 'Bitter Ginger (Narkachoor)',
          EczemaCapsulesIngredients3: 'East Indian Globe Thisle (Gul Mandi)',
          EczemaCapsulesIngredients4: ' Turmeric (Haldi)',
          EczemaCapsulesIngredients5: 'Swertia (Charaita)',
          EczemaCapsulesIngredients6: 'Mexican Sarsaparilla (Ushb E Maghribi)',
          EczemaCapsulesIngredients7: 'Jujube (Unaab)',
          Dosage: 'Dosage',
          Dosage1: 'Adults – Two capsules in the morning and two capsules at night, after meals with water.',
          Dosage2: 'Upto 12 years – One capsule in the morning and one capsule at night, after meals with water.',
          EczemaVaseline: 'Eczema Vaseline',
          EczemaVaselineBenefits: 'Benefits',
          EczemaVaselineBenefits1: 'Improves skin conditions',
          EczemaVaselineBenefits2: ' Promotes wound healing',
          EczemaVaselineBenefits3: 'Keeps the skin hydrated',
          EczemaVaselineBenefits4: 'Anti inflammatory',
          EczemaVaselineBenefits5: 'Anti bacterial',
          EczemaVaselineBenefits6: 'Contains cooking effects',
          EczemaVaselineIngredients: 'Ingredients',
          EczemaVaselineIngredients1: '– Vaseline',
          EczemaVaselineIngredients2: '– Neem oil',
          EczemaVaselineIngredients3: '-Tea tree oil',
          EczemaVaselineIngredients4: '-Eucalyptus Oil',
          EczemaVaselineUsage: 'Usage',
          EczemaVaselineUsage1: 'Apply on the effected areas as required.',
          Weight: ' Weight',
          Weight1: '425 g',
        },
      
      
        {
          id: 205,
          title: "Cholesterol Capsules",
          price: 2500.0,
          img: require("./assets/Medicine/Cholesterol Capsules.jpeg"),
          quantity: 1,
      
          dis: 'Provides nutritional support and help maintain a healthy Cholesterol level.',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' Capsules, Cholesterol, fiber, good cholesterol, heart disease, lipoprotein, low density, stroke, Triglycerides',
          Benefits: 'Benefits',
          Benefits1: 'Decreases inflammation',
          Benefits2: 'Raises HDL (good cholesterol)',
          Benefits3: ' Decreases triglycerides',
          Benefits4: ' Slows down LDL absorption in the intestines',
          Benefits5: 'Rich in fibre, helps keep cholesterol levels lows',
          Ingredients: 'Ingredients',
          Ingredients1: 'Darcheeni (Cinnamon)',
          Ingredients2: 'Qaste Shireen (Marine Costus)',
          Ingredients3: 'Kalonji (Black Cumin)',
          Ingredients4: 'Methi Dana (Fenugreek seeds)',
          Ingredients5: 'Zeera (White Cumin)',
          Ingredients6: ' Saunf (Fennel)',
          Ingredients7: 'Elaichi Khurd (Elettaria Cardamomum)',
          Ingredients8: 'Kishneez Khushk (Coriander Sativum)',
          Dosage: 'Dosage',
          Dosage1: 'Adults: Two capsules in the morning and night with water (after meals)',
          Dosage2: 'Upto 12 years: 1 capsule in the morning and night with water (after meals)',
          Weight: 'Weight',
          Weight1: '135 g',
      
        },
      
      
        {
          id: 206,
          title: "Psoriasis Capsules and Eczema Vaseline",
          price: 3400.0,
          img: require("./assets/Medicine/Psoriasis Capsules and Eczema Vaseline.jpeg"),
          quantity: 1,
      
          dis: 'The natural and only permanent cure for Psoriasis.',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'psoriasis, psoriasis awareness, psoriasis community, psoriasis diet, psoriasis life, psoriasis relief, psoriasis sucks, psoriasis treatment, psoriasis warrior',
          PsoriasisCapsules: 'Psoriasis Capsules',
          PsoriasisCapsulesBenefits: 'Benefits',
          PsoriasisCapsulesBenefits1: 'Reduces inflammation',
          PsoriasisCapsulesBenefits2: ' Anti bacterial',
          PsoriasisCapsulesBenefits3: 'Reduces redness',
          PsoriasisCapsulesBenefits4: ' Boosts skin cell growth',
          PsoriasisCapsulesBenefits5: ' Reduces dryness and irritation',
          PsoriasisCapsulesBenefits6: ' Treats wounds and burns',
          PsoriasisCapsulesBenefits7: 'Aids healing',
          PsoriasisCapsulesBenefits8: ' Keeps the skin hydrated',
          PsoriasisCapsulesBenefits9: ' Oxygenates the skin',
          PsoriasisCapsulesIngredients: 'Ingredients',
          PsoriasisCapsulesIngredients1: ' Indian Barberry (Simblu)',
          PsoriasisCapsulesIngredients2: 'Margosa (neem ka patta)',
          PsoriasisCapsulesIngredients3: 'Rhubarb Roots (Raiwand Khatai)',
          PsoriasisCapsulesIngredients4: ' Raw Turmeric (Amba Haldi)',
          PsoriasisCapsulesIngredients5: ' Centella (Barhami Booti)',
          PsoriasisCapsulesIngredients6: ' Indian Fumitory (Shahatra)',
          PsoriasisCapsulesIngredients7: ' Bitter Stick (Chirata Nepali)',
          PsoriasisCapsulesDosage: 'Dosage',
          PsoriasisCapsulesDosage1: 'Adults: Two capsules in the morning and two capsules at night, one hour after meals with water.',
          PsoriasisCapsulesDosage2: 'Upto 12 years: One capsule in the morning and one capsule at night, one hour after meals.',
          EczemaVaseline: 'Eczema Vaseline',
          EczemaVaselineBenefits: 'Benefits',
          EczemaVaselineBenefits1: ' Improves skin conditions',
          EczemaVaselineBenefits2: 'Promotes wound healing',
          EczemaVaselineBenefits3: ' Keeps the skin hydrated',
          EczemaVaselineBenefits4: ' Anti inflammatory',
          EczemaVaselineBenefits5: 'Anti bacterial',
          EczemaVaselineBenefits6: 'Contains cooking effects',
          EczemaVaselineIngredients: 'Ingredients',
          EczemaVaselineIngredients1: '– Vaseline',
          EczemaVaselineIngredients2: '– Neem oil',
          EczemaVaselineIngredients3: '-Tea tree oil',
          EczemaVaselineIngredients4: '-Eucalyptus Oil',
          EczemaVaselineUsage: 'Usage',
          EczemaVaselineUsage1: 'Apply on the effected areas as required.',
          Weight: ' Weight',
          Weight1: '420 g',
        },
      
      
        {
          id: 207,
          title: "Migraine Capsule",
          price: 2600.0,
          img: require("./assets/Medicine/Migraine-cap.jpeg"),
          quantity: 1,
      
          dis: 'A permanent and natural cure for migraines.',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' Anti inflammatory anxiety, depression, headaches, insomnia, Migraine Capsule, Relieves pain, seizures, sleep disorders,treat migraine',
          Benefits: 'Benefits',
          Benefits1: 'Anti inflammatory',
          Benefits2: 'Reduces the risks of seizures',
          Benefits3: ' Naturally sedates the nerves',
          Benefits4: 'Relieves pain naturally',
          Benefits5: ' Eases headaches',
          Benefits6: ' Supports restful sleep',
          Benefits7: 'Treats insomnia',
          Benefits8: ' Treats anxiety and depression',
          Benefits9: 'Treats sleep disorders',
          Benefits10: ' Reduces inflammation',
          Ingredients: 'Ingredients',
          Ingredients1: ' Lavandula Stoechas (Ustukhuddus)',
          Ingredients2: ' Borage Flower (Gul E Gaozaban)',
          Ingredients3: 'Violet Flower (Gul E banafsha)',
          Ingredients4: ' East Indian Globe Thistle (Gul Mandi)',
          Ingredients5: ' Fennel (saunf)',
          Ingredients6: ' Valerian (Balchar)',
          Ingredients7: ' Coriander (khishneez khushk)',
          Ingredients8: ' Chamomile (Gul E Baaboona)',
          Usage: 'Usage/Dosage',
          Usage1: 'For adults; Two capsules in the morning and two capsules at night, after meals with water',
          Usage2: 'Upto 12 years: One capsule in the morning and one at night, after meals with water.',
          Weight: ' Weight',
          Weight1: '135 g',
      
        },
      
      
        {
          id: 208,
          title: "Asthma Capsule",
          price: 2400.0,
          img: require("./assets/Medicine/Asthma-capsule.jpeg"),
          quantity: 1,
      
          dis: 'A natural and the only guaranteed cure for Asthma.',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' Asthma Capsule, Borage Flower, bronchioles, chest congestion, congestion, coughs, Fennel, Hyssop, inflammation, Liquorice, swelling, Violet Flower',
          Benefits: 'Benefits',
          Benefits1: 'Sooths the bronchioles',
          Benefits2: 'Treats coughs associated with asthma',
          Benefits3: 'Breaks up chest congestion',
          Benefits4: 'Thins mucus',
          Benefits5: 'Decreases swelling and inflammation in the airways',
          Benefits6: 'Reduces congestion',
          Benefits7: 'Clears sinuses',
          Benefits8: 'Aids upper respiratory conditions.',
          Benefits9: 'Helps relieve asthma, providing a long term, permanent riddance.Ingredients',
          Benefits10: 'Hyssop (Gul e zoofa) ',
          Benefits11: 'Parsiyon Shan',
          Benefits12: 'Violet Flower (Gul e banafsha)',
          Benefits13: 'Borage Flower (Gul e Gaozaban)',
          Benefits14: 'Fennel (saunf)',
          Benefits15: 'Liquorice Root Powder (mulethi)    ',
          Usage: 'Usage/dosage;',
          Usage1: 'For adults; Two capsules in the morning and two capsules in the night, after meals with water.',
          Usage2: 'Upto 12 years; One capsule in the morning and one at night after meals with water.',
          Weight: 'Weight',
          Weight1: '130 g',
      
        },
      
      
        {
          id: 209,
          title: "Thyroid Hypo Capsules",
          price: 2400.0,
          img: require("./assets/Medicine/Thyroid-Hypo-Capsules.jpeg"),
          quantity: 1,
      
          dis: 'Cure your Thyroid the natural way.',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'Green Roots., herbal, medicines, thyroid, thyroid awarenesst, hyroid cancer, thyroid diet, thyroid disease, thyroid healing, thyroid health, thyroid problems, thyroid weight loss',
          Benefits: 'Benefits',
          Benefits1: 'Helps treat the function of hormones',
          Benefits2: ' Improves hepatic and renal oxidative stress',
          Benefits3: 'Improves the inflammatory response in hypothyroidism',
          Benefits4: ' Stimulates weight loss',
          Benefits5: ' Reduces joint pains',
          Benefits6: ' Helps regulate hypothyroidism levels',
          Benefits7: ' Eases stress',
          Benefits8: ' Increases metabolism',
          Benefits9: ' Fenugreek helps reduce the thyroid hormone level and modify the T3/T4 ratio',
          Benefits10: ' Improves blood levels',
          Ingredients: 'Ingredients',
          Ingredients1: ' Banslochan (tabasheer)',
          Ingredients2: ' Fenugreek Seeds (methi dana)',
          Ingredients3: 'Ceylon Cinnamon (Ceylon darcheeni)',
          Ingredients4: ' Dried Indian gooseberry (amla khushk)',
          Ingredients5: 'Coriander Sativum (Kishneez Khush)',
          Ingredients6: 'Indian Ginseng (Asgand Nagori)',
          Ingredients7: 'Spearmint (Desi Pudeena)',
          Dosage: 'Dosage',
          Dosage1: 'Adults: Two capsules in the morning and two capsules at night, after meals with water.',
          Dosage2: 'Upto 12 years: One capsule in the morning and one at night, after meals with water.',
          Weight: ' Weight',
          Weight1: '140 g',
      
        },
      
      
        {
          id: 210,
          title: "Uric Acid Capsules",
          price: 2600.0,
          img: require("./assets/Medicine/Uric-acid-capsules.jpeg"),
          quantity: 1,
      
          dis: 'For the treatment of gout and excessive Uric Acid.',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' bloodstream, calcium, gout attacksGreen Roots.,herbal, inflammation, iron, medicines, phosphorus, Protects body, Reduces pain, Uric acid, Uric Acid Capsules',
          Benefits: 'Benefits',
          Benefits1: ' Reduces pain and inflammation',
          Benefits2: ' Protects body from inflammation',
          Benefits3: ' Reduces severity of gout attacks',
          Benefits4: 'Reduces serum Uric acid levels',
          Benefits5: 'Rich in zinc, iron, calcium and phosphorus helps maintain Uric acid level in the body',
          Benefits6: ' Clears out excess Uric acid in the bloodstream and breaks down bile.',
          Ingredients: 'Ingredients',
          Ingredients1: ' Suranjan shireen (colchicum lutem)',
          Ingredients2: 'Qaste Shireen (Marine costus )',
          Ingredients3: ' Kalonji (Black cumin)',
          Ingredients4: 'Methi dana (Fenugreek seeds)',
          Ingredients5: ' Zeera Safaid (White Cumin)',
          Ingredients6: ' Elaichi Khurd (Elettaria Cardamomum)',
          Ingredients7: 'Asgand Nagori (Indian Ginseng)',
          Ingredients8: 'Saunf (Fennel)',
          Dosage: 'Dosage',
          Dosage1: ' Adults: Two capsules in the morning and night with water (after meals)',
          Dosage2: 'Upto 12 years: 1 capsule in the morning and night with water. (After meals)',
          Weight: ' Weight',
          Weight1: '	135 g',
      
        },
      
      
        {
          id: 211,
          title: "Pilex Capsules and Pilex Oil",
          price: 3000.0,
          img: require("./assets/Medicine/Pilex-capsules.jpeg"),
          quantity: 1,
      
          dis: 'The complete natural solution to get rid of haemorrhoids. An oil to be used with the medicine for a natural and complete treatment for haemorrhoids.',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: ' constipation, Green Roots., Hemorrhoids Oil, herbal, Medicine, Neem Seed, Pilex Capsules, Reduces itching, Reduces pain, Reduces swelling, turmeric',
          PilexCapsules: 'Pilex Capsules',
          PilexCapsulesBenefits: 'Benefits',
          PilexCapsulesBenefits1: 'Anti Inflammatory',
          PilexCapsulesBenefits2: 'Reduces pain',
          PilexCapsulesBenefits3: ' Reduces itching',
          PilexCapsulesBenefits4: ' Reduces the probability of worsening the problem',
          PilexCapsulesBenefits5: ' Reduces swelling',
          PilexCapsulesBenefits6: ' Eases bowel movements',
          PilexCapsulesBenefits7: ' Treats constipation',
          PilexCapsulesBenefits8: 'Treats Hemorrhoids permanently.',
          PilexCapsulesIngredients: 'Ingredients',
          PilexCapsulesIngredients1: 'Turmeric (Haldi)',
          PilexCapsulesngredients2: ' Neem Seed (Nimboli)',
          PilexCapsulesIngredients3: 'Chebulic Myrobalan (Bari Harr)',
          PilexCapsulesIngredients4: ' Dried Indian Gooseberry (Amla khushk)',
          PilexCapsulesIngredients5: 'Banslochan (Tabasheer)',
          PilexCapsulesIngredients6: 'Cumin (Zeera)',
          PilexCapsulesIngredients7: ' Mango Ginger (Amba Haldi)',
          PilexCapsulesIngredients8: ' Bitter Ginger (narkachoor)',
          PilexCapsulesUsage: 'Usage/dosage',
          PilexCapsulesUsage1: 'Adults: Two capsules in the morning and two at night, after meals with water.',
          PilexCapsulesUsage2: 'Upto 12 years: One capsule in the morning and one at night, after meals with water.',
          HemorrhoidsOil: 'Hemorrhoids (Oil)',
          HemorrhoidsOilHemorrhoidsOilHemorrhoidsOilBenefits: 'Benefits',
          HemorrhoidsOilHemorrhoidsOilBenefits1: ' Anti inflammatory',
          HemorrhoidsOilHemorrhoidsOilBenefits2: ' Reduces swelling',
          HemorrhoidsOilHemorrhoidsOilBenefits3: ' Relieves pain',
          HemorrhoidsOilHemorrhoidsOilBenefits4: '  Kills bacteria',
          HemorrhoidsOilHemorrhoidsOilBenefits5: 'Soothes the area',
          HemorrhoidsOilHemorrhoidsOilBenefits6: ' Reduces itching',
          HemorrhoidsOilHemorrhoidsOilIngredients: 'Ingredients',
          HemorrhoidsOilHemorrhoidsOilIngredients1: 'Neem Oil',
          HemorrhoidsOilHemorrhoidsOilIngredients2: ' Sesame Oil',
          HemorrhoidsOilHemorrhoidsOilIngredients3: 'Mastic Gum (mastagi roomi)',
          HemorrhoidsOilUsage: 'Usage/dosage',
          HemorrhoidsOilUsage1: 'Apply on the effected area as required.',
          Weight: ' Weight',
          Weight1: '225 g',
        },
      
      
        {
          id: 212,
          title: "Eczema and Psoriasis Vaseline",
          price: 1400.0,
          img: require("./assets/Medicine/Eczema-and-Psoriasis-Vaseline.jpg"),
          quantity: 1,
      
          dis: 'The natural and only permanent cure for Eczema.',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: 'chronic disease, dermatitis, Eczema, Improves skin, inflammation, milk crust, psoriasis, psoriasis treatment, rash, Vaseline',
          Benefits: 'Benefits',
          Benefits1: 'Improves skin conditions',
          Benefits2: 'Promotes wound healing',
          Benefits3: 'Keeps the skin hydrated',
          Benefits4: ' Anti inflammatory',
          Benefits5: ' Anti bacterial',
          Benefits6: ' Contains cooking effects',
          Ingredients: 'Ingredients',
          Ingredients1: '– Vaseline',
          Ingredients2: '– Neem oil',
          Ingredients3: '-Tea tree oil',
          Ingredients4: '-Eucalyptus Oil',
          Usage: 'Usage',
          Usage1: 'Apply on the effected areas as required.',
          Weight: 'Weight',
          Weight1: '190 g',
        },
      
      
        {
          id: 213,
          title: "Pilex Oil",
          price: 1000.0,
          img: require("./assets/Medicine/pilex-oil.jpg"),
          quantity: 1,
      
          dis: 'A natural and complete treatment for hemorrhoids.',
          Category: 'Medicine',
          Tags: 'Tags',
          Tags1: '100% pure, constipation, Green Roots., herbal medicines, herbs, Neem Seed, Pilex Oil, pure herbs, swollen veins, turmeric',
          Benefits: 'Benefits',
          Benefits1: 'Anti inflammatory',
          Benefits2: 'Reduces swelling',
          Benefits3: ' Relieves pain',
          Benefits4: ' Kills bacteria',
          Benefits5: ' Soothes the area',
          Benefits6: 'Reduces itching',
          Ingredients: 'Ingredients',
          Ingredients1: 'Neem Oil ',
          Ingredients2: 'Sesame Oil',
          Ingredients3: 'Mastic Gum (mastagi roomi)',
          Usage: 'Usage/dosage',
          Usage1: 'Apply on the effected area as required.',
          Weight: ' Weight',
          Weight1: '80 g',
      
        },


];
