import React from 'react'
import "./NewCards.css"
import image1 from "../../../../assets/Health & Beauty/acne-clear-face-wash-greenrootspk.com_.png"
import  image2 from "../../../../assets/Health & Beauty/age-reverse-oil-greenrootspk.com_.png"
import image3 from "../../../../assets/Health & Beauty/aloevera-neem-facewash-greenrootspk.com_.png"
import image11 from "../../../../assets/Medicine/allergy-cure-greenrootspk.com_.png"
import image12 from "../../../../assets/Medicine/acidity-medicine-greenrootspk.com_.png"
import image13 from "../../../../assets/Medicine/cardium-90-greenrootspk.com_.png"
import image14 from "../../../../assets/Medicine/clear-vision-greenrootspk.com_.png"
import image22 from "../../../../assets/Herbs & Spices/ajwain-seeds-greenrootspk.com_.png"
import image33 from "../../../../assets/Herbs & Spices/amla-powder-greenrootspk.com_.png"
import image44 from "../../../../assets/Herbs & Spices/asgandh-nagori-greenrootspk.com_.png"
import image54 from "../../../../assets/Essential Oil/aloe-vera-oil-greenrootspk.com_.png"
import image64 from "../../../../assets/Essential Oil/black-pepper-oil-greenrootspk.com_.png"
import image74 from "../../../../assets/Essential Oil/cinnamon-oil-greenrootspk.com_.png"
import image84 from "../../../../assets/Essential Oil/clove-oil-greenrootspk.com_.png"
import image92 from "../../../../assets/Natural Oil/argan-oil-greenrootspk.com_.png"
import image94 from "../../../../assets/Natural Oil/castor-oil-greenrootspk.com_.png"
import image96 from "../../../../assets/Natural Oil/coconut-oil-greenrootspk.com_.png"
import patt from "../../../../assets/HOME/partern.webp"
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
function NewCards() {
    return (
        <div className='new_cardss my-5'>
            <Container>
                <h3>
                    Health & Beauty
                    <span>
                        <img src={patt} alt="" />
                    </span>
                </h3>
                <main className='d-flex new_cards'>

                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/health-beauty/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image1}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Acne Clear Face Wash</Link>
                                                </h3>
                                                <div className="prices"><span>200 ML</span> <br /> <span
                                                    className="price actual-price"> <b>  Rs 1000  <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                               
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/health-beauty/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image2}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Age Reverse Oil</Link>
                                                </h3>
                                                <div className="prices"><span>30 ML</span> <br /> <span
                                                    className="price actual-price"> <b>  Rs 9500 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/health-beauty/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image3}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Aloe Vera & Neem Face Wash</Link>
                                                </h3>
                                                <div className="prices"><span>200 ML</span> <br /> <span
                                                    className="price actual-price"> <b>  Rs 1000  <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/health-beauty/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image1}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Acne Clear Face Wash</Link>
                                                </h3>
                                                <div className="prices"><span>200 ML</span> <br /> <span
                                                    className="price actual-price"> <b>  Rs 1000  <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </Container>

            <Container>
                <h3>
                Medicines
                    <span>
                        <img src={patt} alt="" />
                    </span>
                </h3>
                <main className='d-flex new_cards'>

                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/medicines/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image11}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Allergy Cure</Link>
                                                </h3>
                                                <div className="prices"><span>140 Grams</span> <br /> <span
                                                    className="price actual-price"> <b>  Rs 2000 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/medicines/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image12}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Acidity Medicine</Link>
                                                </h3>
                                                <div className="prices"><span>140 Grams</span> <br /> <span
                                                    className="price actual-price"> <b> Rs 1800 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/medicines/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image13}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Cardium 90</Link>
                                                </h3>
                                                <div className="prices"><span>175 Grams</span> <br /> <span
                                                    className="price actual-price"> <b>  Rs 1400  <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/medicines/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image14}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Clear Vision</Link>
                                                </h3>
                                                <div className="prices"><span>175 Grams</span> <br /> <span
                                                    className="price actual-price"> <b>  Rs 1400  <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </Container>

            <Container>
                <h3>
                Herbs & Spices

                    <span>
                        <img src={patt} alt="" />
                    </span>
                </h3>
                <main className='d-flex new_cards'>

                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/herbs-spices/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image22}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Ajwain Seeds</Link>
                                                </h3>
                                                <div className="prices"><span>50 Grams</span> <br /> <span
                                                    className="price actual-price"> <b> Rs 90 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/herbs-spices/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image22}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Ajwain Seeds</Link>
                                                </h3>
                                                <div className="prices"><span>100 Grams</span> <br /> <span
                                                    className="price actual-price"> <b> Rs 180 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/herbs-spices/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image33}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Amla Powder (Indian Gooseberry)</Link>
                                                </h3>
                                                <div className="prices"><span>100 Grams</span> <br /> <span
                                                    className="price actual-price"> <b> Rs 200  <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/herbs-spices/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image44}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Asgandh Nagori Powder</Link>
                                                </h3>
                                                <div className="prices"><span>100 Grams</span> <br /> <span
                                                    className="price actual-price"> <b> Rs 800 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </Container>

            <Container>
                <h3>
                Essential Oils


                    <span>
                        <img src={patt} alt="" />
                    </span>
                </h3>
                <main className='d-flex new_cards'>

                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/essential-oils/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image54}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Aloe Vera Oil</Link>
                                                </h3>
                                                <div className="prices"><span>30 ML</span> <br /> <span
                                                    className="price actual-price"> <b> Rs 1000 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/essential-oils/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image64}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Black Pepper Oil</Link>
                                                </h3>
                                                <div className="prices"><span>30 ML</span> <br /> <span
                                                    className="price actual-price"> <b> Rs 6000 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/essential-oils/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image74}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Cinnamon Oil</Link>
                                                </h3>
                                                <div className="prices"><span>30 ML </span> <br /> <span
                                                    className="price actual-price"> <b> Rs 1600 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/essential-oils/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image84}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Clove Oil</Link>
                                                </h3>
                                                <div className="prices"><span>30 ML</span> <br /> <span
                                                    className="price actual-price"> <b> Rs 1400 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </Container>

            <Container>
                <h3>
                Natural Oils


                    <span>
                        <img src={patt} alt="" />
                    </span>
                </h3>
                <main className='d-flex new_cards'>

                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/natural-oils/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image92}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Argan Oil</Link>
                                                </h3>
                                                <div className="prices"><span>120 ML</span> <br /> <span
                                                    className="price actual-price"> <b>Rs 7000 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/natural-oils/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image94}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Castor Oil</Link>
                                                </h3>
                                                <div className="prices"><span>120 ML</span> <br /> <span
                                                    className="price actual-price"> <b>Rs 400 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/natural-oils/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image94}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Castor Oil</Link>
                                                </h3>
                                                <div className="prices"><span>200 ML</span> <br /> <span
                                                    className="price actual-price"> <b> Rs 600 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="item" >
                        <div>

                            <div className="item-box product-grid  product-align clearfix">
                                <div className="thumbnail">
                                    <div className="product-item clearfix" data-productid="4125">
                                        <div className="picture"><Link to="https://shop.greenrootspk.com/product-category/natural-oils/"
                                            title="Show details for Herbal Oil 30ml - Rosemary"> <img className="lazyOwl" src={image96}
                                                alt="Picture of Herbal Oil 30ml - Rosemary"
                                            /> </Link></div>
                                        <div className="caption">
                                            <div className="details">
                                                <h3 className="product-title"><Link to="#">Coconut Oil</Link>
                                                </h3>
                                                <div className="prices"><span>120 ML</span> <br /> <span
                                                    className="price actual-price"> <b> Rs 450 <span style={{ color: 'red' }}> </span></b></span></div>
                                                <div className="product-rating-box" title="0 review(s)">
                                                    <div className="rating">
                                                        <div style={{ width: "0%" }}></div>
                                                    </div>
                                                </div>
                                                <p className="description"></p>
                                                <div className="add-info">
                                                    <div className="buttons"></div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </Container>
        </div>
    )
}

export default NewCards
