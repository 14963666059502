import "./MainDashboard.css";
import SideBar from "./components/Sidebar/SideBar";

function MainDashboard() {
  return (
   
      <SideBar>
       
      </SideBar>

  );
}

export default MainDashboard;
