export default [
    {
        id: 1,
        title: "Ajwain Seeds",
        price: 90.0,
        img: require("./assets/Herbs & Spices/ajwain-seeds-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green Roots Ajwain (Bishops weed) is a herb which deals with many health issues and skin issues. Can fight against bacteria and fungus.',
        SKU: 'SKU',
        SKU1: 'GR002HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' ajwain, cholesterol control, healthy breathing, healthy metabolism, menstruation cycle natural remedy, seed, sweight loss',
        Benefits: 'Benefits',
        Benefits1: 'Prevents coughing and opens respiratory passage',
        Benefits2: 'Induces menstrual cycle and relieves pain ',
        Benefits3: 'Fights against ulcer and constipation',
        Benefits4: 'Improves cholesterol level',
        Benefits5: 'Lowers Blood pressure',
        Benefits6: 'Helps in weight loss',
        Benefits7: 'Improves metabolism',
        Usage:'Usage',
        Usage1:'Drinking tea of Ajwain helps relieve in menstrual cramps and relieves cough.',
        Usage2:'Daily intake of 1 teaspoon of Ajwain helps in improving metabolism, lowers cholesterol, helps maintain weight and helps against ulcer and constipation.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '55 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    {
        id: 2,
        title: "Ajwain Seeds",
        price: 180.0,
        img: require("./assets/Herbs & Spices/ajwain-seeds-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Ajwain (Bishops weed) is a herb which deals with many health issues and skin issues. Can fight against bacteria and fungus.',
        SKU: 'SKU',
        SKU1: 'GR002HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' ajwain, cholesterol control, healthy breathing, healthy metabolism, menstruation cycle natural remedy, seed, sweight loss',
        Benefits: 'Benefits',
        Benefits1: 'Prevents coughing and opens respiratory passage',
        Benefits2: 'Induces menstrual cycle and relieves pain ',
        Benefits3: 'Fights against ulcer and constipation',
        Benefits4: 'Improves cholesterol level',
        Benefits5: 'Lowers Blood pressure',
        Benefits6: 'Helps in weight loss',
        Benefits7: 'Improves metabolism',
        Usage:'Usage',
        Usage1:'Drinking tea of Ajwain helps relieve in menstrual cramps and relieves cough.',
        Usage2:'Daily intake of 1 teaspoon of Ajwain helps in improving metabolism, lowers cholesterol, helps maintain weight and helps against ulcer and constipation.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '55 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },


    {
        id: 3,
        title: "Amla Powder (Indian Gooseberry)",
        price: 200.0,
        img: require("./assets/Herbs & Spices/amla-powder-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        
        dis: 'Amla powder is made from the ground-up leaves of the Indian gooseberry. Itâ€™s been used in Ayurvedic medicine for centuries to treat everything from diarrhea to jaundice. Also, beneficial for eyesight, skin health, constipation, diabetes, anaemia, nervous system health and prevention and cure from a lot of other diseases and conditions.',
        SKU: 'SKU',
        SKU1: 'GR003HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' amla, diabetes cure, hair strengthening, heart health, immune booster, indian gooseberry, nerve health, powder, skin care',
        Benefits: 'Benefits',
        Benefits1: 'Improves eye vision',
        Benefits2: 'Improves skin health',
        Benefits3: 'Prevents ageing',
        Benefits4: 'Increases immunity',
        Benefits5: 'Improves hair health',
        Benefits6: 'Prevents constipation',
        Benefits7: 'Improves nervous system health',
        Benefits8: 'Improves memory and brain health',
        Benefits9: 'Protects and cures diabetes',
        Benefits10: 'Improves cardiovascular health',
        Benefits11: 'Prevents anemia',
        Usage:'Usage',
        Usage1:'By mixing 2 teaspoons of Amla Powder with 2 teaspoons of honey will help you get immediate results with sore throat and cold.',
        Usage2:'Take a cup of water and mix it with half teaspoon of Amla Powder can heal mouth ulcer and pain by gargling with this mixture.',
        Usage3:'Mix Amla Powder in Henna and apply on the hair makes your hair nourish and helps slowing down greying of hair, also it gives shine to your hairs.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Amla',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '120 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 4,
        title: "Asgandh Nagori Powder",
        price: 800.0,
        img: require("./assets/Herbs & Spices/asgandh-nagori-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: '(Indian Ginseng Powder) Asgandh Nagori Powder (Indian ginseng powder) is effective in regulating sleep, easing stress and combating depression. Boosts testosterone in men , helps in recovering of joint pains and boosts anti-ageing.',
        SKU: 'SKU',
        SKU1: 'GR004HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' anti ageing, anti anxiety, anti depression, asgandh nagori, fertility booster, immune booster, indian ginseng powder, nerve health, sleep cycle, testosterone booster',
        Benefits: 'Benefits',
        Benefits1: 'Calms the nervous system',
        Benefits2: 'Regulates sleep',
        Benefits3: 'Eases stress',
        Benefits4: 'Helps overcome laziness',
        Benefits5: 'Helps boost fertility',
        Benefits6: 'Helps combat depression',
        Benefits7: 'Reduces anxiety',
        Benefits8: 'Boosts testosterone in men',
        Benefits9: 'Boosts immunity',
        Benefits10: 'Boosts recovery of joint pains',
        Benefits11: 'Boosts anti-ageing',
        Usage:'Usage',
        Usage1:'It can be consumed directly with water by taking one (01) teaspoon of the Asgandh Nagori powder. Or by converting it into capsules.',
        Usage2:'TFor joints and muscles pain take one (01) teaspoon Asgandh Nagori powder daily with milk.',
        Usage3:'For diabetes, take one (01) or half (1/2) teaspoon of Asgandh Nagori powder.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 5,
        title: "Chia Seeds",
        price: 400.0,
        img: require("./assets/Herbs & Spices/chia-seeds-herbs-50-1-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        
        dis: 'Green Roots Chia seeds contain large amounts of fiber and omega 3 fatty acids, plenty of high quality protein and several essential minerals and antioxidants. Which are good for skin, and health.',
        SKU: 'SKU',
        SKU1: 'GR005HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' anti inflammation, bone health, chia, heart health, seeds, skin tightening, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Lowers risk of heart diseases',
        Benefits2: 'Helps in weight loss',
        Benefits3: 'Helps reduce inflammation',
        Benefits4: 'Good for skin tightening',
        Benefits5: 'Gives nutrition to the bones',
        Benefits6: 'FRIENDLY TO USE IN DAILY DIET',
        Usage:'Usage',
        Usage1:'In a glass of water add 1 tablespoon of chia seeds, leave it overnight drink early morning. Same procedure applied for night use, which helps reduce fats from body.',
        Usage2:'Adding in protein shakes or healthy meals gives energy to body and bones.',
        Usage3:'Mixing two tablespoons of chia seeds with a half cup of coconut oil and a tablespoon of lemon juice, let this mask be on your face for 10 to 112 minutes, it will form a gel-like layer, follow this with massaging your face in circular motion for few minutes, which helps to exfoliate your skin and wash with Lukewarm water.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    

    {
        id: 6,
        title: "Chia Seeds",
        price: 800.0,
        img: require("./assets/Herbs & Spices/chia-seeds-herbs-50-1-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Chia seeds contain large amounts of fiber and omega 3 fatty acids, plenty of high quality protein and several essential minerals and antioxidants. Which are good for skin, and health.',
        SKU: 'SKU',
        SKU1: 'GR005HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' anti inflammation, bone health, chia, heart health, seeds, skin tightening, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Lowers risk of heart diseases',
        Benefits2: 'Helps in weight loss',
        Benefits3: 'Helps reduce inflammation',
        Benefits4: 'Good for skin tightening',
        Benefits5: 'Gives nutrition to the bones',
        Benefits6: 'FRIENDLY TO USE IN DAILY DIET',
        Usage:'Usage',
        Usage1:'In a glass of water add 1 tablespoon of chia seeds, leave it overnight drink early morning. Same procedure applied for night use, which helps reduce fats from body.',
        Usage2:'Adding in protein shakes or healthy meals gives energy to body and bones.',
        Usage3:'Mixing two tablespoons of chia seeds with a half cup of coconut oil and a tablespoon of lemon juice, let this mask be on your face for 10 to 112 minutes, it will form a gel-like layer, follow this with massaging your face in circular motion for few minutes, which helps to exfoliate your skin and wash with Lukewarm water.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 7,
        title: "Dhamasa Booti",
        price: 120.0,
        img: require("./assets/Herbs & Spices/dhamsa-booti-herbs-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: '(Fagonia Arabica) Dhamasa Booti is a single herbal medicine and very effective on general health issues i.e cancers, hepatitis, heart disorders. Along with that it also works on curing asthma, managing weight, skin problems, gum problems, allergies and blood purification.',
        SKU: 'SKU',
        SKU1: 'GR008HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti allergy, antioxidant, blood purifier, cancer beneficent, dhamsa booti, fertility booster, gum health, hepatitis cure, painkiller, skin health, smallpox cure',
        Benefits: 'Benefits',
        Benefits1: 'Beneficial for cancers, specially blood cancer and liver cancer',
        Benefits2: 'Cures hepatitis, smallpox, reproductive issues',
        Benefits3: 'Beneficial for heart, skin, gums, kidneys',
        Benefits4: 'Works as a painkiller',
        Benefits5: 'Helps fight against allergies, blood pressure',
        Benefits6: 'Antioxidant',
        Benefits7: 'Helps cure asthma',
        Benefits8: 'Helps manage weight.',
        Usage:'Usage',
        Usage1:'Prepare a cup of Dhamasa tea by putting one teaspoon in a cup of water and boil for couple of minutes util its color turns light brown.',
        Usage2:'Taking it twice a day helps in curing breast cancer and pain relieve.',
        Usage3:'Soak 3 tablespoons of Dhamasa into 2 glass of water leave it overnight. Boil it in the morning to reduce half of the liquid and drink it as tea, twice a day for cancer preventing and pain relieving.',
        Usage4:'For dizziness, consume 3 tablespoons of Dhamasa Booti with ghee twice a day.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Dhamsa',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 8,
        title: "Flax Seeds",
        price: 90.0,
        img: require("./assets/Herbs & Spices/flax-seed-50-gram-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green Roots Flax Seeds (alsi) is full of nutrients and high in proteins. It is high in omega 3, reduces the risk of cancer and helps improves cholesterol. Helps control blood sugar and maintaining and losing weight. Flax seeds can be mixed with anything easily to add in daily use.',
        SKU: 'SKU',
        SKU1: 'GR009HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' blood pressure natural medicin, ecancer beneficent, cholesterol control, diabetes cotrol, fiber boostflax, natural protein, omega 3, seeds, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Full of nutrients',
        Benefits2: 'High in omega 3',
        Benefits3: 'Reduces the risk of cancer',
        Benefits4: 'Rich in dietary fiber',
        Benefits5: 'Helps improves cholesterol',
        Benefits6: 'Reduces blood pressure',
        Benefits7: 'High in proteins',
        Benefits8: 'Helps control blood sugar',
        Benefits9: 'Helps in maintaining and losing weight',
        Benefits10: 'Can be mixed with anything easily to add in daily use.',
        Usage:'Usage',
        Usage1:'To make a weight loss drink out of flax seeds, take one (01) teaspoon of flax seed powder and mix it in a glass of boiled water, add a little amount of fresh lemon and drink daily for visible results.',
        Usage2:'To make a healthy supplement for hair and skin take one (01) teaspoon of Flax Seeds Powder and one (01) teaspoon of Pumpkin Seeds powder â€“ take about one (01) teaspoon of the mixture daily with milk or water.',
        Usage3:'Flax seeds are filled with protein and can be sprinkled on smoothies or fruit salads.',
        Usage4:'By taking one (01) or half (1/2) teaspoon of Flax Seeds powder with water helps in diabetes control',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
       
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',  
    },

    {
        id: 9,
        title: "Flax Seeds",
        price: 180.0,
        img: require("./assets/Herbs & Spices/flax-seed-50-gram-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        
        dis: 'Green Roots Flax Seeds (alsi) is full of nutrients and high in proteins. It is high in omega 3, reduces the risk of cancer and helps improves cholesterol. Helps control blood sugar and maintaining and losing weight. Flax seeds can be mixed with anything easily to add in daily use.',
        SKU: 'SKU',
        SKU1: 'GR009HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' blood pressure natural medicin, ecancer beneficent, cholesterol control, diabetes cotrol, fiber boostflax, natural protein, omega 3, seeds, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Full of nutrients',
        Benefits2: 'High in omega 3',
        Benefits3: 'Reduces the risk of cancer',
        Benefits4: 'Rich in dietary fiber',
        Benefits5: 'Helps improves cholesterol',
        Benefits6: 'Reduces blood pressure',
        Benefits7: 'High in proteins',
        Benefits8: 'Helps control blood sugar',
        Benefits9: 'Helps in maintaining and losing weight',
        Benefits10: 'Can be mixed with anything easily to add in daily use.',
        Usage:'Usage',
        Usage1:'To make a weight loss drink out of flax seeds, take one (01) teaspoon of flax seed powder and mix it in a glass of boiled water, add a little amount of fresh lemon and drink daily for visible results.',
        Usage2:'To make a healthy supplement for hair and skin take one (01) teaspoon of Flax Seeds Powder and one (01) teaspoon of Pumpkin Seeds powder â€“ take about one (01) teaspoon of the mixture daily with milk or water.',
        Usage3:'Flax seeds are filled with protein and can be sprinkled on smoothies or fruit salads.',
        Usage4:'By taking one (01) or half (1/2) teaspoon of Flax Seeds powder with water helps in diabetes control',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        Ingredients:'Ingredients',
        Ingredients1:'Dhamsa',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 10,
        title: "Gond Katira",
        price: 400.0,
        img: require("./assets/Herbs & Spices/gond-katira-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: '(Tragacanth Gum) Gond Katira is a natural gum obtained from the dried sap of several species of Middle Eastern legumes of the genus Astragalus. Gondh Katira serves for several health benefits, such as; prevent heart strokes, Good for pregnant women, urinary lewdness, health benefits for men, health benefits for women, provides energy, Good for Lactating mothers, Benefits for skin and beauty, good for constipation.',
        SKU: 'SKU',
        SKU1: 'GR010HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'blood circulation, blood sugar, constipation cure, gond katira, healthy heart, herb, tragacanth gum',
        Benefits: 'Benefits',
        Benefits1: 'Prevents heart strokes',
        Benefits2: 'Beneficial for lactating mothers',
        Benefits3: 'Beneficial for skin & beauty',
        Benefits4: 'Beneficial for pregnant women',
        Benefits5: 'Beneficial for men and women',
        Benefits6: 'Cures constipation',
        Benefits7: 'Fights against urinary infections (UTIs)',
        Benefits8: 'Reduces acne and tightens skin',
        Benefits9: 'Acts like a cooking agent in summers',
        Benefits10: 'Regulates blood sugar levels',
        Benefits11: 'Improves blood circulation',
        Usage:'Usage',
        Usage1:'Gond Katira is an excellent antiaging supplement for skin. Take half teaspoon, add in water and mix it. Apply the mixture on your face neck leave for 10 to 15 minute and wash.',
        Usage2:'By taking half teaspoon Gond Katira daily can help relieves constipation and performs function that helps in improving digestive function.',
        Usage3:'Take one teaspoon daily with milk once in a day or twice helps in relieving joints pain and makes bones stronger.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 11,
        title: "Gul Mandi",
        price: 260.0,
        img: require("./assets/Herbs & Spices/gul-mandi-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: '(East Indian Globe Thistle) East Indian Globe Thistle/Gul Mandi (Ú¯Ù„ Ù…Ù†Ø¯ÛŒ) also known as Sphaeranthus indicus is a medicinal plant that is widely found in Southeast Asia. It is beneficial for treating indigestion, tuberculosis, bronchitis, spleen infections, anemia, piles, skin infections and other similar health conditions.',
        SKU: 'SKU',
        SKU1: 'GR011HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'asthma cure, bronchitis cure, east indian globe thistle, gul mandi, healthy digestion, herbskin disease cure, tuberculosis cure, vaginal pain cure, vomit control',
        Benefits: 'Benefits',
        Benefits1: 'Treats tuberculosis, asthma, bronchitis',
        Benefits2: 'Treats indigestion',
        Benefits3: 'Treats spleen diseases',
        Benefits4: 'Treats anemia, piles',
        Benefits5: 'Beneficial for vaginal & uterus pains',
        Benefits6: 'Treats parasitic worms',
        Benefits7: 'Treats skin infections',
        Benefits8: 'It used to control dysentery and vomiting',
        Usage:'Usage',
        Usage1:'Gul Mandi helps in treating with Acne Vulgaris, constipation, blood Impurities, Jaundice. By consuming the half teaspoon of 1 teaspoon of this herb or by making it as a tea.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Gul Mandi',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 12,
        title: "Mehndi (Henna Plant)",
        price: 175.0,
        img: require("./assets/Herbs & Spices/mehndi-henna-plant-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',


        dis: 'Pudina also known as mentha is an energizing herb. Mint is used as mint oil, tooth pastes, mouthwashes and flavoring agents in many dishes. It works as a painkiller, cures multiple health problems, highly beneficial for digestion, works on the skin and respiratory disorders as well.',
        SKU: 'SKU',
        SKU1: 'GR007HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti allergy, anti depression, healthy breathing, healthy digestion, memory booster, mint, pudin, aweight loss',
        Benefits: 'Benefits',
        Benefits1: 'Strengthens hair',
        Benefits2: 'Acts as natural hair color',
        Benefits3: 'Nourishes the hair',
        Benefits4: 'Makes hair shinny',
        Benefits5: 'Contains energy healing',
        Benefits6: 'Covers grey hair',
        Benefits7: 'Makes hair thicker',
        Benefits8: 'Improves digestive health',
        Benefits9: 'Improves blood circulation',
        Benefits10: 'Reduces anxiety and stress',
        Benefits11: 'Prevents diabetes',
        Benefits12: 'Anti inflammatory activities',
        Benefits13: 'Good for wound treatments',
        Benefits14: 'Prevents the risk of cancer',
        Benefits15: 'Improves skin health',
        Ingredients:'Ingredients',
        Ingredients1:'Podina',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 13,
        title: "Moringa Powder (Suhanjna)",
        price: 450.0,
        img: require("./assets/Herbs & Spices/moringa-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Moringa is taken by mouth for â€œtired bloodâ€ (anemia), arthritis and other joint pain (rheumatism), asthma, cancer, constipation, diabetes, diarrhea, seizures, stomach pain, stomach and intestinal ulcers, intestinal spasms, headache, heart problems, high blood pressure, kidney stones, symptoms of menopause, thyroid.',
        SKU: 'SKU',
        SKU1: 'GR017HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' eyesight cure, hair nourishment, healthy bones, healthy heart, herb, high blood pressure, moringa, skin care, skin nourishment, suhanjna',
        Benefits: 'Benefits',
        Benefits1: 'Protects and nourishes hair and skin.',
        Benefits2: 'Protects the liver & heart',
        Benefits3: 'Prevents and treats cancer',
        Benefits4: 'Helps treat stomach disorders & heals wounds',
        Benefits5: 'Makes bones healthier & treats anemia',
        Benefits6: 'Treats mood disorders, asthma & diabetes',
        Benefits7: 'Protects against kidney disorders',
        Benefits8: 'Reduces high blood pressure',
        Benefits9: 'Improve eye health',
        Usage:'Usage',
        Usage1:'(For Adults) One tablespoon Moringa Powder, mix it with milk and take once in a day .',
        Usage2:'(For Children above 10 years age) One teaspoon Moringa Powder to be added in one cup milk or water for once in a day only.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 14,
        title: "Orange Peel Powder",
        price: 140.0,
        img: require("./assets/Herbs & Spices/orange-peel-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

         
        dis: '(Keenu Ke Chilke Ka Powder) Considered as a boon for face and skincare, orange peel powder helps to cure blackheads, dead cells, acne, pores, blemishes, dark circles, dry skin and brightens your face. It can also be applied with milk or curd for that extra glow or for removing tan. Also, rub your nails with its peel for shine. Along with that it also prevents viral infections, helps fight cancer, beneficial for the kidney and many more health related problems',
        SKU: 'SKU',
        SKU1: 'GR019HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'bone health, cancer beneficent, healthy heart, herb, high blood pressure, kidney health, orange peel, skin nourishment, virus protection',
        Benefits: 'Benefits',
        Benefits1: 'Whitens skin & teeth',
        Benefits2: 'Fights acne',
        Benefits3: 'Delays wrinkles',
        Benefits4: 'Removes dead skin cells & scars',
        Benefits5: 'Works against dandruff and scalp problems',
        Benefits6: 'Aids in weight loss',
        Benefits7: 'Balances natural oils',
        Benefits8: 'Reduces inflammation',
        Benefits9: 'Keeps bones and teeth strong',
        Benefits10: 'Prevents viral infections prevents arthritis',
        Benefits11: 'Improves blood circulation',
        Benefits12: 'Eliminates bad breath',
        Benefits13: 'Helps fight cancer & regulate high blood pressure',
        Benefits14: 'Improves heart health',
        Benefits15: 'Good for kidneys',
        Usage:'Usage',
        Usage1:'Orange Peel Powder is used as a beauty agent. For brightening of skin and removing dark spots, add 1/2 teaspoon of Orange Peel Powder and 1/2 teaspoon Sandalwood Powder, mix it with milk or yogurt and apply on face and neck and hands for 20 to 25 minutes then gently wash your skin.',
        Usage2:'For reducing acne, add one teaspoon of Orange Peel Powder and two teaspoon of Kaolin Clay, mix in green tea or rose water and apply on your face, let it get dried then wash your skin gently. Same can be done twice a week for effective results.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Orange Peel',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 15,
        title: "Qast E Shireen",
        price: 180.0,
        img: require("./assets/Herbs & Spices/qaste-shireen-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: '(Marine Costus) Costus root is used for treating worm (nematode) infections. Costus oil is used for asthma, cough, gas, and severe intestinal diseases such as dysentery and cholera. It is also used as a tonic and to stimulate digestion.',
        SKU: 'SKU',
        SKU1: 'GR020HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' blood purifier, detox, healing, healthy digestion, herb, intestinal diseases cure, liver detox, marine costus, qaste shireen, regulates blood pressure, skin nourishment',
        Benefits: 'Benefits',
        Benefits1: 'Purifies the blood',
        Benefits2: 'Treats respiratory diseases, worm infections, cough, gas, asthma & severe intestinal disease',
        Benefits3: 'Stimulates digestion',
        Benefits4: 'Helps improve skin health',
        Benefits5: 'Detoxes the liver',
        Benefits6: 'Maintains sexual health in men',
        Benefits7: 'Heals wounds',
        Benefits8: 'Regulates blood pressure',
        Usage:'Usage',
        Usage1:'Qust E Shireen herb helps in treating skin allergies, eczema, analgesic and others. For treating skin allergies, take three (03) teaspoons of Qust E shireen powder, one (01) teaspoon of Choti Elaichi (cardamom) powder and eight (08) teaspoons of boiled water. Put it in a container, cover it for half an hour and take one ounce of it whenever required..',
        Usage2:'By taking one (01) gram Qust E Shireen powder daily will help in treating paralysis.',
        Usage3:'For treating cough, cold and fever, take one (01) teaspoon of Qust E Shirren mix it with one (01) tablespoon of honey to make paste of it. Take half (1/2) or one (01) teaspoon of the mixture daily for as long as it is required for an instant relieve.',
        Usage4:'For treating Varicose veins take equal quantity of Qust E shireen powder and vegetable oil to make fine paste (as much required for effected area), apply on the affected areas helps in blood circulation and strengthens the weak nerves.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 16,
        title: "Shikakai",
        price: 200.0,
        img: require("./assets/Herbs & Spices/shikakai-herbs-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: '(Acacia Concinna) Shikakai Powder acts as a natural astringent for hair enhancing its binding properties. It clears. dandruff and cleans the dirt accumulated on the scalp. It is also beneficial for hair lice, hairfall, bad cholesterol, glucose levels and aids in eczema and psoriasis.',
        SKU: 'SKU',
        SKU1: 'GR024HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' acacia concinna, dry hair, eczema relief, hair conditions, hair nourishment, herb, oily hair, psoriasis relief, shikakai, skin care, skin nourishment',
        Benefits: 'Benefits',
        Benefits1: 'Makes hair soft and shiny',
        Benefits2: 'Treats dandruff',
        Benefits3: 'Conditions dry hair',
        Benefits4: 'Beneficial for oily scalps',
        Benefits5: 'Controls hair fall',
        Benefits6: 'Prevents pre-ageing of the hair',
        Benefits7: 'Makes natural hair color long lasting',
        Benefits8: 'Helps in removing hair lice, healing scalp wounds, quick hair growth',
        Benefits9: 'Helps soothe side effects of eczema and psoriasis.',
        Benefits10: 'Nourishes & detangles hair',
        Benefits11: 'Repairs and prevents split ends',
        Benefits12: 'Boosts good cholesterol and reduces prevents bad cholesterol',
        Benefits13: 'Helps keep blood glucose level in control',
        Benefits14: 'Hydrates dry skin',
        Benefits15: 'Gives the skin a natural glow',
        Usage:'Usage',
        Usage1:'By mixing Shikakai Powder with yogurt and applying onto the hairs for 20 minutes will give nourishment to the hairs and make them shiny and silky.',
        Usage2:'Make a mixture by adding two (02) teaspoons of Shikakai Powder with one (01) teaspoon of Reetha Powder, one (01) teaspoon of Amla and adding two (02) egg whites to apply on hairs will help control Hair fall, controls dandruff and stimulates hair follicles.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        Ingredients:'Ingredients',
        Ingredients1:'Shikai',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },


    {
        id: 17,
        title: "Simblu (Indian Barberry)",
        price: 200.0,
        img: require("./assets/Herbs & Spices/simblu-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'The Powder of Simblu Root (also known as Sumbloo) is good for healing cancer patients. It also works on bronchitis, asthma, controlling muscle spasms, aids in weight loss and has antibacterial properties.',
        SKU: 'SKU',
        SKU1: 'GR029HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'antibacterial, asthma cure, bronchitis cure, cancer beneficent, herb, indian barberry, muscle spasm control, simblu, stress reliever, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Cures bronchitis & asthma',
        Benefits2: 'Controls muscle spasms',
        Benefits3: 'Creates relaxation',
        Benefits4: 'Helps treat fatal diseases',
        Benefits5: 'Aids weight loss',
        Benefits6: 'Antibacterial',
        Benefits7: 'Anti cancer',
        Benefits8: 'Helps fight various kinds of cancers',
        Usage:'Usage',
        Usage1:'By taking half (1/2) teaspoon of Simblu Powder daily with water helps treat with acne and allergy.',
        Usage2:'By making a paste of Simblu Powder and applying on effected skin will help reducing inflammation in body.',
        Usage3:'Add one (01) teaspoon of Simblu Powder in a glass of water and leave it overnight. Gargle the next morning with this mixture helps throat infection and clears voice.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        Ingredients:'Ingredients',
        Ingredients1:'Indian Barberry',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 18,
        title: "Tukhm-e-Jaman",
        price: 120.0,
        img: require("./assets/Herbs & Spices/tukhme-jaman-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: '(Jambolan Seeds) Tukhm-e-Jaman is also known as Jambolan. It is full of essential nutrients and contains many vitamins and minerals. There are many health benefits as well, such as relief from fatigue, pains, digestion disorders, and diabetes.',
        SKU: 'SKU',
        SKU1: 'GR030HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'diabetes cure, fatigue reliever, healthy digestion, herb, immune booster, jambolan seeds, jamun, pain reliever, skin nourishment, tukhme jaman',
        Benefits: 'Benefits',
        Benefits1: 'Gives relief from fatigue and pain.',
        Benefits2: 'Helps in digestion',
        Benefits3: 'Cures digestion disorders',
        Benefits4: 'Improves the immune system',
        Benefits5: 'Treats diabetes',
        Benefits6: 'Itâ€™s good for skin and prevents blemishes.',
        Usage:'Usage',
        Usage1:'By consuming Tukhm E Jaman powder directly with water will regulate blood sugar level, boosts stomach health, posts immunity, helps in weight loss,',
        Usage2:'For urine control, take half (1/2) teaspoon of Tukhm E Jaman powder and half (1/2) teaspoon of Methi Dana Powder, mix it properly and take one (01) teaspoon (once in a day) with water. Half (1/2) teaspoon can also be given to the kids (once in a day).',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        Ingredients:'Ingredients',
        Ingredients1:'Jamun Seeds',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 19,
        title: "Tukhm E Balanga",
        price: 700.0,
        img: require("./assets/Herbs & Spices/tukhme-balanga-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: '(Basil Seeds) Green Roots Tukh malanga seeds have a mild flavour of basil and have been used in Ayurvedic and Chinese medicines for centuries. It has multiple health benefits, as well as is very beneficial for the hair. Tukhme Balanga also aids in weight loss.',
        SKU: 'SKU',
        SKU1: 'GR031HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' antioxidant, basil seeds, body coolant, constipation curedet, oxflu curehair nourishment, healthy breathing, healthy digestion, healthy metabolism, liver protection, tukhme balanga, vitamin a, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Boosts digestion',
        Benefits2: 'Improves metabolism & respiratory system',
        Benefits3: 'Protects the liver',
        Benefits4: 'Rich in vitamin A',
        Benefits5: 'Strengthens hair',
        Benefits6: 'Soothes nerves',
        Benefits7: 'Antioxidant',
        Benefits8: 'Cures constipation',
        Benefits9: 'Fights off flu',
        Benefits10: 'Reduces body heat',
        Benefits11: 'Removes toxins from the body',
        Benefits12: 'Helps in weight loss',
        Usage:'Usage',
        Usage1:'Tukhm E Balanga is used in soft drinks by adding half (1/2) teaspoon in normal water or milk. Drink once or twice a day.',
        Usage2:'For immunity boosting and weakness make a supplement by adding one (01) tablespoon of Tukhm E Balanga with one (01) tablespoon of Gond Kateera and one (01) tablespoon of White Musli powder, mix all three herbs and take one (01) teaspoon daily with milk or water helps in making bones strong and gives strength to body.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 20,
        title: "White Musli Powder",
        price: 1500.0,
        img: require("./assets/Herbs & Spices/white-musli-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        
        dis: 'Green roots White Musli powder are rich source of vitamin D and calcium, which helps in arthritis, joints pain, diabetes, boosting vitality, provides energy to body, helps in infertility.',
        SKU: 'SKU',
        SKU1: 'GR032HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' ED cure, fertility booster, herb, impotence cure, joint pain relief, premature ejaculation cure, sexual performance, sperm count booster, weight loss, hite musli',
        Benefits: 'Benefits',
        Benefits1: 'Beneficial for reproduction',
        Benefits2: 'Beneficial for low sperm counts',
        Benefits3: 'Beneficial for premature ejaculation and impotence',
        Benefits4: 'Beneficial during pregnancy and to increase breast milk flow',
        Benefits5: 'Beneficial for arthritis and joint pains',
        Benefits6: 'Beneficial for weakness and illness',
        Benefits7: 'Beneficial to improve sexual performances',
        Benefits8: 'Beneficial for weight loss',
        Usage:'Usage',
        Usage1:'Take 1/2 teaspoon of White Musli powder with yogurt or milk for the deficiency of vitamin D and joints pain.',
        Usage2:'Take 1/2 teaspoon twice a day with milk helps in boosting vitality, gives strength, helps in infertility.',
        Usage3:'Or used in any other herbal remedies.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        Ingredients:'Ingredients',
        Ingredients1:'Indian Barberry',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    {
        id: 21,
        title: "White Musli Powder",
        price: 3000.0,
        img: require("./assets/Herbs & Spices/white-musli-50-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green roots White Musli powder are rich source of vitamin D and calcium, which helps in arthritis, joints pain, diabetes, boosting vitality, provides energy to body, helps in infertility.',
        SKU: 'SKU',
        SKU1: 'GR032HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' ED cure, fertility booster, herb, impotence cure, joint pain relief, premature ejaculation cure, sexual performance, sperm count booster, weight loss, hite musli',
        Benefits: 'Benefits',
        Benefits1: 'Beneficial for reproduction',
        Benefits2: 'Beneficial for low sperm counts',
        Benefits3: 'Beneficial for premature ejaculation and impotence',
        Benefits4: 'Beneficial during pregnancy and to increase breast milk flow',
        Benefits5: 'Beneficial for arthritis and joint pains',
        Benefits6: 'Beneficial for weakness and illness',
        Benefits7: 'Beneficial to improve sexual performances',
        Benefits8: 'Beneficial for weight loss',
        Usage:'Usage',
        Usage1:'Take 1/2 teaspoon of White Musli powder with yogurt or milk for the deficiency of vitamin D and joints pain.',
        Usage2:'Take 1/2 teaspoon twice a day with milk helps in boosting vitality, gives strength, helps in infertility.',
        Usage3:'Or used in any other herbal remedies.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        Ingredients:'Ingredients',
        Ingredients1:'Indian Barberry',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 22,
        title: "Haldi (Turmeric)",
        price: 125.0,
        img: require("./assets/Herbs & Spices/haldi-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Turmeric is a spice that comes from the turmeric plant. It is commonly used in Asian food. You probably know turmeric as the main spice in curry. It has a warm, bitter taste and is frequently used to flavor or color curry powders, mustards, butters, and cheeses. It has much more to it, like preventing cancers, detoxing the liver, promoting weight loss, boosting muscle and joint health, great for the skin and hair, beneficial for diabetes, mood health, wounds, and arthritis as well.',
        SKU: 'SKU',
        SKU1: 'GR012HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, cancer beneficent, hair nourishment, haldi, heals wounds, healthy digestion, healthy heart, immune booster, joint pain reliever, liver detox, skin lightening, skin nourishment, turmeric',
        Benefits: 'Benefits',
        Benefits1: 'Prevents cancer',
        Benefits2: 'Detoxes the liver',
        Benefits3: 'Improves skin health & digestion',
        Benefits4: 'Helps heal wounds, control diabetes & prevent arthritis',
        Benefits5: 'Anti inflammatory properties',
        Benefits6: 'Promotes weight loss, healthy mood balance',
        Benefits7: 'Boosts immune system',
        Benefits8: 'Support cardiovascular functions, joint & muscle health',
        Benefits9: 'Moisturizes skin',
        Benefits10: 'Prevents acne',
        Benefits11: 'Lightens dark circles',
        Benefits12: 'Aids in healthy hair growth',
        Usage:'Usage',
        Usage1:'Take Haldi Powder with a glass of milk and drink as it helps in Osteoarthritis and internal pain.',
        Usage2:'By consuming Haldi powder helps lower down bad cholesterol level in body and helps in reducing fever.',
        Usage3:'Can be applied on skin by mixing with milk or tougher helps in removing scars from skin.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',

    },

    {
        id: 23,
        title: "Haldi (Turmeric)",
        price: 250.0,
        img: require("./assets/Herbs & Spices/haldi-50-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Turmeric is a spice that comes from the turmeric plant. It is commonly used in Asian food. You probably know turmeric as the main spice in curry. It has a warm, bitter taste and is frequently used to flavor or color curry powders, mustards, butters, and cheeses. It has much more to it, like preventing cancers, detoxing the liver, promoting weight loss, boosting muscle and joint health, great for the skin and hair, beneficial for diabetes, mood health, wounds, and arthritis as well.',
        SKU: 'SKU',
        SKU1: 'GR012HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, cancer beneficent, hair nourishment, haldi, heals wounds, healthy digestion, healthy heart, immune booster, joint pain reliever, liver detox, skin lightening, skin nourishment, turmeric',
        Benefits: 'Benefits',
        Benefits1: 'Prevents cancer',
        Benefits2: 'Detoxes the liver',
        Benefits3: 'Improves skin health & digestion',
        Benefits4: 'Helps heal wounds, control diabetes & prevent arthritis',
        Benefits5: 'Anti inflammatory properties',
        Benefits6: 'Promotes weight loss, healthy mood balance',
        Benefits7: 'Boosts immune system',
        Benefits8: 'Support cardiovascular functions, joint & muscle health',
        Benefits9: 'Moisturizes skin',
        Benefits10: 'Prevents acne',
        Benefits11: 'Lightens dark circles',
        Benefits12: 'Aids in healthy hair growth',
        Usage:'Usage',
        Usage1:'Take Haldi Powder with a glass of milk and drink as it helps in Osteoarthritis and internal pain.',
        Usage2:'By consuming Haldi powder helps lower down bad cholesterol level in body and helps in reducing fever.',
        Usage3:'Can be applied on skin by mixing with milk or tougher helps in removing scars from skin.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },


    {
        id: 24,
        title: "Husn E Yousuf",
        price: 300.0,
        img: require("./assets/Herbs & Spices/Husne-Yousuf-50-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',


        dis: '(Kaolin Clay) Kaolin clay is a cosmetic clay rich in silica and with a neutral pH. Itâ€™s often called White Clay or China Clay and of all the cosmetic clays, kaolin clay is the mildest. It works wonders for the skin, making it glow, healthy, tight, and bright. It helps in preventing wrinkles as well.',
        SKU: 'SKU',
        SKU1: 'GR013HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, anti ageing, herb, husne yusuf, kaolin clay, skin glow, skin lightening, skin nourishment',
        Benefits: 'Benefits',
        Benefits1: 'Makes skin healthy & glow',
        Benefits2: 'Helps in skin tightening',
        Benefits3: 'Reduces acne',
        Benefits4: 'Brightens the skin',
        Benefits5: 'Prevents wrinkles',
        Usage:'Usage',
        Usage1:'Husn e Yousuf herb can be used in many beauty related remedies and herbal face masks. One of which is making a face mask that glows your skin and works as antiageing agent â€“ take a little amount of Husn e Yousuf herb and mix it with rose water, milk or yougurt make a paste and apply on skin for 20 to 25 minutes, rinse with water or wash with soft hands.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Kaolin Clay ',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',  
    },

    {
        id: 25,
        title: "Husn E Yousuf",
        price: 600.0,
        img: require("./assets/Herbs & Spices/Husne-Yousuf-100-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: '(Kaolin Clay) Kaolin clay is a cosmetic clay rich in silica and with a neutral pH. Itâ€™s often called White Clay or China Clay and of all the cosmetic clays, kaolin clay is the mildest. It works wonders for the skin, making it glow, healthy, tight, and bright. It helps in preventing wrinkles as well.',
        SKU: 'SKU',
        SKU1: 'GR013HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, anti ageing, herb, husne yusuf, kaolin clay, skin glow, skin lightening, skin nourishment',
        Benefits: 'Benefits',
        Benefits1: 'Makes skin healthy & glow',
        Benefits2: 'Helps in skin tightening',
        Benefits3: 'Reduces acne',
        Benefits4: 'Brightens the skin',
        Benefits5: 'Prevents wrinkles',
        Usage:'Usage',
        Usage1:'Husn e Yousuf herb can be used in many beauty related remedies and herbal face masks. One of which is making a face mask that glows your skin and works as antiageing agent â€“ take a little amount of Husn e Yousuf herb and mix it with rose water, milk or yougurt make a paste and apply on skin for 20 to 25 minutes, rinse with water or wash with soft hands.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Kaolin Clay ',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 26,
        title: "Kalonji Seeds",
        price: 175.0,
        img: require("./assets/Herbs & Spices/kalonji-50-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green Roots Kalonji Seeds, (also known as Black caraway, Black cumin, Nigella, Kalojeera, kalonji or kalanji), is a very useful herb that is useful in lot of ways, it fights Acne. and other skin problems, keeps a check on diabetes, increases memory and Alleviates Asthma, Aids weight loss, eases Joint pain, controls blood pressure and protects the kidney from infections.',
        SKU: 'SKU',
        SKU1: 'GR014HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, asthma cure, blood pressure control, herb, joint pain relief, kalonji seeds, kidney health, memory booster, skin nourishment, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Fights Acne. and other skin problems',
        Benefits2: 'Keeps a Check on Diabetes',
        Benefits3: 'Increases Memory and Alleviates Asthma',
        Benefits4: 'Gets Rid of Headaches',
        Benefits5: 'Aids Weight Loss',
        Benefits6: 'Eases Joint Pain',
        Benefite7: 'Controls Blood Pressure',
        Benefits8: 'Protects the Kidney',
        Usage:'Usage',
        Usage1:'Among many known ways of using Kalonji Seeds, one of the most famous is consuming 3 to 4 seeds with water (only once in a day) will protect you from many commonly found diseases as Kalonji Seeds are filled with many dietary fibers, calcium, iron that cures instantly over the period of time.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',  
    },

    {
        id: 27,
        title: "Kalonji Seeds",
        price: 350.0,
        img: require("./assets/Herbs & Spices/kalongi-100-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Kalonji Seeds, (also known as Black caraway, Black cumin, Nigella, Kalojeera, kalonji or kalanji), is a very useful herb that is useful in lot of ways, it fights Acne. and other skin problems, keeps a check on diabetes, increases memory and Alleviates Asthma, Aids weight loss, eases Joint pain, controls blood pressure and protects the kidney from infections.',
        SKU: 'SKU',
        SKU1: 'GR014HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, asthma cure, blood pressure control, herb, joint pain relief, kalonji seeds, kidney health, memory booster, skin nourishment, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Fights Acne. and other skin problems',
        Benefits2: 'Keeps a Check on Diabetes',
        Benefits3: 'Increases Memory and Alleviates Asthma',
        Benefits4: 'Gets Rid of Headaches',
        Benefits5: 'Aids Weight Loss',
        Benefits6: 'Eases Joint Pain',
        Benefite7: 'Controls Blood Pressure',
        Benefits8: 'Protects the Kidney',
        Usage:'Usage',
        Usage1:'Among many known ways of using Kalonji Seeds, one of the most famous is consuming 3 to 4 seeds with water (only once in a day) will protect you from many commonly found diseases as Kalonji Seeds are filled with many dietary fibers, calcium, iron that cures instantly over the period of time.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 28,
        title: "Methi Dana",
        price: 100.0,
        img: require("./assets/Herbs & Spices/methi-50-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green Roots Methi Dana contains a good amount of fiber, minerals, proteins and iron etc. Which helps in hair, skin and health problems.',
        SKU: 'SKU',
        SKU1: 'GR016HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'hair nourishment, herb, kidney health, liver health, menstrual pain reliever, methi dana, pain relief, pain reliever, skin nourishment',
        Benefits: 'Benefits',
        Benefits1: 'Maintains liver and kidney health',
        Benefits2: 'Reduces fever',
        Benefits3: 'Soothes muscle pain',
        Benefits4: 'Helps in weight loss',
        Benefits5: 'Improves insulin activity',
        Benefits6: 'Multiple benefits for hair and skin',
        Benefits7: 'Reduces menstrual pain',
        Usage:'Usage',
        Usage1:'Mix 1 or 2 tablespoon of methi dana into your hair oil helps in hair growth.',
        Usage2:'Take few seeds (3,4) of methi dana early morning with water helps in diabetes, cholesterol, reduces fats.',
        Usage3:'Take half teaspoon of methi dana in a cup of water cook for few minutes until it turns into a fine paste. Let it cool down then apply the paste on skin scars and pores, massage gently for 5 minutes and leave it dried down then wash with water.',
        Usage4:'Or can be used in any other herbal remedies.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',  
    },

    {
        id: 29,
        title: "Methi Dana",
        price: 200.0,
        img: require("./assets/Herbs & Spices/methi-100-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Methi Dana contains a good amount of fiber, minerals, proteins and iron etc. Which helps in hair, skin and health problems.',
        SKU: 'SKU',
        SKU1: 'GR016HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'hair nourishment, herb, kidney health, liver health, menstrual pain reliever, methi dana, pain relief, pain reliever, skin nourishment',
        Benefits: 'Benefits',
        Benefits1: 'Maintains liver and kidney health',
        Benefits2: 'Reduces fever',
        Benefits3: 'Soothes muscle pain',
        Benefits4: 'Helps in weight loss',
        Benefits5: 'Improves insulin activity',
        Benefits6: 'Multiple benefits for hair and skin',
        Benefite7: 'Reduces menstrual pain',
        Usage:'Usage',
        Usage1:'Mix 1 or 2 tablespoon of methi dana into your hair oil helps in hair growth.',
        Usage2:'Take few seeds (3,4) of methi dana early morning with water helps in diabetes, cholesterol, reduces fats.',
        Usage3:'Take half teaspoon of methi dana in a cup of water cook for few minutes until it turns into a fine paste. Let it cool down then apply the paste on skin scars and pores, massage gently for 5 minutes and leave it dried down then wash with water.',
        Usage4:'Or can be used in any other herbal remedies.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 30,
        title: "Mulethi (Licorice)",
        price: 100.0,
        img: require("./assets/Herbs & Spices/mulethi-50-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Mulethi is found in many regions of Europe and Asia and is also used for this naturally sweet taste in different cuisines. Consuming the essence of this root is beneficial for numerous aspects of our health, from being antiseptic, anti-diabetic to having antioxidant properties and fighting respiratory infections.',
        SKU: 'SKU',
        SKU1: 'GR018HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' cancer sores cure, healthy breathing, healthy digestion, herb, hormone regulation, immunity booster, licorice, liver protect, ionmemory boost, mulethi, skin nourishment, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Combats digestive issues',
        Benefits2: 'Treats cancer sores',
        Benefits3: 'Helps in weight reduction',
        Benefits4: 'Improves immunity',
        Benefits5: 'Combats respiratory infections',
        Benefits6: 'Protects the liver ',
        Benefits7: 'Promotes oral health',
        Benefits8: 'Memory improvement',
        Benefits9: 'Regulates hormones',
        Benefits10: 'Beneficial for the skin',
        Usage:'Usage',
        Usage1:'For sore throat and coughing, drink a cup of Mulethi tea by adding 1/2 teaspoon of Mulethi Powder in a cup of boiled water and take twice a day daily for relief.',
        Usage2:'Mulethi Powder is also used as face mask by adding it in milk and making paste and apply on face to removes dark spots blemishes.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',  
    },

    {
        id: 31,
        title: "Mulethi (Licorice)",
        price: 200.0,
        img: require("./assets/Herbs & Spices/mulethi-100-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Mulethi is found in many regions of Europe and Asia and is also used for this naturally sweet taste in different cuisines. Consuming the essence of this root is beneficial for numerous aspects of our health, from being antiseptic, anti-diabetic to having antioxidant properties and fighting respiratory infections.',
        SKU: 'SKU',
        SKU1: 'GR018HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' cancer sores cure, healthy breathing, healthy digestion, herb, hormone regulation, immunity booster, licorice, liver protect, ionmemory boost, mulethi, skin nourishment, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Combats digestive issues',
        Benefits2: 'Treats cancer sores',
        Benefits3: 'Helps in weight reduction',
        Benefits4: 'Improves immunity',
        Benefits5: 'Combats respiratory infections',
        Benefits6: 'Protects the liver ',
        Benefits7: 'Promotes oral health',
        Benefits8: 'Memory improvement',
        Benefits9: 'Regulates hormones',
        Benefits10: 'Beneficial for the skin',
        Usage:'Usage',
        Usage1:'For sore throat and coughing, drink a cup of Mulethi tea by adding 1/2 teaspoon of Mulethi Powder in a cup of boiled water and take twice a day daily for relief.',
        Usage2:'Mulethi Powder is also used as face mask by adding it in milk and making paste and apply on face to removes dark spots blemishes.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 32,
        title: "Sabit Ispaghol",
        price: 400.0,
        img: require("./assets/Herbs & Spices/sabit-ispaghol-100-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        
        dis: 'Green Roots Sabit Ispaghol are a rich source of nutrients of fibre, protein, carbohydrates. Rich in minerals of iron, calcium and fats / fatty acids.',
        SKU: 'SKU',
        SKU1: 'GR021HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' anal fissure, constipation cure, diarrhea cure, fiber, herb, ispaghol, sabit ispaghol, stomach cleansing, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Benefits for the symptoms of anal fissure.',
        Benefits2: 'Acts as a remedy for weight loss.',
        Benefits3: 'Clears toxins from the stomach.',
        Benefits4: 'Rich supplement of fiber.',
        Benefits5: 'Treat constipation.',
        Benefits6: 'Treat Diarrhea.',
        Usage:'Usage',
        Usage1:'By mixing Sabit Ispaghol of 1 teaspoon with warm water and add a few drop of lemon in it, which helps reduce fat from body and clears toxin from body.',
        Usage2:'Or in any other herbal remedies.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110  g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 33,
        title: "Sandal (Chandanis)",
        price: 1250.0,
        img: require("./assets/Herbs & Spices/sandal-herb-50-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        
        dis: 'Sandalwood is a virtual ware house of goodness and has antiseptic, astringent, anti inflammatory and disinfectant properties. The antiseptic quality of sandalwood benefits to treat rashes, acne, blackheads and other skin eruptions. Its germicidal quality inhibits the growth of bacteria. Itâ€™s highly beneficial for the liver, heart, gallbladder, ulcers and more health related issues.',
        SKU: 'SKU',
        SKU1: 'GR022HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, anti ageing, chandanis, eczema relief, eye rejuvenating, gallbladder cure, healthy heart, herb, liver disease cure, muscle relaxant, psoriasis relief, sandal, skin brightening, stress reliever, ulcer cure',
        Benefits: 'Benefits',
        Benefits1: 'Treats eczema, psoriasis and dermatitis',
        Benefits2: 'Anti ageing properties',
        Benefits3: 'Removes scars & acne',
        Benefits4: 'Softens skin',
        Benefits5: 'Anti tanning properties',
        Benefits6: 'Saves skin from prickly heat',
        Benefits7: 'Cooling properties',
        Benefits8: 'Treats puffy eyes and dark circles',
        Benefits9: 'Relaxes the body',
        Benefits10: 'Soothes urinary system',
        Benefits11: 'Heals ulcers',
        Benefits12: 'Reduces stress and hypertension',
        Benefits13: 'Relaxes nerves and muscles',
        Benefits14: 'Prevents natural teeth loss',
        Benefits15: 'Treats liver diseases',
        Benefits16: 'Treats heart diseases',
        Benefits17: 'Treats gallbladder problems',
        Benefits18: 'Beneficial in cardiovascular diseases',
        Usage:'Usage',
        Usage1:'For removing acne and blackheads mix one (01) teaspoon of Sandalwood Powder, one (01) teaspoon of Aloe Vera gel and two teaspoons of yogurt to form a fine paste. Apply the paste on the affected areas and let it get dried for 30 minutes and rinse well with water. It helps eliminating bacteria that cause acne.',
        Usage2:'To make a fairer skin complexion, take one (01) teaspoon of Sandalwood Powder one (01) teaspoon of Besan (Gram Flour) and one (01) teaspoon of Haldi) Turmeric powder â€“ mix all three and make paste with little amount of Rose water and apply on the skin. Let it be applied on your skin and leave it for 15 to 20 minutes, gently wash it with water.',
        Usage3:'By taking a pinch of Sandal Powder with water helps in treating anti inflammation.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        Ingredients:'Ingredients',
        Ingredients1:'Sandal Stick ',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',  
    },


    {
        id: 34,
        title: "Sandal (Chandanis)",
        price: 2500.0,
        img: require("./assets/Herbs & Spices/sandal-herb-100-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Sandalwood is a virtual ware house of goodness and has antiseptic, astringent, anti inflammatory and disinfectant properties. The antiseptic quality of sandalwood benefits to treat rashes, acne, blackheads and other skin eruptions. Its germicidal quality inhibits the growth of bacteria. Itâ€™s highly beneficial for the liver, heart, gallbladder, ulcers and more health related issues.',
        SKU: 'SKU',
        SKU1: 'GR022HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, anti ageing, chandanis, eczema relief, eye rejuvenating, gallbladder cure, healthy heart, herb, liver disease cure, muscle relaxant, psoriasis relief, sandal, skin brightening, stress reliever, ulcer cure',
        Benefits: 'Benefits',
        Benefits1: 'Treats eczema, psoriasis and dermatitis',
        Benefits2: 'Anti ageing properties',
        Benefits3: 'Removes scars & acne',
        Benefits4: 'Softens skin',
        Benefits5: 'Anti tanning properties',
        Benefits6: 'Saves skin from prickly heat',
        Benefits7: 'Cooling properties',
        Benefits8: 'Treats puffy eyes and dark circles',
        Benefits9: 'Relaxes the body',
        Benefits10: 'Soothes urinary system',
        Benefits11: 'Heals ulcers',
        Benefits12: 'Reduces stress and hypertension',
        Benefits13: 'Relaxes nerves and muscles',
        Benefits14: 'Prevents natural teeth loss',
        Benefits15: 'Treats liver diseases',
        Benefits16: 'Treats heart diseases',
        Benefits17: 'Treats gallbladder problems',
        Benefits18: 'Beneficial in cardiovascular diseases',
        Usage:'Usage',
        Usage1:'For removing acne and blackheads mix one (01) teaspoon of Sandalwood Powder, one (01) teaspoon of Aloe Vera gel and two teaspoons of yogurt to form a fine paste. Apply the paste on the affected areas and let it get dried for 30 minutes and rinse well with water. It helps eliminating bacteria that cause acne.',
        Usage2:'To make a fairer skin complexion, take one (01) teaspoon of Sandalwood Powder one (01) teaspoon of Besan (Gram Flour) and one (01) teaspoon of Haldi) Turmeric powder â€“ mix all three and make paste with little amount of Rose water and apply on the skin. Let it be applied on your skin and leave it for 15 to 20 minutes, gently wash it with water.',
        Usage3:'By taking a pinch of Sandal Powder with water helps in treating anti inflammation.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        Ingredients:'Ingredients',
        Ingredients1:'Sandal Stick ',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },


    {
        id: 35,
        title: "Sesame White Seeds",
        price: 200.0,
        img: require("./assets/Herbs & Spices/seasame-white-seeds-50-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green Roots Sesame seeds are a good source of fiber, vitamin B. Which helps in health and beauty.',
        SKU: 'SKU',
        SKU1: 'GR023HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti ageing, blood pressure control, bone health, energizing, hair growth, hair nourishment, seeds, sesame white seeds, skin nourishment, teeth health',
        Benefits: 'Benefits',
        Benefits1: 'Improves hair growth',
        Benefits2: 'Contains anti-ageing properties',
        Benefits3: 'Boosts skin health',
        Benefits4: 'Boosts dental health',
        Benefits5: 'Rich source of energy',
        Benefits6: 'Stabilizes blood pressure',
        Benefits7: 'Boosts bone health',
        Usage:'Usage',
        Usage1:'Take pumpkin seeds and sesame seeds at an equal quantity, mix them to form as fine powder, take 1 teaspoon daily with milk, which will help in hair, skin and physical health.',
        Usage2:'Or used in other herbal remedies.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',  
    },

    {
        id: 36,
        title: "Sesame White Seeds",
        price: 400.0,
        img: require("./assets/Herbs & Spices/seasame-white-seeds-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Sesame seeds are a good source of fiber, vitamin B. Which helps in health and beauty.',
        SKU: 'SKU',
        SKU1: 'GR023HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti ageing, blood pressure control, bone health, energizing, hair growth, hair nourishment, seeds, sesame white seeds, skin nourishment, teeth health',
        Benefits: 'Benefits',
        Benefits1: 'Improves hair growth',
        Benefits2: 'Contains anti-ageing properties',
        Benefits3: 'Boosts skin health',
        Benefits4: 'Boosts dental health',
        Benefits5: 'Rich source of energy',
        Benefits6: 'Stabilizes blood pressure',
        Benefits7: 'Boosts bone health',
        Usage:'Usage',
        Usage1:'Take pumpkin seeds and sesame seeds at an equal quantity, mix them to form as fine powder, take 1 teaspoon daily with milk, which will help in hair, skin and physical health.',
        Usage2:'Or used in other herbal remedies.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 37,
        title: "Singhara Powder",
        price: 220.0,
        img: require("./assets/Herbs & Spices/singhara-powder-50-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Singhara is a herb which is rich in calcium, fibers, potassium and minerals.',
        SKU: 'SKU',
        SKU1: 'GR025HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'antioxidant, bone health, cholesterol control, energizing, fiber, healthy brain, herb, potassium, singhara, water retention, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Good for energy',
        Benefits2: 'Powerhouse of antioxidants and minerals',
        Benefits3: 'Helps in weight loss',
        Benefits4: 'Rich in potassium',
        Benefits5: 'Helps water retention in the body',
        Benefits6: 'Beneficial for diabetes',
        Benefits7: 'Rich in fiber',
        Benefits8: 'Rich in vitamins',
        Benefits9: 'Increases brain function',
        Benefits10: 'Reduces cholesterol and blood sugar levels',
        Benefits11: 'Makes the bones stronger',
        Benefits12: 'Gluten free',
        Benefits13: 'Reduces the risk of diseases.',
        Usage:'Usage',
        Usage1:'(For energy and brain function) â€“ take 1 teaspoon once in a day with milk.',
        Usage2:'(For water Retention in the body and Diabetes) â€“ take 1 teaspoon once in a day with water.',
        Usage3:'Or used in other herbal remedies.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',  
    },

    {
        id: 38,
        title: "Singhara Powder",
        price: 450.0,
        img: require("./assets/Herbs & Spices/singhara-powder-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Singhara is a herb which is rich in calcium, fibers, potassium and minerals.',
        SKU: 'SKU',
        SKU1: 'GR025HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'antioxidant, bone health, cholesterol control, energizing, fiber, healthy brain, herb, potassium, singhara, water retention, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Good for energy',
        Benefits2: 'Powerhouse of antioxidants and minerals',
        Benefits3: 'Helps in weight loss',
        Benefits4: 'Rich in potassium',
        Benefits5: 'Helps water retention in the body',
        Benefits6: 'Beneficial for diabetes',
        Benefits7: 'Rich in fiber',
        Benefits8: 'Rich in vitamins',
        Benefits9: 'Increases brain function',
        Benefits10: 'Reduces cholesterol and blood sugar levels',
        Benefits11: 'Makes the bones stronger',
        Benefits12: 'Gluten free',
        Benefits13: 'Reduces the risk of diseases.',
        Usage:'Usage',
        Usage1:'(For energy and brain function) â€“ take 1 teaspoon once in a day with milk.',
        Usage2:'(For water Retention in the body and Diabetes) â€“ take 1 teaspoon once in a day with water.',
        Usage3:'Or used in other herbal remedies.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },

    {
        id: 39,
        title: "Sonth Powder",
        price: 300.0,
        img: require("./assets/Herbs & Spices/sonth-50-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green Roots sonth powder is extracted from the ginger root. Which is widely used in kitchen to add flavor and aroma. Also has many benefits in health issues and skin issues.',
        SKU: 'SKU',
        SKU1: 'GR027HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, blood circulation, bowel cleansing, cough cure, detoxginger, healthy digestion, herb, kidney cleansing, migraine relief, skin nourishment, sonth, UTI beneficent',
        Benefits: 'Benefits',
        Benefits1: 'Cures indigestion',
        Benefits2: 'Treats nausea',
        Benefits3: 'Stimulates blood circulation',
        Benefits4: 'Nourishes the skin',
        Benefits5: 'Cleans bowel and kidney',
        Benefits6: 'Removes toxin from the body',
        Benefits7: 'Beneficial for cough, sore throat and flu',
        Benefits8: 'Beneficial for headache & migraine',
        Benefits9: 'Good for urine infection',
        Benefits10: 'Beneficial for acne',
        Usage:'Usage',
        Usage1:'Drink a cup of sonth tea daily, which helps in headache, migraine, removes toxin, treats acne, burns fat, treat cough, flu, and sore throats.',
        Usage2:'Or used in other herbal remedies.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        Ingredients:'Ingredients',
        Ingredients1:'Dry Ginger',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',  
    },

    {
        id: 40,
        title: "Sonth Powder",
        price: 600.0,
        img: require("./assets/Herbs & Spices/sonth-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots sonth powder is extracted from the ginger root. Which is widely used in kitchen to add flavor and aroma. Also has many benefits in health issues and skin issues.',
        SKU: 'SKU',
        SKU1: 'GR027HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, blood circulation, bowel cleansing, cough cure, detoxginger, healthy digestion, herb, kidney cleansing, migraine relief, skin nourishment, sonth, UTI beneficent',
        Benefits: 'Benefits',
        Benefits1: 'Cures indigestion',
        Benefits2: 'Treats nausea',
        Benefits3: 'Stimulates blood circulation',
        Benefits4: 'Nourishes the skin',
        Benefits5: 'Cleans bowel and kidney',
        Benefits6: 'Removes toxin from the body',
        Benefits7: 'Beneficial for cough, sore throat and flu',
        Benefits8: 'Beneficial for headache & migraine',
        Benefits9: 'Good for urine infection',
        Benefits10: 'Beneficial for acne',
        Usage:'Usage',
        Usage1:'Drink a cup of sonth tea daily, which helps in headache, migraine, removes toxin, treats acne, burns fat, treat cough, flu, and sore throats.',
        Usage2:'Or used in other herbal remedies.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any way and consult your personal physician).',
        Ingredients:'Ingredients',
        Ingredients1:'Dry Ginger',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',  
    },


    {
        id: 41,
        title: "German Chamomile",
        price: 0.0,
        img: require("./assets/Herbs & Spices/germanchamomiloutofstock.png"),
        quantity: 1,
        gram: '50 Grams',
        Availability:'Availability',
        Availability1:' : Out of stock',

        dis: 'Green Roots German Chamomile is effective in diarrhea and indigestion. Used for hemorrhoid, insomnia and mouth sores.',
        SKU: 'SKU',
        SKU1: 'GR044HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'ADHD remedy, anti anxiety, anti inflammation, cold cure, dermatitis cure, diarrhea remedy, eczema cure, german chamomile, healing, heartburn remedy, hemorrhoids cure, herbindigestion remedy, insomnia cure, menstrual cramps remedy, mouth sore remedy, vaginal infection remedy',
        Benefits: 'Benefits',
        Benefits1: 'Beneficial for diarrhea',
        Benefits2: 'Beneficial for indigestion',
        Benefits3: 'Beneficial for anxiety, and excessive crying in kids (colic)',
        Benefits4: 'Used for hemorrhoid',
        Benefits5: 'Used for insomnia',
        Benefits6: 'Used for mouth sores',
        Benefits7: 'Beneficial for attention deficit-hyperactivity disorder (ADHD)',
        Benefits8: 'Beneficial for stomach and intestinal spasms, stomach and intestinal inflammation, stomach ulcers',
        Benefits9: 'Beneficial for menstrual cramps',
        Benefits10: 'Beneficial for intestinal gas',
        Benefits11: 'Works on heartburn',
        Benefits12: 'Beneficial for Skin irritation (dermatitis).',
        Benefits13: 'Cures common cold',
        Benefits14: 'Beneficial for gum diseases',
        Benefits15: 'Works on Inflamed or irritated skin (eczema)',
        Benefits16: 'Beneficial for Vaginal infection (vaginitis)',
        Benefits17: 'Aids in wound healing',
        Weight: ' Weight',
        Weight1: '50 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    {
        id: 42,
        title: "Rose Leaves",
        price: 80.0,
        img: require("./assets/Herbs & Spices/rose-leaves-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green Roots Rose Leaves purifies blood, anti inflammatory, anti septic and anti bacterial. Effective in intestinal ulcers and in diarrhea.',
        SKU: 'SKU',
        SKU1: 'GR045HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti bacterial, anti inflammation, antiseptic, blood pressure control, blood purifier, colic remedy, constipation cure, diarrhea relief, healthy heart, insomnia remedy, intestinal ulcer relief, skin nourishment',
        Benefits: 'Benefits',
        Benefits1: 'Blood purification',
        Benefits2: 'Anti inflammatory, anti septic and anti bacterial',
        Benefits3: 'Effective in intestinal ulcers',
        Benefits4: 'Effective in diarrhea',
        Benefits5: 'Improves high blood pressure',
        Benefits6: 'Beneficial for heart health',
        Benefits7: 'Useful in eye problems',
        Benefits8: 'Useful in constipation',
        Benefits9: 'Cures insomnia',
        Benefits10: 'Beneficial for colic problems',
        Benefits11: 'Good for dry skin',
        Benefits12: 'Good for sore throats',
        Weight: ' Weight',
        Weight1: '50 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    {
        id: 43,
        title: "Neem Leaves",
        price: 100.0,
        img: require("./assets/Herbs & Spices/neem-leaves-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        
        dis: 'Green Roots Neem Leaves are used for leprosy and beneficial for eye disorders, intestinal worms, diseases of the heart and blood vessels (cardiovascular disease) and beneficial for diabetes.',
        SKU: 'SKU',
        SKU1: 'GR046HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'appetite remedy, diabetes remedy, eye disorder remedy, gum disease remedy, healthy heart, intestinal worms cure, leaves, leprosy remedy,  liver health, neem, skin ulcer remedy',
        Benefits: 'Benefits',
        Benefits1: 'Used for leprosy',
        Benefits2: 'Beneficial for eye disorders',
        Benefits3: 'Beneficial for intestinal worms',
        Benefits4: 'Beneficial for diseases of the heart and blood vessels (cardiovascular disease)',
        Benefits5: 'Beneficial for diabetes',
        Benefits6: 'Cures fevers',
        Benefits7: 'Beneficial for gum disease (gingivitis)',
        Benefits8:'Beneficial for liver problems',
        Benefits9:'Also used for birth control',
        Benefits10:'Beneficial for bloody nose and skin ulcers',
        Benefits11:'Beneficial for loss of appetite',
        Weight: ' Weight',
        Weight1: '50 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    {
        id: 44,
        title: "Gul E Nilofer Leaves",
        price: 200.0,
        img: require("./assets/Herbs & Spices/gule-nilofer-leaves-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: '(Water Lilly Flower) Green Roots Gul E Nilofer Leaves ( Water Lilly Flower) act as a cardiac tonic and refrigerant and is Antiseptic. Treats kidney pains, congestion, sore throats. Helps in curing uterine cancer and works as a sedative and alleviates pain.',
        SKU: 'SKU',
        SKU1: 'GR047HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'antiseptic, cardiac tonic, congestion cure, diarrhea cure, dysentery cure, gastrointestinal cure, gul e nilofer leaves, IBS cure, kidney pain relief, lungs disorder cure, sedativesore throat cure, uterine cancer cure, water lily flower leaves',
        Benefits: 'Benefits',
        Benefits1: 'Act as a cardiac tonic and refrigerant',
        Benefits2: 'Antiseptic',
        Benefits3: 'Treats kidney pains',
        Benefits4: 'Treats congestion',
        Benefits5: 'Treats sore throats',
        Benefits6: 'Acts as a sedative',
        Benefits7: 'Alleviates pain',
        Benefits8: 'Cures uterine cancer',
        Benefits9: 'Cures dysentery, IBS (irritable bowel syndrome)',
        Benefits10: 'Cures diarrhea',
        Benefits11: 'Treats lung disorders',
        Benefits12: 'Treats gastrointestinal conditions and skin conditions.',
        Weight: ' Weight',
        Weight1: '50 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    {
        id: 45,
        title: "Hibiscus Petals",
        price: 700.0,
        img: require("./assets/Herbs & Spices/hibiscus-petals-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green roots Hibiscus Petals Helps reduces Blood Pressure and helps reduce Blood Fat Levels. Boosts Liver Health, and promotes weight Loss. Contains Compounds that may help prevent Cancer. Helps fight Bacteria, and purifies skin (breaks down skin cells and increases cell turnover).',
        SKU: 'SKU',
        SKU1: 'GR048HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, anti ageing, anti bacterial, antioxidant, blood pressure control, cancer prevention, healthy liver, hibiscus petals, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Packed With Antioxidants.',
        Benefits2: 'Helps reduce Blood Pressure.',
        Benefits3: 'Helps reduce Blood Fat Levels.',
        Benefits4: 'Boosts Liver Health.',
        Benefits5: 'Promotes Weight Loss.',
        Benefits6: 'Contains Compounds That May Help Prevent Cancer.',
        Benefits7: 'Helps Fight Bacteria.',
        Benefits8: 'Flavorful and Easy to Make',
        Benefits9: 'Purifies skin (breaks down skin cells and increases cell turnover)',
        Benefits10: 'Helps control acne breakouts',
        Benefits11: 'Helps with age spots',
        Benefits12: 'Makes the skin overall fresher, younger and smoother complexion.',
        Benefits13: 'Increases breast milk supply',
        Weight: ' Weight',
        Weight1: '50 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    // {
    //     id: 46,
    //     title: "Ajwain (Carom) Leaves",
    //     price: 0.0,
    //     img: require("./assets/Herbs & Spices/ajwainleavesoutofstock.png"),
    //     quantity: 1,
    //     gram: '50 Grams',
    //     Availability:'Availability',
    //     Availability1:' : Out of stock',
        
    //     dis: 'Green Roots Ajwain Leaves (Carom Leaves) cures stomach pain, improves digestion, cures common colds and improves cholesterol levels.',
    //     SKU: 'SKU',
    //     SKU1: 'GR049HS',
    //     Category: 'Herbs & Spices',
    //     Tags: 'Tags',
    //     Tags1: ' asthma cure, cholesterol control, cold cure, diabetes cure, food flavoring, healthy digestion, kidney stone cure, stomach pain relief, weight loss',
    //     Benefits: 'Benefits',
    //     Benefits1: 'Cures stomach pains',
    //     Benefits2: 'Improves digestion',
    //     Benefits3: 'Cures common colds',
    //     Benefits4: 'Improves cholesterol levels',
    //     Benefits5: 'Gives flavor to foods',
    //     Benefits6: 'Cures asthma',
    //     Benefits7: 'Cures acidity',
    //     Benefits8: 'Helps get rid of alcohol',
    //     Benefits9: 'Cures kidney stones',
    //     Benefits10: 'Helps in weight loss',
    //     Benefits11: 'Helps cure diabetes',
    //     Weight: ' Weight',
    //     Weight1: '50 g',
    //     dicQuantity: 'Quantity',
    //     dicQuantity1: '50 grams',
    // },

    {
        id: 47,
        title: "Tulsi (Holy Basil) Leaves",
        price: 600.0,
        img: require("./assets/Herbs & Spices/tulsi-leaves-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Tulsi Leaves (Holy Basil) lowers blood pressure and cholesterol levels, reduces the risk of heart attacks, heart strokes, and other heart related diseases.',
        SKU: 'SKU',
        SKU1: 'GR050HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti anxiety, anti bacterial, anti depression, anti viral, antiseptic, blood pressure control, cholesterol control, headache relief, low heart attack risk, peaceful sleep, vitamin c',
        Benefits: 'Benefits',
        Benefits1: 'Lowers blood pressure levels',
        Benefits2: 'Lowers cholesterol levels',
        Benefits3: 'Reduces the risk of heart attacks, heart strokes, and other heart related diseases',
        Benefits4: 'Promotes better sleep',
        Benefits5: 'Works for anxiety and depression',
        Benefits6: 'Work on headaches',
        Benefits7: 'Rich in vitamin C, anti bacterial, anti viral, anti septic.',
        Weight: ' Weight',
        Weight1: '105 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 48,
        title: "Mehndi Leaves",
        price: 150.0,
        img: require("./assets/Herbs & Spices/mehndi-leaves-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'GGreen Roots Mehndi Leaves cures wounds, anti bacterial, anti fungal, improves hair health, works as a harm free hair dye and eases arthritis pains.',
        SKU: 'SKU',
        SKU1: 'GR051HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' antibacterial, antifungal, arthritis relief, blood pressure control, fever relief, hair dye, hair growth, healin, gmehndi leaves',
        Benefits: 'Benefits',
        Benefits1: 'Cures wounds',
        Benefits2: 'Anti bacterial, anti fungal',
        Benefits3: 'Improves hair health',
        Benefits4: 'Works as a harm free hair dye',
        Benefits5: 'Eases arthritis pains',
        Benefits6: 'Draws out fever gives relief from headaches',
        Benefits7: 'Regulates blood pressure',
        Weight: ' Weight',
        Weight1: '50 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    {
        id: 49,
        title: "Stevia Leaves",
        price: 1000.0,
        img: require("./assets/Herbs & Spices/stevia-leaves-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green Roots Stevia (leaf/powder) is safe and healthy substitute for sugar, healthy alternate for refined sugar. It reduces calorie intake, blood sugar levels and reduces risk of dental cavities.',
        SKU: 'SKU',
        SKU1: 'GR052HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'allergy prevention, blood pressure control, blood sugar control, calorie reduction, healthy sweet, healthy teeth, pancreatic cancer prevention, sugar substitut, eweight control',
        Benefits: 'Benefits',
        Benefits1: 'Safe and healthy substitute for sugar',
        Benefits2: 'Healthy alternate for refined sugar',
        Benefits3: 'Reduces calorie intake',
        Benefits4: 'Reduces blood sugar levels',
        Benefits5: 'Reduces risk of dental cavities',
        Benefits6: 'Helps in weight control',
        Benefits7: 'Reduces risk of pancreatic cancer',
        Benefits8:'Reduces blood pressure',
        Benefits9:'Food to add in childrenâ€™s diet (low calorie and harmless sugar alternate)',
        Benefits10:'Helps prevent allergies',
        Weight: ' Weight',
        Weight1: '55 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },


    {
        id: 50,
        title: "Olive Leaves",
        price: 1000.0,
        img: require("./assets/Herbs & Spices/olive-leaves-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',

        dis: 'Green Roots Olive Leaves are beneficial is gastroprotective (protects digestive system), neuroprotective (protects central nervous system and antimicrobial (inhibits microorganism growth).',
        SKU: 'SKU',
        SKU1: 'GR053HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti inflammation, anticancer, antimicrobial, antioxidant, blood pressure control, diabetes type 2 cure, digestive system protection, herpes remedy, immunity boost, nervous system protection, pain relief, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Gastroprotective (protects digestive system)',
        Benefits2: 'Neuroprotective (protects central nervous system)',
        Benefits3: 'Antimicrobial (inhibits microorganism growth)',
        Benefits4: 'Anticancer (reduces risk of cancer)',
        Benefits5: 'Anti-inflammatory (reduces risk of inflammation)',
        Benefits6: 'Reduces pain stimuli',
        Benefits7: 'Antioxidant (prevents oxidation or cell damage)',
        Benefits8:'Reduces cardiovascular risk, like atherosclerosis',
        Benefits9:'Lowers blood pressure',
        Benefits10:'Helps treats type 2 diabetes',
        Benefits11:'Supports weight loss',
        Benefits12:'Eliminates free radicals',
        Benefits13:'Boosts immunity',
        Benefits14:'Fights herpes',
        Weight: ' Weight',
        Weight1: '50 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    {
        id: 51,
        title: "Moringa Leaves",
        price: 180.0,
        img: require("./assets/Herbs & Spices/moriga-leaves-greenrootspk.com_.png"),
        quantity: 1,
        gram: '50 Grams',


        dis: 'Green Roots Moringa Leaves protects and nourishes the hair, protects the liver, prevents and treats cancer and treats stomach disorders.',
        SKU: 'SKU',
        SKU1: 'GR054HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti bacterial, blood pressure control, cancer cure, cancer prevention, cardiovascular protection, diabetes cure, hair nourishment, liver protection, mood disorder control, moringa leaves',
        Benefits: 'Benefits',
        Benefits1: 'Protects and nourishes the hair.',
        Benefits2: 'Protects the liver',
        Benefits3: 'Prevents and treats cancer',
        Benefits4: 'Treats stomach disorders',
        Benefits5: 'Makes bones healthier',
        Benefits6: 'Protects the cardiovascular system',
        Benefits7: 'Treats Diabetes',
        Benefits8:'Reduces high blood pressure',
        Benefits9:'Treats mood disorders',
        Benefits10:'Fights against bacterial diseases',
        Weight: ' Weight',
        Weight1: '50 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50 grams',
    },

    {
        id: 52,
        title: "Pumpkin Seeds",
        price: 950.0,
        img: require("./assets/Herbs & Spices/pumpkin-seed-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Pumpkin Seeds are full valuable nutrients, high in Antioxidants, reduces risk of certain Cancers and improves Prostate and Bladder Health',
        SKU: 'SKU',
        SKU1: 'GR055HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'antioxidant, blood sugar control, cancer beneficent, cholesterol control, fertility booster, fiber, healthy heart, immune booster, magnesium, menstrual cycle, peaceful sleep, pumpkin, seeds, sperm quality, weight loss, zinc',
        Benefits: 'Benefits',
        Benefits1: 'Full of valuable nutrients',
        Benefits2: 'High in Antioxidants',
        Benefits3: 'Reduces Risk of Certain Cancers',
        Benefits4: 'Improves Prostate and Bladder Health',
        Benefits5: 'Very High in Magnesium',
        Benefits6: 'May Improve Heart Health',
        Benefits7: 'Can Lower Blood Sugar Levels',
        Benefits8: 'High in Fiber',
        Benefits9: 'May Improve Sperm Quality',
        Benefits10: 'May Help Improve Sleep',
        Benefits11: 'Easy to Add to Your Diet',
        Benefits12: 'Aids in weight loss.',
        Benefits13: 'Works for fertility issues in men and women',
        Benefits14: 'Lowers cholesterol',
        Benefits15: 'High in Zinc which helps in the menstrual cycle phase.',
        Benefits16: 'Boosts the immune system',
        Usage:'Usage',
        Usage1:'Can be eaten orally.',
        Usage2:'Can add them to smoothies',
        Usage3:'Can be made in meals by sprinkling them into salads, soups or cereals',
        Usage4:'Can be used in baking',
        Weight: ' Weight',
        Weight1: '100  g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 53,
        title: "Laal Methi",
        price: 1600.0,
        img: require("./assets/Herbs & Spices/lal-methi-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Laal Methi (Red Fenugreek).  ',
        SKU: 'SKU',
        SKU1: 'GR056HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti dandruff, anti hair fall, blood sugar control, cholesterol control, hair nourishment, healthy digestion, herb, increase breast milk, laal methi, menstrual cramps remedy, pain relief, red fenugreek, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Reduces blood sugar level',
        Benefits2: 'Reduces menstrual cramps',
        Benefits3: 'Increases breast milk supply',
        Benefits4: 'Reduces pains such as nausea, fatigue, headaches',
        Benefits5: 'Prevents heart attacks',
        Benefits6: 'Reduces cholesterol',
        Benefits7: 'Aids digestion',
        Benefits8: 'Helps in weight loss',
        Benefits9: 'Treats hair fall and dandruff',
        Usage:'Usage',
        Usage1:'Can be chewed',
        Usage2:'Can be soaked in water overnight and consumed',
        Usage3:'Can be taken orally like a medicine',
        Usage4:'Can be grinded and later made into a paste to apply on the scalp and roots of the hair',
        Usage5:'Can be taken on an empty stomach',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },


    {
        id: 54,
        title: "Fungus Powder",
        price: 400.0,
        img: require("./assets/Herbs & Spices/fungus-powder-greenrootspk.com_.png"),
        quantity: 1,
        gram: '140 Grams',

        dis: 'Green Roots Fungus Powder.',
        SKU: 'SKU',
        SKU1: 'GR057HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti dandruff, anti fungal, fungus powder, hair nourishment, herb, scalp nourishment',
        Benefits: 'Benefits',
        Benefits1: 'Beneficial for dandruff and fungus in the hair.',
        Usage:'Usage',
        Usage1:'Take an amount enough to apply in the hair. Soak the powder in Apple Cider Vinegar at night. Apply it the next morning, half an hour before shower.',
        Ingredients:'Ingredients',
        Ingredients1:'Lakh dana (Shellac)',
        Ingredients2:'Mehndi (Henna)',
        Ingredients3:'Methi dana (Fenugreek)',
        Ingredients4:'Sanna Makki (Senna Makki)',
        Weight: ' Weight',
        Weight1: '150 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '140 grams',
    },

    {
        id: 55,
        title: "Multani Mitti",
        price: 120.0,
        img: require("./assets/Herbs & Spices/multani-mitti-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Multani Mitti â€“ Fullerâ€™s Earth.',
        SKU: 'SKU',
        SKU1: 'GR058HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti acne, anti inflammation, antiseptic, blood circulation, even skin tone, hair nourishment, herb, multani mitti, pigmentation, skin brightening, skin cleansing, skin glow, skin nourishment, sunburn soothing',
        Benefits: 'Benefits',
        Benefits1: 'Multani Mitti is known to fight acne and pimples.',
        Benefits2: 'Removes excess sebum and oil.',
        Benefits3: 'Tightens the skin',
        Benefits4: 'Deep cleanses skin removing dirt, sweat and impurities.',
        Benefits5: 'Evens out skin tone and brightens complexion.',
        Benefits6: 'Treats tanning and pigmentation.',
        Benefits7: 'Effective in treating sunburn, skin rashes and infections (multani mitti is an effective cooling agent)',
        Benefits8: 'Facilitates blood circulation, leading to radiant, glowing skin',
        Benefits9: 'Can be used in cold compresses to treat skin inflammation and insect bites',
        Benefits10: 'Fights and helps in keeping away blackheads/whiteheads, blemishes, freckles, pimple/acne spots',
        Benefits11: 'Has antiseptic properties',
        Benefits12: 'Cleansing properties for the hair',
        Benefits13: 'Its naturally absorbent properties allow it to cleanse your hair of oil while remaining gentle on your skin',
        Usage:'Usage',
        Usage1:'Can be made into a hair mask to clean and condition your hair.',
        Usage2:'Can be mixed with rose water and lemon juice and other herbs and ingredients to make a paste for the face, hair and body',
        Usage3:'Can be applied daily or twice a week',
        Usage4:'Can be used as a body scrub',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 56,
        title: "Unaab (Jujube)",
        price: 250.0,
        img: require("./assets/Herbs & Spices/unaab-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Unaab (Jujube).',
        SKU: 'SKU',
        SKU1: 'GR059HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: ' anti acne, anti ageing, anti anxiety, asthma remedy, blood pressure control, blood sugar control, cancer cure, cancer prevention, herb, immune booster, insomnia cure, nerve relaxant, skin glowing, skin nourishment, stress reliever, unaab, vitamin b1, vitamin b12, vitamin c, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Anti-cancer properties',
        Benefits2: 'Promotes Weight loss.',
        Benefits3: 'Calms Your Nervous System.',
        Benefits4: 'Strengthens Immune System.',
        Benefits5: 'Used for Anti-ageing.',
        Benefits6: 'Beneficial for Bones and Muscles.',
        Benefits7: 'Regulates Blood Pressure.',
        Benefits8: 'Treats Insomnia.',
        Benefits9: 'Reduces stress and anxiety',
        Benefits10: 'Protects the heart and brain',
        Benefits11: 'Offers protection against cancers',
        Benefits12: 'Beneficial for the skin',
        Benefits13: 'Rich in vitamin C',
        Benefits14: 'Helps fight acne',
        Benefits15: 'Makes the skin glowing and fresh',
        Benefits16: 'This fruit is a rich source of vitamin C, B1 and B2.',
        Benefits17: 'Lowers blood sugar levels',
        Benefits18: 'Helps control asthma',
        Usage:'Usage',
        Usage1:'Can be taken orally with water',
        Usage2:'Can be used to make green tea',
        Usage3:'Can be eaten as a fruit (raw form)',
        Usage4:'Can be added in milk and taken as a drink',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 57,
        title: "Triphala",
        price: 350.0,
        img: require("./assets/Herbs & Spices/triphala-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Triphala.',
        SKU: 'SKU',
        SKU1: 'GR060HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti bacterial, anti dandruff, anti inflammation, cancer prevention, cholesterol control, constipation cure, hair growth, healthy digestion, healthy teeth, herb, immunity booster, skin nourishment, triphala, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Anti inflammatory',
        Benefits2: 'Can prevent certain types of cancers',
        Benefits3: 'Helps treat constipation',
        Benefits4: 'Resolve dental problems',
        Benefits5: 'Promotes weight loss',
        Benefits6: 'Lowers cholestero',
        Benefits7: 'Stimulates hair growth',
        Benefits8: 'Treats dandruff',
        Benefits9: 'Prevents viral and bacterial infections',
        Benefits10: 'Rejuvenates skin',
        Benefits11: 'Aids digestion',
        Benefits12: 'Boosts immunity',
        Usage:'Usage',
        Usage1:'It is suggested to take triphala on an empty stomach between meals.',
        Usage2:'Powdered versions can be mixed with warm water and honey and taken before meals.',
        Usage3:'Recommended doses range from 500 mg to one gram per day, though larger amounts can be used to treat symptoms like constipation',
        Usage4:'Ideally, the best time to drink triphala water is around 4:00am to 5:00am in the morning',
        Usage5:'Triphala powder paste could be applied to face to clear pigmentation, acne causing bacteria and dry skin. (Gently warm 2 tablespoons of coconut oil until melted and add 2 tsp of Triphala powder to it. Let the mixture cool. Apply the paste evenly on your face and neck.)',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 58,
        title: "Indigo",
        price: 400.0,
        img: require("./assets/Herbs & Spices/indigooutofstock.png"),
        quantity: 1,
        gram: '100 Grams',
        Availability:'Availability',
        Availability1:' : Out of stock',

        dis: 'Green Roots Indigo.',
        SKU: 'SKU',
        SKU1: 'GR061HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'alopecia cure, anti dandruff, hair dye, hair growth, hair nourishment, hair strengthening, premature hair greying, scalp nourishment',
        Benefits: 'Benefits',
        Benefits1: 'It treats and prevents premature greying.',
        Benefits2: 'It can lead to new hair growth and treat baldness.',
        Benefits3: 'It treats dandruff and conditions dry hair.',
        Benefits4: 'It prevents scalp infections and soothes the scalp.',
        Benefits5: 'It smoothens the tangles, makes your hair thicker, more manageable and lustrous.',
        Benefits6: 'Acts like a natural hair dye',
        Benefits7: 'Nourishes the hair follicles',
        Benefits8: 'Enhances natural hair color',
        Usage:'Usage',
        Usage1:'Mix the Indigo Powder (100 grams for short hair, 200 grams for shoulder-length hair, 300 grams for long hair) in warm water to prepare a paste. Apply on your hair, using gloves if applying with your hands, or a spatula. Leave on for 45 minutes, or more, depending on preferred intensity.',
        Usage2:'Add a pinch of salt with the indigo powder paste to release the dye faster and better.',
        Usage3:'Always apply indigo hair dye on clean hair.',
        Usage4:'Using indigo only will give you a cool toned brown color. For a black color mix it with Henna',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },

    {
        id: 59,
        title: "Ispaghol Chilka",
        price: 800.0,
        img: require("./assets/Herbs & Spices/ispagol-chilka-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Green Roots Ispaghol Chilka (Psyllium Husk).',
        SKU: 'SKU',
        SKU1: 'GR062HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'blood sugar control, chilka, cholesterol control, constipation cure, diarrhea cure, healthy heart, herb, ispaghol, laxative, prebiotics, psyllium husk, satiety boost, weight loss',
        Benefits: 'Benefits',
        Benefits1: 'Psyllium relieves constipation. Psyllium is a bulk forming laxative.',
        Benefits2: 'It may help treat diarrhea.',
        Benefits3: 'It can lower blood sugar levels.',
        Benefits4: 'It may boost satiety and aid weight loss.',
        Benefits5: 'It can lower cholesterol levels.',
        Benefits6: 'It seems to be good for the heart.',
        Benefits7: 'It has prebiotic effects.',
        Usage:'Usage',
        Usage1:'Can consume Isabgol for healthy bowel movement.',
        Usage2:'Can be taken orally with water.',
        Usage3:'As a bulk laxative supplement, 5 g with a glass of water 3 times per day is a common starting point. People can increase this gradually if they find it tolerable. It depends on the product how many grams are in 1 teaspoon or tablespoon, but 1 tablespoon is a common recommendation as a serving for psyllium husk.',
        Usage4:'Using indigo only will give you a cool toned brown color. For a black color mix it with Henna',
        Usage5:'The best time to consume Isabgol for weight loss is on an empty stomach in the morning or at night before going to bed',
        Usage6:'Psyllium usually produces a bowel movement within 12 to 72 hours. Do not take psyllium for longer than 7 days in a row without a doctorâ€™s advice.',
        Usage7:'Mix isabgol in warm water and add a few drops of lemon in it. You can drink the mixture right before your meal. Also, you can drink this mixture first thing in the morning. By drinking it early in the morning helps in losing weight.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },


    {
        id: 60,
        title: "Desi Pudina (Mint)",
        price: 200.0,
        img: require("./assets/Herbs & Spices/desi-pudina-greenrootspk.com_.png"),
        quantity: 1,
        gram: '100 Grams',

        dis: 'Pudina also known as mentha is an energizing herb. Mint is used as mint oil, tooth pastes, mouthwashes and flavoring agents in many dishes. It works as a painkiller, cures multiple health problems, highly beneficial for digestion, works on the skin and respiratory disorders as well.',
        SKU: 'SKU',
        SKU1: 'GR007HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti allergy, anti depression, healthy breathing, healthy digestion, memory booster, mint, pudin, aweight loss',
        Benefits: 'Benefits',
        Benefits1: 'Helps treat Asthma',
        Benefits2: 'Keeps respiratory disorders low',
        Benefits3: 'Prevents allergies',
        Benefits4: 'Improves oral hygiene',
        Benefits5: 'Boost skin health',
        Benefits6: 'Helps in the process of digestion',
        Benefits7: 'Aids in weight loss',
        Benefits8: 'Strengthens the liver',
        Benefits9: 'Prevents memory loss',
        Benefits10: 'Prevents depression',
        Usage:'Usage',
        Usage1:'By consuming half teaspoon of Desi Pudina powder with water before or after taking meal helps you in digestion of food.',
        Usage2:'Take half teaspoon of Desi Pudina, add in water, take about 5 ml to 10 ml and mix with honey helps relieve in cough and sore throat.',
        Usage3:'Drinking a cup of Desi Pudina tea will help relieve stomach pain and bloating.',
        Usagenotice:'(These remedies may effect certain people having specific medical or physical conditions â€“ it is highly advisable to STOP USING ANY OF THESE REMEDIES IMMEDIATELY if it affects you in any ways and consult your personal physician)',
        Ingredients:'Ingredients',
        Ingredients1:'Podina',
        SpecialInstructions:'Special Instructions',
        SpecialInstructions1:'Keep the herb airtight after opening the seal (at all times). Refold package liner tightly after opening it.',
        SpecialInstructions2:'Keep it in vacuum-sealed jar in a dark, cool location for longer use.',
        Weight: ' Weight',
        Weight1: '110 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '100 grams',
    },



    {
        id: 61,
        title: "Shilajit",
        price: 1800.0,
        img: require("./assets/newgreen  Web Products 1500 x1200px-05/Shilajit.jpg"),
        quantity: 1,
        gram: '50  Grams',

        dis: 'ntroducing our newly launched Shilajit – Redefining the herbal standard for a balanced mind, body, and soul.',
        SKU: 'SKU',
        SKU1: 'GR007HS',
        Category: 'Herbs & Spices',
        Tags: 'Tags',
        Tags1: 'anti allergy, anti depression, healthy breathing, healthy digestion, memory booster, mint, pudin, aweight loss',
        Benefits: 'Benefits',
        Benefits1: 'Anti inflammatory ',
        Benefits2: 'Enhances memory ',
        Benefits3: 'Beneficial for asthma ',
        Benefits4: 'Boosts heart health ',
        Benefits5: 'Boosts liver health ',
        Benefits6: 'Hydrates skin ',
        Benefits7: 'Boosts energy',
        Weight: ' Weight',
        Weight1: '50 g',
        dicQuantity: 'Quantity',
        dicQuantity1: '50  grams',
    },
]